import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { saleInvoiceEP, mySaleDetailEP, mySalesEP, updateStateEP } from "endpoints";
export const mySalesSlice = createSlice({
  name: "mySales",
  initialState: {
    files: [],
    updatingStatus: {},
    saleDetail: {
      status: "",
      shippingMethod:"",
      date: "",
      status_description: "",
      cart_id: "",
      payment: {
        amount:0,
        changeStatusDate:"",
        date:"",
        status:"",
        status_description:"",
        whipay_fee:0,
      },
      billToData:{},
      products: [],
      invoices: [],
    },
    allSales: [],
    loadingSales: false,
    loadingDetail: false,
  },
  reducers: {
    setFiles: (state, action) => {
      if (typeof action.payload.uploaded != "undefined") {
        state.files[action.payload.uploaded] = action.payload;
      } else {
        state.files = [...state.files, action.payload];
      }
    },
    setProgress: (state, action) => {
      state.files[action.payload.id].uploading = action.payload.per;
    },
    setDelete: (state, action) => {
      state.files[action.payload].deleting = true;
    },
    removeFile: (state, action) => {
      state.files.splice(action.payload, 1);
    },
    setFilesDirect: (state, action) => {
      state.files = action.payload;
    },
    updateFile: (state, action) => {
      state.files = action.payload;
    },
    updateMessage: (state, action) => {
      state.updatingStatus = action.payload;
    },
    setSaleDetail: (state, action) => {
      state.saleDetail = action.payload;
    },
    setAllSales: (state, action) => {
      state.allSales = action.payload;
    },
    setLoadingSales: (state, action) => {
      state.loadingSales = action.payload;
    },
    setLoadingDetails: (state, action) => {
      state.loadingDetail = action.payload;
    },
    setIsPicked: (state, action) => {

      const { id, value, sales } = action.payload;
      state.allSales = sales.map((el, index) =>
        index === id
          ? {
              ...el,
              readyToPickup: value,
            }
          : el
      );
    },
    setIsDelivered: (state, action) => {
      const { id, value, sales } = action.payload;
      state.allSales = sales.map((el, index) =>
        index === id
          ? {
              ...el,
              isDelivered: value,
            }
          : el
      );
    },
  },
});
export const {
  setFiles,
  setProgress,
  removeFile,
  setFilesDirect,
  setDelete,
  updateFile,
  updateMessage,
  setSaleDetail,
  setAllSales,
  setLoadingSales,
  setLoadingDetails,
  setIsPicked,
  setIsDelivered,
} = mySalesSlice.actions;

export const files = (state) => state.mySales.files;
export const message = (state) => state.mySales.updatingStatus;
export const saleDetail = (state) => state.mySales.saleDetail;
export const getAllSales = (state) => state.mySales.allSales;
export const loadingSales = (state) => state.mySales.loadingSales;
export const loadingDetail = (state) => state.mySales.loadingDetail;

export const allSales = (action) => async (dispatch) => {
  dispatch(setLoadingSales(true));
  const data = await axios.get(mySalesEP).then((response) => {
    return response.data;
  });
  dispatch(setLoadingSales(false));
  dispatch(setAllSales(data));
};

export const getDetail = (action) => async (dispatch) => {
  dispatch(setLoadingDetails(true));
  const data = await axios
    .post(mySaleDetailEP, {
      sale_slug: action,
    })
    .then((response) => {
      return response.data;
    });
  dispatch(setSaleDetail(data));
  dispatch(setLoadingDetails(false));
};
export const updateFiles = (action, files) => async (dispatch) => {
  const count = files.length;
  const temp = {
    id: "",
    url: "Uploading...",
    uploading: 0,
  };
  dispatch(setFiles(temp));
  const data = await axios
    .post(saleInvoiceEP, action, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: function (data) {
        const percentCompleted = Math.round((data.loaded * 100) / data.total);
        const _data = {
          per: percentCompleted,
          id: count,
        };
        dispatch(setProgress(_data));
      },
    })
    .then((response) => {
      return response.data;
    });

  if (typeof data.id != "undefined") {
    const uploaded = {
      id: data.id,
      url: data.url,
      //uploaded: 1,
      uploaded: count,
    };
    dispatch(setFiles(uploaded));
  } else {
    dispatch(removeFile(count));
  }
};

export const getAllFiles = (action) => async (dispatch) => {
  const data = await axios.put(saleInvoiceEP, action).then((response) => {
    return response.data;
  });
  dispatch(setFilesDirect(data));
};

export const deleteFile = (action) => async (dispatch) => {
  dispatch(setDelete(action.key));
  let formData = new FormData();
  formData.append("id", action.data.id);
  await axios.delete(saleInvoiceEP, { data: formData }).then((response) => {
    return response.data;
  });
  dispatch(removeFile(action.key));
};

export const updateIsPickedUp = (action) => async (dispatch) => {

  await axios.patch(updateStateEP, action.updateEP).then((response) => {
    return response;
  });
  dispatch(setIsPicked(action.updateState));
};

export const updateIsDelivered = (action) => async (dispatch) => {
  await axios.patch(updateStateEP, action.updateEP).then((response) => {
    return response;
  });
  dispatch(setIsDelivered(action.updateState));
};
export const updateInvoice = (action, files) => async (dispatch) => {
  const data = await axios.patch(saleInvoiceEP, action).then((response) => {
    return response;
  });
  const updateInvoice = files.map((file) =>
    file.id === data.data.id ? { ...file, url: data.data.url } : file
  );
  const status =
    data.status === 202 ? "Se actualizo tu documento" : "No se actualizo";
  const message = {
    id: data.data.id,
    status: status,
  };
  dispatch(updateMessage(message));
  dispatch(updateFile(updateInvoice));
};

export default mySalesSlice.reducer;
