import { createSlice } from '@reduxjs/toolkit';

export const headerSlice = createSlice({
    name: 'header',
    initialState: {
        actionPop: false,
        miniCart: false,
        notification: false,
        wallet: false,
        searchBox: false
    },
    reducers: {
        _actionPopAction: ( state, action ) => {
            state.miniCart = false;
            state.notification = false;
            state.actionPop = action.payload;
        },
        _minCartAction: ( state, action ) => {
            state.actionPop = false;
            state.notification = false;
            state.miniCart = action.payload;
        },
        _notificationAction: ( state, action ) => {
            state.actionPop = false;
            state.miniCart = false;
            state.notification = action.payload;
        },
        _walletAction: ( state, action ) => {
            state.wallet = action.payload;
        },
        _searchBoxAction: ( state, action ) => {
            state.searchBox = action.payload;
        }
    },
});

export const { _actionPopAction, _minCartAction, _notificationAction, _walletAction, _searchBoxAction } = headerSlice.actions;

export const actionPop = state => state.header.actionPop;
export const miniCart = state => state.header.miniCart;
export const notification = state => state.header.notification;
export const wallet = state => state.header.wallet;
export const searchBox = state => state.header.searchBox;

export default headerSlice.reducer;