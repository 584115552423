import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CardProduct from "../list/card";

export default function ProductSlider(props) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <Carousel
        style={{ width: "100%" }}
        responsive={responsive}
        infinite={true}
        showDots={false}
        dotListClass="dashboard-offer-dot-style"
        swipeable={false}
        draggable={false}
      >
        {props._product.map((product) => (
          <CardProduct data={product} key={product.id}/>
        ))}
      </Carousel>
    </>
  );
}
