import { makeStyles } from "@material-ui/core/styles";
export const orderDetailStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    margin:"auto",
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      display: "flow-root",
    },

  },
  pageTitle: {
    width:"100%",
    margin:"auto",
    color: "#53CD94",
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "38px",
    alignItems: "baseline",
    flexGrow: 1,

  },
  paper: {
    // display: "flex",
    // flexDirection: "column",
    padding: "55px",
    borderRadius: "12px",
    fontSize: "25px",
    boxShadow: "none",
    width: "100%",
    marginBottom: "30px",
    overflow: "auto",
    color: "#616060",
    [theme.breakpoints.down("sm")]: {
      padding: "25px",
    },
  },
  statusTitle: {
    fontSize: "20px",
    lineHeight: "20px",
    fontWeight: "normal",
  },
  column: {
    display :"flex",
    flexDirection: "column",
  },
  subtitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18.96px",
  },
  secondaryButton: {
    border: "1px solid #53cd94",
    fontSize: "12px",
    padding: "0.3rem",
    textTransform: "none",
    marginBottom:"1rem"
  },
  summaryContainer: {
    border: "1px solid #e0e0e0",
    margin: "1rem 0",
    padding: "0.5rem 1rem",
  },
  summaryRow: {
    "& h3": {
      color: "#000000",
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: "400",
      margin: "0.5rem 0",
    },
    "& span": {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "19px",
    },
  },
  hiperLink: {
    textDecoration: "none",
    color: "#53cd94",
    fontWeight:400,
    fontSize:"14px",
    lineHeight:"16.59px"
  },
  order__perSeller:{
    borderBottom:"1px solid #e0e0e0",
    padding:"1.5rem 0",
  },
  order__items:{
    flexDirection:"row",
    [theme.breakpoints.down("xs")]: {
      flexDirection:"column",
      alignItems:"center"
    },
  },
  order__item:{
    width:"20%",
    alignSelf:"center",
    [theme.breakpoints.down("xs")]: {
      width:"100%",
    },
  },
  order__itemPrice:{
    "& span": {
      // color:"#333333",
      fontWeight: 600,
    },
  },
    invoice: {
    fontSize: "12px",
    lineHeight: "14.22px",
    fontWeight: "400",
    color: "#808285",
    // opacity: "0.5",
    fontStyle:"italic"
  },
  // detailTitles: {
  //   justifyContent: "space-between",
  //   background: "#f1f2f2",
  //   padding: "0.5rem",
  //   "& span": {
  //     fontWeight: "400",
  //     fontSize: "16px",
  //     lineHeight: "18.96px",
  //   },
  // },
  // detailTitle: {
  //   fontWeight: "400",
  //   fontSize: "16px",
  //   lineHeight: "18.96px",
  // },
  // detailContainer: {
  //   padding: "1rem 0.5rem",
  // },
  // detailRow: {
  //   // flexDirection: "row",
  //   // padding: "0.5rem 0",
  //   borderBottom: "1px solid #f1f2f2",

  //   // [theme.breakpoints.down("xs")]: {
  //   //   flexDirection: "column",
  //   // },
  // },
  // seller: {
  //   color: "#53cd94",
  //   fontSize: "14px",
  //   lineHeight: "16.59px",
  //   padding:" 0 0.5rem 0 0 "
  // },
  // invoiceRow: {
  //   alignItems: "flex-end",
  // },
  // inProcessColor: {
  //   color: "#F2994A",
  // },
  // notDisplay: {
  //   [theme.breakpoints.down("sm")]: {
  //     display: "none",
  //   },
  // },
  // detailItem: {
  //   fontWeight: "400",
  //   fontSize: "14px",
  //   lineHeight: "16.59px",
  // },
  // invoice: {
  //   fontSize: "12px",
  //   lineHeight: "14.22px",
  //   fontWeight: "400",
  //   color: "#808285",
  //   opacity: "0.5",
  // },

  // sumContainer: {
  //   textAlign: "end",
  //   "& > span:nth-child(1)": {
  //     padding: "0 2rem 0 0.5rem",
  //   },
  //   [theme.breakpoints.down("xs")]: {
  //     textAlign: "start",
  //     display: "flex",
  //     flexDirection: "row",
  //     justifyContent: "space-between",
  //   },

  //   "& span": {
  //     fontWeight: "400",
  //     fontSize: "14px",
  //     lineHeight: "16.59px",
  //     color: "#000",
  //   },
  // },
  // downloadBox:{
  //   display:"flex",
  //   alignItems:"center",
  //   margin:"1rem auto",
  //   "& a": {
  //     fontWeight: "400",
  //     fontSize: "14px",
  //     lineHeight: "16.59px",
  //     color: "#616060",
  //     textDecoration:"none"
  //   },
  // },
  // icon:{
  //   color:"#53cd94"
  // }
}));
