/*-- Libraries --*/
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

/*-- redux --*/
import { getBannerImages } from "features/product/list/Slice";

/*-- styles,components,functions --*/
import { AppShopStyles } from "./styles";
import AppProductList from 'features/product/list';
import LoadingData from "features/loader";

export default function AppShop() {

    const classes = AppShopStyles()
    const [banners, setbanners] = useState([]);
    const [isLoadingBanners, setIsLoadingBanners] = useState(false);

    useEffect(() => {
        setIsLoadingBanners(true);
        getBannerImages((response) => {
            setbanners(response);
            setIsLoadingBanners(false);
        });

    }, []);

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <>
            <Carousel className={classes.banner} responsive={responsive} infinite={true} showDots={true} dotListClass="dashboard-offer-dot-style" swipeable={false}
                draggable={false}>
                { isLoadingBanners ? <LoadingData/> : banners.map((banner, index) => (
                    <Link to={banner.path} key={index} >
                        <div className={classes.banner__item}>
                            <img src={banner.desktop_url} alt='banner' width="100%" className={classes.banner__img_hideDesktop} />
                            <img src={banner.mobile_url} alt='banner' width="100%" className={classes.banner__img_hideMobile} />
                            {/* <Button variant="contained" component={Link} to={banner.path}>Regístrate</Button> */}
                        </div>
                    </Link>

                ))}
            </Carousel>
            <AppProductList />
        </>
    );

}
