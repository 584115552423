import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { configProfileEP, 
  configDataProfileEP,
  checkUserProfileEP,
  userAccountProfEP,
  request_bankProfEP,
  notifConfigEP
} from "endpoints";
// import { updateVoucher } from "pages/MyOrders/slices";
export const configurationSlice = createSlice({
  name: "configuration",
  initialState: {
    background:"",
    profile:"",
    address:"",
    presentation:""
  },
  reducers: {
    setBackground: (state, action) => {
      state.background = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setAddress: (state,action) => {
      state.address = action.payload;
    },
    setPresentation: (state,action) => {
      state.presentation = action.payload;
    },
  },
});

export const { setBackground, setProfile, setAddress, setPresentation } = configurationSlice.actions;
export const background = (state) => state.configuration.background;
export const profile = (state) => state.configuration.profile;
export const address = (state) => state.configuration.address;
export const presentation = (state) => state.configuration.presentation;

export const updateBackground= (action) => (dispatch)=> {
    dispatch(setBackground(action));
};

export const updateProfile = (action) => (dispatch) => {
    dispatch(setProfile(action));
};

export const updateDescription= (action) => (dispatch) => {
    dispatch(setPresentation(action));
};

export const updateAddress= (action) => (dispatch) => {
    dispatch((setAddress(action)));
};

export const getConfigProfile = (success) => {
    axios.get(configProfileEP).then((response) => {
        success(response.data);
    })
};

export const getContactData = (success) => {
  axios.get(configDataProfileEP).then((response) => {
    success(response.data);
  });
};

export const updateContactData =  (action, callback) => {
   axios.patch(configDataProfileEP,action).then((response) => {
    callback(response.data);
  });
};

export const updateConfigProfile = (action, callback) => {
    axios.patch(configProfileEP,action).then((response) => {
        callback(response.data);
    });
};

export const checkedUser = (action, callback) => {
  axios.post(checkUserProfileEP,action).then((response) => {
    callback(response.data.detail);
  });
};

export const updateUser = (action, callback) => {
  axios.patch( userAccountProfEP,action).then((response) => {
    callback(response.data);
  });
};

export const requestBankData = (action, callback) => {
  axios.post(request_bankProfEP,action).then((response) => {
    callback(response.data);
  });
};

export const getNotifConfig = (callback) => {
  axios.get(notifConfigEP).then((response) => {
    callback(response.data);
  })
};

export const updateNotifConfig = (action) => {
  axios.post(notifConfigEP, action).then((response) => {
    return response.data;
  });
};

export default configurationSlice.reducer;
