import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import IconButton from "@material-ui/core/IconButton";
import React, { useEffect } from "react";
import { notificationPopupStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "../header/Slice";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Typography from "@material-ui/core/Typography";
import { CircularProgress, withStyles } from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { Popover } from "@material-ui/core";
import { isloading, list, unseen, getNotifications, markAsSeen } from "./Slice";
import { useHistory } from "react-router-dom";
import { isLogin } from "features/Auth/authSlice";
import TimeAgo from "javascript-time-ago";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import es from "javascript-time-ago/locale/es-MX";

TimeAgo.addLocale(es);

const StyledBadge = withStyles({
  badge: {
    background: "#F2C94C",
    color: "#FFF",
  },
})(Badge);

export default function NotificationPopup(props) {

  const timeAgo = new TimeAgo("es-MX");
  const classes = notificationPopupStyles();

  const dispatch = useDispatch();

  const anchorRef = React.useRef("notification");
  const _notification = useSelector(notification);
  const _isloading = useSelector(isloading);
  const _list = useSelector(list);
  const _unseen = useSelector(unseen);
  let history = useHistory();

  const handleClick = (idNoti, sale_slug, nPath) => {
    dispatch(
      markAsSeen(idNoti, (success) => {
        if (success) {
          if (nPath === 3) {
            history.push(`/mis-ventas/detail/${sale_slug}`);
          } else {
            history.push(`/mercado/${sale_slug}`);
          }
        }
      })
    );
  };
  const _isLogin = useSelector(isLogin);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
  };

  useEffect(() => {
    let intervalId;
    if (_isLogin) {
      dispatch(getNotifications());

      intervalId = setInterval(function () {
        dispatch(getNotifications());
      }, 1000 * 60);
    }
    return function cleanup() {
      clearInterval(intervalId);
    };
  }, [dispatch, _isLogin]);

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <>
            <IconButton
              color="inherit"
              style={{
                height: "36px",
                width: "36px",
                marginLeft: "20px",
                background: "#DDF5EA",
                color: "#006D51",
              }}
              variant="contained"
              ref={anchorRef}
              aria-controls={_notification ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              {...bindTrigger(popupState)}
            >
              <StyledBadge badgeContent={_unseen}>
                <NotificationsIcon />
              </StyledBadge>
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              style={{ borderRadius: "12px" }}
            >
              <ClickAwayListener onClickAway={(e) => handleClose(e)}>
                <Paper className={classes.notContainer}>
                  {_isloading ? (
                    <CircularProgress
                      style={{
                        height: "40px",
                        width: "40px",
                        color: "#4BB985",
                        marginLeft: "calc( 50% - 20px )",
                      }}
                    />
                  ) : _list.length ? (
                    <>
                      <Typography gutterBottom className={classes.heading}>
                        Notificaciones
                      </Typography>
                      {_list.map((noti, index) => (
                        <div
                          className={classes.notificationWrap}
                          key={index}
                          onMouseDownCapture={popupState.close}
                          onClick={() =>
                            handleClick(noti.id, noti.sale_slug, noti.nPath)
                          }
                        >
                          <div className={classes.notiTitle}>
                            <span>{noti.title}</span>
                          </div>
                          <div className={classes.notiDate}>
                            <span>
                              {timeAgo.format(Date.parse(noti.date))}{" "}
                            </span>
                          </div>
                          <div className={classes.notiIcon}>
                            <span>
                              {noti.seen ? (
                                ""
                              ) : (
                                <FiberManualRecordIcon
                                  style={{
                                    color: "#4BB985ed",
                                    fontSize: "14px",
                                  }}
                                />
                              )}
                            </span>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <Typography
                      variant="h4"
                      component="h5"
                      gutterBottom
                      className={classes.emptyNotofication}
                    >
                      No hay notificaciones
                    </Typography>
                  )}
                </Paper>
              </ClickAwayListener>
            </Popover>
          </>
        )}
      </PopupState>
    </>
  );
}
