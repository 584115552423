import { makeStyles } from "@material-ui/core/styles";

export const mySaleDetailStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      display: "flow-root",
    },
  },
  pageTitle: {
    color: "#53CD94",
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "38px",
    alignItems: "baseline",
    flexGrow: 1,
  },
  miniPaper: {
    padding: "14px 25px",
    borderRadius: "10px",
    color: "#53CD94",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "11.85px",
    boxShadow: "none",
    minHeight: "150px",
  },
  popover: {
    pointerEvents: "none",
  },
  popoverPaper: {
    padding: theme.spacing(1),
    width: "300px",
    height:"35px",
    color: "#808285",
    display:"flex",
    justifyContent:"center",
  },
  popoverText: {
    fontSize: "10px",
    lineHeight: "11.85px",
  },
  warningIcon: {
    color: "#f2994a",
  },
  containerStatus: {
    display: "flex",
    alignItems: "baseline",
    marginTop:"20px",
    marginBottom:"30px",
  },
  containerStatus2: {
    display: "flex",
    alignItems: "baseline",

  },

  title3: {
    color: "#000000",
    fontWeight: "normal",
    fontSize: "24px",
    marginBottom: "20px",
    marginRight: "1rem",
    marginTop: "1rem",
  },

  dateTitle: {
    color: "#616060",
    fontSize: "14px",
  },
  dateTitle1: {
    color: "#616060",
    fontSize: "14px",
  },
  title: {
    maxWidth: "100px",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  imageProduct: {
    width: "90px",
    minWidth: "40px",
    maxWidth: "100%",
  },

  product__textBold: {
    fontSize: "16px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      textAlign:"center",
    },
  },
  product__textBold1:{
    fontSize: "12px",
    color:"#808285",
  },
  product__textBold2: {
    fontSize: "16px",
    marginBottom: "20px",
    textAlign:"center",
    margin:"0 0 0 15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      textAlign:"center",
      margin:"0 0 0 45px",
    },
    [theme.breakpoints.up("lg")]: {
      width:"55%",
      textAlign:"end",
    },

  },
  product__textBold3: {
    fontSize: "16px",
    marginBottom: "20px",
    textAlign:"center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      textAlign:"center",
      margin:"0 0 0 35px",
    },

  },
  detailStatus: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #e0e0e0",
    padding: "1.5rem 0",
    "& p": {
      color: "#616060",
      fontSize: "14px",
      "& span":{
        color: "#616060",
        fontSize: "14px",
      },
    },
  },
  detailTotal:{
    borderTop: "1px solid #e0e0e0",
    display: "flex",
    justifyContent: "space-between",
    marginTop:"30px",
    "& p": {
      marginTop:"25px",
      color: "#616060",
      fontSize: "14px",
      fontWeight:"bolder",
    },

  },
  box: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop:"20px",
    marginBottom:"20px",
    justifyContent: "space-between",
    "& p": {
      fontSize: "14px",
      color: "#616060",
      "& span" :{
        fontSize: "10px",
        color: "#808285",
      },
    },
  },
  textBox: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    color: "#808285",
    textAlign: "start",
    marginTop: "1rem",
  },
  uploadFilesWrap: {
    width: "80%",
    margin: "10px auto",
    textAlign: "center",
    display: "flex",
    border: "1px dashed #E0E0E0",
    padding: "15px",
    marginTop: "24px",
    boxSizing: "border-box",
    borderRadius: "4px",
    "& p": {
      padding: "1rem",
    },
  },
  uploadFilesButtonWrap: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  alignColumn: {
    display: "flex",
    flexDirection: "column",
  },
  textInvoice: {
    fontSize: "14px",
    color: "#616060",
    minWidth: "80px",
  },
  icons: {
    color: "#53cd94",
    marginRight: "0.5rem",
    "&:hover": {
      color: "rgb(224, 224, 224)",
      boxShadow: "none",
    },
  },
  row: {
    "& p": {
      fontSize: "14px",
      color: "#616060",
    },
  },
  box2:{
    display:"flex",
    marginLeft:"auto",
    backgroundColor: "#F7F7F7",
    width:"55%",
    padding: "0.5rem ",
    marginTop: "0.5rem",
    marginBottom:"20px",
    justifyContent:"space-around",
    "& p": {
      color: "#333333",
      fontSize: "14px",
      fontWeight:"bolder",
    },
    [theme.breakpoints.up("lg")]: {
      width:"55%",
    },
  },
  box3:{
    display:"flex",
    marginLeft:"auto",
    backgroundColor: "#F7F7F7",
    width:"55%",
    padding: "0.5rem ",
    marginTop: "0.5rem",
    marginBottom:"20px",
    justifyContent:"space-between",
    "& p": {
      marginLeft:"30px",
      marginRight:"30px",
      color: "#616060",
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      width:"50%",
      backgroundColor: "#FFFFFF",
    },
  },
  billToContainer: {
    backgroundColor: "#F7F7F7",
    padding: "0.5rem ",
    marginTop: "0.5rem",
    "& span": {
      color: "#808285",
      fontSize: "14px",
    },
    "& p": {
      color: "#808285",
      fontSize: "14px",
    },
  },
  filesList: {
    listStyle: "none",
    margin: "0 auto",
    minWidth: "342px",
    padding: "0px",
    "& li": {
      border: "1px solid #53CD94",
      padding: "15px",
      borderRadius: "4px",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "14.22px",
      color: "#333",
      display: "flex",
      alignItems: "center",
      marginBottom: "15px",
      "& img": {
        marginRight: "20px",
      },
      "& span": {
        flexGrow: 1,
        margin: "0 0.5rem",
      },
      "& svg": {
        color: "#53CD94",
        cursor: "pointer",
      },
    },
  },
  invoiceBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
    border: "1px solid #53CD94",
    padding: "15px",
    borderRadius: "4px",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14.22px",
    color: "#333",
    marginBottom: "15px",
  },
  modal: {
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    padding:"1rem"
  },
  modal__icon: {
    color: "#F2994A",
    fontSize: "60px",
  },
  modal__box: {
    margin:"1rem",
  },
  modal__alert: {
    fontSize: "20px",
    color: "#808285",
  },
  modal__message: {
    fontSize: "18px",
    color: "#808285",
    opacity:"0.5"
  },
  modal__button: {
    fontSize: "12px",
    color:"#ffffff",
    fontWeight: 500,
    lineHeight: "14px",
    height: "32px",
    width: "189px",
    background: "#4BB985",
    boxShadow: "none",
    borderRadius: "8px",
    "&:hover": {
      background: "#e0e0e0",
      boxShadow: "none",
    },
  },
  modal__listItem:{
    '&:hover': {
      background: "rgba(128, 130, 133, 0.3)",
      cursor: "pointer"
    }
  }
}));
