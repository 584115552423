import { makeStyles } from '@material-ui/core/styles';

export const minCartStyles = makeStyles((theme) => ({
    topBar: {
        display: "flex",
        width: '100%',
        alignItems: 'center'
    },
    miniCartBox:{
        width: "360px",
        padding: "25px",
        borderRadius: "12px",
        maxHeight:"330px",
        overflowY:"scroll",
        [theme.breakpoints.down('sm')]: {
            width: "250px",
            maxHeight:"530px",
            overflowY:"scroll",
       
          }
    },
    icon: {
        height: '24px',
        width: '24px',
        color: '#006D51',
    },
    topBarText:{
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16.59px',
        alignItems: 'center',
        color: '#000',
        flexGrow: 1,
        textAlign: "right",
        '& > span':{
            color: '#4BB985',
            marginLeft: '10px'
        }
    },
    orderTable: {
        width: '100%',
        textAlign: 'left',
        '& tr': {
            '& th': {
                borderBottom: '1px solid #E6E7E8',
                padding: '10px 0px'
            },
            '& td': {
                padding: '10px 0px'
            },
            '&:last-child': {
                '& th': {
                    borderBottom: '0px'
                },
                '& td': {
                    borderBottom: '0px'
                }
            }
        }
    },
    productImage: {
        width: '60px',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: "30px",
            // maxHeight:"530px",
          }
    },
    productTitle:{
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16.59px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            // maxHeight:"530px",
          }
    },
    productGrade: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16.59px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            // maxHeight:"530px",
          }
    },
    deleteIcon: {
        cursor: "pointer",
        color: "#53CD94",
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            // maxHeight:"530px",
          }
      },
    checkcoutButton: {
        width: '100%',
        height: '48px',
        boxShadow: 'none',
        borderRadius: '8px',
        background: '#53CD94',
        '&:hover': {
            background: '#006D51',
        }
    },
    emptyCart: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16.59px',
        textAlign: 'center',
        marginTop: '25px',
        marginBottom: '15px'
    }
}));