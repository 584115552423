import { makeStyles } from '@material-ui/core/styles';

export const filterStyles = makeStyles((theme) => ({
  container1: {
    width: '100%',
    margin:"2rem 0rem",
    padding: theme.spacing( 3 ),
    paddingTop: '13px',
    paddingBottom: '11px',
    background: '#FFF',
    borderRadius: '12px',
    display: "flex",
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
        padding: '20px 10px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flow-root'
    }
  },
  button: {
    color: '#808285',
    textTransform: 'inherit',
    background: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
    fontSize: '18px',
    borderBottom: '3px solid #FFF',
    padding: '15px',
    '&:hover': {
      background: 'transparent',
      boxShadow: 'none',
      borderColor: '#4BB985',
      color: '#53CD94'
    }
  },
  buttonActive: {
    borderColor: '#4BB985',
    color: '#53CD94'
  },
  filterTitle: {
    fontSize: '12px',
    color: '#808285',
    marginRight: '24px',
    marginLeft: '24px',
    lineHeight: '36px',
    [theme.breakpoints.down('lg')]: {
        marginRight: '14px',
        marginLeft: '14px',
        lineHeight: '20px',
    },
  },
  dropDown: {
    minWidth:"180px",
    marginRight: '20px',
    marginTop: '5px',
    marginBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100% !important',
      // marginTop: '20px',
      '& > div': {
        width: '95% !important'
      }
    }
  },
  chip: {
    border: '2px solid #DDF5EA',
    borderRadius: '100px',
    fontSize: '13px',
    height: 'auto',
    margin: 0,
    padding: '3px 15px',
    color: '#808285',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    background: '#DDF5EA',
    marginRight: '20px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      // marginTop: '20px',
      width: '100% !important'
    }
  },
  iconBox: {
    height: '20px',
    width: '20px',
    background: '#53CD94',
    marginRight: '15px',
    borderRadius: '4px',
  },
  iconBoxDown: {
    background: '#F2C94C',
  },
  icon: {
    transform: 'rotate(-45deg)',
    height: '20px',
    width: '20px',
    color: '#FFF',
    fontWeight: 'bold'
  },
  iconDown: {
    transform: 'rotate(45deg)',
  },
  filterIcon: {
    border: "1px solid #E0E0E0",
    borderRadius: '4px',
    height: '36px',
    width: '36px',
    padding: '4px'
  },
  firstBlock: {
    display: 'flex',
    alignItems:'center',
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  hideFilter: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  showFilter: {
    [theme.breakpoints.down('md')]: {
      display: 'flex !important',
      marginBottom: '5px',
      marginTop: '5px'
    }
  }
}));
