/*-- Libraries --*/
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {ListItem, ListItemIcon, ListItemText, ListSubheader } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import ShoppingBasketOutlinedIcon from "@material-ui/icons/ShoppingBasketOutlined";
import ListAltIcon from "@material-ui/icons/ListAlt";
import StoreIcon from "@material-ui/icons/Store";
// import Collapse from '@material-ui/core/Collapse';
// import List from '@material-ui/core/List';
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';
// import StarBorder from '@material-ui/icons/StarBorder';

/*-- redux --*/
import { setUserData } from "features/Auth/authSlice";
import { getLogOutData } from "./Slice";
import { toggleSidebar } from "features/layout/layoutSlice";

/*-- styles,components,functions --*/
import setAuthToken from "features/API/index";


const useStyles = makeStyles((theme) => ({
  list: {

    borderLeft: "5px solid #FFF",
    "&:hover": {
      borderLeft: "5px solid #e0e0e0",
      color:"#333",
    },
  },
  heading: {
    "&:hover": {
      background: "#FFF",
    },
  },
  nested:{
    paddingLeft:"2rem"
  },
}));

export default function MainListItems() {
  const [isSeller, setIsSeller] = useState(false);
//   const [open, setOpen] = useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const loginUser = useSelector((state) => state.auth.client);

  useEffect(() => {
    if (loginUser) {
      if (
        // loginUser.user_category === "Normal" &&
        loginUser.can_publish === true
      ) {
        setIsSeller(true);
      }
    }
  }, [loginUser]);
  // TO DO: CUSTOM HOOKS
  const logoutCall = () => {
    dispatch(getLogOutData()); //  Call endpoint to delete token
    dispatch(setUserData({ token: "" }));
    setAuthToken(false); //  Delete axios header
    localStorage.clear();
    history.push("/");
  };

  return (
    <div>
      <ListItem
            button
            onClick={() => dispatch(toggleSidebar())}
            component={NavLink}
            className={classes.list}
            activeStyle={{
              borderLeft: "5px solid #53CD94",
              backgroundColor: "#ddf5ea",
            }}
            to={"/mercado"}
      >
        <ListItemIcon>
          <ShoppingBasketOutlinedIcon style={{ color: "#006D51" }} />
        </ListItemIcon>
        <ListItemText primary="Ir al mercado" />
        {/* {open ? <ExpandLess /> : <ExpandMore />} */}
      </ListItem>
      {/* <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
          button
          sx={{ pl: 4 }}
          onClick={() => dispatch(toggleSidebar())}
          component={NavLink}
          to="/mercado_mayorista/frutas"
          className={classes.nested}
          activeStyle={{
          borderLeft: "5px solid #53CD94",
          backgroundColor: "#ddf5ea",
        }}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Frutas" />
          </ListItem>
        </List>
      </Collapse> */}
      <ListItem
        button
        onClick={() => dispatch(toggleSidebar())}
        component={NavLink}
        className={classes.list}
        activeStyle={{
          borderLeft: "5px solid #53CD94",
          backgroundColor: "#ddf5ea",
        }}
        to="/dashboard"
      >
        <ListItemIcon>
          <HomeIcon style={{ color: "#006D51" }} />
        </ListItemIcon>
        <ListItemText primary="Análisis de precios" />
      </ListItem>
      <ListItem
            button
            onClick={() => dispatch(toggleSidebar())}
            component={NavLink}
            activeStyle={{
              borderLeft: "5px solid #53CD94",
              backgroundColor: "#ddf5ea",
            }}
            className={classes.list}
            to={"/mis-ordenes"}
          >
            <ListItemIcon>
              <ListAltIcon style={{ color: "#006D51" }} />
            </ListItemIcon>
            <ListItemText primary="Mis compras" />
          </ListItem>
      {/* <ListItem
        button
        component={NavLink}
        className={classes.list}
        activeStyle={{
          borderLeft: "5px solid #53CD94",
          backgroundColor: "#ddf5ea",
        }}
        to="/"
      >
        <ListItemIcon>
          <HomeIcon style={{ color: "#006D51" }} />
        </ListItemIcon>
        <ListItemText primary="Mis negociaciones" />
      </ListItem> */}
      {isSeller ? (
        <>
          <ListItem
            button
            onClick={() => dispatch(toggleSidebar())}
            component={NavLink}
            className={classes.list}
            activeStyle={{
              borderLeft: "5px solid #53CD94",
              backgroundColor: "#ddf5ea",
            }}
            to={"/ofertas"}
          >
            <ListItemIcon>
              <AttachMoneyOutlinedIcon style={{ color: "#006D51" }} />
            </ListItemIcon>
            <ListItemText primary="Mis publicaciones" />
          </ListItem>
          <ListItem
            onClick={() => dispatch(toggleSidebar())}
            button
            component={NavLink}
            activeStyle={{
              borderLeft: "5px solid #53CD94",
              backgroundColor: "#ddf5ea",
            }}
            className={classes.list}
            to={"/mis-ventas"}
          >
            <ListItemIcon>
              <StoreIcon style={{ color: "#006D51" }} />
            </ListItemIcon>
            <ListItemText primary="Mis ventas" />
          </ListItem>
        </>
      ) : null}
      {/* <ListItem
        button
        component={Link}
        activeStyle={{
          borderLeft: "5px solid #53CD94",
          backgroundColor: "#ddf5ea",
        }}
        className={classes.list}
        to="/404"
      >
        <ListItemIcon>
          <AddCommentIcon style={{ color: "#006D51" }} />
        </ListItemIcon>
        <ListItemText primary="Comentarios" />
      </ListItem> */}
      <ListItem
        button
        className={classes.list}
        activeStyle={{
          borderLeft: "5px solid #53CD94",
          backgroundColor: "#ddf5ea",
        }}
        onClick={() => logoutCall()}
      >
        <ListItemIcon>
          <ExitToAppOutlinedIcon style={{ color: "#006D51" }} />
        </ListItemIcon>
        <ListItemText primary="Cerrar sesión" />
      </ListItem>
    </div>
  );
}

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);
