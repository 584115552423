/*-- Libraries --*/
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";

/*-- redux --*/
import { user, sellerProductsList, getData } from './Slice';

/*-- styles,components,functions --*/
import AppProductListItem from "features/product/list/listItems";
import Author from 'features/Auth/Author';
import LayoutWrapper from "features/layout/wrapper";

export default function Seller ( props ) {

    const dispatch  = useDispatch ();
    const _products = useSelector( sellerProductsList );
    const _user     = useSelector( user );
    const sellerName=props.match.params.sellerName

    useEffect(() => {
        dispatch ( getData ( sellerName ) );
    }, [dispatch, sellerName] );

    return (
        <LayoutWrapper>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4} md={3}>
                    <Author user={_user} />
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                    <Grid container spacing={3}>
                        {
                            _products.map( (item, key ) => {
                                return <Grid item xs={12} sm={6} md={4} key={key}><AppProductListItem data={item} {...props} /></Grid>;
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </LayoutWrapper>
    );

}