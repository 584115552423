import { createSlice } from '@reduxjs/toolkit';
import axios from "axios";

import {tickerEP} from 'endpoints'

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    sidebar: false,
    ticker: [],
    wallet: 120
  },
  reducers: {
    toggleSidebar: state => {
      state.sidebar = !state.sidebar;
    },
    setTicker: (state, action) => {
      state.ticker = action.payload
    }
  }
});

export const { toggleSidebar, setTicker } = layoutSlice.actions;

export const getTickerData = ( state ) => async dispatch => {

    const data = await axios.get( tickerEP ).then((response) => {

    return response.data;

  });

  dispatch( setTicker( data ) );

}

export const sidebarStatus = state => state.layout.sidebar;
export const ticker = state => state.layout.ticker;
export const wallet = state => state.layout.wallet;

export default layoutSlice.reducer;