import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import {indicatorsEP, downPricesEP, upPricesEP, latestSalesEP, tradedGoodIndexEP, recomendationsEP} from 'endpoints'

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    indicatorsLoading: true,
    indicators: {
      numTrans: 87,
      tVentasM: 75,
      ordenesC: 82,
      ofertasA: 81,
    },
    graph: [],
    priceBoardType: false,
    priceBoardData: [],
    priceBoardPage: 0,
    altaData: [],
    bajaData: [],
    offers: [],
    sliderOffer: [],
    products: [],
    loadingSlideOffers:false,
  },
  reducers: {
    setIndicators: (state, action) => {
      state.indicators = action.payload;
      state.indicatorsLoading = false;
    },
    changePriceBoard: (state) => {
      state.priceBoardType = !state.priceBoardType;
      state.priceBoardPage = 0;

      if (state.priceBoardType) {
        state.priceBoardData = state.bajaData;
      } else {
        state.priceBoardData = state.altaData;
      }
    },
    initialPriceBoard: (state) => {
      state.priceBoardType = false;
    },
    setAltaData: (state, action) => {
      state.altaData = action.payload;
      state.priceBoardData = action.payload;
    },
    setBajaData: (state, action) => {
      state.bajaData = action.payload;
    },
    setBoardPage: (state, action) => {
      state.priceBoardPage = action.payload;
    },
    setOffers: (state, action) => {
      state.offers = action.payload;
    },
    setSliderOffers: (state, action) => {
      state.sliderOffer = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setLoadingSlideOffers: (state,action) => {
      state.loadingSlideOffers = action.payload
    }
  },
});

export const {
  setIndicators,
  changePriceBoard,
  initialPriceBoard,
  setAltaData,
  setBajaData,
  setBoardPage,
  setOffers,
  setProducts,
  setSliderOffers,
  setLoadingSlideOffers
} = dashboardSlice.actions;

export const indicatorsLoading = (state) => state.dashboard.indicatorsLoading;
export const indicators = (state) => state.dashboard.indicators;
export const products = (state) => state.dashboard.products;

export const getIndicators = (state) => async (dispatch) => {
  const data = await axios.get(indicatorsEP).then((response) => {
    return response.data;
  });
  dispatch(setIndicators(data));
};

export const getBoardPrices = (state) => async (dispatch) => {
  const altaData = await axios.get(downPricesEP).then((response) => {
      
    return response.data;
    
  });

  //dispatch(initialPriceBoard());
  dispatch(setAltaData(altaData));

  const bajaData = await axios.get(upPricesEP).then((response) => {
    
    return response.data;
  
  });

  dispatch(setBajaData(bajaData));
};

export const getOffers = (state) => async (dispatch) => {
  const data = await axios.get(latestSalesEP).then((response) => {
    return response.data;
  });

  dispatch(setOffers(data));
};

export const getSliderOffers = (state) => async (dispatch) => {
  dispatch(setLoadingSlideOffers(true));  
  const data = await axios.get(tradedGoodIndexEP).then((response) => {
      return response.data;
    });

  dispatch(setSliderOffers(data));
  dispatch(setLoadingSlideOffers(false));
};

export const getSliderOffersPOST = (state) => async (dispatch) => {
  dispatch(setLoadingSlideOffers(true));  
  const data = await axios.post(tradedGoodIndexEP).then((response) => {
      return response.data;
    });

  dispatch(setSliderOffers(data));
  dispatch(setLoadingSlideOffers(false));
};

export const getProductsBySeller = (state) => async (dispatch) => {
  const data = await axios.get(recomendationsEP).then((response) => {
    return response.data;
  });

  dispatch(setProducts(data));
};

export const priceBoardOnChange = (state) => async (dispatch) => {
  dispatch(changePriceBoard());
};

export const priceBoardPageChange = (page) => (dispatch) => {
  dispatch(setBoardPage(page));
};

export const priceBoard = (state) => state.dashboard.priceBoardType;
export const priceBoardData = (state) => state.dashboard.priceBoardData;
export const priceBoardPage = (state) => state.dashboard.priceBoardPage;
export const offers = (state) => state.dashboard.offers;
export const sliderOffer = (state) => state.dashboard.sliderOffer;
export const loadingOffer = (state) => state.dashboard.loadingSlideOffers;

export default dashboardSlice.reducer;
