import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { orderListEP, orderCommentsEP, orderDetailEP,shippingDetailsEP,orderHelpMessageEP,paymentVoucherEP} from "endpoints";

export const myOrderSlice = createSlice({
  name: "myOrder",
  initialState: {
    loadingMyOrders: false,
    orders: [],
    isSubmited : false,
    vouchers:[],
  },
  reducers: {
    setOrders: (state, action) => {
    
      state.orders = action.payload;
    },
    setDelete : (state,action) => {
      state.orders.splice(action.payload,1);
    },
    setLoading: (state,action) =>{
      state.loadingMyOrders = action.payload;
    },
    setIsSubmited: (state,action) => {
      state.isSubmited = action.payload;
    },
    setVouchers : (state,action) => {
      state.vouchers = action.payload;
    }
  },
});
export const { setOrders, setLoading ,setIsSubmited, setVouchers, setDelete} = myOrderSlice.actions;


export const orders = (state) => state.myOrders.orders;
export const loadingMyOrders = (state) => state.myOrders.loadingMyOrders;
export const isSubmited = (state) => state.myOrders.isSubmited;
export const vouchers = (state) => state.myOrders.vouchers;

export const getOrders = (action) => async (dispatch) => {

  dispatch(setLoading(true));

  const data = await axios.get(orderListEP).then((response) => {
    return response.data;
  });

  dispatch(setOrders(data));
  dispatch(setLoading(false));
};

export const getDetailsPerOrder = async (action,callback,isLoading) => {
    isLoading(true);
    const data = await axios.post(orderDetailEP,action).then((response) => {
      return response.data;
    });
    isLoading(false);
    callback(data);
  };

  export const receivedConfirmation= (action) => async (dispatch) => {
  const response =await axios.post(orderCommentsEP,action).then((response) => {
        return response.status;
      });
      if(response === 201){
        dispatch(setIsSubmited(true))
      }

  }
  export const getShipData = async(action,callback) => {
    const data = await axios.post(shippingDetailsEP,action).then((response) => {
      return response.data;
    });
    callback(data);
  }

  export const requestForHelp = (action) => async (dispatch) => {
    await axios.post(orderHelpMessageEP,action).then((response) => {
      return response.data;
    });
  }
  export const getVouchers = (action,fail) => async (dispatch) => {
    await axios.put(paymentVoucherEP,action).then((response) => {
      dispatch(setVouchers(response.data))
    }).catch ((error) =>{
      if (error.response.status === 401) {
        fail();
      }
    })
    
  }
  
  export const deleteVoucher = (action) => async (dispatch) => {
    const data = await axios.delete(paymentVoucherEP,{data:{id:action.id}}).then((response) => {
      return response.data;
    });
    dispatch(setVouchers(data));
  }

  export const updateVoucher = (action,success,fail) => async (dispatch) => {
    await axios.post(paymentVoucherEP,action).then((response) => {
      success(true);
      dispatch(setVouchers(response.data));
    }).catch((error) => {
      fail('La imagen subida es superior a los 5MB,intente con otra imagen');
      if (error.response){
        // ToDo: Hay que controlar el catch
        // Explicación: Servidor devuelve 413 porque el archivo es muy grande
        // para el servidor debido a la limitación por parte de NGINX
        fail(error.response.data.detail);
      }
    });
  }



export default myOrderSlice.reducer;
