/*-- Libraries --*/
import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import { Typography, Divider, Button, Paper } from "@material-ui/core";
//import AddToCart from "features/product/common/addToCart";

/*-- styles,components,functions --*/
import { productListItemStyles } from "./styles";
import MatureComponent from "features/maturityMeter";
import formatNumber from 'functions/regex';

export default function AppProductListItem(props) {

  const seller = props.data.seller,
    sellerSlug = props.data.seller_slug,
    description = props.data.short_description,
    priceVariation = props.data.price_variation,
    lastPrice = props.data.price,
    quantity = props.data.quantity,
    saleSlug = props.data.sale_slug;


  const classes = productListItemStyles();

  return (
    <>
      <Paper className={classes.paper}>
        <Link
          to={`/mercado/${saleSlug}`}
          style={{ textDecoration: "none" }}
        >
          <img
            src={props.data.image}
            alt={props.data.short_description}
            className={classes.block5}
          />
        </Link>

        <div style={{ padding: "10px 15px 0px" }}>
          <div className={classes.block1}>
            <Typography
              variant="h2"
              component="h6"
              gutterBottom
              className={clsx(classes.block4, classes.block6)}
            >
              <Link to={`/vendedor/` + sellerSlug} className={classes.visited}>
                {seller}
              </Link>
            </Typography>

          </div>
          <div className={classes.block13}>
            <Link
              to={`/mercado/${saleSlug}`}
              style={{ textDecoration: "none" }}
            >
              <Typography
                variant="h2"
                component="h6"
                gutterBottom
                className={clsx(classes.block4, classes.block7)}
              >
                {props.data.product_description}
              </Typography>
            </Link>
          </div>
          <div className={classes.block1}>
            <MatureComponent
              maduration={props.data.maduration}
              maduration_description={props.data.maduration_description}
            />
            <div>
              <span
                style={{
                  color: "#53CD94",
                  background: "#DDF5EA",
                  fontSize: "10px",
                  fontWeight: 500,
                  lineHeight: "11.85px",
                  padding: "5px",
                  borderRadius: "4px",
                  textTransform: "uppercase",
                }}
              >
                {props.data.grade}
              </span>
            </div>
          </div>
          <Divider style={{ marginTop: "10px", marginBottom: "20px" }} />
          <div className={classes.block1}>
            <div className={classes.block9}>
              <Typography
                variant="h2"
                component="h6"
                gutterBottom
                className={clsx(classes.block10,classes.block10a)}
              >
                {quantity} {props.data.product_unit}
              </Typography>
              <Typography
                variant="h2"
                component="h6"
                gutterBottom
                className={clsx(classes.block4, classes.block6)}
              >
                STOCK DISPONIBLE
              </Typography>
            </div>
            <div>
              <div style={{display:"flex"}}>
              <div
                className={clsx(
                  classes.block2A,
                  priceVariation > 0 && classes.block2B,
                  priceVariation < 0 && classes.block2C
                )}
              >
                {priceVariation > 0 ? (
                  <TrendingUpIcon className={classes.block3} />
                ) : priceVariation < 0 ? (
                  <TrendingDownIcon className={classes.block3} />
                ) : (
                  <TrendingFlatIcon className={classes.block3} />
                )}
              </div>
              <Typography
                variant="h2"
                component="h6"
                gutterBottom
                className={clsx(classes.block10, classes.block11)}
              >
                <b>S/ {formatNumber(lastPrice)}</b> x {props.data.product_unit}
              </Typography>
              </div>
              <Typography
                variant="h2"
                component="h6"
                gutterBottom
                className={clsx(
                  classes.block4,
                  classes.block6,
                  classes.block11
                )}
              >
                {props.data.equivalency_kg}
              </Typography>
            </div>
          </div>
          <div style={{ display: "flex", height: "90px" }}>
            <Typography
              variant="h2"
              component="h6"
              gutterBottom
              style={{ alignSelf: "flex-start" }}
              className={clsx(classes.block4, classes.block8)}
            >
              {description}
            </Typography>
          </div>
        </div>
      </Paper>
      <Link
        to={`/mercado/${saleSlug}`}
        style={{ textDecoration: "none" }}
      >
        <Button variant="contained" className={classes.block12}>
          VER OFERTA
        </Button>
      </Link>
    </>
  );
}
