import {
    makeStyles,
  } from "@material-ui/core";
export const popoverStyles = makeStyles((theme) => ({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
      width:"250px",
      [theme.breakpoints.down("sm")]: {
        width:"200px",
      },
    },
    titlePopover:{
        margin:"0.5rem 0",
    },
    popoverText:{
        color:'#616060',
        fontSize:"12px",
        lineHeight:"19px",
        fontWeight:"normal",
    },
    
  }));