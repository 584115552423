import React from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { withoutResultStyles } from "./styles";

export default function WithoutResult(props) {
  const classes = withoutResultStyles();
  return (
    <Paper className={classes.paper}>
      <Grid className={classes.modalContainer} container>
        <Grid className={classes.box} item>
          <HourglassEmptyIcon className={classes.icon} />
        </Grid>
        <Grid className={classes.box} item>
          <Typography
            className={classes.alert}
            variant="h2"
            component="h5"
            gutterBottom
          >
            {props.data.message}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
