import { Paper, Typography } from "@material-ui/core";
import Moment from "react-moment";
import { shipMethodStyles } from "./styles";
import formatNumber from "functions/regex";
import Estibador from "assets/images/Estibador.svg";
import Vector from "assets/images/Vector (1).svg";
import clsx from "clsx";
export default function OrderSummary(props) {

  const classes = shipMethodStyles();

  return (
    <Paper className={classes.paper}>
      <Typography
        variant="h2"
        component="h5"
        gutterBottom
        className={classes.pageSectionTitle}
        style={{ marginBottom: "0px" }}
      >
        Resumen de tu pedido
      </Typography>
      <div className={clsx(classes.summary,classes.summary_detail)}>
        <span>Producto({props.cartData.length})</span>
        <span>
          S/{" "}
          {formatNumber(props.cartData.reduce((n, { total }) => n + total, 0))}
        </span>
      </div>
      {props.cartData.length > 0
        ? props.cartData.map((product, index) => (
            <div key={index} style={{ paddingBottom: "1rem" }}>
              <div className={classes.orderWrap}>
                <img
                  src={product.image}
                  alt={product.name}
                  className={classes.img}
                />
                <Typography
                  variant="h2"
                  component="h6"
                  className={classes.pageProductDetails}
                >
                  {product.name}
                  <span>CALIBRE:{product.grade.toUpperCase()} </span>
                  <p>S/ {formatNumber(product.total)}</p>
                </Typography>
                <Typography
                  variant="h2"
                  component="h6"
                  className={classes.priceDetails}
                >
                  S/ {formatNumber(product.price)}
                  <span>
                    {product.quantity} {product.unit}
                  </span>
                </Typography>
              </div>
              { product.price_deadline && ( new Date(product.price_deadline) > new Date()) ?
                  <span className={classes.productCaption}>
                    Precio válido hasta <Moment format="HH:mm" >{product.price_deadline}</Moment>
                  </span> : null
              }
              {!product.mensajeError ? (
                ""
              ) : (
                <span className={clsx(classes.errorLabel, classes.errorColor)}>
                  {product.mensajeError}
                </span>
              )}
            </div>
          ))
        : null}
      {props.cartData.length > 0 ? (
        <>
        {props.shipMethod !=='2' && <div>
            <div
              className={clsx(
                classes.summary,
                classes.border,
                classes.summary_detail
              )}
            >
               <Typography
                          variant="h2"
                          component="h5"
                          gutterBottom
                          className={classes.stateTitle}
                        >
                          Costos de envío
                          <span> (Inc. IGV)</span>
                </Typography>

              <span>S/ {formatNumber(props.price + props.estevedore)}</span>
            </div>
            <div className={classes.container2}>
            <div className={classes.item}>
              <img src={Vector} alt="icon" style={{width:"30px", marginRight:"3%",}}/>
              <span style={{flexGrow:1}}>Transportista</span>
              <span>S/ {formatNumber(props.price)}</span>
            </div>

            <div className={classes.item}>
              <img src={Estibador} alt="icon" style={{width:"30px", marginRight:"3%",}}/>
              <span style={{flexGrow:1}}>Estibador</span>
              <span>S/ {formatNumber(props.estevedore)}</span>
            </div>
          </div>
          </div>}

          <div>
            <div
              className={clsx(
                classes.summary,
                classes.border,
                classes.summary_detail
              )}
            >
              <span> Sub-total</span>
              <span>
                S/
                {formatNumber(
                  props.price + props.estevedore +
                    props.cartData.reduce((n, { total }) => n + total, 0)
                )}
              </span>
            </div>
            {props.discount.length > 0 && (
              props.discount.map((item,index)=>(
                <div key={index}>
                  <div className={clsx(classes.summary, classes.summary_discount)}>
                    <span> Descuento: </span>
                    <span>
                      - S/
                      {formatNumber(item.discount)}
                    </span>
                  </div>
                  <span className={classes.summary_discount}>
                    {item.discount_desc}
                  </span>
                </div>
              ))

            )}
          </div>
          <div
            className={clsx(
              classes.summary,
              classes.border,
              classes.summary_detail
            )}
          >
            <span> Monto final a pagar</span>
            <span>
              S/
              {props.discount.length > 0 ? (
                <>
                  {formatNumber(
                    props.price +
                    props.estevedore-
                props.discount.reduce((n, {discount})=> n+discount,0)+
                      props.cartData.reduce((n, { total }) => n + total, 0)
                  )}
                </>
              ) : (
                <>
                  {formatNumber(
                    props.price +
                    props.estevedore+
                    props.cartData.reduce((n, { total }) => n + total, 0)
                  )}
                </>
              )}
            </span>
          </div>
        </>
      ) : (
        <div className={classes.orderWrap}>
          <Typography
            variant="h2"
            component="h6"
            gutterBottom
            className={classes.pageProductDetails}
            style={{
              textAlign: "left",
              fontWeight: 500,
              color: "#808285",
            }}
          >
            No hay productos en tu pedido
          </Typography>
        </div>
      )}
    </Paper>
  );
}
