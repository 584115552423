import { makeStyles } from "@material-ui/core/styles";

export const shipMethodStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    // alignItems:"center",
    // paddingBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  pageTitle: {
    color: "#53CD94",
    fontSize: "32px",
    lineHeight: "38px",
    alignItems: "baseline",
    flexGrow: 1,
    paddingTop:"8px",
    "& span": {
      fontSize: "16px",
      lineHeight: "19px",
      marginLeft: "15px",
      color: "#808285",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "15px 25px",
    borderRadius: "12px",
    color: "#53CD94",
    fontSize: "25px",
    fontWeight: "bold",
    boxShadow: "none",
    width: "100%",
    paddingTop: "46px",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
    },
  },
  shippingSectionTitle: {
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "23.7px",
    color: "#808285",
    marginBottom: "15px",
  },
  icons: {
    color: "#808285",
    fontSize: "30px",
    alignSelf: "center",
    width: "15%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  widthBox: {
    width: "55%",
  },
  widthBox1: {
    width: "20%",
  },
  alignLastDescriptionText: {
    textAlign: "end",
  },
  widthBox2: {
    width: "75%",
  },
  editButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
  },

  descriptionText: {
    "& p": {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "17px",
      color: "#808285",
      margin: "0",
    },
  },
  descriptionText_title: {
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#000000",
    margin:'0.5rem 0'
  },
  detailContainer: {
    "& img": {
      width: "30px",
    },
    "& span": {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "17px",
      color: "#808285",
      margin: "0",
    },
  },
  detailIcon: {
    color: "#53cd94",
    fontSize: "20px",
  },
  shipDetail: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "5px",
    border: "1px solid #53cd94",
    padding: "1rem",
    
    "& div": {
      display: "flex",
      flexDirection: "row",
      justifyContent:"space-between",
    },
    "& span": {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "17px",
      color: "#808285",
      margin: "0",
      marginBottom: "1px",
    },
  },
  show: {
    display: "none",
  },
  paddingSection: {
    padding: "1rem",
  },
  formTitle: {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "18.96px",
    color: "#000000",
    margin: "1.5rem 0",
  },
  input: {
    display: "flex",
    fontFamily: '"Rubik",sans-serif',
    width: "100%",
    height: "36px",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    color: "#333333",
    borderRadius: "4px",
    border: "1px solid #E0E0E0",
    background: "#F1F2F2",
    padding: "10px 15px",
    "&:focus": {
      borderRadius: 4,
      outlineColor: "#53CD94",
    },
  },
  label: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    color: "#808285",
    display: "flex",
    marginBottom: "10px",
    minHeight: "16px",
  },
  submit: {
    height: "52px",
    width: "50%",
    color: "#616060",
    borderColor: "#53cd94",
    margin: "1rem 0",
    borderRadius: "8px",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "21.33px",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      background: "#53cd94",
      color: "#ffffff",
      borderColor: "#53cd94",
    },
  },
  button: {
    height: "52px",
    width: "50%",
    margin: "1rem",
    borderRadius: "8px",
    background: "#53CD94",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "21.33px",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      background: "#006D51",
    },
  },
  simpleText: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#808285",
    margin: "0 1rem",
  },
  errorLabel: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    color: "red",
    display: "flex",
    marginTop: "10px",
    minHeight: "16px",
  },
  sucessLabel : {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    color: "#53cd94",
    display: "flex",
    marginTop: "10px",
    minHeight: "16px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    [theme.breakpoints.up("lg")]: {
      width: "5%",
      margin: "auto",
    },
  },
  summaryAccordeon: {
    border: "1px solid #53cd94",
    background: "#f1f2f2",
    padding: "1rem",
    margin: "0.5rem 0",
    "& span": {
      color: "#53cd94",
    },
  },
}));
