import { createSlice } from '@reduxjs/toolkit';
import axios from "axios";

import {profileEP} from "endpoints"

export const sellerSlice = createSlice({
    name: 'seller',
    initialState: {
        user: {
            name: "",
            avatar: "",
            address: "",
            description: "",
            email: "",
            rating: "0",
        },
        products: []
    },
    reducers: {
        setData: ( state, action ) => {
            state.user = action.payload.user;
            state.products = action.payload.sales;
        }
    }
} );

export const { setData } = sellerSlice.actions;

export const user = state => state.seller.user;
export const sellerProductsList = state => state.seller.products;

export const getData = ( state ) => async dispatch => {
    /*const data = await axios.get( 'profile/profile/', {
        params : {
            seller: state
        }
    }).then((response) => {

        return response.data;

    });

    dispatch( setData( data ) );*/

    const _data = await axios.post(profileEP, {
        seller_slug: state
    }).then((response) => {

        return response.data;

    });

    dispatch( setData( _data ) );

}

export default sellerSlice.reducer;