import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { cartEP } from "endpoints";


export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    loading: true,
    items: [],
    discount:[]
  },
  reducers: {
    _setCartItems: (state, action) => {
      state.items = action.payload.products;
      state.discount = action.payload.coupons;
    },
    setIdCart: (state, action) => {
      state.id = action.payload;
    },
  },
});

export const { _setCartItems, setErrorDetail, setIsSuccess, setIdCart } =
  cartSlice.actions;

export const _getCartItems = () => async (dispatch) => {
  const results = await axios.get(cartEP).then((response) => {
    return response.data;
  });
  dispatch(_setCartItems(results));
};

export const getCart = (state) => state.cart.items;
// export const getErrorDetail = (state) => state.cart.errorDetail;
// export const getIsSuccess = (state) => state.cart.isSuccess;
export const getId = (state) => state.cart.id;
export const getDiscount = (state) => state.cart.discount;

export const addToCart = (data, success, fail) => async (dispatch) => {
  await axios
  .post(cartEP, data)
  .then((response) => {
    success(response.data);
    dispatch(_setCartItems(response.data.cart));
  })
  .catch((error) => {
    fail(error.response.data.detail);
    dispatch(setIdCart(error.response.data.id));
  });
};

export const deleteCartItem = (id) => async (dispatch) => {
  const results = await axios
    .delete(cartEP, { data: { id: id } })
    .then((response) => {
      return response.data;
    });
  dispatch(_setCartItems(results.cart));
  dispatch(setIdCart(null));
};

export const updateCartItem = (data) => async (dispatch) => {
  const results = await axios.patch(cartEP, data).then((response) => {
    return response.data;
  });
  dispatch(_setCartItems(results.cart));
};
export default cartSlice.reducer;
