import { makeStyles } from "@material-ui/core/styles";
export const trackingStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      display: "flow-root",
    },
  },
  pageTitle: {
    color: "#53CD94",
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "38px",
    alignItems: "baseline",
    flexGrow: 1,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "55px",
    borderRadius: "12px",
    fontSize: "25px",
    boxShadow: "none",
    width: "100%",
    marginBottom: "30px",
    overflow: "auto",
    color: "#616060",
    [theme.breakpoints.down("sm")]: {
      padding: "25px",
    },
  },
  statusTitle:{
    fontSize: "20px",
    lineHeight: "20px",
    fontWeight: "normal",
  },
  inProcessColor:{
    color: "#F2994A",
  },
  subtitle:{
    fontSize: "16px",
    lineHeight: "21.33px",
    fontWeight: "normal",
    display:"flex",
    alignItems:"center",

  },
  popover:{
    pointerEvents: "none",
  },
  popoverText:{
    color:'#616060',
    fontSize:"14px",
    lineHeight:"19px",
    fontWeight:"normal",
  },
  stepper: {
    border: "1px solid #e0e0e0",
    margin: "1rem 0",
    display:"flex",
    flexDirection:"column",
   
    [theme.breakpoints.down("xs")]: {
      alignItems:'center',
    },
    '& span':{
      fontSize:'26px',
      lineHeight:'33.18px',
      textAlign:'center',
      color:'#000',
      [theme.breakpoints.down("xs")]: {
        fontSize:'18px',
        lineHeight:'33.18px',
      },
    },

  },
  icon: {
    fontSize: "30px",
    color: "#006d51",
    margin:'0 0.5rem'
  },
  driverInfoContainer: {
    border: "1px solid #e0e0e0",
    padding: "1rem 0",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent:"space-between"
  },
  driverInfoRow: {
    //width: "60%",
    display: "flex",
    flexDirection: "row",
    flexWrap:'noWrap',
    alignItems:'center',
    margin:'0 2rem'
  },
  driverInfoRow1: {
    //width: "60%",
    display: "flex",
    flexDirection: "column",
  },
  text:{
    fontSize:'18px',
    lineHeight:"26.07px"
  },
  phoneIcon: {
    color: "#ffffff",
    padding: "0.2rem",
    backgroundColor: "#006d51",
    borderRadius: "50%",
  },
  icons:{
    margin:'0 1rem',
    [theme.breakpoints.down("sm")]: {
      margin:'0 0.1rem',
    },
  },
  box:{
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    margin:'auto'
  },
  driverBox: {
    margin: "1rem",
  },
  icon1: {
    color: "#F2994A",
    fontSize: "40px",
  },
  alert: {
    fontSize: "14px",
    color: "#808285",
    textAlign:"center"
  },
}));
