/*----Libraries----*/
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { Typography, Grid, Paper, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

/*----Redux----*/
import { getVouchers, deleteVoucher, updateVoucher, vouchers } from "../slices";

/*----Styles,Components,features----*/
import { TransferStateStyles } from "./styles";
import product_1 from "assets/images/image-gallery.svg";
import voucher from "assets/images/voucher.png";
import WithoutResult from "features/layout/withoutResult";
import { useHistory } from "react-router-dom";
import LayoutWrapper from "features/layout/wrapper";

export default function TransferState(props) {
  const classes = TransferStateStyles();
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const [filesToUpload, setfilesToUpload] = useState([]);
  const [numTran, setnumTran] = useState("");
  const [errorNumTran, setErrorNumTran] = useState(false);
  const [errorImage, setErrorImage] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const { orderSlug } = useParams();
  const vouchersValues = useSelector(vouchers);
  let history = useHistory();

  const fileChange = (event) => {
    setfilesToUpload([...filesToUpload, event.target.files[0]]);
    event.target.value = null;
  };

  const openSelectFile = () => {
    inputFile.current.click();
  };

  const numTranChange = (event) => {
    setnumTran(event.target.value);
  };

  useEffect(() => {
    dispatch(getVouchers({ order_slug: orderSlug },
    () => {
        history.push('/no-autorizado')
    }
    ));
  // Added by Javier Arias, history not needed
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, orderSlug]);

  const onDeleteFile = (data) => {
    const formData = new FormData();
    formData.append("id", data.id);
    dispatch(deleteVoucher(data));
  };

  const submitDocument = () => {
    let submit = true;
    if (numTran) {
      setErrorNumTran(false);
    } else {
      submit = false;
      setErrorNumTran(true);
    }
    if (!filesToUpload.length) {
      submit = false;
      setErrorImage(true);
    } else {
      setErrorImage(false);
    }
    const formData = new FormData();
    formData.append("numTran", numTran);
    formData.append("order_slug", orderSlug);

    filesToUpload.forEach((file) => {
      formData.append("file", file);
    });
    if (submit) {
      dispatch(
        updateVoucher(formData, () => {
          setIsSaved(true);
          setfilesToUpload([]);
          setnumTran("");
          setTimeout(() => {
            setIsSaved(false);
          }, 1000);
        })
      );
    }
  };

  const emptySaleData = {
    message: "No hay comprobante de transferencia adjuntado.",
  };

  return (
    <LayoutWrapper>
      <div className={classes.container}>
        <Typography
          variant="h2"
          component="h5"
          gutterBottom
          className={classes.pageTitle}
        >
          Comprobante de la compra
        </Typography>
      </div>
      <Grid
        container
        spacing={3}
        style={{ flexDirection: "row", justifyContent: "space-evenly" }}
      >
        <Grid item xs={12} sm={12} md={6}>
          <Paper className={classes.paper}>
            <Typography variant="h2" gutterBottom className={classes.subTitle}>
              {vouchersValues.length >= 0
                ? "Comprobantes adjuntados"
                : "Comprobante adjuntado"}
            </Typography>
            {vouchersValues.length ? (
              <ul className={clsx(classes.filesList, classes.margin)}>
                {vouchersValues.map((file, index) => (
                  <li key={index}>
                    <a
                      href={file.file}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div style={{ position: "relative" }}>
                        <img src={voucher} alt="voucher-icon" />
                        <span
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          ver
                        </span>
                      </div>
                    </a>
                    <span>
                      {file.file
                        .replace(/^.*[\\/]/, "")
                        .substring(0, 15)
                        .padEnd(15, "-")}
                    </span>
                    <DeleteIcon
                      style={{ color: "#53CD94" }}
                      onClick={() => onDeleteFile({ key: index, id: file.id })}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <WithoutResult data={emptySaleData} />
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Paper className={classes.paper}>
            <Typography variant="h2" gutterBottom className={classes.subTitle}>
              Subir comprobante
            </Typography>
            <div className={classes.uploadFilesWrap}>
              <div className={classes.uploadFilesButtonWrap}>
                <input
                  type="file"
                  id="file"
                  accept="image/pdf/*"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={fileChange}
                />
                <AddIcon
                  onClick={openSelectFile}
                  style={{
                    height: "48px",
                    width: "48px",
                    borderRadius: "50px",
                    background: "#E0E0E0",
                    color: "#FFF",
                    cursor: "pointer",
                    margin: "0 auto",
                  }}
                />
                <p className={classes.filesListHeading}>
                  Adjunta el comprobante de transferencia
                </p>
                <ul className={classes.filesList}>
                  {filesToUpload.map((file, index) => (
                    <li key={index}>
                      <img src={product_1} alt={file.file} />
                      <span>{file.name}</span>
                      <DeleteIcon
                        style={{ color: "#53CD94" }}
                        onClick={() =>
                          onDeleteFile({ key: index, id: file.id })
                        }
                      />
                    </li>
                  ))}
                </ul>
                {errorImage && (
                  <span
                    className={clsx(classes.errorColor, classes.errorLabel)}
                  >
                    Por favor suba su comprobante.
                  </span>
                )}
              </div>
            </div>
            <div className={classes.uploadFilesWrap}>
              <div className={classes.transactionNumber}>
                <label className={classes.label}>Número de transacción</label>
                <input
                  type={"text"}
                  placeholder={"Ingresa el número"}
                  className={classes.input}
                  value={numTran}
                  onChange={numTranChange}
                />
                {errorNumTran && (
                  <span
                    className={clsx(classes.errorColor, classes.errorLabel)}
                  >
                    Por favor ingrese el número de transferencia
                  </span>
                )}
              </div>
              <Button
                style={{ color: "#53CD94" }}
                onClick={() => submitDocument()}
              >
                Adjuntar
              </Button>
              {isSaved && (
                <span className={clsx(classes.errorColor, classes.errorLabel)}>
                  {filesToUpload.length > 1
                    ? "Se guardaron los archivos seleccionados."
                    : "Se guardó el archivo seleccionado"}
                </span>
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </LayoutWrapper>
  );
}
