
import { makeStyles } from "@material-ui/core/styles";
export const TransferStateStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        width: "100%",
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
      },
      pageTitle: {
        color: "#53CD94",
        fontSize: "32px",
        lineHeight: "38px",
        alignItems: "baseline",
        flexGrow: 1,
        "& span": {
          fontSize: "16px",
          lineHeight: "19px",
          marginLeft: "15px",
          color: "#808285",
        },
      },
      paper: {
        padding: "20px",
        fontSize: "25px",
        fontWeight: "bold",
        boxShadow: "none",
        width: "100%",
        minHeight:"500px",
        [theme.breakpoints.down("sm")]: {
          padding: "25px",
        },
      },
      subTitle:{
        fontSize:"20px",
        fontWeight:400,
        margin:"2rem"
      },
      uploadFilesWrap: {
        width: "80%",
        display: "flex",
        flexDirection:"column",
        margin: "10px auto",
        textAlign: "center",
        border: "1px dashed #E0E0E0",
        padding: "15px",
        marginTop: "24px",
        boxSizing: "border-box",
        borderRadius: "4px",
        "& p": {
          padding: "1rem",
        },
      },
      uploadFilesButtonWrap: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
      },
      filesListHeading: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16.59px",
        color: "#808285",
        width: "100%",
        textAlign: "center",
        marginTop: "15px",
        marginBottom: "15px",
      },
      filesList: {
        listStyle: "none",
        width: "60%",
        padding: "0px",
        "& li": {
          border: "1px solid #53CD94",
          padding: "0.5rem",
          borderRadius: "4px",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "14.22px",
          color: "#333",
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
          justifyContent:"space-evenly",
          "& img": {
            width: "30px",
          },
          "& span": {
            [theme.breakpoints.down("xs")]: {
              display:"none"
            },
          },
          "& svg": {
            color: "#53CD94",
            cursor: "pointer",
          },
          "& a": {
            textDecoration:"none",

            "&:visited": {
              textAlign: "left",
              textTransform: "uppercase",
              fontSize: "10px",
              fontWeight: 500,
              lineHeight: "11.85px",
              textDecoration: "none",
              color: "black",
            },
            "&:hover": {
                boxShadow: "none",
              },
          },
          
        },
      },
      margin:{
        margin: "5rem auto",
      },
      transactionNumber: {
        padding: "1rem 1rem",
        margin:'auto',
        display:"flex",
        flexDirection:"column",
        "& input": {
          display:"flex",
          justifyContent:"center",
          fontFamily: '"Rubik",sans-serif',
          height: "36px",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "16.59px",
          color: "#333333",
          borderRadius: "4px",
          border: "1px solid #E0E0E0",
          background: "#F1F2F2",
          padding: "10px 15px",
          "&:focus": {
            borderRadius: 4,
            outlineColor: "#53CD94",
          },
          "&::placeholder": {
            color: "#808285",
            fontSize: "0.7em",
          },
    
        },
        "& label": {
          display:"flex",
          justifyContent:"center",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "16.59px",
          color: "#808285",
          marginBottom: "10px",
          minHeight: "16px",
        },
      },
      errorLabel: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16.59px",
        display: "flex",
        minHeight: "16px",
        justifyContent:"space-around",
        color:"red",
        padding:"15px",
      },
      title3:{
        fontSize: "24px",
        marginBottom: "20px",
        marginRight: "1rem",
        marginTop: "1rem",
      },
      imageListItem:{
        position:"relative",
        "& span":{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }
      }
}));