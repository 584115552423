import clsx from "clsx";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { matureStyles } from "./styles";
export default function MatureComponent(props) {
  const { maduration, maduration_description } = props;
  const classes = matureStyles();
  return (
    <span
      className={clsx({
        [classes.mature]: true,
        [classes.matureState1]: maduration === 1,
        [classes.matureState2]: maduration === 2,
        [classes.matureState3]: maduration === 3,
        [classes.matureState4]: maduration === 4,
        [classes.matureState5]: maduration === 5,
      })}
    >
      <FiberManualRecordIcon />
      <span className={classes.matureText}>{maduration_description}</span>
    </span>
  );
}
