import { makeStyles } from '@material-ui/core/styles';

export const cardProductStyles = makeStyles((theme) => ({
    card: {
        // padding: "1.5rem",
        fontSize: "12px",
        boxShadow: "none",
        border: "2px solid #E6E7E8",
        minHeight: "auto",
        // [theme.breakpoints.down("md")]: {
        //   padding: "1rem",
        // },
        // [theme.breakpoints.down("sm")]: {
        //   fontSize: "14px",
        // },
      },
      card__image:{
        width:"100%",
      },
      card__details:{
        padding:"0.5rem",
        [theme.breakpoints.down("md")]: {
          padding:"0.3rem",
        },
      },
      card__detail:{
        display:"flex",
        flexDirection:"row",
        alignItems:"baseline",

      },
      block1: {
        // display: "flex",
        // width: "100%",
      },
      card__arrow:{
        marginRight:"0.5rem",

      },
      block2A: {
        width: "18px",
        height: "18px",
        borderRadius: "4px",
        background: "#E0E0E0",
      },
      block2B: {
        background: "#53CD94",
        width: "18px",
        height: "18px",
        borderRadius: "4px",
      },
      block2C: {
        background: "#F2994A",
        width: "18px",
        height: "18px",
        borderRadius: "4px",
      },
      block3: {
        fontSize: "16px",
        color: "#FFF",
        "& a": {
          color: "#006D51",
        },
        "& a:visited": {
          color: "#006D51",
        },
      },
      block4: {
        fontSize: "14px",
        color: "#808285",
        textAlign: "right",
        flexGrow: 1,
        alignSelf: "center",
        margin: "0 auto",
      },
      block5: {
        maxWidth: "100%",
        // maxHeight: "140px",
        // minHeight:"100px",
        display: "block",
        // margin: "10px auto",
        // [theme.breakpoints.down("sm")]: {
        //   maxHeight: "140px",
        // },
      },
      block6: {
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "21.33px",
        minHeight:"46px",
        [theme.breakpoints.down("md")]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "14px",
          minHeight:"30px",
        },
      },
      priceWrap: {
        color: "#f2994a",
        "& span":{
          fontSize: "12px",
          fontWeight: 500,
          lineHeight: "16.59px",
          [theme.breakpoints.down("md")]: {
            fontSize: "10px",
          },
          [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
          },
          "& b":{
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "28.44px",
            [theme.breakpoints.down("md")]: {
              fontSize: "14px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "14px",
            },
          },
        },

      },
      caption: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "14.22px",
        color: "#808285",
        margin:"0.5rem 0"
      },
}))