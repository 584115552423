import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import {tradedGoodIndexEP} from 'endpoints'

export const myProductsSlice = createSlice({
    name: 'myProducts',
    initialState: {
        products: []
    },
    reducers: {
        setProductData: (state, action) => {
            state.products = action.payload
        }
    }
});

export const { setProductData } = myProductsSlice.actions;
export const products = state => state.myProducts.products;

export const getProductData = ( state ) => async dispatch => {

    const data = await axios.get(tradedGoodIndexEP).then((response) => {

        return response.data;

    })

    dispatch( setProductData( data ) );
}

export default myProductsSlice.reducer;