import React from 'react';
// import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel } from "@material-ui/core";
import StepConnector from '@material-ui/core/StepConnector';
import StoreIcon from "@material-ui/icons/Store";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import HomeIcon from "@material-ui/icons/Home";
import deliveryIcon from "assets/images/delivery.svg";
import clsx from "clsx";

// const useStyles = makeStyles({
//   customLabelStyle: {
//     fontSize: "20px !important",
//     display: "flex",
//     justifyContent: "left",
//   }
// });

// const StyledStepConnector = withStyles({
//   alternativeLabel: { top: 9 },
//   active: { '& $line': { borderColor: '#69CC4E', borderLeftWidth:'15px', height:'50px'} },
//   completed: { '& $line': { borderColor: '#69CC4E', borderLeftWidth:'15px', height:'50px' } },
//   line: {
//     borderTopWidth: 2,
//   },
//   vertical: {
//     marginLeft: "1%",
   
//   },
//   lineVertical: {
//     borderLeftWidth: "15px",
//     height:'50px',
//   },
// })(StepConnector);

// const theme = createTheme({
//   overrides: {
//     MuiStepIcon: {
//       root: {
//         width: '50px',
//         height: '50px',
//         '&$completed': {
//          color: '#69CC4E',
//         },
//         '&$active': {
//           color: '#69CC4E',
//         },
        
//       },
//       active: {},
//       completed: {},
   
//     },
//     MuiStepConnector: {
//       root: {
//         '&$lineVertical': {
//           color: '#69CC4E',
//         },
//       },
//     }
//   }
// });



// function getSteps() {
//   return [
//     'Tu mercadería ha sido marcada',
//     'El estibador está entregando tu mercadería al transportista',
//     'El transportista está en camino con tu mercadería',
//     'El transportista ha llegado'
//   ];
// }

function DeliveryIcon() {
  return <img src={deliveryIcon} alt="deliveryIcon" width="30px" />;
}

const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: "#006d51",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#006d51",
    },
  },
  line: {
    height: 1,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}))(StepConnector);

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    zIndex: 1,
    color: "#006d51",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #eaeaf0",
    [theme.breakpoints.down("sm")]: {
      width: 35,
      height: 35,
    },
  },
  active: {
    // border:'1px solid #006d51',
  },
  completed: {
    border: "1px solid #006d51",
  },
}));

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <StoreIcon />,
    2: <DeliveryIcon />,
    3: <LocalShippingIcon />,
    4: <HomeIcon />,
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

export default function OrderStepper(props) {
  // const classes = useStyles();
  // const [activeStep, setActiveStep] = useState(0);
  // const steps = getSteps();

  return (
    <Stepper
      alternativeLabel
      activeStep={props.activeStep}
      connector={<ColorlibConnector />}
    >
      {[1,2,3,4].map((label, index) => (
        <Step key={index}>
          <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
        </Step>
      ))}
    </Stepper>

    // <ThemeProvider theme={theme}>
    //   <Stepper activeStep={props.activeStep} orientation="vertical" connector={<StyledStepConnector/>}  >
    //     {steps.map((label) => (
    //       <Step key={label}>
    //         <StepLabel classes={{ label: classes.customLabelStyle }}>{label}</StepLabel>
    //       </Step>
    //     ))}
    //   </Stepper>
    // </ThemeProvider>

  );
}
