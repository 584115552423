import { makeStyles } from "@material-ui/core/styles";

export const actionPopStyles = makeStyles((theme) => ({
  logOut: {
    fontSize: "14px",
    lineHeight: "16.59px",
    fontWeight: 400,
  },
  visited: {
    textDecoration: "none",
    color:"black",
    "&:visited": {
      textAlign: "left",
      textTransform: "uppercase",
      fontSize: "10px",
      fontWeight: 500,
      lineHeight: "11.85px",
      textDecoration: "none",
      color: "black",
    },
    "&:hover": {
        boxShadow: "none",
        color: "#53cd94",
      },
  },
  popUsername: {
    color: "#006D51",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16.59px",
    marginLeft: "20px",
    display: "block",
    "& > span": {
      display: "block",
      fontSize: "8px",
      lineHeight: "16px",
      fontWeight: 500,
      height: "16px",
      width: "89px",
      background: "#F2C94C",
      color: "#FFF",
      borderRadius: "4px",
      textAlign: "center",
      marginTop: "10px",
    },
  },
}));
