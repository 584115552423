/*-- Libraries --*/
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { 
    Typography, 
    Paper,
    AppBar, 
    Tabs, 
    Tab, 
    Box, 
    Button,
    Input, 
    withStyles, 
    useTheme, 
    Switch,
    FormGroup,
    FormControlLabel,
    Grid,
    NativeSelect,
    InputBase,
    IconButton,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider
} from "@material-ui/core";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { teal } from "@material-ui/core/colors";
import SwipeableViews from "react-swipeable-views";

/*-- redux --*/
import { 
    getConfigProfile, 
    getContactData,
    updateConfigProfile,
    checkedUser,
    updateContactData,
    updateUser,
    requestBankData,
    getNotifConfig,
    updateNotifConfig
} from './slice';
import { 
    updateShipTo, 
    updateAddress, 
    updatePhone, 
    updateProvince, 
    updateDistrict, 
    submitData,
} from 'pages/ShipMethod/slice';


/*-- styles,components,functions --*/
import Vector from "assets/images/Vector.svg";
import { SettingStyle } from "./style";
import ModalComponent from 'features/modal';
import JustCropper from '../../features/cropper';
import LayoutWrapper from 'features/layout/wrapper';
import useShippingInfo from 'hooks/useShippingInfo';

const AntTabs = withStyles({
    indicator: {
        backgroundColor: "#53CD94",
    },
})(Tabs);

const DialogActionCentered = withStyles({
    root:{
        justifyContent:"center"
    }
})(DialogActions);
const BootstrapInput = withStyles((theme) => ({
    root: {
        width: "100%",

        "label + &": {},
    },
    dialog: {
        position: "absolute",
        left: 10,
        top: 50,
    },
    input: {
        borderRadius: 4,
        position: "relative",
        width: "100%",
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "7.5px 7px 7.5px 15px",
        backgroundColor: "#F1F2F2",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderRadius: 4,
            borderColor: "#53CD94",
            outlineColor: "#53CD94",
        },
    },
}))(InputBase);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
};
const GreenSwitch = withStyles({
    switchBase: {
      color: teal[300],
      "&$checked": {
        color: teal[500],
      },
      "&$checked + $track": {
        backgroundColor: teal[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

// AA: access acount
// PD: profile data
// BUD: business data
// CD: contact data
// BD: bank data
// external account

export default function Setting(props) {
    const classes = SettingStyle();
    const dispatch = useDispatch();

    const [value, setValue] = useState(0);

    const [addressPro, setAddressPro] = useState("");
    const [descriptionPro, setdescriptionPro] = useState("");
    const [background, setBackground] = useState("");
    const [backgUploading, setBackgUploading] = useState("");
    const [backgroundName, setBackgroundName] = useState("");
    const [croppedBackg, setCroppedBackg] = useState("")
    const [openBackg, setOpenBackg] = useState(false);
    const [bgFile, setbgFile] = useState(null);
    const [profileFile, setprofileFile] = useState(null);
    const [profile, setprofile] = useState("");
    const [openProfile, setopenProfile] = useState(false);
    const [profileName, setprofileName] = useState("");
    const [profileUploading, setprofileUploading] = useState("");
    const [croppedProf, setcroppedProf] = useState("");
    const [isSavedProf, setisSavedProf] = useState(false);

    const [emailAA, setEmailAA] = useState('');
    const [userAA, setUserAA] = useState('');
    const [isUserAvailable, setisUserAvailable] = useState(true);
    const [errorUserAA, setErrUserAA] = useState(false);
    const [isSavedAI, setisSavedAI] = useState(false);

    const [tradeNameBUD, settradeNameBUD] = useState('');
    const [businessNameBUD, setbusinessNameBUD] = useState('');
    const [rucBUD, setRucBUD] = useState('');
    const [errorTradeNameBUD, setErrTradeNameBUD] = useState(false);
    const [nameCD, setnameCD] = useState('');
    const [full_nameCD, setfull_nameCD] = useState('')
    const [docNumberCD, setDocNumberCD] = useState('');
    const [numberPhoneCD, setnumberPhoneCD] = useState('');
    const [errorNameCD, setErrNameAA] = useState(false);
    const [errorFullNameCD, setErrFullNameAA] = useState(false);
    const [errdocNumberCD, setErrdocNumberCD] = useState(false);
    const [errorNumberPhoneCD, setErrNumberPhoneCD] = useState(false);
    const [isSavedCI, setisSavedCI] = useState(false);
    
    const [openBD, setOpenBD] = useState(false);
    const [cardholderBD, setcardholderBD] = useState('');
    const [docNumberBD, setdocNumberBD] = useState('');
    const [accountTypesBD, setAccountTypesBD] = useState([]);
    const [accountName, setaccountName] = useState('');
    const [accountTypeBD, setAccountTypeBD] = useState('');
    const [currencyListBD, setCurrencyListBD] = useState([]);
    const [currencyTypeBD, setCurrencyTypeBD] = useState('')
    const [currencyName, setcurrencyName] = useState('');
    const [accountNumberBD, setaccountNumberBD] = useState('');
    const [extAccountNumbBD, setExtAccountNumbBD] = useState('');
    const [errorCardHBD, setErrCardHBD] = useState(false);
    const [errorDocNumbBD, setErrDocNumbBD] = useState(false);
    const [errorAccountNumb, setErrAccountNumb] = useState(false);
    const [errorExtAccNumb, setErrExtAccNumb] = useState(false);
    const [isSavedBI, setisSavedBI] = useState(false);

    const {
        phoneValue,
        addressValue,
        shipToValue,
        idValue,
        provinceList,
        districtList,
        provinceValue,
        districtValue,
        _districtName,
    } = useShippingInfo();
    const [openShipTo, setopenShipTo] = useState(false);
    const [errorName, setErrorName] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const [errorAddress, setErrorAddress] = useState(false);
    const [isSavedShipTo, setisSavedShipTo] = useState(false);

    const switchValues = {
        email_buy_sell: false,
        email_offers_promotions: false,
        email_news_updatedes: false,
        email_shipping_status: false,
        whatsapp_buy_sell_reminder: false,
        whatsapp_by_sell: false,
        whatsapp_shipping_status: false,
    };

    const [checkedNotif, setCheckedNotif] = useState(switchValues);

    const theme = useTheme();

    // HANDLE CHANGE PROFILE
    const backgroundChange = (event) => {
        
        if (event.target.files && event.target.files.length > 0) {

            setBackgroundName(event.target.files[0].name);

            const reader = new FileReader();
            reader.addEventListener("load", () => {
                var img = new Image();
                img.onload = function () {

                    if (img.width >=500 && img.height >= 300) {
                        setBackgUploading(reader.result);
                        setOpenBackg(!openBackg);
                    } else {
                        alert("Las dimensiones de la foto debe ser de 500x300 como mínimo, intenta con otra foto");
                    };
                    
                }
                img.src = reader.result;
            });

            reader.readAsDataURL(event.target.files[0]);
             event.target.value = null;
        }
    };

    const handleProfile = (event) => {

        if (event.target.files && event.target.files.length > 0) {

            setprofileName(event.target.files[0].name);

            const reader = new FileReader();
            reader.addEventListener("load", () => {
                var img = new Image();
                img.onload = function () {

                    if (img.width >= 300 && img.height >= 300) {
                        setprofileUploading(reader.result);
                        setopenProfile(!openProfile);
                    } else {
                        alert("Las dimensiones de la foto debe ser de 300x300 como mínimo, intenta con otra foto");
                    };

                }
                img.src = reader.result;
            });

            reader.readAsDataURL(event.target.files[0]);
            event.target.value = null;
        }
    };

    const addressChange = (event) => {
        setAddressPro(event.target.value);
    };

    const descriptionChange = (event) => {
        setdescriptionPro(event.target.value);
    };

    // HANDLE CHANGE ACCESS ACOUNT SECTION
    const userAAChange = (event) => {

        setUserAA(event.target.value);
        setErrUserAA(false);

        const userName = {'username': event.target.value};
        checkedUser(userName, (response) => {
            setisUserAvailable(response);
        }); 

        if (!event.target.value) {
            setErrUserAA(true);
        };
    };

    // HANDLE CHANGE BUSINESS AND CONTACT SECTION
    const tradeNameBUDChange =  (event) => {
        settradeNameBUD(event.target.value);
    };

    const businessNameChangeBUD =   (event) => {
        setbusinessNameBUD(event.target.value);
    };

    const rucBUDChange = (event) => {
        setRucBUD(event.target.value);
    };
       
    const nameCDChange = (event) => {
        setnameCD(event.target.value);
    };
    
    const full_nameCDChange = (event) => {
        setfull_nameCD(event.target.value);
    };

    const docNumberCDChange = (event) => {
        setDocNumberCD(event.target.value);
    };

    const numberCDChange = (event) => {
        setnumberPhoneCD(event.target.value);
    };

    // HANDLE CHANGE BANK SECTION
    const cardholderChange = (event) => {
        setcardholderBD(event.target.value);
    };

    const docNumberBDChange = (event) => {
        setdocNumberBD(event.target.value)
    };

    const accountTypeBDChange = (value) => (event) => {

        const name = value.filter((type) => type.id === parseInt(event.target.value));
        setAccountTypeBD(event.target.value);
        setaccountName(name[0].description);
    };

    const currencyTypeBDChange = (value) => (event) => {
        
        const currency = value.filter((curr) => curr.id === parseInt(event.target.value));
        setCurrencyTypeBD(event.target.value);
        setcurrencyName(currency[0].description);
    };

    const accountNumberBDChange = (event) => {
        setaccountNumberBD(event.target.value);
    };

    const interbakBDChange = (event) => {
        setExtAccountNumbBD(event.target.value);
    };
    // HANDLE CHANGE SHIPTO SECTION
    const nameChange = (event) => {
        dispatch(updateShipTo(event.target.value));
    };

    const phoneChange = (event) => {
        //TO DO: CHECK RE TEST
        const re = /^[0-9\b]+.?([1-9]{1,2})?$/;
        if (event.target.value === "" || re.test(event.target.value)) {
          dispatch(updatePhone(event.target.value));
        }
    };
    
    const addressDetailChange = (event) => {
        dispatch(updateAddress(event.target.value));
    };

    const provinceChange = (event) => {
        dispatch(updateProvince(event.target.value));
    };

    const districtChange = (value) => (event) => {

        const filtered= value.filter((selected,index) => selected.id === parseInt(event.target.value));

        const data = {
            districtId: event.target.value,
            districtName: filtered[0].district,
        }
        dispatch(updateDistrict(data));
    };

    // HANDLE SWITCH IN NOTIFICATIONS SECTION
    const handleNotifications = (event) => {
        const {name, checked} = event.target;
        setCheckedNotif({...checkedNotif, [name]: checked});
        updateNotifConfig({...checkedNotif, [name]: checked})
    };

    // Toggle functions
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    // GET DATA FROM EDIT PROFILE SECTION
    useEffect(() => {

        getConfigProfile((data) => {
            setdescriptionPro(data.description);
            setBackground(data.background);
            setprofile(data.avatar);
            setAddressPro(data.address);
        });

        getNotifConfig((response) => {
            setCheckedNotif(response);
        });

    }, [])

    // GET BUSINESS AND CONTACT INFO       
    useEffect(() => {

        getContactData((data) => {
            setEmailAA(data.account_info.email);
            setUserAA(data.account_info.username);
            setcardholderBD(data.bank_info.representative);
            setdocNumberBD(data.bank_info.document);
            setAccountTypeBD(data.bank_info.account_type);
            setAccountTypesBD(data.bank_info.account_type_list);
            setaccountName(data.bank_info.account_type_description)
            setCurrencyListBD(data.bank_info.currency_list);
            setCurrencyTypeBD(data.bank_info.currency);
            setcurrencyName(data.bank_info.currency_description);
            setaccountNumberBD(data.bank_info.account_number);
            setExtAccountNumbBD(data.bank_info.external_account_number);

            settradeNameBUD(data.company_info.comercial_name);
            setbusinessNameBUD(data.company_info.name);
            setRucBUD(data.company_info.ruc);
            setnameCD(data.contact_info.first_name);
            setfull_nameCD(data.contact_info.last_name)
            setDocNumberCD(data.contact_info.document);
            setnumberPhoneCD(data.contact_info.mobile_phone);
        });

    }, [])

    // SAVE SELECTED BACKGROUND JUST IN THE STATE
      const handleSaveBackground = () => {

        const reader = new FileReader();
        reader.onloadend = function () {
            setBackground(reader.result);
        };

        reader.readAsDataURL(croppedBackg);
        setbgFile(croppedBackg);
        setOpenBackg(!openBackg);
    };

    // SAVE SELECTED PROFILE JUST IN THE STATE
    const handleSaveProfile = () => {

        const reader = new FileReader();

        reader.onloadend = function () {
            setprofile(reader.result);
        };

        reader.readAsDataURL(croppedProf);
        setprofileFile(croppedProf);
        setopenProfile(!openProfile);
    }

    // SAVE FIRST PART OF PROFILE 
    // METHOD: PATCH 
    // ENDPOINT: profile/presentation/
    // ALL FIELDS TO BE SENT ARE OPTIONAL: "address", "description", "background", "avatar"
    const onHandleSaveProfile = () => {

        const formData = new FormData();
        formData.append("address", addressPro);
        formData.append("description", descriptionPro);

        if (bgFile) {
            formData.append("background", bgFile);
        };

        if (profileFile) {
            formData.append("avatar", profileFile);
        };
        
        updateConfigProfile(formData, () => {
            setisSavedProf(true);
            setTimeout(() => {
                setisSavedProf(false);
            },2000)
        });
    };

    // SAVE ACCES ACCOUNT 
    // METHOD: PATCH 
    // ENDPOINT: configuration/profile/user_account/
    const saveAccessAccount = () => {

        let submit = true;

        if (isUserAvailable&&!errorUserAA) {
            submit = true
        } else {
            submit = false 
        };

        if (submit) {
            updateUser({'username': userAA}, (response) => {
                setisSavedAI(true);
                setTimeout(() => {
                    setisSavedAI(false);
                  }, 2000);
            })
        };
    };

    // SAVE SHIPPING SECTION 
    // METHOD: PATCH 
    // ENDPOINT: shipping/shipTo/
    const saveShipToData = () => {

        let submit = true;

        if (!shipToValue) {
        submit = false;
        setErrorName(true);
        } else {
        setErrorName(false);
        };

        if (!phoneValue) {
        submit = false;
        setErrorPhone(true);
        } else {
        setErrorPhone(false);
        };

        if (!addressValue) {
        submit = false;
        setErrorAddress(true);
        } else {
        setErrorAddress(false);
        };

        if (submit) {

            const data = {
                id: idValue,
                shipTo: shipToValue,
                phone: phoneValue,
                address: addressValue,
                district: districtValue,
            };

            submitData(data, (success) => {
                setisSavedShipTo(true);
                setTimeout(() => {
                    setisSavedShipTo(false);
                    setopenShipTo(false)

                }, 5000)
            });
        }
    };

    // SAVE BUSINESS AND CONTACT SECTION 
    // METHOD: PATCH
    // ENDPOINT: /profile/data/
    const saveUpdatedMyProfile = () => {

        let submit = true;

        if (!tradeNameBUD) {
            submit=false;
            setErrTradeNameBUD(true)
        } else {
            setErrTradeNameBUD(false);
        };

        if (!nameCD) {
            submit=false;
            setErrNameAA(true);
        } else {
            setErrNameAA(false)
        };

        if (!full_nameCD) {
            submit=false;
            setErrFullNameAA(true);
        } else {
            setErrFullNameAA(false);
        };

        if (!docNumberCD) {
            submit=false;
            setErrdocNumberCD(true);
        } else {
            setErrdocNumberCD(false);
        };

        if (!numberPhoneCD) {
            submit=false;
            setErrNumberPhoneCD(true);
        } else {
            setErrNumberPhoneCD(false);
        };

        if (submit) {

            const data = {
               'commercial_name': tradeNameBUD,
               'first_name': nameCD,
               'last_name': full_nameCD,
               'document' : docNumberCD,
               'mobile_phone' : numberPhoneCD
            };

            updateContactData(data, (response) => {
                setisSavedCI(true);
                setTimeout(() => {
                    setisSavedCI(false);
                }, 2000)
            });
        };
    };

    // SAVE BUSINESS AND CONTACT SECTION 
    // METHOD: POST
    // ENDPOINT: profile/request_bank_change/
    const requestUpdateBank = () => {

        let submit=true;

        if (!cardholderBD) {
            submit= false;
            setErrCardHBD(true);
        } else {
            setErrCardHBD(false);
        };

        if (!docNumberBD) {
            submit=false;
            setErrDocNumbBD(true);
        } else {
            setErrDocNumbBD(false);
        };

        if (!accountNumberBD) {
            submit=false;
            setErrAccountNumb(false);
        } else {
            setErrAccountNumb(false);
        };

        if (errorExtAccNumb) {
            submit=false;
            setErrExtAccNumb(true);
        } else {
            setErrExtAccNumb(false);
        };

        if (submit) {

            const data = {
                "representative": cardholderBD,
                    "dni": docNumberBD,
                    "account_number": accountNumberBD ,
                    "external_account_number": extAccountNumbBD,
                    "account_type": accountTypeBD,
                    "currency": currencyTypeBD
            };

            requestBankData(data, (response) => {
                setisSavedBI(true);
                setTimeout(() => {
                    setisSavedBI(false);
                    setOpenBD(!openBD)
                }, 3000)
            });
        }

    };

    return (
        <LayoutWrapper>
            <div className={classes.container}>
                <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.pageTitle}
                >
                    Configuraciones
                </Typography>
                <p>Las personas que visiten tu perfil verán la siguiente información</p>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                    <AppBar position="static" color="default" className={classes.appBar} >
                        <AntTabs
                            value={value}
                            onChange={handleChange}
                            // indicatorColor="primary"
                            // textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            // className={classes.slideBox}
                        >
                            <Tab className={classes.appBar__slide} label="Editar perfil" {...a11yProps(0)} />
                            <Tab className={classes.appBar__slide} label="Mis datos" {...a11yProps(1)} />
                            <Tab className={classes.appBar__slide} label="Notificaciones"{...a11yProps(2)} />
                        </AntTabs>
                    </AppBar>
                    <SwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                    >
                    <TabPanel
                        value={value}
                        index={0}
                        dir={theme.direction}
                        className={classes.tabpanel}
                    >
                        <Paper className={classes.paper} >
                            <div className={classes.profile}>
                                <img src={background} alt={''} className={classes.background__image} />
                                <div className={classes.background_button}>
                                    <label htmlFor="contained-button-file" className={classes.background__edit}>
                                        <Input accept="image/*" id="contained-button-file" multiple type="file" style={{display:"none"}}  onChange={backgroundChange}/>
                                        <Button size="small" variant="contained" component="span">
                                            Editar<CameraAltIcon className={classes.hideIcon} />
                                        </Button>
                                    </label>
                                </div>
                                <ModalComponent
                                    openModal={openBackg}
                                    onClose={() => setOpenBackg(!openBackg)}
                                    ariaLabelledby="alert-dialog-title"
                                    ariaDescribedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{"Actualizar portada"}</DialogTitle>
                                    <DialogContent>
                                        <JustCropper
                                            aspect={16/9}
                                            width={200}
                                            upImg={backgUploading}
                                            imgName={backgroundName}
                                            cropImage={setCroppedBackg}
                                            {...props}
                                        />
                                    </DialogContent>
                                    <DialogActionCentered className={classes.actions}>
                                        <Button style={{color:"#53cd94"}} onClick={handleSaveBackground}>
                                            Seleccionar la foto
                                        </Button>
                                    </DialogActionCentered>
                                </ModalComponent>
                                <div className={classes.profPhoto}>
                                    <img src={profile} alt={''} className={classes.profPhoto__image} />
                                    <input accept="image/*"  id="icon-button-file" type="file" style={{display:"none"}} onChange={handleProfile}/>
                                    <label htmlFor="icon-button-file" className={classes.profPhoto__button}>
                                        <IconButton color="primary" aria-label="upload picture" component="span" style={{backgroundColor:"#e0e0e0"}}>
                                            <PhotoCamera />
                                        </IconButton>
                                    </label>
                                    <ModalComponent
                                        openModal={openProfile}
                                        onClose={() => setopenProfile(!openProfile)}
                                        ariaLabelledby="alert-dialog-title"
                                        ariaDescribedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Actualizar portada"}</DialogTitle>
                                        <DialogContent>
                                            <JustCropper
                                                aspect={1/1}
                                                width={200}
                                                upImg={profileUploading}
                                                imgName={profileName}
                                                cropImage={setcroppedProf}
                                                {...props}
                                            />
                                        </DialogContent>
                                        <DialogActionCentered>
                                            <Button style={{color:"#53cd94"}} onClick={handleSaveProfile}>
                                                Seleccionar la foto
                                            </Button>
                                        </DialogActionCentered>
                                    </ModalComponent>
                                </div>
                                <div className={classes.details}>
                                    <label className={classes.label}>
                                        Ubicación
                                    </label>
                                    <input
                                        type={"text"}
                                        placeholder={"Ej. Mercado Mayorista de Frutas N° 2"}
                                        className={classes.input}
                                        onChange={addressChange}
                                        value={addressPro}
                                    />
                                    <label className={classes.label}>Presentación</label>
                                    <textarea
                                        maxLength={140}
                                        placeholder={"Cuéntales a los clientes un poco de tu empresa. "}
                                        className={classes.input}
                                        style={{ height: "auto" }}
                                        rows={4}
                                        onChange={descriptionChange}
                                        value={descriptionPro}
                                    ></textarea>
                                    <div className={classes.details__warnings}>
                                        <small>
                                            No incluyas números de contacto o referencias a páginas externas en la presentación, logo o portada de la empresa, ya que esto es considerado una violación de contrato y puede ser motivo de suspensión definitiva de tu cuenta. Para más información, revisa los <a rel="noopener noreferrer" href="/terminos-y-condiciones" className={classes.enlace}>términos y condiciones</a> o comunícate con un asesor de cuenta al correo <a href="mailto:clientes@whipay.pe?Subject=Info: WhiPay - Mercado Mayorista" target="_top" className={classes.enlace}> clientes@whipay.pe</a>.
                                        </small>
                                    </div>
                                    <div className={classes.sucess_message_container} style={{justifyContent:"center"}}>
                                        {isSavedProf?                            
                                            <Typography variant="caption" display="block" gutterBottom className={classes.success_message_text} >
                                                 Se guardó exitosamente
                                            </Typography>
                                            :null
                                        }
                                    </div>
                                    <div className={classes.details__button}>
                                        <Button
                                            variant="contained"
                                            size="large"
                                            color="primary"
                                            className={classes.button}
                                            onClick={onHandleSaveProfile}
                                        >
                                            GUARDAR
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Paper>

                    </TabPanel>
                    <TabPanel
                        value={value}
                        index={1}
                        dir={theme.direction}
                        className={classes.tabpanel}
                    >
                      <div className={classes.subtitle}>
                        <h2 className={classes.label2}>
                            Acceso a tu cuenta
                        </h2>
                        <div>
                            {isSavedAI?                            
                                <Typography variant="caption" display="block" gutterBottom className={classes.success_message_text}>
                                    Se guardó exitosamente
                                </Typography>
                                :null
                            }
                        </div>
                        </div>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>        
                                <Grid item xs={12}  sm={12} md={6}>
                                <label className={classes.label}>
                                    E-mail
                                </label>
                                <input
                                    disabled
                                    type={"text"}
                                    placeholder={"email"}
                                    // onChange={emailChange}
                                    value={emailAA}
                                    className={classes.input}
                                />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <label className={classes.label}> Usuario</label>
                                    <input
                                        type={"text"}
                                        placeholder={"Usuario"}
                                        onChange={userAAChange}
                                        value={userAA}
                                        className={classes.input}
                                    />
                                    {!isUserAvailable? (
                                        <span className={classes.errorLabel}>
                                            Usuario no disponible
                                        </span>
                                    ): null }
                                    {errorUserAA ? (
                                        <span className={classes.errorLabel}>
                                            Por favor, ingresa tu usuario
                                        </span>
                                        ) : (
                                            null
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                        <div className={classes.accountAccess_actions}>
                            <Link to="/recuperar-contraseña"> Cambiar contraseña</Link>
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                className={classes.button}
                                onClick={saveAccessAccount}
                            >
                                GUARDAR
                            </Button>
                        </div>
                        <div className={classes.subtitle}>
                            <h2 className={classes.label2}>
                                Datos de empresa / Datos de contacto
                            </h2>
                            <div>     
                                {isSavedCI?              
                                    <Typography variant="caption" display="block" gutterBottom className={classes.success_message_text}>
                                        Se guardó exitosamente
                                    </Typography> : null
                                }
                            </div>
                        </div>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <label className={classes.label}>
                                        Nombre comercial
                                    </label>
                                    <input
                                        type={"text"}
                                        placeholder={"Nombre comercial de tu empresa"}
                                        className={classes.input}
                                        onChange={tradeNameBUDChange}
                                        value={tradeNameBUD}
                                    />
                                    {errorTradeNameBUD ? (
                                        <span className={classes.errorLabel}>
                                            Por favor, ingresa tu nombre comercial
                                        </span>
                                    ) : (
                                        null
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <label className={classes.label}>
                                        Razón social
                                    </label>
                                    <input
                                        disabled
                                        type={"text"}
                                        placeholder={"Razón social"}
                                        className={classes.input}
                                        onChange={businessNameChangeBUD}
                                        value={businessNameBUD}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <label className={classes.label}>
                                        RUC
                                    </label>
                                    <input
                                        disabled
                                        type={"text"}
                                        placeholder={"RUC"}
                                        className={classes.input}
                                        onChange={rucBUDChange}
                                        value={rucBUD}
                                    />
                                    {errorTradeNameBUD ? (
                                        <span className={classes.errorLabel}>
                                            Por favor, ingresa tu nombre comercial
                                        </span>
                                    ) : (
                                        null
                                    )}
                                </Grid>
                                <Divider />
                                <Grid item xs={12} sm={12} md={6}>
                                    <label className={classes.label}>
                                        Nombres
                                    </label>
                                    <input
                                        type={"text"}
                                        placeholder={"Nombres"}
                                        className={classes.input}
                                        onChange={nameCDChange}
                                        value={nameCD}
                                    />
                                        {errorNameCD ? (
                                            <span className={classes.errorLabel}>
                                                Por favor, ingresa tus nombres
                                            </span>
                                        ) : (
                                            null
                                        )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <label className={classes.label}>
                                        Apellidos
                                    </label>
                                    <input
                                        type={"text"}
                                        placeholder={"Apellidos"}
                                        className={classes.input}
                                        onChange={full_nameCDChange}
                                        value={full_nameCD}
                                    />
                                    {errorFullNameCD ? (
                                        <span className={classes.errorLabel}>
                                            Por favor, ingresa tus apellidos
                                        </span>
                                    ) : (
                                        null
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <label className={classes.label}>
                                        Número de documento
                                    </label>
                                    <input
                                                type={"text"}
                                                placeholder={"Número del documento de identidad"}
                                                className={classes.input}
                                                onChange={docNumberCDChange}
                                                value={docNumberCD}
                                            />
                                            { errdocNumberCD ? (
                                                <span className={classes.errorLabel}>
                                                Por favor, ingresa el  número de tu documento de identidad.
                                                </span>
                                            ) : (
                                                null
                                            )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <label className={classes.label}>
                                        Teléfono celular
                                    </label>
                                    <input
                                            type={"text"}
                                            placeholder={"Ej. 9** *** **1"}
                                            className={classes.input}
                                            onChange={numberCDChange}
                                            value={numberPhoneCD}
                                        />
                                        {errorNumberPhoneCD ? (
                                            <span className={classes.errorLabel}>
                                                Por favor, ingresa tu número de contacto
                                            </span>
                                        ) : (
                                            null
                                        )}
                                </Grid>
                            </Grid>     
                        </Paper>
                        <div className={classes.contactInfo_actions}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    className={classes.button}
                                    onClick={saveUpdatedMyProfile}
                                >
                                    GUARDAR
                                </Button>
                            </div> 
                        <div className={classes.subtitle}>
                            <h2 className={classes.label2}>
                                Datos bancarios (pagos y devoluciones)
                            </h2>
                            <button
                                type="button"
                                className={classes.editButton2}
                                onClick={()=>setOpenBD(!openBD)}
                            >
                                <span>Solicitar edición</span>
                                <img src={Vector} alt="icon"/>
                            </button>
                        </div>
                        <ModalComponent
                                openModal={openBD}
                                onClose={()=>setOpenBD(!openBD)}
                                ariaLabelledby="alert-dialog-title"
                                ariaDescribedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Actualización de datos bancarios"}</DialogTitle>
                            <DialogContent>
                                <div className={classes.sucess_message_container}> 
                                {isSavedBI?                
                                    <Typography variant="caption" display="block" gutterBottom className={classes.success_message_text}>
                                        Comprobaremos la solicitud y actualizaremos los datos bancarios en un máximo de 48 horas
                                    </Typography>
                                    :null
                                }
                                </div>
                                <Grid container spacing={3} style={{margin:"1rem 0", width:"100%"}}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <label className={classes.label}>
                                            Nombre del titular
                                        </label>
                                        <input
                                            type={"text"}
                                            placeholder={"Nombre del titular de la cuenta"}
                                            className={classes.input}
                                            onChange={cardholderChange}
                                            value={cardholderBD}
                                        />
                                        {errorCardHBD ? (
                                            <span className={classes.errorLabel}>
                                                Por favor, ingresa el nombre del titular de la cuenta
                                            </span>
                                        ) : (
                                            null
                                        )}
                                    </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <label className={classes.label}>
                                                Documento
                                            </label>
                                            <input
                                                type={"text"}
                                                placeholder={"Número del documento de identidad"}
                                                className={classes.input}
                                                onChange={docNumberBDChange}
                                                value={docNumberBD}
                                            />
                                            {errorDocNumbBD ? (
                                                <span className={classes.errorLabel}>
                                                Por favor, ingresa el número de tu documento de identidad
                                                </span>
                                            ) : (
                                                null
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <label className={classes.label}>
                                                Tipo de cuenta
                                            </label>
                                            <NativeSelect
                                                id="demo-customized-select-native"
                                                input={<BootstrapInput />}
                                                value={accountTypeBD}
                                                onChange={accountTypeBDChange(accountTypesBD)}
                                            >
                                            {accountTypesBD.map((account, index) => (
                                                <option value={account.id} key={index}>
                                                    {account.description}
                                                </option>
                                            ))}
                                            </NativeSelect>
                                    
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <label className={classes.label}>
                                                Cuenta soles/dólares
                                            </label>
                                            <NativeSelect
                                                id="demo-customized-select-native"
                                                input={<BootstrapInput />}
                                                value={currencyTypeBD}
                                                onChange={currencyTypeBDChange(currencyListBD)}
                                            >
                                                {currencyListBD.map((currency, index) => (
                                                    <option value={currency.id} key={index}>
                                                        {currency.description}
                                                    </option>
                                                ))}
                                            </NativeSelect>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <label className={classes.label}>
                                                Número de cuenta
                                            </label>
                                            <input
                                                type={"text"}
                                                placeholder={"Número de cuenta bancaria"}
                                                className={classes.input}
                                                onChange={accountNumberBDChange}
                                                value={accountNumberBD}
                                            />
                                            {errorAccountNumb ? (
                                                <span className={classes.errorLabel}>
                                                Por favor, ingresa tu número de cuenta bancaria
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <label className={classes.label}>
                                                Código de cuenta interbancaria
                                            </label>
                                            <input
                                                type={"text"}
                                                placeholder={"Número de cuenta interbancaria"}
                                                className={classes.input}
                                                onChange={interbakBDChange}
                                                value={extAccountNumbBD}
                                            />
                                            {errorAccountNumb ? (
                                                <span className={classes.errorLabel}>
                                                Por favor, ingresa tu número de cuenta interbancaria
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActionCentered>
                                <Button style={{color:"#53cd94"}} onClick={requestUpdateBank}>
                                    Solicitar cambio 
                                </Button>
                                </DialogActionCentered>  
                        </ModalComponent>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <label className={classes.label}>
                                        Nombre del titular
                                    </label>
                                        <Typography variant="h3" gutterBottom>
                                        {cardholderBD}
                                        </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <label className={classes.label}>
                                        Documento
                                    </label>
                                    <Typography variant="h3" gutterBottom >
                                        {docNumberBD}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <label className={classes.label}>
                                        Tipo de cuenta
                                    </label>
                                    <Typography variant="h3" gutterBottom >
                                        {accountName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <label className={classes.label}>
                                        Cuenta soles/dólare
                                    </label>
                                    <Typography variant="h3" gutterBottom >
                                        {currencyName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <label className={classes.label}>
                                        Número de cuenta
                                    </label>
                                    <Typography variant="h3" gutterBottom >
                                    {accountNumberBD}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <label className={classes.label}>
                                        Código de cuenta interbancaria:
                                    </label>
                                    <Typography variant="h3" gutterBottom >
                                    {extAccountNumbBD}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                        <div className={classes.subtitle}>
                                <h2 className={classes.label2}>
                                    Dirección de envío
                                </h2>
                                <button
                                    type="button"
                                    className={classes.editButton2}
                                    onClick={()=>setopenShipTo(!openShipTo)}
                                >
                                    <span className={classes.text2}>Editar</span>
                                    <img src={Vector} alt="icon" className={classes.imgVector}/>
                                </button>
                        </div>
                            <ModalComponent
                                openModal={openShipTo}
                                onClose={() => setopenShipTo(!openShipTo)}
                                ariaLabelledby="alert-dialog-title"
                                ariaDescribedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Actualizar información de envío"}</DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={3} style={{margin:"1rem 0", width:"100%"}}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <label className={classes.label}>
                                                Nombres y apellidos
                                            </label>
                                            <input
                                                type={"text"}
                                                placeholder={"Katherine Ramos Garcés"}
                                                onChange={nameChange}
                                                className={classes.input}
                                                value={shipToValue}
                                            />
                                            {errorName ? (
                                                <span className={classes.errorLabel}>
                                                    Por favor, ingresa tus nombres y apellidos
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <label className={classes.label}>
                                                Teléfono
                                            </label>
                                            <input
                                                type={"text"}
                                                placeholder={"985566787"}
                                                className={classes.input}
                                                onChange={phoneChange}
                                                value={phoneValue}
                                            />
                                            {errorPhone ? (
                                                <span className={classes.errorLabel}>
                                                Por favor, ingresa un número telefónico válido
                                                </span>
                                            ) : (
                                                null
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <label className={classes.label}>
                                                Provincia
                                            </label>
                                            <NativeSelect
                                                id="demo-customized-select-native"
                                                input={<BootstrapInput />}
                                                value={provinceValue}
                                                onChange={provinceChange}
                                            >
                                                {provinceList.map((province, index) => (
                                                    <option value={province.province_id} key={province.province_id}>
                                                        {province.province}
                                                    </option>
                                                ))}
                                            </NativeSelect>
                                        </Grid>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <label className={classes.label}>
                                                    Distrito
                                                </label>
                                                <NativeSelect
                                                    input={<BootstrapInput />}
                                                    value={districtValue}
                                                    onChange={districtChange(districtList)}
                                                >
                                                    <option value="">Selecciona Distrito</option>
                                                    {districtList.map((district) => (
                                                    <option value={district.id} key={district.id}>
                                                        {district.district}
                                                    </option>
                                                    ))}
                                                </NativeSelect>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <label className={classes.label}>
                                                    Detalle
                                                </label>
                                                <input
                                                    type={"text"}
                                                    placeholder={"Av.Miguel Iglesias 2345"}
                                                    className={classes.input}
                                                    onChange={addressDetailChange}
                                                    value={addressValue}
                                                />
                                                {errorAddress ? (
                                                    <span className={classes.errorLabel}>
                                                    Por favor, ingresa tu dirección
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </Grid>                 
                                    </Grid>
                                    <div className={classes.sucess_message_container}>
                                        { isSavedShipTo  ?                            
                                            <Typography variant="caption" display="block" gutterBottom className={classes.success_message_text} >
                                                Se guardó exitosamente
                                            </Typography> : null
                                        }
                                    </div>
                                </DialogContent>
                                <DialogActionCentered>
                                <Button style={{color:"#53cd94"}} onClick={saveShipToData}>
                                    Actualizar información
                                </Button>
                                </DialogActionCentered>
                            </ModalComponent>
                            <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <label className={classes.label}>
                                        Entregar a
                                    </label>
                                    <Typography variant="h3" gutterBottom >
                                      {shipToValue}
                                        </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <label className={classes.label}>
                                        Télefono
                                    </label>
                                    <Typography variant="h3" gutterBottom >
                                         {phoneValue}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <label className={classes.label}>
                                        Dirección
                                    </label>
                                    <Typography variant="h3" gutterBottom >
                                        {addressValue}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <label className={classes.label}>
                                        Distrito
                                    </label>
                                    <Typography variant="h3" gutterBottom >
                                       {_districtName}
                                    </Typography>
                                </Grid>
                            </Grid>
                            </Paper>
                    </TabPanel>
                    <TabPanel
                        value={value}
                        index={2}
                        dir={theme.direction}
                        className={classes.tabpanel}
                    >
                            <div className={classes.subtitle}>
                                <h2 className={classes.label2}>
                                    E-mail
                                </h2>
                            </div>
                            <div className={classes.container7}>
                                <Paper className={classes.paper}>
                                    <div className={classes.boxTittle}>
                                        <p>Compraste/vendiste un producto.</p>
                                        <FormGroup>

                                            <FormControlLabel
                                                control={<GreenSwitch checked={checkedNotif.email_buy_sell} onChange={handleNotifications} name="email_buy_sell" />}

                                            />
                                        </FormGroup>
                                    </div>
                                    <div className={classes.boxTittle}>
                                        <p>Ofertas y promociones personalizadas.</p>
                                        <FormGroup>

                                            <FormControlLabel
                                                control={<GreenSwitch checked={checkedNotif.email_offers_promotions} onChange={handleNotifications} name="email_offers_promotions" />}

                                            />
                                        </FormGroup>
                                    </div>
                                    <div className={classes.boxTittle}>
                                        <p>Novedades y actualizaciones disponibles.</p>
                                        <FormGroup>

                                            <FormControlLabel
                                                control={<GreenSwitch checked={checkedNotif.email_news_updatedes} onChange={handleNotifications} name="email_news_updatedes" />}

                                            />
                                        </FormGroup>
                                    </div>
                                    <div className={classes.boxTittle}>
                                        <p>Notificaciones del estado de tus envíos.</p>
                                        <FormGroup>

                                            <FormControlLabel
                                                control={<GreenSwitch checked={checkedNotif.email_shipping_status} onChange={handleNotifications} name="email_shipping_status" />}

                                            />
                                        </FormGroup>
                                    </div>

                                </Paper>
                            </div>
                            <div className={classes.subtitle}>
                                <h2 className={classes.label2}>
                                    WhatsApp
                                </h2>
                            </div>
                            <div className={classes.container7}>
                                <Paper className={classes.paper}>
                                    <div className={classes.boxTittle}>
                                        <p>Notificaciones sobre tus compras y ventas.</p>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<GreenSwitch checked={checkedNotif.whatsapp_buy_sell_reminder} onChange={handleNotifications} name="whatsapp_buy_sell_reminder"/>}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className={classes.boxTittle}>
                                        <p>Estado de tus envíos.</p>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<GreenSwitch checked={checkedNotif.whatsapp_shipping_status} onChange={handleNotifications} name="whatsapp_shipping_status"/>}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className={classes.boxTittle}>
                                        <p>Recordatorios sobre tus compras y ventas</p>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<GreenSwitch checked={checkedNotif.whatsapp_by_sell} onChange={handleNotifications} name="whatsapp_by_sell"/>}
                                            />
                                        </FormGroup>
                                    </div>

                                </Paper>
                            </div>

                    </TabPanel>
                </SwipeableViews>
                </Grid>
            </Grid>
        </LayoutWrapper>
    );
}
