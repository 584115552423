import { makeStyles } from '@material-ui/core/styles';

export const unauthorizedStyles = makeStyles((theme) => ({
    paper: {
        padding: '15px 25px',
        borderRadius: '12px',
        color: '#53CD94',
        fontSize: '25px',
        fontWeight: 'bold',
        boxShadow: 'none',
        width: '100%',
        paddingTop: '46px',
        paddingBottom: '46px',
        textAlign: "center"
    },
    pageTitle: {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '21.33px',
        marginBottom: '10px'
    },
    pageSubTitle: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16.59px',
        marginBottom: '20px',
        color: '#808285'
    },
    image: {
        width: '100px',
        marginBottom: '20px'
    },
    buttonWrap: {
        clear: "both",
        display: "flex",
        alignItems: "center",
        '& a': {
            margin: '0 auto',
        }
    },
    button: {
        height: '32.75px',
        width: '200px',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14.22px',
        background: '#53CD94',
        borderRadius: '8px',
        boxShadow: 'none',
        '&:hover': {
            background: '#006D51',
        }
    }
}));