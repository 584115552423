import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import Typography from "@material-ui/core/Typography";
import { cardProductStyles } from "./styles";
import formatNumber from 'functions/regex';

export default function CardProduct(props) {

    const priceVariation = props.data.price_variation,
    classes = cardProductStyles();

  return (
    <Link to={`/mercado/${props.data.sale_slug}`} style={{ textDecoration: "none" }}>
      <Paper className={classes.card}>
        <img
          src={props.data.image}
          alt={props.data.short_description}
          className={classes.card__image}
        />
        <div className={classes.card__details}>
          <Typography
            variant="h2"
            component="h6"
            gutterBottom
            className={classes.block6}
          >
            {props.data.product_description}
          </Typography>
          <div className={classes.card__detail}>
            <div
              className={clsx(
                classes.card__arrow,
                classes.block2A,
                priceVariation > 0 && classes.block2B,
                priceVariation < 0 && classes.block2C
              )}
            >
              {priceVariation > 0 ? (
                <TrendingUpIcon className={classes.block3} />
                  ) : priceVariation < 0 ? (
                  <TrendingDownIcon className={classes.block3} />
                    ) : (
                    <TrendingFlatIcon className={classes.block3} />
              )}
            </div>
            <div className={classes.priceWrap}>
              <span><b>S/{formatNumber(props.data.price)}</b> x {props.data.product_unit}</span>
            </div>
          </div>
                  
                  <span className={classes.caption}>{props.data.equivalency_kg}</span>
        </div>
        
      </Paper>
    </Link>
  );
}
