/*-- Libraries --*/
import React from 'react';
import {Typography} from "@material-ui/core";

/*-- styles,components,functions --*/
import { sellWithUsStyle } from "pages/Resources/SellWithUs/style";
import LayoutWrapper from 'features/layout/wrapper';

export default function SellWithUs(props) {
    const classes = sellWithUsStyle();
    return (
        <LayoutWrapper>
            <Typography
                variant="h2"
                component="h5"
                gutterBottom
                className={classes.pageTitle}
            >
                Vende con nosotros
            </Typography>
        </LayoutWrapper>
    )
}
