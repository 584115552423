/*-- Libraries --*/
import React from "react";
import {CircularProgress, withStyles} from "@material-ui/core";

/*-- styles,components,functions --*/
import logo from "assets/images/logo.svg";

const Loader = withStyles( (theme) => ({
    root:{
        height: '30px !important',
        width: '30px !important',
        color: '#006D51 !important',
        marginTop: '30px'
    }
}) )(CircularProgress);

export default function Loading() {

    return (
      <div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
        <img
          src={logo}
          alt="logo"
          style={{
            width: "200px",
            // marginLeft: "calc( 50% - 130px )",
            // marginRight: "30px",
          }}
        />
          <Loader />
      </div>
    );

}