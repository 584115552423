/*-- Libraries --*/
import React from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import TimeAgo from 'javascript-time-ago';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
/*-- redux --*/

/*-- styles,components,functions --*/

import formatNumber from 'functions/regex';
import { lastPublicationStyles } from './styles';

export default function LastPublications(props) {
    const timeAgo = new TimeAgo('es-MX');
    const classes = lastPublicationStyles();
    const row = props.row;
    return (
        <tr>
            <th style={{ textAlign: 'center' }}>
                <img
                    src={row.avatar}
                    height={48}
                    width={48}
                    style={{ margin: '0 auto' }}
                    alt={''}
                />
            </th>
            <th>
                <Typography
                    // onClick={() => CompareClick(row)}
                    variant="h2"
                    // component="h3"
                    gutterBottom
                    className={clsx(
                        classes.orderTableTitle,
                        classes.link,
                        classes.visited
                    )}
                >
                    {row.product_description}
                </Typography>
                <Typography
                    variant="h2"
                    component="h3"
                    gutterBottom
                    className={clsx(
                        classes.orderTableSubTitle,
                        true && classes.orderTableSubTitleUp
                    )}
                >
                    <span>{row.seller}</span>
                </Typography>
            </th>
            <th>
                <div
                    className={clsx(
                        classes.iconBox,
                        row.price_variation > 0 && classes.iconBoxUp,
                        row.price_variation < 0 && classes.iconBoxDown
                    )}
                >
                    {row.price_variation > 0 ? (
                        <TrendingUpIcon className={classes.icon} />
                    ) : row.price_variation < 0 ? (
                        <TrendingDownIcon className={classes.icon} />
                    ) : (
                        <TrendingFlatIcon className={classes.icon} />
                    )}
                </div>
                <Typography
                    variant="h2"
                    component="h3"
                    gutterBottom
                    className={clsx(
                        classes.orderTableTitle,
                        classes.orderTableTitle1
                    )}
                >
                S/{row.price_variation}
                </Typography>

            </th>
            <th>
                <Typography
                    variant="h2"
                    component="h3"
                    gutterBottom
                    className={clsx(
                        classes.orderTableTitle,
                        classes.orderTableTitle1
                    )}
                >
                    S/{formatNumber(row.price)}
                </Typography>
            </th>
            <th>
                <div className={classes.orderType}>{row.grade}</div>
            </th>
            <th
                style={{
                    textAlign: 'right',
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '14.22px',
                    color: '#808285',
                }}
            >
                <span> {timeAgo.format(Date.parse(row.date))} </span>
            </th>
        </tr>
    );
}
