import axios from "axios";

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};
export default setAuthToken;

// Resources
// https://ichi.pro/es/como-implementar-la-funcionalidad-refresh-token-front-end-263837909853540

// Add a request interceptor
// You can intercept requests or responses before they are handled by then or catch.
axios.interceptors.request.use(
	function (config) {
  // Do something before request is sent
		return config;
	},
	function (err) {
    // Do something with request error
		return Promise.reject(err);
	});
// Add a response interceptor

  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
    if (error.response) {
        const { status } = error.response;
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
        if (status === 403) {

          delete axios.defaults.headers.common["Authorization"];
          localStorage.clear();
          window.location.href = "/login";

        }
      }
      return Promise.reject(error);
    }
  );





