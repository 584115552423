/*-- Libraries --*/
import React, { useState } from "react";
import {useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Typography,
  Button,
  Paper,
} from "@material-ui/core";

/*-- redux --*/
import { requestForHelp } from "../slices";

/*-- styles,components,functions --*/
import { needHelpStyles } from "./styles";
import LayoutWrapper from "features/layout/wrapper";

export default function NeedHelp(props) {
  const classes = needHelpStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const orderSlug = props.match.params.orderSlug;
  const sellerSlug = props.location.state;
  //const [document, setDocument] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  // const inputFile = useRef(null);

  // const openSelectFile = () => {
  //   inputFile.current.click();
  // };
  const requestChange = (event) => {
    setMessage(event.target.value);
  };
  // const fileChange = (event) => {
  //   if (document) {
  //     setDocument("");
  //   } else {
  //     setDocument(event.target.files[0]);
  //     event.target.value = null;
  //   }
  // };

  const submit = () => {

    let submit = true;

    if (message === "" || message == null) {
      submit = false;
      setErrorMessage(true);

    } else {
      submit = true;
      setErrorMessage(false);
    }

    if (submit) {
      // TO DO :sellerSlug is not required anymore
      dispatch(requestForHelp({order_slug:orderSlug, seller_slug:sellerSlug, message:message}));
      history.push(`/mis-ordenes/detalle/${orderSlug}`);
    }
  };

  return (
    <LayoutWrapper>
      <div className={classes.container}>
        <Typography
          variant="h2"
          component="h5"
          gutterBottom
          className={classes.pageTitle}
        >
          Necesito ayuda
        </Typography>
      </div>
      <Paper className={classes.paper}>
        <span className={classes.text}>
          Hola, por favor describe tu consulta o inconveniente presentado, y en
          la brevedad posible te daremos respuesta al correo electrónico
          registrado.
        </span>
        <textarea
          className={classes.input}
          style={{ height: "auto" }}
          rows={6}
          onChange={requestChange}
        ></textarea>
        {errorMessage ? (
          <span className={classes.errorLabel}>Por favor ingresa tu solicitud de ayuda</span>
        ) : (
          ""
        )}
        {/* <div className={classes.uploadFilesWrap}>
          <div className={classes.uploadFilesButtonWrap}>
            <input
              type="file"
              id="file"
              accept="image/pdf/*"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={fileChange}
            />
            {document ? (
              <p className={classes.filesListHeading}>
                Agrega imagenes o Pdf. Máximo 5 MB
              </p>
            ) : (
              <>
                <AddIcon
                  onClick={openSelectFile}
                  style={{
                    height: "48px",
                    width: "48px",
                    borderRadius: "50px",
                    background: "#E0E0E0",
                    color: "#FFF",
                    cursor: "pointer",
                    margin: "0 auto",
                  }}
                />
                <p className={classes.filesListHeading}>
                  Adjunta el comprobante de transferencia
                </p>
              </>
            )}

            {document === "" ? (
              ""
            ) : (
              <ul className={classes.filesList}>
                <li>
                  <img src={product_1} alt={document.name} />
                  <span>{document.name}</span>
                  <DeleteIcon
                    style={{ color: "#53CD94" }}
                    onClick={() => setDocument("")}
                  />
                </li>
              </ul>
            )}
          </div>
        </div>
 */}
         <Button
          variant="contained"
          size="medium"
          color="primary"
          className={classes.submit}
          onClick={submit}
        >
          ENVIAR
        </Button>
      </Paper>
    </LayoutWrapper>
  );
}
