import { makeStyles } from "@material-ui/core";

export const myTermsAndConditionsStyle = makeStyles((theme) => ({

  paper: {
    padding: "35px 55px",
    borderRadius: "12px",
    color: "#53CD94",
    fontSize: "25px",
    fontWeight: "bold",
    boxShadow: "none",
    width: "100%",
    marginBottom: "30px",
    overflow: "auto", 
    background:"white",
    [theme.breakpoints.down("sm")]: {
      padding: "25px",
    },
  },
  container:{
    display:"flex",
    "& p":{
      color:"#808285",
      fontSize:"12px",
      fontWeight:100,
    },
  },
  container2:{
    textAlign:"justify",
    color:"black",
    fontSize:"14px",
    fontWeight:100,
    paddingTop:"10px",
  },
  pageTitle: {
    color: "#333333",
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "38px",
    alignItems: "baseline",
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "23px",
    },
  },
  index:{
    "& h2":{
      color:"black",
      fontSize:"25px",
      fontWeight:100,
    },
    "& ul":{
      color:"black",
      fontSize:"14px",
      fontWeight:100,
      listStyle:"none",
      "& li":{
        "& a":{
          color:"black",
          textDecoration:"none",
          '&:hover': {
            color: '#53CD94',
        }
        } 
      }

    }
  },

  subTitulos:{
    color: "#333333",
    fontSize: "25px",
    fontWeight: 500,
    lineHeight: "38px",
    alignItems: "baseline",
    flexGrow: 2,
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  container3:{
    "& p":{
      color:"black",
      fontSize:"14px",
      fontWeight:100,
      textAlign:"justify",
    }
  },

  
 
}))