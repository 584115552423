/*-- Libraries --*/
import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
    Typography,
    CircularProgress,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    withStyles,
    Paper,
    Grid,
    Button,
} from '@material-ui/core';

import CallMadeIcon from '@material-ui/icons/CallMade';

import Carousel from 'react-multi-carousel';
/*-- redux --*/
import {
    indicators,
    indicatorsLoading,
    getIndicators,
    getBoardPrices,
    getProductsBySeller,
    priceBoardOnChange,
    priceBoard,
    priceBoardData,
    priceBoardPage,
    priceBoardPageChange,
    getOffers,
    offers,
    products,
    // getSliderOffers,
    getSliderOffersPOST,
} from './Slice';

/*-- styles,components,functions --*/
import { dashboardStyles } from './styles';
import TradedGoodsList from 'features/offersSldier';
import ProductSlider from 'features/product/slider';
import formatNumber from 'functions/regex';
import LayoutWrapper from 'features/layout/wrapper';
import LastPublications from 'features/lastPublications';

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 50,
        background: '#DDF5EA !important',
        borderRadius: '50px',
        display: 'flex',
        height: '30px',
    },
    switchBase: {
        color: '#EB5757 !important',
        padding: '5px 5px',
        '&$checked': {
            backgound: '#DDF5EA !important',
            color: '#53CD94 !important',
        },
    },
    track: {
        backgroundColor: '#DDF5EA !important',
        opacity: '0 !important',
    },
    checked: {},
}))(Switch);

const Loader = withStyles((theme) => ({
    root: {
        height: '20px !important',
        width: '20px !important',
        color: '#53CD94 !important',
    },
}))(CircularProgress);

// Renders dashboard page
export default function AppDashboard(props) {
    const dispatch = useDispatch();
    const classes = dashboardStyles();
    const [rowsPerPage] = useState(10);

    const _indicatorsLoading = useSelector(indicatorsLoading);
    const _indicators = useSelector(indicators);
    const _priceBoard = useSelector(priceBoard);
    const _priceBoardData = useSelector(priceBoardData);
    const _priceBoardPage = useSelector(priceBoardPage);
    const _offers = useSelector(offers);
    const _products = useSelector(products);
    const general_info = useSelector((state) => state.auth.client);

    const [isSeller, setIsSeller] = useState(false);

    const history = useHistory();
    // const [selectedOpen, setSelectedOpen] = useState(false);
    // const [selectedProduct, setSelectedProduct] = React.useState({});

    useEffect(() => {
        // ENDPOINT: dashboard/down_prices/
        // METHOD:GET
        dispatch(getBoardPrices());
        // ENDPOINT: "dashboard/latest_sales/"
        // METHOD: GET
        dispatch(getOffers());
        // ENDPOINT: "dashboard/recomendations/"
        // METHOD: GET
        dispatch(getProductsBySeller());
        // ENDPOINT: "dashboard/recomendations/"
        // METHOD: POST
        dispatch(getSliderOffersPOST());
    }, [dispatch]);
        
    useEffect(() => {
        if (general_info && general_info.can_publish) {
            // ENDPOINT: dashboard/indicators/
            // METHOD: GET
            dispatch(getIndicators());
            setIsSeller(true);
        }
    }, [dispatch, general_info]);

    const handleBuy = (row) => {
        history.push('/mercado', { selectedProduct: row });
    };

    const handleSell = () => {
        history.push('/publicar-oferta');
    };

    const responsive = {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024,
            },
            items: 5,
            partialVisibilityGutter: 40,
        },
        mobile: {
            breakpoint: {
                max: 500,
                min: 0,
            },
            items: 1,
            partialVisibilityGutter: 0,
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 501,
            },
            items: 3,
        },
    };
    return (
        <LayoutWrapper>
            <div className={classes.container1}>
                <Typography
                    variant="h2"
                    component="h3"
                    gutterBottom
                    className={classes.title1}
                >
                    {general_info.full_name}
                </Typography>
            </div>
            { isSeller ? (
                <>
                    <div className={classes.container2}>
                        <Typography
                            variant="h2"
                            component="h3"
                            gutterBottom
                            className={classes.title3}
                        >
                            Indicadores
                        </Typography>
                    </div>
                    
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={6} md={3}>
                            <Paper className={classes.miniPaper}>
                                TRANSACCIONES EN EL MES
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className={classes.miniCartTitle}
                                >
                                    {_indicatorsLoading ? (
                                        <Loader value={10} />
                                    ) : (
                                        +_indicators.numTrans
                                    )}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <Paper className={classes.miniPaper}>
                                TOTAL DE VENTAS MENSUALES
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className={classes.miniCartTitle}
                                >
                                    {_indicatorsLoading ? (
                                        <Loader value={10} />
                                    ) : (
                                        `S/ ` + formatNumber(_indicators.tVentasM)
                                    )}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <Paper className={classes.miniPaper}>
                                TOTAL DE ORDENES EN CURSO
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className={classes.miniCartTitle}
                                >
                                    {_indicatorsLoading ? (
                                        <Loader value={10} />
                                    ) : (
                                        _indicators.ordenesC
                                    )}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <Paper className={classes.miniPaper}>
                                TOTAL DE OFERTAS ACTIVAS
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className={classes.miniCartTitle}
                                >
                                    {_indicatorsLoading ? (
                                        <Loader value={10} />
                                    ) : (
                                        _indicators.ofertasA
                                    )}
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </>
            ) : (<></>)}

            <div className={classes.container3}>
                <Typography
                    variant="h2"
                    component="h3"
                    gutterBottom
                    className={classes.title4}
                    style={{ flexGrow: 1 }}
                >
                    Indice de bienes comercializados
                </Typography>
                <Typography
                    variant="h2"
                    component="h3"
                    gutterBottom
                    className={classes.titleLink}
                    to={'/indice-de-bienes'}
                >
                    <Link to="/indice-de-bienes">
                        Ver todos los indicadores
                    </Link>
                </Typography>
            </div>

            <Grid container spacing={3}>
                <TradedGoodsList {...props} />
            </Grid>

            <div className={classes.container3}>
                <Typography
                    variant="h2"
                    component="h3"
                    gutterBottom
                    className={classes.title4}
                >
                    Tendencias del mercado
                </Typography>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Paper
                        className={clsx(
                            classes.paper,
                            classes.board,
                            _priceBoard && classes.boardRed
                        )}
                    >
                        <div className={classes.boardTitleWrap}>
                            <Typography
                                variant="h2"
                                component="h3"
                                gutterBottom
                                className={classes.boardTitle}
                            >
                                <CallMadeIcon
                                    className={clsx(
                                        classes.iconrotate,
                                        _priceBoard && classes.iconrotateChange
                                    )}
                                />
                                {_priceBoard
                                    ? 'Precios al alza'
                                    : 'Precios a la baja'}
                            </Typography>
                            <div className={classes.boardSwitch}>
                                <span style={{ textTransform: 'uppercase' }}>
                                    {' '}
                                    {_priceBoard
                                        ? 'VER PRECIOS A LA BAJA'
                                        : 'Precios al alza'}
                                </span>
                                <AntSwitch
                                    checked={_priceBoard}
                                    onChange={() =>
                                        dispatch(priceBoardOnChange())
                                    }
                                    name="checkedC"
                                />
                            </div>
                        </div>
                        <TableContainer
                            rowcount={5}
                            className={classes.tableCon1}
                        >
                            <Table
                                className={classes.boardTable}
                                aria-label="simple table"
                                rowcount={5}
                            >
                                <TableHead rowcount={5}>
                                    <TableRow className={classes.boardTableRow}>
                                        <TableCell
                                            className={classes.boardTableCell}
                                        >
                                            VARIACIÓN
                                        </TableCell>
                                        <TableCell
                                            className={classes.boardTableCell}
                                            align="left"
                                        >
                                            PRODUCTO
                                        </TableCell>
                                        <TableCell
                                            className={classes.boardTableCell}
                                            align="left"
                                        >
                                            CALIBRE
                                        </TableCell>
                                        <TableCell
                                            className={classes.boardTableCell}
                                            align="left"
                                        >
                                            PRECIO
                                        </TableCell>
                                        <TableCell
                                            className={classes.boardTableCell}
                                            align="right"
                                        ></TableCell>
                                        <TableCell
                                            className={classes.boardTableCell}
                                            align="right"
                                        ></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? _priceBoardData.slice(
                                              _priceBoardPage * rowsPerPage,
                                              _priceBoardPage * rowsPerPage +
                                                  rowsPerPage
                                          )
                                        : _priceBoardData
                                    ).map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell
                                                className={
                                                    classes.boardTableBodyCell
                                                }
                                                component="th"
                                            >
                                                <CallMadeIcon
                                                    className={clsx(
                                                        classes.priceBoardUp,
                                                        !_priceBoard &&
                                                            classes.priceBoardDown
                                                    )}
                                                />{' '}
                                                {row.price_variation}
                                            </TableCell>
                                            <TableCell
                                                className={
                                                    classes.boardTableBodyCell
                                                }
                                                align="left"
                                            >
                                                {row.product}
                                            </TableCell>
                                            <TableCell
                                                className={
                                                    classes.boardTableBodyCell
                                                }
                                                align="left"
                                            >
                                                {row.grade}
                                            </TableCell>
                                            <TableCell
                                                className={
                                                    classes.boardTableBodyCell
                                                }
                                                align="left"
                                            >
                                                S/ {formatNumber(row.price)}
                                            </TableCell>
                                            <TableCell
                                                className={
                                                    classes.boardTableBodyCell
                                                }
                                                align="right"
                                            >
                                                <Button
                                                    onClick={() => handleSell()}
                                                    className={
                                                        classes.boardTableCellButton
                                                    }
                                                >
                                                    VENDER
                                                </Button>
                                            </TableCell>
                                            <TableCell
                                                className={
                                                    classes.boardTableBodyCell
                                                }
                                                align="right"
                                            >
                                                <Button
                                                    onClick={() =>
                                                        handleBuy(row)
                                                    }
                                                    className={clsx(
                                                        classes.boardTableCellButton,
                                                        classes.boardTableCellButton1
                                                    )}
                                                >
                                                    COMPRAR
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={_priceBoardData.length}
                            rowsPerPage={rowsPerPage}
                            page={_priceBoardPage}
                            className={classes.testClass}
                            onPageChange={(event, page) =>
                                dispatch(priceBoardPageChange(page))
                            }
                            // onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Paper className={clsx(classes.paper, classes.order)}>
                        <Typography
                            variant="h2"
                            component="h3"
                            gutterBottom
                            className={classes.orderTitle}
                        >
                            Ultimas ventas publicadas
                        </Typography>
                        <TableContainer
                            rowcount={5}
                            className={classes.tableCon1}
                        >
                            <table className={classes.orderTable}>
                                <thead>
                                    <tr>
                                        <th> </th>
                                        <th> PRODUCTO </th>
                                        <th> VARIACIÓN </th>
                                        <th> PRECIO </th>
                                        <th> CALIBRE </th>
                                        <th> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_offers.map((row, index) => (
                                        <LastPublications row={row} />
                                    ))}
                                </tbody>
                                {/* DEPRECATED - Will be possible enabled it again later */}
                                {/* <tfoot>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <img
                        src={InfoIcon}
                        height={20}
                        width={20}
                        style={{ margin: "0 auto" }}
                        alt={""}
                      />
                    </td>

                    <td colSpan={3}>
                      <Typography
                        variant="h2"
                        component="h3"
                        gutterBottom
                        className={clsx(classes.orderTableFooterText)}
                      >
                        ¿Tienes productos en oferta?
                      </Typography>
                      <Typography
                        variant="h2"
                        component="h3"
                        gutterBottom
                        className={clsx(classes.orderTableFooterSubText)}
                      >
                        Consulta las condiciones y precios para publicar ofertas
                        destacadas
                        <br />
                        SOLO PRODCUTOS QUE CUMPLAN LAS CONDICIONES
                      </Typography>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Link
                        to="/publicar-oferta"
                        style={{ textDecoration: "none" }}
                      >
                        <Button className={clsx(classes.orderTableCellButton)}>
                          PUBLICAR OFERTA
                        </Button>
                      </Link>
                    </td>
                  </tr>
                </tfoot> */}
                            </table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
            <div className={classes.container3}>
                <Typography
                    variant="h2"
                    component="h3"
                    gutterBottom
                    className={classes.title4}
                >
                    Recomendaciones para ti
                </Typography>
            </div>
            <Grid container spacing={3}>
                <Carousel
                    style={{ width: '100%' }}
                    infinite={true}
                    showDots={true}
                    dotListClass="dashboard-offer-dot-style"
                    responsive={responsive}
                >
                    {_products.map((product, index) => (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            key={index}
                            id={'by-seller-slider'}
                            style={{ margin: '0 15px' }}
                        >
                            <ProductSlider _product={product} {...props} />
                        </Grid>
                    ))}
                </Carousel>
            </Grid>
        </LayoutWrapper>
    );
}
