import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import {
    myPublishedSalesEP,
    saleCreateEP,
    saleGalleryEP,
    saleExtraInfoEP,
    categoryFilterEP,
    productsFiltersEP,
    varietiesFiltersEP,
    updateSaleEP,
} from "endpoints";

export const offersSlice = createSlice({
    name: "offers",
    initialState: {
        category: "",
        product: "",
        variety: "",
        grade: "",
        maturation: "",
        categories: [],
        loadingOffers: false,
        products: [],
        varieties: [],
        grades: [],
        files: [],
        offers: [],
        maturationDegrees: [],
        graph: {},
        loadingGraph: true,
        setIHaveInitialInfo: false,
        loading: true,
    },
    reducers: {
        setLoadingOffers: (state, action) => {
            state.loadingOffers = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setCategories: (state, action) => {
            state.categories = action.payload;
        },
        setProductList: (state, action) => {
            state.products = action.payload;
        },
        setVarieties: (state, action) => {
            state.varieties = action.payload;
        },
        setCategory: (state, action) => {
            state.category = action.payload;
        },
        setProduct: (state, action) => {
            state.product = action.payload.product;
        },
        setVerity: (state, action) => {
            state.variety = action.payload;
        },
        setGrade: (state, action) => {
            state.grade = action.payload;
            state.loadingGraph = true;
        },
        setFiles: (state, action) => {
            if (typeof action.payload.uploaded != "undefined") {
                state.files[action.payload.uploaded] = action.payload;
            } else {
                state.files = [...state.files, action.payload];
            }
        },
        setProgress: (state, action) => {
            state.files[action.payload.id].uploading = action.payload.per;
        },
        setDelete: (state, action) => {
            state.files[action.payload].deleting = true;
        },
        removeFile: (state, action) => {
            state.files.splice(action.payload, 1);
        },
        setOffers: (state, action) => {
            state.offers = action.payload;
        },
        deleteOffers: (state, action) => {
            state.offers.splice(action.payload, 1);
        },
        setGraph: (state, action) => {
            state.graph = action.payload;
            state.loadingGraph = false;
        },
        setEmptyFiles: (state, action) => {
            state.files = action.payload;
        },
        setFilesDirect: (state, action) => {
            state.files = action.payload;
        },
        setFilesUpdated: (state, action) => {
            state.files = action.payload;
        },
        setMaturation: (state, action) => {
            state.maturation = action.payload;
        },
    },
});

export const {
    setCategories,
    setCategory,
    setProductList,
    setVarieties,
    setProduct,
    setLoadingOffers,
    setLoading,
    setVerity,
    setGrade,
    setFiles,
    setProgress,
    setDelete,
    removeFile,
    setOffers,
    deleteOffers,
    setGraph,
    setEmptyFiles,
    setFilesDirect,
    setFilesUpdated,
    setMaturation,
} = offersSlice.actions;

export const categories = state => state.offers.categories;
export const products = state => state.offers.products;
export const varieties = state => state.offers.varieties;
export const grades = state => state.offers.grades;
export const offers = (state) => state.offers.offers;
export const loadingOffers = (state) => state.offers.loadingOffers;
export const loading = (state) => state.offers.loading;
export const category = (state) => state.offers.category;
export const product = (state) => state.offers.product;
export const variety = (state) => state.offers.variety;
export const grade = (state) => state.offers.grade;
export const files = (state) => state.offers.files;
export const graph = (state) => state.offers.graph;
export const loadingGraph = (state) => state.offers.loadingGraph;
export const maturationDegrees = (state) => state.offers.maturationDegrees;
export const mature = (state) => state.offers.maturation;

//
export const getCategories = (action) => async (dispatch) => {

    const data = await axios.get(categoryFilterEP).then((response) => {

        return response.data
    })
    dispatch((setCategories(data)))
};

export const getProductList = (action) => async (dispatch) => {
    const data = await axios.post(productsFiltersEP, action).then((response) => {

        return response.data
    })
    dispatch((setProductList(data)))
}
export const getVarietyList = (action) => async (dispatch) => {
    const data = await axios.post(varietiesFiltersEP, action).then((response) => {

        return response.data
    })
    dispatch((setVarieties(data)))
}

export const updateOffer = (action, callback) => {

    axios.put(updateSaleEP, action).then((response) => {

        callback(response.data);
    })
}

//saleCreateEP


export const submitProduct = async (action, succesCallback) => {
    await axios.post(saleCreateEP, action).then((response) => {
        succesCallback(response.data)
    });
};

// updating states

export const updateCategory = (action) => (dispatch) => {
    dispatch(setCategory(action));
};
export const updateProduct = (action) => (dispatch) => {
    dispatch(setProduct(action));
};

export const updateVerity = (action) => (dispatch) => {
    dispatch(setVerity(action));
};

export const updateGrade = (action) => (dispatch) => {
    dispatch(setGrade(action));
};

export const updateMature = (action) => async (dispatch) => {
    dispatch(setMaturation(action));
};

//saleGalleryEP

export const updateFiles = (action, files, sucess) => async (dispatch) => {

    const count = files.length;
    const temp = {
        id: "",
        url: "Uploading...",
        uploading: 0,
    };

    dispatch(setFiles(temp));

    const saveLocally = (data) => {

        if (typeof data.id != "undefined") {
            const uploaded = {
                id: data.id,
                url: data.url,
                uploaded: count,
            };

            dispatch(setFiles(uploaded));

        } else {
            dispatch(removeFile(count));
        }
    }
    await axios
        .post(saleGalleryEP, action, {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: function (data) {
                const percentCompleted = Math.round((data.loaded * 100) / data.total);
                const _data = {
                    per: percentCompleted,
                    id: count,
                };
                dispatch(setProgress(_data));
            },
        })
        .then((response) => {
            sucess()
            return saveLocally(response.data);
        })
        .catch((error) => {
            return error.response
        });
};
export const getFile = (action) => async (dispatch) => {
    //dispatch(setDelete(action.key));

    let formData = new FormData();

    formData.append("id", action);

    const data = await axios.put(saleGalleryEP, action).then((response) => {
        return response.data;
    });

    dispatch(setFilesDirect(data));
};
export const deleteFile = (action) => async (dispatch) => {
    dispatch(setDelete(action.key));

    //let formData = new FormData();

    //formData.append("id", action.data.id);
    await axios.delete(saleGalleryEP, action).then((response) => {
        return response.data;
    });

    dispatch(removeFile(action.key));
};
export const cleanFiles = () => async (dispatch) => {
    dispatch(removeFile([]));
};
export const getGallery = () => async (dispatch) => {
    const data = await axios.get(saleGalleryEP).then((response) => {
        return response.data;
    });

    // data.map((firstFile, index) => {
    //   firstFile.uploaded = index;
    // });

    dispatch(setFilesDirect(data));
};

//myPublishedSalesEP

export const getOffers = () => async (dispatch) => {

    dispatch(setLoadingOffers(true));
    const data = await axios.get(myPublishedSalesEP).then((response) => {
        return response.data;
    });
    dispatch(setLoadingOffers(false));
    dispatch(setOffers(data));

};

export const updateOffers = async (newData, callback, fail) => {
    await axios
        .patch(myPublishedSalesEP, newData)
        .then((response) => {
            if (response.status === 202) {
                callback();
            }
        }).catch((error) => {
            if (error.response) {
                fail(error.response.data.detail);
            }
        });
};

export const updateStateSale = (action) => async (dispatch) => {
    const getAllOffers = await axios
        .get(myPublishedSalesEP)
        .then(async (response) => {
            const data = await axios
                .put(myPublishedSalesEP, action)
                .then((response) => {
                    return response.data;
                });
            return response.data.map((item) =>
                item.id === data.id ? { ...item, status: data.status } : item
            );
        });
    dispatch(setOffers(getAllOffers));
};

export const deleteOffer = (action) => async (dispatch) => {
    let formData = new FormData();
    formData.append("id", action.data.id);
    await axios.delete(myPublishedSalesEP, { data: formData }).then((response) => {
        return response.data;
    });
    dispatch(deleteOffers(action.key));
};

// saleExtraInfoEP

export const getGraph = (action) => async (dispatch) => {
    const data = await axios
        .post(saleExtraInfoEP, { product: action.verity, grade: action.grade })
        .then((response) => {
            return response.data;
        });
    dispatch(setGraph(data));
};

export const emptyFiles = (action) => async (dispatch) => {
    dispatch(setEmptyFiles(action));
};

export const unMountComponent = () => async (dispatch, getState) => {
    const { offers } = getState();
    offers.files.forEach((singleFile, index) => {
        const key = {
            key: index,
            data: singleFile,
        };
        dispatch(deleteFile(key));
    });
};

export default offersSlice.reducer;
