/*-- Libraries --*/
import React, { useState, useRef, useCallback } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";



export default function JustCropper(props) {
  const { upImg,imgName, cropImage, aspect, width} = props;
  // const classes = cropperImageStyles();
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "px", width: width ,aspect: aspect});

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const croppImage = new File([u8arr], filename, { type: mime });
    cropImage(croppImage);
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;

    const ctx = canvas.getContext("2d");

    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = "medium";
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        dataURLtoFile(reader.result, imgName);
      };
    }, 'image/jpeg', 0.5);
  };


  const onCropComplete = (crop) => {
    if (imgRef.current && crop.width && crop.height) {
      getCroppedImg(imgRef.current, crop);
    }
  };

  return (
    <>
      <ReactCrop
        src={upImg}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={onCropComplete}
        minWidth={250}
        style = {{ maxWidth: '800px',margin:"auto"}}
      />
    </>
  );
}