import { makeStyles } from "@material-ui/core/styles";
export const needHelpStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      display: "flow-root",
    },
  },
  pageTitle: {
    color: "#53CD94",
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "38px",
    alignItems: "baseline",
    flexGrow: 1,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "55px",
    borderRadius: "12px",
    fontSize: "25px",
    boxShadow: "none",
    width: "100%",
    marginBottom: "30px",
    overflow: "auto",
    color: "#616060",
    [theme.breakpoints.down("sm")]: {
      padding: "25px",
    },
  },
  text:{
   width:"80%",
   margin:"auto",
    fontSize:'18px',
    fontWeight:'400',
    lineHeight:'23,7px',
    [theme.breakpoints.down("sm")]: {
      fontSize:'14px',
    },
  },
  input: {
    display: "flex",
    fontFamily: '"Rubik",sans-serif',
    width: "80%",
    height: "36px",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    color: "#333333",
    borderRadius: "4px",
    border: "1px solid #E0E0E0",
    background: "#F1F2F2",
    margin:'3rem auto',
    padding: "10px 15px",
    "&:focus": {
      borderRadius: 4,
      outlineColor: "#53CD94",
    },
  },
  uploadFilesWrap: {
    display: "flex",
    width:"40%",
    margin:'auto',
    border: "1px dashed #E0E0E0",
    padding: "15px",
    marginTop: "24px",
    boxSizing: "border-box",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width:"100%",
    },
  },
  uploadFilesButtonWrap: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  filesListHeading: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    color: "#808285",
    width: "100%",
    textAlign: "center",
    marginTop: "15px",
    marginBottom: "15px",
  },
  filesList: {
    listStyle: "none",
    margin: "0 auto",
    width: "80%",
    minWidth: "342px",
    padding: "0px",
    "& li": {
      border: "1px solid #53CD94",
      padding: "15px",
      borderRadius: "4px",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "14.22px",
      color: "#333",
      display: "flex",
      alignItems: "center",
      marginBottom: "15px",
      "& img": {
        marginRight: "20px",
        // width: "60px",
      },
      "& span": {
        flexGrow: 1,
      },
      "& svg": {
        color: "#53CD94",
        cursor: "pointer",
      },
    },
  },
  submit: {
    height: "52px",
    width: "30%",
    margin:"1rem auto",
    borderRadius: "8px",
    background: "#53CD94",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "21.33px",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      background: "#006D51",
    },
    [theme.breakpoints.down("sm")]: {
      width:"100%",
    },
  },
  errorLabel: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    color: "red",
    display: "flex",
    marginTop: "10px",
    minHeight: "16px",
    margin:"1rem 0"
  },
}));
