import {
  Typography,
  Popover,
} from "@material-ui/core";
import clsx from 'clsx';
import {popoverStyles} from './styles'
export default function PopoverComponent(props) {
  const classes = popoverStyles();

  return (
    <Popover
      id="mouse-over-popover"
      className={clsx(classes.popover,classes.container)}
      classes={{
        paper: classes.paper,
      }}
      open={props.open}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={props.handlePopoverClose}
      disableRestoreFocus
    >
      <Typography className={clsx(classes.popoverText,classes.titlePopover)}>{props.shipToData.shipTo}</Typography>
      <Typography className={classes.popoverText}>Dirección : {props.shipToData.address}</Typography>
      <Typography className={classes.popoverText}>Distrito : {props.shipToData.district}</Typography>
      <Typography className={classes.popoverText}>Télefono : {props.shipToData.phone}</Typography>
    </Popover>
  );
}
