import { makeStyles } from "@material-ui/core/styles";

export const AppShopSingleStyles = makeStyles((theme) => ({
  singlePage:{
    width:"80%",
    margin:"5rem auto",
    [theme.breakpoints.down("lg")]: {
      margin:"5rem auto",
    },
    [theme.breakpoints.down("md")]: {
      width:"90%",
      margin:"5rem auto",
    },
    [theme.breakpoints.down("sm")]: {
      width:"90%",
      margin:"2rem auto",
    },
  },
  paper: {
    // borderRadius: "12px",
    // color: "#53CD94",
    // fontSize: "25px",
    // fontWeight: "bold",
    boxShadow: "none",
    width: "100%",
    marginBottom: "30px",
    overflow: "auto",
    padding: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  // marginPaper: {
  //   padding: "55px",
  //   [theme.breakpoints.down("sm")]: {
  //     padding: "10px",
  //   },
  // },
  singlePage__wrapper:{
    flexDirection:"row",
    justifyContent:"space-around",
    borderRadius:"4px",
    [theme.breakpoints.down("sm")]: {
      flexDirection:"column"
    },
  },
  images:{
      flexDirection:"column",
      padding:"0 1rem",
      borderBottom:"1px solid #e0e0e0",
      [theme.breakpoints.down("sm")]: {
        borderBottom:"none",
      },
  },
  images__bigImage:{
    width:"100%",
    display:"flex"
  },
  images__image:{
    width:"100%",
    margin:"auto",
    [theme.breakpoints.down("sm")]: {
      width:"100%",
    },
  },
  images__carousel:{
    textAlign:"center"
  },
  images__smallImage:{
    maxWidth: "60px",
    margin: "0.1rem auto",
    cursor: "pointer",
    border: "1px solid rgba(128, 130, 133, 0.25)",
    padding: "5px",
  },
  details:{
    border:"1px solid #e0e0e0",
    borderRadius:"10px",
  },
  details__variation:{
    color: "#FFF",
    textShadow: "0.5px 0.5px 2px rgba(0,0,0,0.50)",
    height: "24px",
    lineHeight: "24px",
    fontSize: "10px",
    fontWeight: 500,
    display: "flex",
    padding: "0px 15px",
    borderRadius: "4px",
    // marginBottom: "19px",
  },
  iconBoxDown: {
    background: "#F2C94C",
  },
  iconBoxUp: {
    background: "#53CD94",
  },
  iconBoxFlat: {
    background: "#e0e0e0",
  },
  icon: {
    color: "#FFF",
    marginRight: "10px",
  },
  details__detail:{
    padding: "1.5rem",
    "& h1":{
      color: "#333333",
      fontSize: "32px",
      fontWeight: 400,
      lineHeight: "37.92px",
      margin: "0px",
      marginBottom: "0.5rem",
    },
    [theme.breakpoints.down("md")]: {
      padding: "1rem",
    },
  },
  details__characteristics:{
    display: "flex",
    flexDirection: "row",
    padding:"1rem 0 1rem 0",
    [theme.breakpoints.down("md")]: {
      padding:"0 0 1rem 0",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  details__characteristic:{
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  details__captions:{
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14.22px",
    color: "#808285",
    margin: "0.5rem 0",
  },
  details__characteristic_mod:{
    textAlign: "center",
    width:"fit-content",
    borderRadius: "3px",
    lineHeight: "25px",
    alignItems: "center",
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: 400,
    color: "rgb(83, 205, 148)",
    background: "rgb(221, 245, 234)",
    margin:"5px 0 0 0",
  },
  details__price:{
    color: "#FF6600",
    paddingTop:"1.5rem",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16.59px",
    [theme.breakpoints.down("md")]: {
      paddingTop:"1rem",
    },
    "& b":{
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "28.44px",
    }
  },
  details__options:{
    [theme.breakpoints.up("lg")]: {
      margin:"1rem 0",
    },

  },
  details__cartOptions:{
    display: "flex",
    marginTop: "14px",
    marginBottom:"1.5rem",
    paddingLeft: "12px",

    "& > .MuiFormGroup-root": {
      display: "block",
      "& > .MuiFormControlLabel-root": {
        color: "#333333",
        width: "58px",
        height: "36px",
        border: "1px solid #E0E0E0",
        textAlign: "center",
        lineHeight: "36px",
        borderRadius: "4px",
        justifyContent: "center",
        "& > .MuiTypography-body1": {
          fontSize: "13px",
        },
        [theme.breakpoints.down("md")]: {
          width: "auto",
          height: "auto",
          padding:"5px 1px"
        },
      },
    },
  },
  details__selected: {
    background: "rgba(83, 205, 148, 0.5)",
    borderColor: "#53CD94 !important",
  },
  details__quantityInput: {
    textAlign: "center",
    "& span": {
      color: "#333333",
      fontSize: "14px",
      fontWeight: "normal",
    },
  },
  details__qtySelectWrap: {
    background: "rgba(128, 130, 133, 0.25)",
    height: "36px",
    width: "100%",
    // minWidth: "212px",
    borderRadius: "50px",
    marginTop: "14px",
    marginBottom: "21px",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-around",
  },
  details__qtySelectIconWrap: {
    display: "inline-flex",
    height: "36px",
    width: "36px",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  details__qtySelectIcon: {
    height: "24px",
    width: "24px",
    borderRadius: "50px",
    background: "#53CD94",
    color: "#FFF",
    margin: "6px",
    cursor: "pointer",
  },

  details__cartTotal: {
    color: "#333333",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "28.44px",
    textAlign: "right",
  },
  details__cartTotalText: {
    fontSize: "9px",
    fontWeight: 500,
    lineHeight: "10.67px",
    textAlign: "right",
    color: "#53CD94",
    marginBottom: "24px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "0px",
    },
  },
  details__buttons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop:"0.5rem"
  },
  details__addToCartButton: {
    width: "100%",
    height: "52px",
    fontSize: "14px",
    fontWeight: 400,
    borderRadius: "8px",
    boxShadow: "none",
    textTransform: "uppercase",
    "&:hover": {
      boxShadow: "none",
      background: "#006D51",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  details__addToCartColor: {
    color: "#FFF",
    background: "#53CD94",
  },
  details__buttonIcons: {
    height: "22px",
    width: "22px",
    marginRight: "15px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  details__negotiateColor: {
    color: "#f2994a",
    borderColor: "#f2994a",
    "&:hover": {
      boxShadow: "none",
      background: "#f2994a",
      color: "#FFF",
    },
  },
  details__errorLabel: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    display: "flex",
    marginTop: "10px",
    minHeight: "16px",
  },
  details__errorColor: {
    color: "red",
  },
  details__stock: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    color: "#333",
    marginTop:"1rem",
  },

  details__updateButton: {
    width: "100%",
    height: "52px",
    color: "#FFF",
    fontSize: "18px",
    fontWeight: 400,
    background: "#53CD94",
    borderRadius: "8px",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      background: "#006D51",
    },
  },

  singlePage__extraInfo:{
    marginTop:"1rem",
    [theme.breakpoints.down("sm")]: {
      width:"99%",
    },
  },
  extraInfo__title:{
    display:"flex",
    marginBottom:"0.5rem",
    fontSize:"16px",
    lineHeight:"18.96px",
    fontWeight:500,
    color:"#333333",
  },
  seller:{
    border:"1px solid #e0e0e0",
    borderRadius:"10px",
    padding:"1rem",
    // "& > span:nth-child(1)":{
    //   fontSize:"16px",
    //   lineHeight:"18.96px",
    //   fontWeight:500,
    //   color:"#333333"
    // },
    "& button":{
      border:"none",
      textAlign:"center",
      backgroundColor:"#53cd94",
      borderRadius:"10px",
      padding:"0.5rem",

      "& a":{
        textDecoration:"none",
        color:"#fff",
      }
    }
  },
  seller__goStore:{
    display:"flex",
    justifyContent:"center",
    marginTop:"1rem"
  },
  seller__icons:{
    margin:"1rem 0",
    "& > span:nth-child(1)":{
      textAlign: "center",
      width:"fit-content",
      borderRadius: "3px",
      lineHeight: "25px",
      alignItems: "center",
      fontSize: "12px",
      fontWeight: 400,
      color: "rgb(83, 205, 148)",
      background: "rgb(221, 245, 234)",
    },
    "& > span:nth-child(2)":{
      paddingLeft:"2rem",
      color:"#808285"
    },
    "& svg": {
      color:"#006d51",
      fontSize:"15px"
    }
  },
  seller__data:{
    display:"flex",
    flexDirection:"row", 
    // justifyContent:"space-around",
    "& b":{
      fontSize:"18px",
      color:"#333333",
    },
    "& > span":{
      fontSize:"12px",
      color:"#808285",
      textAlign:"center",
      padding:"0.5rem",
      display:"flex",
      flexDirection:"column",
      flexGrow:1,
      width:"25%",
      // borderRight:"1px solid #e0e0e0",
      "& span":{
        fontSize:"20px",
        [theme.breakpoints.down("sm")]: {
          fontSize:"14px",
        },
      }
    },
  },
  seller__data_center:{
    borderRight:"1px solid #e0e0e0",
    borderLeft:"1px solid #e0e0e0"
  },
  payment:{
    margin:"1rem 0",
    border:"1px solid #e0e0e0",
    borderRadius:"10px",
    padding:"1rem",
  },
  payment__cards:{
    margin:"1rem 0",
    "& > span":{
      fontSize:"14px",
      color:"#808285",
    },
  },
  payment__creditCards:{
    marginTop:"0.5rem",
    "& img":{
      // opacity: "0.3",
      width:"50px", 
      height: "25px" ,
      marginRight:"4px"
    }
  },
  payment__transfers:{
    marginTop:"0.5rem",
    "& img":{
      // opacity: "0.3",
      width:"36px", 
      height: "36px" ,
      marginRight:"4px"
    }
  },
  shipping:{
    border:"1px solid #e0e0e0",
    borderRadius:"10px",
    padding:"1rem",
  },
  shipping__method:{
    display:"flex",
    alignItems:"center",
    marginTop:"1rem",
    color:"#808285",
    "& svg":{
      width:"15%",
      [theme.breakpoints.down("sm")]: {
        width:"20%",
      },
    },
    "& span":{
      width:"75%",
      fontSize:"14px",
      lineHeight:"16.59px",
      // color:"#808285",
      [theme.breakpoints.down("xs")]: {
        width:"80%",
        fontSize:"12px",
      },
      "& b":{

      }
    }
  },
  carousel:{
    width:"100%",
    // margin:"1rem 0",
    padding:"1.5rem 0",
    borderBottom:"1px solid #e0e0e0",
    "& > span:nth-child(1)":{
      fontSize:"20px",
      lineHeight:"23.7px",
      color:"#333333",
      padding:"1rem 0"
    },
  },
  carousel__carousel:{
    margin:"1.5rem 0"
  },
  summary: {
    width:"100%",
    padding:"1.5rem 0",
    borderBottom:"1px solid #e0e0e0",
    "& > span:nth-child(1)":{
      fontSize:"18px",
      color:"#333333",
      fontWeight:500,
      lineHeight:"21.33px",
      // margin:"1rem 0"
    }
  },
  summary__table: {
    marginTop:"1rem",
    width:"100%",
    border:"1px solid #e0e0e0",
    borderRadius:"10px"
  },
  summary__row: {
    display:"flex",
    flexDirection:"row",
    flexWrap:"nowrap",
    fontSize:"14px",
    lineHeight:"16.59px",
    border:"1px solid #e0e0e0",
    "& > span:nth-child(1)":{
      width:"40%",
      padding:"0.5rem"
    },
    "& > span:nth-child(2)": {
      color:"#808285",
      width:"60%",
      padding:"0.5rem"
    }
  },
  description:{
    width:"100%",
    padding:"1.5rem 0",
    borderBottom:"1px solid #e0e0e0",
    "& > span:nth-child(1)":{
      fontSize:"18px",
      color:"#333333",
      fontWeight:500,
      lineHeight:"21.33px",
      // margin:"1rem 0"
    },
    "& > span:nth-child(2)":{
      display:"flex",
      margin:"1rem 0"

    }
  },
  description__tags:{
    display:"flex",
    flexDirection:"row",
    "& span":{
      padding:" 0 0.5rem",
      textAlign: "center",
      // width:"fit-content",
      borderRadius: "3px",
      // lineHeight: "25px",
      alignItems: "center",
      // textTransform: "uppercase",
      fontSize: "12px",
      fontWeight: 400,
      color: "rgb(83, 205, 148)",
      background: "rgb(221, 245, 234)",
      margin:"0.5rem 0.5rem 0.5rem 0",
    }

  },
  visited: {
    textDecoration: "none",
    color:"#333333",
    "&:hover" :{
      color:"#53cd94"
    },
  },
  // sideImages: {
  //   display: "flex",
  //   flexDirection: "column",
  //   margin: "0px 10px",
  //   [theme.breakpoints.down("sm")]: {
  //     flexDirection: "row",
  //     alignSelf: "center",
  //   },
  // },
  // imagesForMediumScreens: {
  //   display: "none",
  //   [theme.breakpoints.down("sm")]: {
  //     display: "flex",
  //     flexDirection: "column",
  //     flexWrap: "nowrap",
  //     alignItems:"center"
  //   },
  // },

  // image: {
  //   // maxWidth: "80%",

  //   maxHeight:"400px",
  //   [theme.breakpoints.up("md")]: {
  //     maxHeight: "450px",
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     margin: "0 auto",
  //     maxHeight: "max-content",
  //   },
  // },

  // catText: {
  //   textAlign: "center",
  //   borderRadius: "3px",
  //   lineHeight: "25px",
  //   alignItems: "center",
  //   textTransform: "uppercase",
  //   fontSize: "12px",
  //   fontWeight: 400,
  //   color: "rgb(83, 205, 148)",
  //   background: "rgb(221, 245, 234)",
  //   margin:"5px 0 0 0",
  // },
  

  // productTitle: {
  //   color: "#333333",
  //   fontSize: "32px",
  //   fontWeight: 400,
  //   lineHeight: "37.92px",
  //   margin: "0px",
  //   marginBottom: "9px",
  // },


  // caption1: {
  //   fontSize: "12px",
  //   fontWeight: 400,
  //   lineHeight: "14.22px",
  //   color: "#808285",
  //   margin: "0.8rem 0",
  // },

  box: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  body2: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "15px",
    color: "#808285",
  },



  subtitle1: {
    fontSize: "24px",
    lineHeight: "28.44px",
    fontWeight: 400,
    margin: "1rem",
    color: "#616060",
  },
  cardsImages : {
    opacity: "0.3", 
    height: "25px" ,
    marginRight:"4px"
  },
  shipBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    padding: "0.5rem 0",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  shipBoxTitle: {
    minWidth: "120px",
    color: "#333333",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "16.59px",
    textAlign: "start",
    marginTop:"0.5rem"
  },
  section1: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
  },
  antiguatyIcon: {
    borderRadius: "50%",
    color: "#006d51",
    border: "1px solid #006d51",
    padding: "2px",
    marginRight: "0.5rem",
    fontWeight: 400,
  },

  subtitle2: {
    color: "#53cd94",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16.59px",
    textTransform: "uppercase",
  },
  color: {
    color: "#006d51",
  },
  link: {
    color: "#53cd94",
    fontSize: "14px",
    lineHeight: "18.96px",
    fontWeight: "normal",
    margin: "0.25rem",
    textDecoration: "none",
  },
  shipBoxDetail: {
    " & div": {
      display: "flex",
      alignItems: "center",
    },
  },

}));
