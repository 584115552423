import { makeStyles } from "@material-ui/core/styles";
export const paymentStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  pageTitle: {
    color: "#53CD94",
    fontSize: "32px",
    lineHeight: "38px",
    alignItems: "baseline",
    flexGrow: 1,
    "& span": {
      fontSize: "16px",
      lineHeight: "19px",
      marginLeft: "15px",
      color: "#808285",
    },
  },
  paper: {
    padding: "55px",
    borderRadius: "12px",
    color: "#53CD94",
    fontSize: "25px",
    fontWeight: "bold",
    boxShadow: "none",
    width: "100%",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      padding: "25px",
    },
  },
  summaryAccordeon: {
    border: "1px solid #53cd94",
    background: "#f1f2f2",
    padding: "1rem",
    margin: "0.5rem 0",
    justifyContent:"space-between",
    alignItems:'center',
    
    "& p": {
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#000000",
    },
    "& span": {
      color: "#53cd94",
    },
    "& img": {
      width:"40px",
      marginRight:"0.5rem",
      [theme.breakpoints.down("xs")]: {
        display:"none"
      },
    }
  },
  button: {
    height: "52px",
    borderRadius: "8px",
    background: "#53CD94",
    boxShadow: "none",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "21.33px",
    "&:hover": {
      background: "#006D51",
      boxShadow: "none",
    },
  },
  wireTransfer_account: {
    background: "rgba(241,242,242,0.5)",
    padding: "0.5rem 1rem",
    margin:'0.5rem',
    "& p": {
      color: "#808285",
      fontSize: "14px",
      lineHeight: "16.59px",
      fontWeight: "normal",
      margin:"0.5rem"
    },
  },
  wireTransfer_instructions:{
    "& p": {
        color: "#808285",
        fontSize: "14px",
        lineHeight: "16.59px",
        fontWeight: "normal",
      },
  },
  
  transactionNumber: {
    display: "block",
    padding: "1rem 1rem",
    margin:'auto auto',
    "& input": {
      display:"flex",
      justifyContent:"center",
      fontFamily: '"Rubik",sans-serif',
      height: "36px",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "16.59px",
      color: "#333333",
      borderRadius: "4px",
      border: "1px solid #E0E0E0",
      background: "#F1F2F2",
      padding: "10px 15px",
      margin:"auto",
      "&:focus": {
        borderRadius: 4,
        outlineColor: "#53CD94",
      },
      "&::placeholder": {
        color: "#808285",
        fontSize: "0.7em",
      },

    },
    "& label": {
      display:"flex",
      justifyContent:"center",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "16.59px",
      color: "#808285",
      marginBottom: "10px",
      minHeight: "16px",
    },
  },
  
  
  uploadFilesWrap: {
    width: "80%",
    margin: "10px auto",
    textAlign: "center",
    display: "flex",
    border: "1px dashed #E0E0E0",
    padding: "15px",
    marginTop: "24px",
    boxSizing: "border-box",
    borderRadius: "4px",
    "& p": {
      padding: "1rem",
    },
  },
  uploadFilesButtonWrap: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  filesListHeading: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    color: "#808285",
    width: "100%",
    textAlign: "center",
    marginTop: "15px",
    marginBottom: "15px",
  },
  filesList: {
    listStyle: "none",
    margin: "0 auto",
    minWidth: "342px",
    padding: "0px",
    "& li": {
      border: "1px solid #53CD94",
      padding: "15px",
      borderRadius: "4px",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "14.22px",
      color: "#333",
      display: "flex",
      alignItems: "center",
      marginBottom: "15px",
      "& img": {
        marginRight: "20px",
      },
      "& span": {
        flexGrow: 1,
        margin: "0 0.5rem",
      },
      "& svg": {
        color: "#53CD94",
        cursor: "pointer",
      },
    },
  },
  typeTransferRadio:{
      margin:'1.5rem',
      flexDirection:'row',
      justifyContent:'space-between',
      alignItems: 'flex-start',
      '& span ':{

        color:'#808285',
    },
  },
  interTranferRadio:{
    flexDirection:'row',
    '& span ':{
        fontSize:'10px',
        color:'#808285',
    },
  },
  show: {
    display: "none",
  },
  wireTransfer_button:{
    width:'100%',
    margin:'1rem auto'
  },
  errorLabel: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    display: "flex",
    minHeight: "16px",
    justifyContent:"space-around",
    color:"red",
    padding:"15px",
  },
  errorImagen:{
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    display: "flex",
    justifyContent:"center",
    color:"red",
    padding:"15px",
    marginRight:"50px",
  }
}));
