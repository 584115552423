import { makeStyles } from '@material-ui/core/styles';

export const auhtorStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: '25px !important',
        fontSize: '12px',
        boxShadow: 'none',
        border: '2px solid #E6E7E8'
    },
    profileImage:{
        width: '100%',
        borderTopLeftRadius: '23px !important',
        borderTopRightRadius: '23px !important'
    },
    contentWrap: {
        position: 'relative',
        padding: '24px 20px',
        paddingTop: '45px'
    },
    logoImage: {
        height: '120px',
        width: '120px',
        top: '-75px',
        position: "absolute",
        left: "calc( 50% - 60px )",
        borderRadius: "50%",
    },
    ratingWrap: {
        color: '#53CD94',
        fontSize: '25px',
        height: '25px',
        width: '120px',
        margin: '0 auto',
        position: 'relative',
        padding: 0,
        '& svg': {
            height: '24px',
            width: '24px'
        }
    },
    ratingTop: {
        color: '#53CD94',
        padding: 0,
        position: 'absolute',
        zIndex: 1,
        display: 'flex',
        top: 0,
        left: 0,
        overflow: 'hidden',
        '& span': {
            display: 'inline-block'
        }
    },
    ratingBottom: {
        padding: 0,
        display: 'block',
        zIndex: 0
    },
    authorName: {
        textAlign: 'center',
        fontSize: '22px',
        fontWeight: 500,
        lineHeight: '26.07px',
        color: '#333',
        marginBottom: '10px'
    },
    authorInfo: {
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16.59px',
        color: '#808285',
        display: "flex",
        flexDirection: 'column',
        marginBottom: '10px'
    },
    aboutAuther: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        color: '#808285',
    }
}));