/*-- Libraries --*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import {
  withStyles,
  Typography,
  Grid,
  Paper,
  Button,
  NativeSelect,
  InputBase,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import StarBorderIcon from "@material-ui/icons/StarBorder";

/*-- redux --*/

import { _getCartItems,getDiscount } from "../Cart/cartSlice";
import {
  deliveryCost,
  updateProvince,
  updateDistrict,
  updateShipTo,
  updatePhone,
  updateAddress,
  submitData,
  getDeliveryCost,
  updateShipMethod,
  updateShippingCost,
  shippingCost,
  estevedoreCost,
} from "./slice";

/*-- styles,components,functions --*/
import { shipMethodStyles } from "./styles";
import Estibador from "assets/images/Estibador.svg";
import Vector from "assets/images/Vector.svg";
import AppStepper from "features/Stepper";
import OrderSummary from "features/checkout/orderSummary";
import formatNumber from 'functions/regex';
import useShippingMethod from "hooks/useShippingMethod";
import LayoutWrapper from "features/layout/wrapper";
import useShippingInfo from "hooks/useShippingInfo";

const BootstrapInput = withStyles((theme) => ({
  root: {
    width: "100%",
    "label + &": {},
  },
  input: {
    borderRadius: 4,
    position: "relative",
    width: "100%",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "7.5px 7px 7.5px 15px",
    backgroundColor: "#F1F2F2",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#53CD94",
      outlineColor: "#53CD94",
    },
  },
}))(InputBase);

export default function ShipMethod(props) {
  const classes = shipMethodStyles();
  const dispatch = useDispatch();

  const {
    phoneValue,
    addressValue,
    shipToValue,
    idValue,
    provinceList,
    districtList,
    provinceValue,
    districtValue,
    _districtName,
} = useShippingInfo();

  const discountValue = useSelector(getDiscount);
  const shipDeliveryValue = useSelector(deliveryCost);
  const shippingCostValue = useSelector(shippingCost);
  const estevedoreCostValue = useSelector(estevedoreCost);

  const [edit, setEdit] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorphone, setErrorPhone] = useState(false);
  const [errorAddress, setErrorAddress] = useState(false);
  const [isFailed] = useState(false);
  const [errorResponse, seterrorResponse] = useState("");
  const [sucessResponse, setsucessResponse] = useState("");

  const {cartData,shipMethod,setShipMethod} = useShippingMethod();

  // HANDLE CHANGE SHIPPING METHOD
  const handleShippingMethod = (event) => {

    setShipMethod(event.target.value);

    updateShipMethod({ sType: parseInt(event.target.value) }, () => {
      dispatch(_getCartItems());
    });
  };
  // HANDLE CHANGE SHIPPING DATA
  const nameChange = (event) => {
    dispatch(updateShipTo(event.target.value));
  };

  const phoneChange = (event) => {
    //TO DO : CHECK RE TEST
    const re = /^[0-9\b]+.?([1-9]{1,2})?$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      dispatch(updatePhone(event.target.value));
    }
  };

  const addressDetailChange = (event) => {
    dispatch(updateAddress(event.target.value));
  };

  const provinceChange = (event) => {
    dispatch(updateProvince(event.target.value));
  };

  const districtChange = (value) => (event) => {
    const filtered= value.filter((selected,index) => selected.id === parseInt(event.target.value));
    const data = {
      districtId: event.target.value,
      districtName: filtered[0].district,
    }
    dispatch(updateDistrict(data));

  };

  // GET CART LIST
  // METHOD: GET
  // ENDPOINT: checkout/cart/
  useEffect(() => {
    dispatch(_getCartItems());
  }, [dispatch]);

  // GET DELIVERY COST WHEN DELIVERY IS CHOSEN
  // METHOD: POST
  // ENDPOINT: shipping/shipMethod/
  useEffect(() => {

    if(shipMethod === '1') {

      dispatch(getDeliveryCost());

    } else {

      const deliveryData = {
        updateDelivery: 0,
        updateStevedore: 0,
      };

      dispatch(updateShippingCost(deliveryData));

    }
  }, [dispatch,shipMethod]);


    // SAVE SHIPPING SECTION
    // METHOD: PATCH
    // ENDPOINT: shipping/shipTo/
  const updateShippingData = () => {
    let submit = true;

    if (!shipToValue) {
      submit = false;
      setErrorName(true);
    } else {
      setErrorName(false);
    }

    if (!phoneValue) {
      submit = false;
      setErrorPhone(true);
    } else {
      setErrorPhone(false);
    }

    if (!addressValue) {
      submit = false;
      setErrorAddress(true);
    } else {
      setErrorAddress(false);
    }

    if (submit) {

      const data = {
        id: idValue,
        shipTo: shipToValue,
        phone: phoneValue,
        address: addressValue,
        district: districtValue,
      };

      submitData(data, (success) => {

        setIsSelected(!isSelected);
        setsucessResponse(success);
        setTimeout(()=>{
          setsucessResponse("");
        },2000);
        setEdit(!edit);
        dispatch(_getCartItems());
        dispatch(getDeliveryCost());

      }, (fail) => {
        seterrorResponse(fail);
          setTimeout(()=>{
            seterrorResponse("");
          },2000);
      });
    };
  };

  return (
    <LayoutWrapper>
      <div className={classes.container}>
        <Typography
          variant="h2"
          component="h5"
          gutterBottom
          className={classes.pageTitle}
        >
          Envío
          <span>
            ({cartData.length} {cartData.length === 1 ? "Producto" : "Productos"})
          </span>
        </Typography>
        <AppStepper stepNumber={1} />
      </div>
      <Grid container spacing={3} style={{ marginBottom: "1rem" }}>
        <Grid item xs={12} md={12} lg={8}>
          <Paper className={classes.paper}>
            <RadioGroup value={shipMethod} onChange={handleShippingMethod}>
              <Grid
                container
                spacing={2}
                className={clsx(classes.summaryAccordeon)}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                />
                <LocalShippingIcon className={classes.icons} />
                <div
                  className={clsx(classes.descriptionText, classes.widthBox)}
                >
                  <h2 className={classes.descriptionText_title}>
                    Envío por delivery
                  </h2>
                  <p>Contrata a un estibador y transportista</p>
                </div>
                <div
                  className={clsx(
                    classes.descriptionText,
                    classes.widthBox1,
                    classes.alignLastDescriptionText
                  )}
                >
                  <h2 className={classes.descriptionText_title}>
                    S/ {formatNumber(shipDeliveryValue + estevedoreCostValue)}
                  </h2>
                  <p>Costo de envío</p>
                </div>
              </Grid>
              <Grid
                style={{ margin: "0.5rem 0" }}
                className={shipMethod !== "1" ? classes.show : ""}
              >
                <section
                  className={clsx(
                    classes.paddingSection,
                    edit ? classes.show : ""
                  )}
                >
                  <div className={classes.detailContainer}>
                    <img src={Estibador} alt="icon"/>
                    <span style={{marginLeft:"1%"}}>
                      El estibador llevará tus productos desde el puesto del mayorista hasta el transportista asignado.
                    </span>
                  </div>
                  <div className={classes.detailContainer}>
                    <StarBorderIcon className={classes.detailIcon} />
                    <span style={{marginLeft:"2%"}}>
                      Todos los pedidos confirmados hasta las 2pm se despacharán dentro de las 24 horas.
                    </span>
                  </div>
                  <div className={classes.detailContainer}>
                    <StarBorderIcon className={classes.detailIcon} />
                    <span style={{marginLeft:"2%"}}>
                      Los pedidos que se confirmen después de las 2pm se entregarán dentro de las 48 horas.
                    </span>
                  </div>
                  <div className={classes.detailContainer}>
                    <StarBorderIcon className={classes.detailIcon} />
                    <span style={{marginLeft:"2%"}}>
                      Los despachos se realizarán en la dirección consignada para el envío.
                    </span>
                  </div>
                </section>
                <section className={!edit ? classes.shipDetail : classes.show}>
                  <div>
                    <span
                      className={classes.widthBox2}
                      style={{ color: "#000000" }}
                    >
                      Enviar a:
                    </span>
                    <button
                      type="button"
                      className={classes.editButton}
                      onClick={() => {
                        setEdit(true);
                        setIsSelected(!isSelected);
                      }}
                    >
                      <span style={{ marginRight: "3px" }}>Editar</span>
                      <img src={Vector} alt="icon" />
                    </button>
                  </div>
                  <span>{shipToValue}</span>
                  <span>Teléfono: {phoneValue}</span>
                  <span>{addressValue}</span>
                  <span>{_districtName}</span>
                </section>
                <section
                  className={clsx(
                    classes.paddingSection,
                    !edit ? classes.show : ""
                  )}
                >
                  <span className={classes.formTitle}>
                    Ingresa los datos y dirección de envío
                  </span>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={8}>
                      <label className={classes.label}>
                        Nombres y Apellidos
                      </label>
                      <input
                        type={"text"}
                        placeholder={"Katherine Ramos Garcés"}
                        className={classes.input}
                        onChange={nameChange}
                        value={shipToValue}
                      />
                      {errorName ? (
                        <span className={classes.errorLabel}>
                          Por favor ingresa tu nombre
                        </span>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label className={classes.label}>Teléfono</label>
                      <input
                        type={"text"}
                        placeholder={"985566787"}
                        className={classes.input}
                        onChange={phoneChange}
                        value={phoneValue}
                      />
                      {errorphone ? (
                        <span className={classes.errorLabel}>
                          Por favor ingresa un número válido
                        </span>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label className={classes.label}>Provincia</label>
                      <NativeSelect
                        id="demo-customized-select-native"
                        input={<BootstrapInput />}
                        value={provinceValue}
                        onChange={provinceChange}
                      >
                        {provinceList.map((province) => (
                          <option value={province.province_id} key={province.province_id}>
                            {province.province}
                          </option>
                        ))}
                      </NativeSelect>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label className={classes.label}>Distrito</label>
                      <NativeSelect
                        input={<BootstrapInput />}
                        value={districtValue}
                        onChange={districtChange(districtList)}
                      >
                        {districtList.map((district) => (
                          <option value={district.id} key={district.id}>
                            {district.district}
                          </option>
                        ))}
                      </NativeSelect>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <label className={classes.label}>Detalle</label>
                      <input
                        type={"text"}
                        placeholder={"Av.Miguel Iglesias 2345"}
                        className={classes.input}
                        onChange={addressDetailChange}
                        value={addressValue}
                      />
                      {errorAddress ? (
                        <span className={classes.errorLabel}>
                          Por favor ingresa tu dirección
                        </span>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </section>
                {isSelected ? (
                  <Button
                    variant="outlined"
                    size="medium"
                    color="primary"
                    className={clsx(classes.submit)}
                    onClick={updateShippingData}
                  >
                    Actualizar
                  </Button>
                ) : (
                  ""
                )}
                {errorResponse ? (
                  <span className={classes.errorLabel}>
                    {errorResponse}
                  </span>
                ) : (
                  ""
                )}
                {
                  sucessResponse ? (
                    <span className={classes.sucessLabel}>
                    {sucessResponse}
                  </span>
                  ) : (
                    ""
                  )}
              </Grid>
              <Grid container spacing={2} className={classes.summaryAccordeon}>
                <FormControlLabel value="2" control={<Radio />} />
                <LocalShippingIcon className={classes.icons} />
                <div
                  className={clsx(classes.descriptionText, classes.widthBox2)}
                >
                  <h2 className={classes.descriptionText_title}>
                    Retira tu compra
                  </h2>
                  <p>Puedes retirar tu compra entre las 5:00 am - 12:00 m</p>
                </div>
              </Grid>
              <Grid
                style={{ margin: "0.5rem 0" }}
                className={shipMethod !== "2" ? classes.show : ""}
              >
                <section className={clsx(classes.paddingSection)}>
                  <div className={classes.detailContainer}>
                    <StarBorderIcon className={classes.detailIcon} />
                    <span>
                      Desde la sección “Mis pedidos” podrás descargar tu
                      comprobante.
                    </span>
                  </div>
                  <div className={classes.detailContainer}>
                    <StarBorderIcon className={classes.detailIcon} />
                    <span>
                      Acércate al mercado con tu comprobante impreso o digital.
                    </span>
                  </div>
                  <div className={classes.detailContainer}>
                    <StarBorderIcon className={classes.detailIcon} />
                    <span>
                      Retira tus pedidos personalmente o contrata un estibador.
                    </span>
                  </div>
                </section>
              </Grid>
            </RadioGroup>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className={classes.button}
              component = {Link}
              to = "/checkout"
            >
              CONTINUAR
            </Button>
            {isFailed ? (
              <span className={classes.errorLabel}>
                Es necesario seleccionar un método de envío para continuar con
                la compra
              </span>
            ) : (
              ""
            )}
            <span className={classes.simpleText}>
              En el siguiente paso seleccionarás el método de pago
            </span>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <OrderSummary
            cartData ={cartData}
            price = {shippingCostValue}
            discount = {discountValue}
            estevedore = {estevedoreCostValue}
            shipMethod = {shipMethod}
          />

        </Grid>
      </Grid>
    </LayoutWrapper>
  );
}
