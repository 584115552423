import { makeStyles } from "@material-ui/core";

export const SettingStyle = makeStyles((theme) => ({
    appBar:{
        // margin: "1rem",
        border:"1px solid #e0e0e0",
        boxShadow:"none"
    },
    appBar__slide: {
        "&:hover": {
            color: "#53CD94",
        },
        "&.MuiTab-textColorInherit.Mui-selected": {
            backgroundColor: "#ffffff",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
        },
    },
    tabpanel: {
        // width: "auto",
        display: "flex",
        flexDirection:"column",
        justifyContent: "center",
        margin:"auto",
        maxWidth:"900px",
        // marginLeft: "1%",
        // marginRight: "1%",
        marginTop: "10px",
        [theme.breakpoints.down("sm")]: {
            width: "auto",
        },

    },
    paper: {
        boxShadow: "none",
        width: "100%",
        // marginBottom: "30px",
        overflow: "auto",
        padding: "1.5rem",
        "& h3":{
            fontSize:"16px",
        },
        [theme.breakpoints.down("sm")]: {
          padding: "1rem",
        },
    },
    // COMMON
    label: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16.59px",
        color: "#808285",
        display: "flex",
        marginBottom: "10px",
        minHeight: "16px",
    },
    input: {
        display: "flex",
        fontFamily: '"Rubik",sans-serif',
        width: "100%",
        height: "36px",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16.59px",
        color: "#333333",
        borderRadius: "4px",
        border: "1px solid #E0E0E0",
        background: "#F1F2F2",
        padding: "10px 15px",
        marginBottom: "15px",
        [theme.breakpoints.down('sm')]: {
            padding: "10px 5px",   
            "&::placeholder": {
                fontSize: "10px",
            },
        },
        "&:focus": {
            borderRadius: 4,
            outlineColor: "#53CD94",
        },
    },
    hideIcon:{
        display:"block",
        [theme.breakpoints.down("xs")]: {
            display:"none",
        },
    },
    sucess_message_container:{
        display:"flex",
        justifyItems:"center",
        justifyContent:"center",
        // "& span":{
        //     backgroundColor:"#53cd94",
        //     color:"#fff", 
        //     opacity:"0.5",
        //     padding:"0.2rem 0.5rem"
        // }
    },
    success_message_text:{
        backgroundColor:"#53cd94",
        color:"#fff", 
        opacity:"0.5",
        padding:"0.2rem 0.5rem"
    },
    button: {
        boxShadow: 'none',
        borderRadius: '8px',
        background: '#53CD94',
        display: "flex",
        fontWeight: "bolder",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            width: '40%',
            marginBottom: "0px",
            fontSize:"10px",
            height: '33px',
        },
        '&:hover': {
            background: '#006D51',
        }
    },
    subtitle:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
    },

    // PROFILE
    background__image: {
        minHeight:"400px",
        width: '100%',
        borderTopLeftRadius: '23px !important',
        borderTopRightRadius: '23px !important',
        [theme.breakpoints.down("xs")]: {
            minHeight:"300px",
        },
        [theme.breakpoints.down("sm")]: {
            minHeight:"100px",
        },
    },
    background_button:{
        position:"relative",
        bottom:"10rem",
        width:"100%", 
        display:"flex",
        justifyContent:"flex-end",
        backgroundImage: "linear-gradient(to bottom, transparent 0%,#333333 100%)",
        [theme.breakpoints.down("sm")]: {
            bottom:"5rem",
        },
    },
    background__edit: {
        marginRight:"1rem",
        marginBottom:"0.5rem"
    },

    profPhoto: {
        position: 'relative',
        bottom:"10rem",
        display:"flex",
        justifyContent:"center",
        backgroundColor:"#fff",
        [theme.breakpoints.down("sm")]: {
            bottom:"5rem",
        },
        // backgroundColor:"#53cd94"
        // padding: '24px 20px',
        // paddingTop: '45px'
    },
    profPhoto__image: {
        height: '120px',
        width: '120px',
        top: '-20px',
        position: "relative",
        // left: "calc( 50% - 60px )",
        borderRadius: "50%",
        border:"3px solid #ffffff",
        // backgroundColor:"#fff",
        [theme.breakpoints.down("xs")]: {
            height: '100px',
            width: '100px',
            top: '-12px',
        },
    },
    profPhoto__button:{
        position:"absolute", 
        bottom:"0",
        "& svg":{
            color:"#333333",fontSize:"15px"
        }
    },
    details:{
        position:'relative',
        backgroundColor:"#fff",
        padding:"3rem 0",
        bottom:"10rem",
        [theme.breakpoints.down("sm")]: {
            bottom:"2rem",
            padding:"0",
        },
    },
    container: {
        width: "100%",
        display: "inline-block",
        "& p": {
            color: "#808285",
            fontSize: "12px",
            fontWeight: 100,
            marginLeft: "22px",
        },
    },
    details__button: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        paddingTop: "20px",
        [theme.breakpoints.down("sm")]: {
            width: "auto",
        },
    },

    details__warnings: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        "& small":{
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "10px",
            color: "#808285",
            display: "inline",
            marginBottom: "10px",
            marginTop: "12px",
            textAlign: 'justify',
            [theme.breakpoints.down("sm")]: {
                fontSize: "7px",
                marginBottom: "0px",
                marginTop: "0px",
                textAlign: 'justify',
    
            },
        }
    },
    container7: {
        width: "auto",
        display: "flex",
        justifyContent: "center",
        paddingTop: "5px",
        flexDirection:"column"
    },
    pageTitle: {
        color: "#333333",
        fontSize: "32px",
        fontWeight: 500,
        lineHeight: "38px",
        alignItems: "baseline",
        margin: "20px",
        flexGrow: 1,
        [theme.breakpoints.down("sm")]: {
            fontSize: "23px",
        },
    },
 
    enlace: {
        color: "#808285",
        textDecoration: "none",
        textIndent: "0.3em",
        "&:hover": {
            color: "#53CD94",
        },
    },


    textButton: {
        color: "#53CD94",
        fontSize: "14px",
        fontWeight: "100",
        textDecoration: "underline",
        [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
        },
    },
    label2: {
        fontSize: "20px",
        color: "#333333",
        fontWeight: 500,
        flexGrow: 1,
        lineHeight:"23,7px",
        // [theme.breakpoints.down("md")]: {
        //     fontSize: "16px",
        // },
        [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "14px",
        },
    },
    table: {
        width: "90%",
        marginLeft: "5%",
        marginRight: "5%",
        marginTop: "15px",
        marginBottom: "15px",
    },
    actions:{
        "& MuiDialogActions-root":{
            justifyContent:"center"
        }
    },

    boxTittle: {
        display: "flex",
        "& p":{
            flexGrow:1
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "12px",
        },
    },

    editButton2: {
        background: "none",
        border: "none",
        cursor: "pointer",
        color: "#53CD94",
        display: "flex",
        alignItems:"center",
        marginTop: "10px",
        "& span":{
            fontSize:"15px",
            marginRight:"3.5px",
            [theme.breakpoints.down("sm")]: {
                fontSize:"10px",
            },
        },
        "& img":{
            with:"50px",
            [theme.breakpoints.down("sm")]: {
                width: "20px",
            },
        }
    },
    // text2:{
    //     fontSize:"15px",
    //     marginRight:"3.5px",
    //     [theme.breakpoints.down("sm")]: {
    //         fontSize:"8px",
    //     },
    // },
    // imgVector:{
    //     with:"50px",
    //     [theme.breakpoints.down("sm")]: {
    //         width: "13px",
    //     },
    // },
    accountAccess_actions:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-between",
        margin:"1rem",
        "& a" : {
            color:"#53cd94",
            fontSize:"14px",
            [theme.breakpoints.down("sm")]: {
                fontSize:"10px",
            },
        },
    },
    contactInfo_actions:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"flex-end",
        margin:"1rem",
    },
    errorLabel: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16.59px",
        color: "red",
        display: "flex",
        minHeight: "16px",
        marginTop:"0.5rem"
      },
    isError:{
        color:"#fff",
        border: "1px solid #E0E0E0",
        "&:focus": {
            borderRadius: 4,
            outlineColor: "#f51111",
        },
    },
    detail:{
        color:"#808285",
        fontWeight:400,
        "& b":{
           color:"#333",
            fontWeight:500
        }
    }








}))