import GradeDropdown from "features/product/common/grade";
import Typography from "@material-ui/core/Typography";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import React, {useState} from "react";
import {offerMiniSlideStyles} from './styles'
import SlideChart from './chart';
import formatNumber from "functions/regex";
import clsx from "clsx";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {Divider }from "@material-ui/core";

// renders graphics for each item
export default function VariationSlide ( props ) {

    const classes = offerMiniSlideStyles();
    const [ grad, setGrad ] = useState( 0 );
    const [, setChart] = useState({
      global: {
        useUTC: true,
        timezoneOffset: 100, //UTC-5:00 time zone
      },
      width: 400,
      height: 300,
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
        },
        series: {
          color: "#F2994A",
        },
      },
      chart: {
        type: "spline",
        width: 400,
        height: 96,
        events: {
          load: function () {
            // set up the updating of the chart each second
            /*var series = this.series[0];
                    setInterval(function () {
                      var x = (new Date()).getTime(), // current time
                          y = Math.random();
                      series.addPoint([x, y], true, true);
                    }, 1000);*/
          },
        },
      },
      accessibility: {
        announceNewData: {
          enabled: true,
          minAnnounceInterval: 1000,
          announcementFormatter: function (allSeries, newSeries, newPoint) {
            if (newPoint) {
              return "New point added. Value: " + newPoint.y;
            }
            return false;
          },
        },
      },

      title: {
        text: "",
      },
      xAxis: {
        type: "datetime",
        tickPixelInterval: 150,
      },

      yAxis: {
        title: {
          enabled: false,
          text: "Value",
        },
        plotLines: [
          {
            enabled: false,
            value: 0,
            width: 1,
            color: "#808080",
          },
        ],
      },

      tooltip: {
        // enabled: false,
        headerFormat: "<b>{series.name}</b><br/>",
        pointFormat: "{point.x:%Y-%m-%d %H:%M:%S}<br/>{point.y:.2f}",
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: "Random data",
          data: props._variation.grades[grad].data,
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: "100%",
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    });

    const changeGrade = ( event ) => {
        setChart ( {} );
        setChart ( {
            width:400,
            height:300,
            plotOptions: {
                line: {
                    marker: {
                        enabled: false
                    }
                },
                series: {
                    color: '#F2994A'
                }
            },
            chart: {
                type: 'spline',
                width:400,
                height:96,
                events: {
                    load: function () {

                        // set up the updating of the chart each second
                        /*var series = this.series[0];
                        setInterval(function () {
                          var x = (new Date()).getTime(), // current time
                              y = Math.random();
                          series.addPoint([x, y], true, true);
                        }, 1000);*/
                    }
                }
            },
            accessibility: {
                announceNewData: {
                    enabled: true,
                    minAnnounceInterval: 15000,
                    announcementFormatter: function (allSeries, newSeries, newPoint) {
                        if (newPoint) {
                            return 'New point added. Value: ' + newPoint.y;
                        }
                        return false;
                    }
                }
            },

            title: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                tickPixelInterval: 150
            },

            yAxis: {
                title: {
                    enabled: false,
                    text: 'Value'
                },
                plotLines: [{
                    enabled: false,
                    value: 0,
                    width: 1,
                    color: '#808080'
                }]
            },

            tooltip: {
                // enabled: false,
                headerFormat: '<b>{series.name}</b><br/>',
                pointFormat: '{point.x:%Y-%m-%d %H:%M:%S}<br/>{point.y:.2f}'
            },
            legend: {
                enabled: false
            },
            series: [{
                name: props._variation.grades[ grad ].name,
                data: props._variation.grades[ grad ].data
            }],
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: '100%'
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }
        } );
        props.handleGreadChange(parseInt( event.target.value ));
        setGrad( event.target.value );
    }

    function formatMiles(num) {
      if(num){
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } else {
        return 0
      }
    };
    

    return (
      <div>
        <div className={classes.chartTitleBox}>
          <Typography
            variant="h6"
            gutterBottom
            className={classes.chartTitleText}
          >
            {props._variation.variation_name}
          </Typography>
        </div>
        <div className={classes.chartTitleBox} style={{ marginBottom: "20px" }}>
          <GradeDropdown
            _default={grad}
            data={{ grades: props._variation.grades }}
            _onChange={changeGrade}
            {...props}
          />

        </div>
        <div className={classes.chartTitleBox2}>
          {/*SlideChart*/}
          {props._variation.grades.map((item, index) => (            
            // eslint-disable-next-line eqeqeq
            <div className={classes.boxData} key={index}>{index == grad ? <SlideChart data={item}/> : ""}</div>
          ))}
        <ul
            className={clsx(classes.chartTitleArrowBox,classes.responsiveArrowBox)}
            style={{ minWidth: "150px"}}
          >
            <li className={classes.boxDataItem}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.chartStatText1}
                style={{ color: "#333", paddingTop: "0px",marginBottom:"0.5rem" }}
              >
                S/ {formatNumber(props._variation.grades[grad].average_price)}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.chartStatText2}
              >
                PRECIO PROMEDIO
              </Typography>
            </li>
            <li>
              <div 
              className={clsx(
                props._variation.grades[grad].price_variation > 0 && classes.iconBoxUp,
                props._variation.grades[grad].price_variation < 0 && classes.iconBoxDown,
                
              )}
              >
                {props._variation.grades[grad].price_variation > 0 ? (
                  <>
                  <div className={classes.item1}>
                  <Typography
                  gutterBottom
                  className={classes.numberVariant}>
                  S/ {formatNumber(props._variation.grades[grad].price_variation)}{" "}
                  </Typography>
                  <ArrowUpwardIcon className={classes.icon}/>
                 
                  </div>
                  </>
                ) :  props._variation.grades[grad].price_variation < 0 ? (
                <>
                <div className={classes.item1}>
                  <Typography
                    gutterBottom
                    className={classes.numberVariant}>
                  S/ {formatNumber(props._variation.grades[grad].price_variation)}{" "}
                  </Typography>
                  <ArrowDownwardIcon className={classes.icon}/>
                  </div>
                </>
                ) : (
                  <>
                  <div className={classes.item1}>
                    <Typography
                      gutterBottom
                      style={{color:"#000000"}}
                      className={classes.numberVariant2}>
                    S/ {formatNumber(props._variation.grades[grad].price_variation)}{" "}
                    </Typography>
                    <ArrowForwardIcon style={{color:"#000000"}}/>
                    </div>
                  </>
                  
                )}
              </div>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.chartStatText4}
              >
                VARIACIÓN
              </Typography>
            </li>
          </ul>
        </div>
        <div className={classes.chartTitleBox}>
          <ul
            className={classes.chartTitleArrowBox}
            style={{ minWidth: "120px", display: "flex", flexGrow: 1, justifyContent:"space-between"}}
          >
            <li style={{ marginRight: "20px" }}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.chartStatText1}
                style={{ textAlign: "left", color: "#333" }}
              >
                S/ {formatNumber(props._variation.grades[grad].max_price)}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.chartStatText2}
                style={{ textAlign: "left" }}
              >
                PRECIO MAX
              </Typography>
            </li>
            <li>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.chartStatText1}
                style={{ textAlign: "left", color: "#333" }}
              >
                S/ {formatNumber(props._variation.grades[grad].min_price)}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.chartStatText2}
                style={{ textAlign: "left"}}
              >
                PRECIO MIN
              </Typography>
            </li>
            <li>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.chartStatText3}
                style={{ color: "#333"}}
              >
                {formatMiles(props._variation.grades[grad].sold)}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.chartStatText2}
              >
                {props._variation.unit} VENDIDOS
              </Typography>
            </li>
          </ul>

        </div>
        <Divider style={{marginBottom: "20px" }} />
      </div>
    );

}