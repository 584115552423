import { makeStyles } from '@material-ui/core/styles';

export const AppShopStyles = makeStyles((theme) => ({
    banner: {
        width: "100%",
        minWidth:"150px",
        minHeight:"200px"
    },
    banner__item: {
        width: "100%",
        position: "relative",
        "& a": {
            color: "#fff",
            background: "#F2994A",
            position: "absolute",
            top: "70%",
            left: "15%",
            textAlign: "center",
            transform: "translate(-50%, -50%)",
            [theme.breakpoints.down("sm")]: {
                fontSize: "8px",
                padding: "3px 8px",
                top: "75%",
            },
        }
    },
    banner__img_hideDesktop: {
        display: "block",
        [theme.breakpoints.down("sm")]: {
            display: "none"
        },
    },
    banner__img_hideMobile: {
        display: "none",
        [theme.breakpoints.down("sm")]: {
            display: "block"
        },
    },
    categories: {
        width:"100%",
        padding:"2rem 3rem 0rem 3rem",
        [theme.breakpoints.down("sm")]: {
            padding:"1.5rem 1.5rem 0rem 1.5rem",
        },
        [theme.breakpoints.down("xs")]: {
           padding:"1rem 1rem 0rem 1rem",
        },
    },
}))
