/*-- Libraries --*/
import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  Grid,
  Popover,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ErrorIcon from "@material-ui/icons/Error";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";

/*-- redux --*/
import {
  files,
  updateFiles,
  getAllFiles,
  deleteFile,
  getDetail,
  saleDetail,
  loadingDetail,
} from "../Slice";

/*-- styles,components,functions --*/
import { mySaleDetailStyles } from "./style";
import product_1 from "assets/images/image-gallery.svg";
import LoadingData from "features/loader";
import formatNumber from 'functions/regex';
import LayoutWrapper from "features/layout/wrapper";
import ModalComponent from "features/modal"



export default function SaleDetail(props) {
  const classes = mySaleDetailStyles();
  const [invoice, setinvoice] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const inputFile = useRef(null);
  const files_ = useSelector(files);
  const _saleDetail = useSelector(saleDetail);
  const isLoadingDetail = useSelector(loadingDetail);
  const { sale_slug } = useParams();
  const cart_id = sale_slug.substring(0, sale_slug.indexOf(":"));
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  useEffect(() => {
    dispatch(getDetail(sale_slug));
  }, [dispatch, sale_slug]);

  useEffect(() => {
    dispatch(getAllFiles({ id: cart_id }));
  }, [cart_id, dispatch]);

  const openSelectFile = () => {
    inputFile.current.click();
  };

  const fileChange = (event) => {
    if (invoice) {
      setinvoice("");
    } else {
      setinvoice(event.target.files[0]);
      event.target.value = null;
    }
  };

  const saveInvoice = () => {
    setinvoice("");
    let formData = new FormData();

    formData.append("invoice", invoice);
    formData.append("cart_id", _saleDetail.cart_id);

    dispatch(updateFiles(formData, files_));
  };

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleAccept = (index, data) => {
    dispatch(deleteFile({  data: data, key: index }));
    setOpenModal(false);
  };

  return (
    <LayoutWrapper>
      <div className={classes.container}>
        <Typography
          variant="h2"
          component="h5"
          gutterBottom
          className={classes.pageTitle}
        >
          Detalles de la venta
        </Typography>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={8}>
          <Paper className={classes.miniPaper}>
            {isLoadingDetail ? (
              <LoadingData />
            ) : (
              <>
                <div className={classes.containerStatus2}>
                  <Typography
                    variant="h2"
                    component="h3"
                    gutterBottom
                    className={classes.title3}
                  >
                    {_saleDetail.status}
                  </Typography>
                  <Typography className={classes.dateTitle1}>
                  <Moment format="YYYY-MM-DD HH:mm">
                    {_saleDetail.date}
                  </Moment>

                  </Typography>
                </div>
                <Typography
                  variant="h2"
                  component="h5"
                  gutterBottom
                  className={classes.dateTitle}
                >
                  {_saleDetail.status_description}
                </Typography>
                <TableContainer>
                <div className={classes.box3}>
                  <p>Precio</p>
                  <p>Unidades</p>
                  <p style={{ color: "#616060" }}>Subtotal</p>
                </div>
                  <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                      {_saleDetail.products.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell style={{ borderBottom: "none" }}>
                            <img
                              src={item.image}
                              alt={item.product_description}
                              className={classes.imageProduct}
                            />
                          </TableCell>
                          <TableCell
                            className={classes.typeText}
                            style={{ borderBottom: "none" }}
                          >
                            <>
                              <Typography
                                variant="h2"
                                component="h6"
                                gutterBottom
                                className={classes.title}
                              >
                                {item.product_description}
                              </Typography>
                              <Typography
                                variant="h2"
                                component="h6"
                                gutterBottom
                                className={classes.product__textBold1}
                              >
                                Calibre: {item.grade}
                              </Typography>
                              <Typography
                                variant="h2"
                                component="h6"
                                gutterBottom
                                className={classes.product__textBold1}
                              >
                                Maduración: {item.maduration}
                              </Typography>
                            </>

                          </TableCell>

                          <TableCell style={{ borderBottom: "none" }}>
                            <>
                              <Typography
                                variant="h2"
                                component="h6"
                                gutterBottom
                                className={classes.product__textBold}
                              >
                                S/ {formatNumber(item.price)}
                              </Typography>
                            </>
                          </TableCell>
                          <TableCell style={{ borderBottom: "none" }}>
                            <>

                              <Typography
                                variant="h2"
                                component="h6"
                                gutterBottom
                                className={classes.product__textBold3}
                              >
                                {item.quantity} {item.product_unit}
                              </Typography>
                            </>
                          </TableCell>
                          <TableCell
                            style={{ borderBottom: "none" }}
                            className={classes.row}
                          >
                            <>
                              <Typography
                                variant="h2"
                                component="h6"
                                gutterBottom
                                className={classes.product__textBold2}
                              >
                                S/ {formatNumber(item.quantity * item.price)}
                              </Typography>
                            </>
                          </TableCell>

                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={classes.box2}>
                  <p>Monto total a facturar</p>
                  <p>S/ {formatNumber(_saleDetail.payment.amount)}</p>
                </div>
                <div className={classes.detailStatus}>
                  <p>Tipo de despacho : <span>{_saleDetail.shippingMethod}</span></p>
                </div>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Paper className={classes.miniPaper}>
            {isLoadingDetail ? (
              <LoadingData />
            ) : (
              <>
                <div className={classes.billToContainer}>
                  <Typography
                    variant="h2"
                    component="h3"
                    gutterBottom
                    style={{
                      color: "#000000",
                      fontSize: "14px",
                      marginBottom: "1rem",
                    }}
                  >
                    PEDIDO N° {_saleDetail.order}
                  </Typography>
                  <span> {_saleDetail.billToData.businessName}</span>
                  <p> RUC: {_saleDetail.billToData.ruc}</p>
                </div>
                <div className={classes.containerStatus}>
                  <Typography
                    variant="h2"
                    component="h3"
                    gutterBottom
                    style={{margin:"1rem 0.5rem 0 0"}}
                    className={classes.title3}
                  >
                    Resumen
                  </Typography>
                </div>
                <div className={classes.box}>
                  <p>Monto total a facturar</p>
                  <p>S/ {formatNumber(_saleDetail.payment.amount)}</p>
                </div>
                <div className={classes.box}>
                  <p>Comisión Whipay
                    <span> (incluido IGV)</span>
                  </p>
                  <p>S/ {formatNumber(_saleDetail.payment.whipay_fee)}</p>
                </div>
                <div className={classes.detailTotal}>
                  <p>Monto neto a recibir</p>
                  <p>S/ {formatNumber(_saleDetail.payment.amount - _saleDetail.payment.whipay_fee)}</p>
                </div>

                {/* <p className={classes.textBox}>
                  {_saleDetail.payment.status_description}
                </p> */}

                <span className={classes.textBox}>
                  <ErrorIcon
                    aria-owns={open ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    className={classes.warningIcon}
                  />
                  {_saleDetail.payment.status_description}
                </span>

                <Popover
                  id="mouse-over-popover"
                  className={classes.popover}
                  classes={{
                    paper: classes.popoverPaper,
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Typography className={classes.popoverText}>
                  La fecha de pago se mostrará una vez completada la venta al 100%.
                  </Typography>
                </Popover>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Paper className={classes.miniPaper}>
            <div style={{ minHeight: "200px" }}>
              <Typography className={classes.title3}>Factura</Typography>
              <Grid container spacing={2} style={{ margin: "1rem auto" }}>
                {files_.map((file, index) => (
                  <Grid
                    key={file.id}
                    className={clsx(
                      classes.uploadFilesWrap,
                      classes.alignColumn
                    )}
                  >
                    <Typography className={classes.textInvoice}>
                      ¡Gracias por subir tu Factura!
                    </Typography>
                    <SentimentVerySatisfiedIcon
                      style={{
                        color: "#F2C94C",
                        alignSelf: "center",
                        fontSize: "30px",
                      }}
                    />
                    <Grid className={classes.invoiceBox}>
                      <img src={product_1} alt={file.name} />
                      <Typography className={classes.textInvoice}>
                        {file.url.replace(/^.*[\\/]/, "")}
                      </Typography>
                      <Grid item>
                        <DeleteIcon
                          className={classes.icons}
                          onClick={handleClickOpen}
                        />
                        <ModalComponent
                          openModal={openModal}
                          onClose={handleClose}
                          {...props}

                        >
                          <Grid className={classes.modal}>
                            <Grid className={classes.modal__box} item>
                              <ErrorOutlineIcon className={classes.modal__icon}/>
                            </Grid>
                            <Grid className={classes.modal__box} item>
                              <Typography
                                className={classes.modal__alert}
                                variant="h2"
                                component="h5"
                                gutterBottom
                              >
                                ¿Estás seguro que deseas eliminar la factura?
                              </Typography>
                            </Grid>
                            <Grid  className={classes.modal__box}>
                              <Button  onClick={()=> handleAccept(index, file)} className={classes.modal__button}>
                                Confirmar
                              </Button>
                              </Grid>
                              <Grid  className={classes.modal__box}>
                              <Button onClick={handleClose} className={classes.modal__button} autoFocus>
                                Cancelar
                              </Button>
                              </Grid>
                          </Grid>
                        </ModalComponent>
                        <a
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <VisibilityIcon className={classes.icons} />
                        </a>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              {files_.length === 1 ? (
                ""
              ) : (
                <div className={classes.uploadFilesWrap}>
                  <div className={classes.uploadFilesButtonWrap}>
                    {!invoice?
                    <>                 <input
                      type="file"
                      id="file"
                      accept="image/pdf/*"
                      ref={inputFile}
                      style={{ display: "none" }}
                      onChange={fileChange}
                    />
                    <AddIcon
                      onClick={openSelectFile}
                      style={{
                        height: "48px",
                        width: "48px",
                        borderRadius: "50px",
                        background: "#E0E0E0",
                        color: "#FFF",
                        cursor: "pointer",
                        margin: "0 auto",
                      }}
                    />
                    <p className={classes.filesListHeading}>
                      Agrega tu factura.
                    </p>
                    </>
                    :''}

                    {!invoice? (
                      ""
                    ) : (
                      <ul className={classes.filesList}>
                        <li>
                          <img src={product_1} alt={invoice.name} />
                          <span>{invoice.name}</span>
                          <DeleteIcon
                            style={{ color: "#53CD94" }}
                            onClick={() => setinvoice("")}
                          />

                        </li>
                        <Button
                          style={{ color: "#53CD94" }}
                          onClick={() => saveInvoice()}
                        >
                          Adjuntar
                        </Button>
                      </ul>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </LayoutWrapper>
  );
}
