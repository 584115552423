import { makeStyles } from "@material-ui/core/styles";

export const dashboardStyles = makeStyles((theme) => ({
  container1: {
    display: "flex",
    width: "100%",
  },
  container2: {
    display: "flex",
    width: "100%",
  },
  container3: {
    display: "flex",
    width: "100%",
    paddingTop: "35px",
    paddingBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginBottom: "10px",
    },
  },
  title1: {
    color: "#333",
    flexGrow: 1,
    fontSize: "22px",
    fontWeight: 500,
    lineHeight: "26.07px",
    marginBottom: "25px",
  },
  title2: {
    color: "#006D51",
    fontSize: "15px",
  },
  title3: {
    color: "#53CD94",
    fontSize: "22px",
    fontWeight: 500,
    marginBottom: "20px",
    lineHeight: "26.07px",
  },
  title4: {
    color: "#53CD94",
    fontSize: "22px",
    fontWeight: 400,
    lineHeight: "26.07px",
  },
  paper: {
    padding: "15px 25px",
    borderRadius: "12px",
    color: "#53CD94",
    fontSize: "12px",
    fontWeight: "bold",
    boxShadow: "none",
  },
  paper1: {
    padding: "25px",
    borderRadius: "25px",
    color: "#53CD94",
    fontSize: "12px",
    fontWeight: "bold",
    boxShadow: "none",
  },
  paperHeading: {
    color: "#333",
    margin: "0 auto",
    fontSize: "20px",
    fontWeight: "bold",
    textAlign: "center",
  },
  miniPaper: {
    padding: "14px 25px",
    borderRadius: "10px",
    color: "#53CD94",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "11.85px",
    boxShadow: "none",
  },
  miniCartTitle: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "23.7px",
    color: "#333",
    textAlign: "center",
    margin: "1rem",
  },
  board: {
    background: "#006D51",
    borderRadius: "25px",
    padding: "25px",
    paddingBottom: "10px",
  },
  boardRed: {
    background: "#0D7C8E",
  },
  boardTitleWrap: {
    display: "flex",
    marginBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  boardTitle: {
    display: "flex",
    alignItems: "center",
    color: "#FFF",
    fontSize: "22px",
    fontWeight: 400,
    lineHeight: "26.07px",
    flexGrow: 1,
    "& svg": {
      marginRight: "20px",
    },
  },
  boardSwitch: {
    width: "165px",
    display: "flex",
    alignItems: "center",
    "& > span": {
      display: "flex",
      fontSize: "9px",
      fontWeight: 700,
      lineHeight: "10.67px",
      "&:first-child": {
        color: "#FFF",
        paddingRight: "10px",
      },
    },
  },
  boardTable: {
    minWidth: "550px",
    background: "transparent",
    "& tr": {
      background: "transparent",
      color: "#FFF !important",
    },
  },
  boardTableRow: {
    background: "transparent",
  },
  boardTableCell: {
    color: "#FFF !important",
    fontSize: "9px",
    fontWeight: 700,
    lineHeight: "10.67px",
    textTransform: "uppercase",
    padding: "5px 0",
    borderColor: "rgba(83, 205, 148, 0.5)",
  },
  boardTableBodyCell: {
    color: "#FFF !important",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    padding: "13px 0",
    alignItems: "center",
    borderColor: "rgba(83, 205, 148, 0.5)",
  },
  boardTableCellButton: {
    height: "18px",
    width: "53px",
    color: "#FFF",
    fontSize: "8px",
    fontWeight: 400,
    lineHeight: "9.48px",
    background: "#F2994A",
    borderRadius: "4px",
  },
  boardTableCellButton1: {
    background: "#53CD94",
  },
  testClass: {
    textAlign: "left",
    "& > div": {
      paddingLeft: "0px",
      "& > div": {
        "&:first-child": {
          display: "none !important",
        },
      },
      "& > p": {
        display: "none !important",
      },
      "& > div.MuiTablePagination-selectRoot": {
        display: "none !important",
      },
      "& > .MuiTablePagination-actions": {
        marginLeft: "0px",
        "& > button": {
          borderRadius: "4px",
          border: "1px solid rgba(255, 255, 255, 0.5)",
          marginRight: "15px",
          padding: "0px",
          color: "rgba(255, 255, 255, 0.5)",
        },
      },
    },
  },
  order: {
    borderRadius: "25px",
    padding: "25px",
  },
  orderTitle: {
    fontSize: "22px",
    fontWeight: 400,
    lineHeight: "26.07px",
    color: "#808285",
    marginBottom: "30px",
    marginTop: "15px",
  },
  orderTable: {
    width: "100%",
    minWidth: "550px",
    textAlign: "left",
    "& tr": {
      "& th": {
        borderBottom: "1px solid #E6E7E8",
        padding: "10px 0px",
      },
      "& td": {
        padding: "10px 0px",
      },
    },
  },

  tableCustomeIcon: {
    height: "24px",
    width: "24px",
    background: "#F2994A",
    borderRadius: "50px",
    display: "block",
    textAlign: "center",
    lineHeight: "24px",
    color: "#FFF",
  },
  orderTableCellButton: {
    height: "18px",
    width: "92px",
    color: "#FFF",
    fontSize: "8px",
    fontWeight: 400,
    lineHeight: "9.48px",
    background: "#F2994A",
    borderRadius: "4px",
    "&:hover": {
      boxShadow: "none",
      background: "#006D51",
    },
  },
  orderTableFooterText: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "14.22px",
    color: "#F2994A",
    marginBottom: "10px",
  },
  orderTableFooterSubText: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    color: "#808285",
    marginBottom: "10px",
  },
  visited: {
    textDecoration: "none",
    color: "black",
    "&:visited": {
      textAlign: "left",
      textTransform: "uppercase",
      color: "black",
      fontSize: "10px",
      fontWeight: 500,
      lineHeight: "11.85px",
      textDecoration: "none",
    },
    "&:hover": {
        boxShadow: "none",
        color: "#53cd94",
      },
  },
  // link: {
  //   "& a": {
  //     textDecoration: "none",
  //     "&:hover": {
  //       color: "#006D51",
  //     },
  //   },
  //   "& a:visited": {
  //     color: "#333333",
  //   },
  // },
  titleLink: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "15.41px",
    color: "#006D51",
    "& a": {
      color: "#006D51",
      textDecoration: "none",
    },
    "& a:visited": {
      color: "#006D51",
    },
  },
  priceBoardUp: {
    transform: "rotate(-45deg)",
    height: "12px",
    width: "12px",
  },
  priceBoardDown: {
    transform: "rotate(135deg) !important",
    height: "12px",
    width: "12px",
  },
  tableCon1: {
    [theme.breakpoints.up("sm")]: {
      overflow: "auto",
    },
  },
  iconrotate: {
    transform: "rotate(90deg)",
  },
  iconrotateChange: {
    transform: "rotate(0deg)",
  },
}));
