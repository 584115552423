import { makeStyles } from "@material-ui/core/styles";

export const FullLayoutStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        background: "#F1F2F2",
        fontSize: "12px",
      },
      toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
      },
      menuButton: {
        marginRight: 36,
      },
      menuButtonHidden: {
        display: "none",
      },
    
      appBarSpacer: theme.mixins.toolbar,
      content: {
        flexGrow: 1,
        position: "sticky",
        overflow: "auto",
        paddingTop: "64px",
        overflowX: "hidden",
        [theme.breakpoints.down("xs")]: {
          paddingTop: "56px",
        },
      },
      container: {
        padding: "1rem",
        minHeight: "calc(80vh - 40px)",
        alignContent: "flex-start",
    
        [theme.breakpoints.down("md")]: {
          minHeight: "calc(90vh - 40px)",
        },
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(3),
        },
      },
      paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        position: "sticky",
      },
      fixedHeight: {
        height: 240,
      },
    
      newsListBox: {
        cursor: "pointer",
        display: "inline-flex",
        color: "#808285",
        height: "30px",
        alignItems: "center",
        padding: "4px 20px",
        borderRight: "1px solid #E0E0E0",
        margin: "4px 0px",
        fontSize: "14px",
        fontWeight: 500,
        textTransform: "uppercase",
        "&:first-child": {},
      },
      iconBox: {
        background: "#E0E0E0",
        borderRadius: "4px",
        display: "inline-flex",
        margin: "0 4px",
      },
      iconBoxDown: {
        background: "#F2C94C",
        borderRadius: "4px",
        display: "inline-flex",
        margin: "0 4px",
      },
      iconBoxUp: {
        background: "#53CD94",
        borderRadius: "4px",
        display: "inline-flex",
        margin: "0 4px",
      },
      icon: {
        fontSize: "15px",
        color: "#FFF",
        cursor: "pointer",
        "& a": {
          color: "#006D51",
        },
        "& a:visited": {
          color: "#006D51",
        },
      },
      textbox: {
        color: "#53CD94",
      },
      textboxDown: {
        color: "#F2C94C",
      },
      snackbarStyleTopRight: {
        top: "80px",
      },

}));