/*-- Libraries --*/
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import "react-multi-carousel/lib/styles.css";
import clsx from "clsx";
/*-- redux --*/
import { products } from "./Slice";
import { loadingResult } from "../common/filter/Slice";

/*-- styles,components,functions --*/
import ShopFilter from "features/product/common/filter";
import AppProductListItem from "features/product/list/listItems";
import SkeletonList from "../skeleton";
import WithoutResult from "features/layout/withoutResult";
import LayoutWrapper from "features/layout/wrapper";
import { getCategoriesImages } from "features/product/list/Slice";
import { listStyles } from "./styles";
import LoadingData from "features/loader";

export default function AppProductList(props) {
    const classes = listStyles();
    const _products = useSelector(products);
    const _loadingResult = useSelector(loadingResult);
    const [categories, setcategories] = useState([]);
    const [isLoadingCat, setIsLoadingCat] = useState(false);

    const emptySaleData = {
        message: "No hay resultados para esta búsqueda",
    };

    useEffect(() => {
        setIsLoadingCat(true);
        getCategoriesImages((response) => {
            setcategories(response);
            setIsLoadingCat(false);
        })
    }, []);

    return (
        <>
            <LayoutWrapper>
            <Grid container spacing={2} style={{ minHeight:"150px"}}>
                {
                    isLoadingCat ?
                     Array.from(new Array(4)).map((el, index) => (
                        <LoadingData key={index} />
                    )) : categories.map((category, index) => (

                        <Grid item xs={6} sm={6} md={3} lg={3} xl={3} key={category.id}>
                            <Link
                                to={ category.id === 1 || category.id === 2 ? `/mercado_mayorista/${category.category}/${category.id}` : '#' }
                            >
                                <img key={index} src={category.url} alt={category.category} className={clsx(classes.category__img, category.id === 1 || category.id === 2 ? '' : classes.category__img_disabled )} />
                            </Link>
                        </Grid>
                    ))
                }

                </Grid>
                <ShopFilter />
                <Grid container spacing={2}>
                    { _loadingResult ? (
                        Array.from(new Array(18)).map((el, index) => (
                            <SkeletonList key={index} />
                        ))
                    ) : _products.length !== 0 ? (
                        _products.map((item) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={item.id}>
                                <AppProductListItem data={item} {...props} />
                            </Grid>
                        ))
                    ) : (
                        <WithoutResult data={emptySaleData} />
                    )}
                </Grid>
            </LayoutWrapper>

        </>

    );
}
