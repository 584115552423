import React from "react";
import logo from "assets/images/logo.svg";

import { logoStyles } from './styles';
import clsx from "clsx";
import {Link} from "react-router-dom";

export default function AppLogo( props ) {

    // const _sidebarStatus = useSelector ( sidebarStatus );
    const logoClasses = logoStyles ();

    return (
        <div className={clsx( logoClasses.toolbarIcon, )} component={Link}>

            <Link to={'/'} style={{ width: '100%', display: 'block', textAlign: 'center'}}>
            
                 <img src={logo} className={logoClasses.appLogo} alt="logo" />
            
            </Link>

        </div>
    )
}