import { Paper } from "@material-ui/core";
import React, { useState } from "react";
import { myRecoverPasswordStyle } from "../RecoverPassword/styles";
import logo from "assets/images/logo.svg";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { recoverPasswordEP } from "endpoints";
import axios from "axios";
import { useHistory } from "react-router-dom";
import check from "assets/images/check.svg";
import LayoutWrapper from "features/layout/wrapper";

export default function RecoverPassword () {
    const classes = myRecoverPasswordStyle();
    let history = useHistory();
    const [error, setError] = useState(false);
    const [email, setEmail] = useState("");
    const [userError, setUserError] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);

    const passwordChange = (event) => {
        setError(false);
        setEmail(event.target.value);
        setUserError(false);
        setInvalidEmail(false);
    }

    const recoverPassword = async () => {

        if (!email){
            setError(true);
            return false;
        }

        const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!re.test(email)){
            setInvalidEmail(true);
            return false;
         }

        await axios.post(recoverPasswordEP, {
            email: email,
        }).then ((response) => {
            setChangePassword(true);
        }).catch (function(error) {
            setInvalidEmail(true);
        })

    };
    return (
        <LayoutWrapper>
            <Paper className={classes.paper}>
                <div className={classes.container}>
                <img src={logo} className={classes.logo} alt={""} />
                </div>
                {changePassword ? (
                <>
                <img src={check} className={classes.check} alt={""}/>
                <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.info}
                    style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "23.7px",
                        color: "#333333",
                        marginBottom: "25px",
                        display:"flex",
                        textAlign:"justify",
                        fontFamily: '"Rubik",sans-serif',
                    }}
                >
                El enlace para cambiar tu contraseña se ha enviado a {email}
                </Typography>
                <div className={classes.list}>
                    <lo>
                        <li>Los correos electrónicos pueden demorarse unos minutos.</li>
                        <li>Si no ves el correo electrónico en bandeja de entrada, verifica la carpeta de correos no deseados o spam.</li>
                        <li>Si presentas algún otro inconveniente para iniciar sesión, <a
                            href="mailto:clientes@whipay.pe?Subject=Info: WhiPay - Mercado Mayorista" target="_top"
                            style={{
                                color: "#53CD94",
                                textDecoration: "none",
                                fontWeight: 500,
                                textIndent:"5px",
                            }}>
                            Contáctanos
                            </a>
                        </li>
                    </lo>
                </div>
                <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className={classes.button}
                    onClick={()=> {history.push('/mercado')}}
                >
                VOLVER AL MERCADO
                </Button>
                </>
                ):
                <>
                <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.loginTitle}
                    style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        lineHeight: "23.7px",
                        color: "#333333",
                        marginBottom: "25px",
                        display:"flex",
                        justifyContent:"center",

                    }}
                >
                Olvidaste tu contraseña
                </Typography>
                {error ? (
                    <Typography
                    variant="h2"
                    component="span"
                    gutterBottom
                    className={classes.error}
                    style={{ fontSize: "14px", marginBottom: "10px" }}
                  >
                    ¡Por favor, ingresa tu correo electrónico!
                  </Typography>
                ) : userError}
                 {invalidEmail ? (
                    <Typography
                    variant="h2"
                    component="span"
                    gutterBottom
                    className={classes.error}
                    style={{ fontSize: "14px", marginBottom: "10px" }}
                  >
                    No se encontró el email registrado
                  </Typography>
                ) : userError}
                <p className={classes.label}>
                Ingresa el correo electrónico asociado a tu cuenta. Te enviaremos un enlace para realizar el cambio de contraseña.
                </p>
                <div style={{paddingTop:"20px"}}>
                <label className={classes.label}>E-mail</label>
                <input
                    type={"text"}
                    placeholder={"ejemplo@whipay.pe"}
                    className={classes.input}
                    id={"username"}
                    value={email}
                    onChange={passwordChange}
                    autoFocus
                    onKeyPress={(event) => event.key === "Enter" && recoverPassword()}
                />
                </div>
                <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className={classes.button}
                    onClick={recoverPassword}
                >
                SOLICITAR NUEVA CONTRASEÑA
                </Button>
                <Typography
                    variant="h2"
                    component="span"
                    gutterBottom
                    className={classes.termsText}
                    style={{ fontSize: "14px", color:"#808285" }}
                >
                    ¿Necesitas ayuda? {"  "}
                <a
                    href="mailto:clientes@whipay.pe?Subject=Info: WhiPay - Mercado Mayorista" target="_top"
                    style={{
                        color: "#53CD94",
                        textDecoration: "none",
                        fontWeight: 500,
                        textIndent:"5px",
                    }}
                >
                  Contáctanos
                </a>
                </Typography>
                </>
                }
            </Paper>
        </LayoutWrapper>
    )
}
