import { makeStyles } from "@material-ui/core/styles";

const wrapperStyles =makeStyles((theme)=>({
    layout:{
        width:"100%",
        padding:"2rem 4rem",
        [theme.breakpoints.down("lg")]: {
            padding: "2rem 3rem",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "1.5rem",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "1rem",
        },
    },
}));

function LayoutWrapper ({children}) {
    const classes = wrapperStyles();
    return(
        <div className={classes.layout}>
            {children}
        </div>
    )
};

export default LayoutWrapper;
