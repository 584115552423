/*-- Libraries --*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import Moment from "react-moment";
import { Grid, Typography, Button, Paper } from "@material-ui/core";

/*-- redux --*/
import {
  getOrders,
  orders,
  loadingMyOrders,
} from "./slices";

/*-- styles,components,functions --*/
import { MyOrdersStyles } from "./styles";
import PopoverComponent from "features/popover";
import EmptyMessage from "features/layout/emptyMessage";
import LoadingData from "features/loader";
import formatNumber from 'functions/regex';
import LayoutWrapper from "features/layout/wrapper";

export default function MyOrders(props) {
  const classes = MyOrdersStyles();
  const dispatch = useDispatch();

  const myOrdersValues = useSelector(orders);
  const loading = useSelector(loadingMyOrders);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);

  useEffect(() => {
    // ToDo: Fix blinking
    setTimeout(function () {
      dispatch(getOrders({}));
    }, 100);
  }, [dispatch]);

  const handlePopoverOpen = (event, popoverId) => {
    setAnchorEl(event.currentTarget);
    setOpenedPopoverId(popoverId);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  const emptySaleData = {
    message: "Por el momento no tienes compras realizadas",
    linkTo: "/mercado",
    messageLink: "Te invitamos a visitar el mercado",
  };

  return (
    <LayoutWrapper>
      <div className={classes.container}>
        <Typography
          variant="h2"
          component="h5"
          gutterBottom
          className={classes.pageTitle}
        >
          Mis compras
        </Typography>
        <Button
          variant="contained"
          component={Link}
          color="primary"
          className={classes.button}
          to="/mercado"
        >
          Ir al Mercado
        </Button>
      </div>
      <Paper className={classes.paper}>
        {loading ? (
          <LoadingData />
        ) : myOrdersValues.length !== 0 ? (
          myOrdersValues.map((order) => (
            <Grid
              item
              className={classes.orderContainer}
              key={order.orderNumber}
            >
              <div className={classes.statusContainer}>
                <Typography
                  variant="h2"
                  component="h5"
                  gutterBottom
                  className={clsx(classes.statusTitle, classes.inProcessColor)}
                  style={{ fontWeight: 400 }}
                >
                  {order.status}
                  <span
                    className={classes.statusDate}
                    style={{ marginLeft: "0.5rem" }}
                  >
                    | {order.status_date}
                  </span>
                </Typography>
                <Link
                  to={`mis-ordenes/${order.orderSlug}/tracking`}
                  className={classes.link}
                >
                  Rastrear envío
                </Link>
              </div>

              <Grid container className={classes.statusDetailContainer}>
                <Grid
                  item
                  xs
                  container
                  direction="column"
                  className={classes.statusDetail}
                >
                  <h3>PEDIDO REALIZADO</h3>

                  <span>
                    <Moment format="YYYY-MM-DD HH:mm">{order.orderDate}</Moment>
                  </span>
                </Grid>
                <Grid
                  item
                  xs
                  container
                  direction="column"
                  className={classes.statusDetail}
                >
                  <h3>TOTAL</h3>
                  <span>S/{formatNumber(order.total)}</span>
                </Grid>
                <Grid
                  item
                  xs
                  container
                  direction="column"
                  className={classes.statusDetail}
                >
                  <h3>ENVIAR A </h3>
                  <Typography
                    onMouseEnter={(event) =>
                      handlePopoverOpen(event, order.orderNumber)
                    }
                    onMouseLeave={handlePopoverClose}
                  >
                    {order.shipToData.shipTo}
                  </Typography>
                  <PopoverComponent
                    handlePopoverOpen={handlePopoverOpen}
                    open={openedPopoverId === order.orderNumber}
                    anchorEl={anchorEl}
                    shipToData={order.shipToData}
                  />
                </Grid>
                <Grid className={classes.order}>
                  <Grid
                    item
                    xs
                    container
                    direction="column"
                    className={classes.statusDetail}
                  >
                    <h3>PEDIDO N° {order.orderNumber}</h3>
                    <span style={{ fontSize: "14px" }}>
                      <Link
                        to={`mis-ordenes/detalle/${order.orderSlug}`}
                        className={classes.link}
                      >
                        Ver detalle
                      </Link>
                      |
                      {order.status === "Por confirmar" ? (
                        <Link
                          to={`mis-ordenes/voucher/${order.orderSlug}`}
                          className={classes.link}
                        >
                          Ver comprobante
                        </Link>
                      ) : !order.delivered ? (
                        <Link
                          to={{
                            pathname: `mis-ordenes/recepcion/${order.orderSlug}`,
                            state: order.orderNumber,
                          }}
                          className={classes.link}
                        >
                          Lo recibí
                        </Link>
                      ) : (
                        " Recibido"
                      )}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))
        ) : (
          <EmptyMessage data={emptySaleData} />
        )}
      </Paper>
    </LayoutWrapper>
  );
}
