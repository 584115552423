import { makeStyles } from '@material-ui/core/styles';

export const notificationPopupStyles = makeStyles((theme) => ({
    notContainer:{
        width: "500px",
        padding: "25px",
        borderRadius: "12px",
        maxHeight:"330px",
        overflowY:"scroll",
        [theme.breakpoints.down('sm')]: {
            width: "250px",
            maxHeight:"500px",
            overflowY:"scroll",
          }
    },
    notiTitle:{
        width:"80%",
        color:"#808285",
        fontSize: "12px",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            width: "60%",
          }
    },
    notiDate:{
        width: "10%",
        color: "#808285",
        fontSize: "10px",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            width: "10%",
          }
    },
    notiIcon:{
        width: "5%",
        display: "flex", 
        alignItems: "center",
        justifyContent: "flex-end",
        [theme.breakpoints.down('sm')]: {
            width: "8%",
          }
    },
    heading:{
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '16.59px',
        color: '#4BB985',
        padding: '10px',
        paddingBottom: '20px',
        borderBottom: '1px solid #E6E7E8'
    },
    titleNoti:{
        display:"flex",
        width: 'fit-content',
        fontSize: '12px',
        lineHeight: '11.85px',
        color: '#4BB985',
    },
    
    notificationWrap: {
        display: "flex",
        justifyContent:"space-between",
        width: '100%',
        padding: '10px',
        borderBottom: '1px solid #E6E7E8',
        cursor:"pointer",
        '&:hover':{
            background:"#D3D3D3",
        },
      
    },
    
    emptyNotofication: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '16.59px',
        textAlign: 'center',
        marginTop: '25px',
        marginBottom: '15px'
    }
}));