/*-- Libraries --*/
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

/*-- redux --*/
import { getShipMethod } from "pages/ShipMethod/slice";
import { getCart } from "pages/Cart/cartSlice";

export default function useShippingMethod() {

    const data = useSelector(getCart);
    const [cartData, setCartData] = useState([]);
    const [shipMethod, setShipMethod] = useState("0");

    useEffect(() => {
        getShipMethod((callback) => {
        setShipMethod(callback.id.toString());
        });
    }, []);

    useEffect(() => {
        if (data.length > 0) {
        setCartData(data);
        }
    }, [data]);

    return {
        shipMethod,
        setShipMethod,
        cartData,
    };
}
