import { makeStyles } from '@material-ui/core/styles';

export const offerSlideStyles = makeStyles((theme) => ({
    paper1: {
        padding: '25px',
        borderRadius: '25px',
        color: '#53CD94',
        fontSize: '12px',
        fontWeight: 'bold',
        boxShadow: 'none',
        position: 'relative',
        [theme.breakpoints.down("sm")]: {
            padding: '1rem',
        },  
    },
    chartCategoryBox: {
        display: 'flex',
        width: '100%',
    },
    chartCategoryText: {
        fontSize: '14px',
        lineHeight: '16.59px',
        fontWeight: 500,
        flexGrow: 1
    },
    chartCategoryBulletBox: {
        margin: '0 auto',
        padding: '0px',
        listStyle: 'none'
    },
    chartCategoryBullet: {
        display: 'inline-block',
        height: '10px',
        width: '10px',
        background: '#C4C4C4',
        marginLeft: '10px',
        borderRadius: '10px',
        cursor: 'pointer'
    },
    chartCategoryBulletActive: {
        background: '#53CD94'
    },
    chartTitleBox: {
        display: 'flex',
        width: '100%'
    },
    chartTitleText: {
        fontSize: '18px',
        lineHeight: '21.33px',
        fontWeight: 400,
        flexGrow: 1,
        color: '#333',
        height: '42px',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    chartTitleArrowBox: {
        margin: '0 auto',
        padding: '0px',
        listStyle: 'none',
        minWidth: '80px'
    },
    chartTitleArrow: {
        display: 'inline-block',
        height: '30px',
        width: '30px',
        marginLeft: '10px',
        borderRadius: '4px',
        cursor: 'pointer',
        border: '2px solid #E0E0E0',
        padding: '4px 8px'
    },
    chartTitleArrowLast: {
        padding: '4px'
    },
    chartTitleArrowIcon: {
        fontSize: '18px'
    },
    ChartWrap: {
        width: '100%'
    },
    chartStatText1: {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '21.33px',
        color: '#F2C94C',
        minWidth: '100px',
        paddingTop: '20px',
        textAlign: 'right'
    },
    chartStatText2: {
        fontSize: '9px',
        fontWeight: 500,
        color: '#808285',
        minWidth: '100px',
        textTransform: 'uppercase',
        textAlign: 'right',
        lineHeight: '10.67px'
    },
    chartOfferButton: {
        boxShadow: 'none',
        background: '#53CD94',
        color: '#FFF',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: 700,
        height: '38px',
        padding: '0px 30px',
        '&:hover': {
            boxShadow: 'none',
            background: "#006D51",
        }
    },

}));
