import { makeStyles } from "@material-ui/core/styles";

export const cartStyles = makeStyles((theme) => ({
  cart:{
    padding:"2rem 4rem",
    [theme.breakpoints.down("sm")]: {
      padding:"2rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding:"1.5rem",
    },
  },
  container: {
    display: "flex",
    width: "100%",
    alignItems:"center",
    // paddingBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  pageTitle: {
    color: "#53CD94",
    fontSize: "32px",
    lineHeight: "38px",
    alignItems: "baseline",
    flexGrow: 1,
    paddingTop:"8px",
    "& span": {
      fontSize: "15px",
      lineHeight: "19px",
      marginLeft: "15px",
      color: "#808285",
      whiteSpace: "pre",
    },
  },
  paper: {
    padding: "15px 25px",
    borderRadius: "12px",
    color: "#53CD94",
    fontSize: "25px",
    fontWeight: "bold",
    boxShadow: "none",
    width: "100%",
    paddingTop: "16px",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
    },
  },
  table: {
    boxShadow: "none",
  },
  deleteIcon: {
    cursor: "pointer",
    color: "#53CD94",
  },
  productImageWrap: {
    width: "150px",

  },
  productImage: {
    height: "100px",
    maxWidth: "120px",
  },
  productTitle: {
    fontSize: "18px",
    color: "#333333",
    fontWeight: 400,
    lineHeight: "21.33px",
    marginBottom: "11px",
  },
  productGrade: {
    fontSize: "12px",
    color: "#53CD94",
    fontWeight: 500,
    lineHeight: "14.22px",
    marginBottom: "8px",
  },
  productSellerName: {
    fontSize: "14px",
    color: "#53CD94",
    fontWeight: 500,
    lineHeight: "16.59px",
    marginBottom: "0px",
  },
  productCaption: {
    color: "red",
    fontSize:"11px",
    textAlign:"center"
  },
  qtyWrap: {
    height: "36px",
    width: "137px",
    background: "rgba(128, 130, 133, 0.25)",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    padding: "6px",
    margin: "0 auto",

  },
  qtyButtons: {
    height: "24px",
    width: "24px",
    background: "#53CD94",
    color: "#FFF",
    "&:hover": {
      background: "#006D51",
    },
  },
  qtyInputWrap: {
    flexGrow: 1,
    textAlign: "center",
  },
  pageText: {
    fontSize: "20px",
    color: "#333",
    fontWeight: 400,
    lineHeight: "23.7px",
    marginBottom: "40px",
  },
  totalAmount: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "1rem",
    "& span": {
      fontSize: "14px",
      lineHeight: "17px",
      color: "#333333",
      fontWeight: "normal",
    },
  },
  shipMessage: {
    textAlign: "center",
    marginBottom: "1rem",
    "& span": {
      fontSize: "12px",
      lineHeight: "14px",
      color: "#808285",
      textAlign: "center",
    },
  },
  lockDescription: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  pageLock: {
    marginLeft: "10px",
    color: "#53CD94",
  },
  textLock: {
    fontSize: "18px",
    color: "#808285",
    fontWeight: 400,
    lineHeight: "21.33px",
  },
  totalStyle: {
    color: "black",
    fontSize: "15px",
    fontWeight: "600",
  },
  checkcoutButton: {
    background: "#53CD94",
    color: "#FFF",
    borderRadius: "8px",
    boxShadow: "none",
    padding: "0.5rem",
    width: "100%",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "&:hover": {
      background: "#006D51",
    },
  },
  hideOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  errorLabel: {
    fontSize: "10px",
    fontWeight: 400,
    // lineHeight: "16.59px",
    display: "flex",
    marginTop: "10px",
    minHeight: "16px",
  },
  errorColor: {
    color: "red",
  },
  message:{
    cursor: "pointer",
    color: "red",
    paddingTop: "15px",
    position: "absolute",
    left:"42%",
    [theme.breakpoints.down("lg")]: {
      left:"3%",
      position: "relative",
    },
    [theme.breakpoints.down("sm")]: {
      left:"4%",
      position: "relative",
    },
  }
}));
