import Paper from "@material-ui/core/Paper";

import { notFoundStyles } from './styles';
import Typography from "@material-ui/core/Typography";
import ImageNotFound from 'assets/images/404.svg'
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import LayoutWrapper from "features/layout/wrapper";

export default function APP_404() {

    const classes = notFoundStyles();

    return (
        <LayoutWrapper>
            <Paper className={classes.paper}>
                <Typography variant="h2" component="h5" gutterBottom className={classes.pageTitle}>
                    Ocurrió un error
                </Typography>
                <Typography variant="h2" component="h5" gutterBottom className={classes.pageSubTitle}>
                    No pudimos encontrar la página que estabas buscando
                </Typography>
                <img src={ImageNotFound} className={classes.image} alt={''} />
                <div className={classes.buttonWrap}>
                    <Button variant="contained" component={Link} size="medium" color="primary" className={classes.button} to='/mercado'>
                        REGRESAR AL MERCADO
                    </Button>
                </div>
            </Paper>
        </LayoutWrapper>
    )
}