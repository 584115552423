import { makeStyles } from "@material-ui/core/styles";
export const helpStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: "100%",
      marginTop: "20px",
      // [theme.breakpoints.down("sm")]: {
      //   margin: "8% 0 0 0",
      //   width: 220,
      // },
    },
    paper: {
      padding: "35px 55px",
      borderRadius: "12px",
      color: "#53CD94",
      fontSize: "25px",
      fontWeight: "bold",
      boxShadow: "none",
      width: "100%",
      marginBottom: "30px",
      background: "white",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding: "25px",
      },
    },
    container: {
      display: "flex",
    },
    pageTitle: {
      color: "#333333",
      fontSize: "32px",
      fontWeight: 500,
      lineHeight: "38px",
      alignItems: "baseline",
      flexGrow: 1,
      [theme.breakpoints.down("sm")]: {
        fontSize: "23px",
      },
    },
  
    slide: {
      "&:hover": {
        color: "#53CD94",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
      },
    },
    heading: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    container2: {
      "& root":{
        padding:"1rem 0",
      },
      // marginLeft: "0px",
      // marginRight: "0px",
      // [theme.breakpoints.down("sm")]: {
      //   width: 230,
      // },
    },
    accordeon:{
      padding:"2rem 0",
    },
    answer: {
      fontSize: "15px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
    boxAcordion: {
      "&:hover": {
        background: "#53CD94",
        borderRadius: "7px",
      },
    },
  }));