import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import {productSearchListEP} from "endpoints"

export const searchSlice = createSlice({
  name: "search",
  initialState: {
    loading: 0,
    list: [],
  },
  reducers: {
    readySearch: (state) => {
      state.loading += 1;
    },
    searchData: (state, action) => {
      state.list = action.payload;
      state.loading -= 1;
    },
    closeSearch: (state) => {
      state.list = [];
    },
  },
});

export const { readySearch, searchData, closeSearch } = searchSlice.actions;

export const isloading = (state) => state.search.loading;
export const list = (state) => state.search.list;

export const search = (text) => async (dispatch) => {
  dispatch(readySearch());
  const data = await axios.post(productSearchListEP, {name: text,}).then(async (response) => {
    if (response.data === 0) {
      const allData = await axios.get(productSearchListEP);
      return allData.data;
    } else {
      return response.data
    }
  });
  dispatch(searchData(data));
};

export const close = () => (dispatch) => {
  dispatch(closeSearch());
};

export default searchSlice.reducer;
