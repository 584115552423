import { makeStyles } from "@material-ui/core/styles";
export const receiptStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      display: "flow-root",
    },
  },
  pageTitle: {
    color: "#53CD94",
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "38px",
    alignItems: "baseline",
    flexGrow: 1,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "55px",
    borderRadius: "12px",
    fontSize: "25px",
    boxShadow: "none",
    width: "100%",
    marginBottom: "30px",
    overflow: "auto",
    color: "#616060",
    [theme.breakpoints.down("sm")]: {
      padding: "25px",
    },
  },
  title: {
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "28.44px",
  },
  radioGroup: {
    margin: "1.5rem 0",
  },
  radioButton: {
    "& span ": {
      color: "#53cd94",
    },
    "& p": {
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "18.96px",
    },
  },
  subtitle: {
    fontWeight: "400",
    fontSize: "22px",
    lineHeight: "26.07px",
  },
  caption: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18.96px",
  },
  input: {
    display: "flex",
    fontFamily: '"Rubik",sans-serif',
    width: "80%",
    height: "36px",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    color: "#333333",
    borderRadius: "4px",
    border: "1px solid #E0E0E0",
    background: "#F1F2F2",
    padding: "10px 15px",
    margin: "1.5rem 0",
    "&:focus": {
      borderRadius: 4,
      outlineColor: "#53CD94",
    },
  },

  submit: {
    color: "#ffffff",
    height: "42px",
    width: "auto",
    borderRadius: "8px",
    background: "#53CD94",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "21.33px",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      background: "#006D51",
    },
  },
  cancelButton: {
    height: "42px",
    color: "#53CD94",
    fontSize: "14px",
    lineHeight: "17px",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      background: "#ffffff",
    },
  },
  errorLabel: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    color: "red",
    display: "flex",
    marginTop: "10px",
    minHeight: "16px",
    margin: "1rem 0",
  },
}));
