import { makeStyles } from '@material-ui/core/styles';

export const offerMiniSlideStyles = makeStyles((theme) => ({
    chartCategoryBox: {
        display: 'flex',
        width: '100%',
    },
    ChartWrap: {
        width: '100%'
    },
    chartStatText1: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '21.33px',
        color: '#F2C94C',
        minWidth: '80px',
        paddingTop: '20px',
        textAlign: 'left',
        [theme.breakpoints.down("sm")]: {
            fontSize: '12px', 
            lineHeight: '10px', 
        },  
    },
    chartStatText3: {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '21.33px',
        color: '#F2C94C',
        minWidth: '80px',
        paddingTop: '20px',
        textAlign: 'left',
        [theme.breakpoints.down("sm")]: {
            fontSize: '12px', 
            lineHeight: '10px', 
        },  
    },
    chartStatText2: {
        fontSize: '9px',
        fontWeight: 500,
        color: '#808285',
        minWidth: '100px',
        textTransform: 'uppercase',
        textAlign: 'left',
        lineHeight: '10.67px',
        [theme.breakpoints.down("sm")]: {
            fontSize: '6.5px', 

        }, 
    },
    chartStatText4: {
        fontSize: '9px',
        fontWeight: 500,
        color: '#808285',
        minWidth: '100px',
        textTransform: 'uppercase',
        textAlign: 'left',
        lineHeight: '10.67px',
        [theme.breakpoints.down("xl")]: {
            textIndent:"5.1em",
        },  
        [theme.breakpoints.down("lg")]: {
            textIndent:"2em",
        },
        [theme.breakpoints.down("sm")]: {
            textIndent:"3em",
            fontSize: '6.5px', 

        }, 
    },
    chartTitleBox: {
        display: 'flex',
        width: '100%',
    },
    chartTitleBox2: {
        display: 'flex',
        // width: '100%',
        flexDirection:"row",
        justifyContent:"space-between",
        width:"90%",
        margin:"auto",
        [theme.breakpoints.down("lg")]: {
            flexDirection:"column",
        },   
        [theme.breakpoints.down("md")]: {
          width:"100%",
          flexDirection:"column",
        }, 
    },
    item1:{
        display:"inline-flex",
        "& svg":{
            fontSize:"15px"
        },
        [theme.breakpoints.down("lg")]: {
            fontSize:"12px"
        },  
    },
    numberVariant:{
        [theme.breakpoints.down("xl")]: {
            textIndent:"3em",
        },
        [theme.breakpoints.down("lg")]: {
            textIndent:"1em",

        },
        [theme.breakpoints.down("sm")]: {
            textIndent:"1.5em",
            fontSize:"12px",
        },  
    },
    numberVariant2:{
        color:"#000000",
        [theme.breakpoints.down("xl")]: {
            textIndent:"3em",
        },
        [theme.breakpoints.down("lg")]: {
            textIndent:"1em",

        },
        [theme.breakpoints.down("sm")]: {
            textIndent:"1.5em",
            fontSize:"12px",
        },
    },
    chartTitleArrowBox:{
        margin: '0 auto',
        padding: '0px',
        listStyle: 'none',
        minWidth: '80px',

    },
    responsiveArrowBox:{
        display:"flex",
        flexDirection:"column",
        [theme.breakpoints.down("lg")]: {
            flexDirection:"row",
        },
    },
    chartTitleText: {
        fontSize: '18px',
        lineHeight: '21.33px',
        fontWeight: 400,
        flexGrow: 1,
        color: '#333',
        height: '42px',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginTop: '21px'
    },
    boxData:{
        [theme.breakpoints.down("xl")]: {
            width:"100%",
        },
        [theme.breakpoints.down("lg")]: {
            width: "100%",  
        },

        [theme.breakpoints.down("md")]: {
            width: "100%",  
        },  
    },
    boxDataItem:{
        [theme.breakpoints.down("xl")]: {
            textIndent:"3.8em",
        },
        [theme.breakpoints.down("lg")]: {
            textIndent:"1.5em",
        },
        [theme.breakpoints.down("sm")]: {
            margin:"0 0 0 2px",
            textIndent:"1.3em",
        },  
    },

    iconBoxDown: {
       color:"#F2C94C",
       fontSize: "22px", 
       verticalAlign: "middle",
       [theme.breakpoints.down("lg")]: {
        fontSize:"12px",
    }, 
    },
    iconBoxUp: {
       fontSize: "22px", 
       verticalAlign: "middle",
       [theme.breakpoints.down("lg")]: {
        fontSize:"12px",
    }, 
    },
    
}));