import React, { useState } from "react";
import clsx from "clsx";
import {Typography, Paper,Grid }from "@material-ui/core";
// import { useHistory } from "react-router-dom"
import { offerSlideStyles } from "./styles";
import VariationList from "./variationList";

// This component renders the card for each item
export default function TradedGoodCard (props) {

  const classes = offerSlideStyles();
  // const history = useHistory();
  const [,setGread] = useState(0);

  // const handleSelectOffer = () => {
  //   const gradeName = props._product.variations[0].grades[gread].grade;
  //   history.push("/mercado", {
  //     selectedOffer: props._product.product_name,
  //     veriety: { ...props._product.variations[0], selectedGread: gradeName },
  //   });
  // };

  const handleGreadChange = (value) => {
    setGread(value);
  };

  return (
    <Grid item xs={12} sm={12} style={{ margin: "0px 10px" }}>
      <Paper className={classes.paper1}>
        <div className={classes.chartCategoryBox}>
          <Typography
            variant="h6"
            gutterBottom
            className={classes.chartCategoryText}
          >
            {props._product.product_name}
          </Typography>
          <ul
            className={classes.chartCategoryBulletBox}
            style={{ visibility: "hidden" }}
          >
            <li
              className={clsx(
                classes.chartCategoryBullet,
                classes.chartCategoryBulletActive
              )}
            ></li>
            <li className={classes.chartCategoryBullet}></li>
          </ul>
        </div>
        {/* <div className={classes.chartTitleBox}>
          <Typography
            variant="h6"
            gutterBottom
            className={classes.chartTitleText}
          ></Typography>
        // </div> */}
        <div
          style={{
            // position: "relative",
            // top: "-69px",
            width: "100%",
            // height: "calc( 300px - 69px )",
          }}
        >
          <VariationList _variations={props._product.variations} handleGreadChange={handleGreadChange} {...props} />
        </div>
        <div className={classes.chartTitleBox}>
          <Typography
            variant="h6"
            gutterBottom
            className={classes.chartStatText2}
            style={{
              textTransform: "capitalize",
              flexGrow: 1,
              textAlign: "left",
              fontSize: "14px",
              alignSelf: "flex-end",
              margin: "15px 0 15px 0",
            }}
          >
            Datos históricos
          </Typography>
          {/* <Button
            className={classes.chartOfferButton}
            onClick={handleSelectOffer}
          >
            VER OFERTAS
          </Button> */}
        </div>
      </Paper>
    </Grid>
  );
}
