import { makeStyles } from "@material-ui/core/styles";

export const MyOrdersStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        width: "100%",
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
          display: "flow-root",
        },
      },
      pageTitle: {
        color: "#53CD94",
        fontSize: "32px",
        fontWeight: 500,
        lineHeight: "38px",
        alignItems: "baseline",
        flexGrow: 1,
      },
      button: {
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "14px",
        height: "32px",
        width: "189px",
        background: "#F2994A",
        boxShadow: "none",
        borderRadius: "8px",
        "&:hover": {
          background: "#e0e0e0",
          boxShadow: "none",
          color:"#333"
        },
      },
      paper: {
        padding: "55px",
        borderRadius: "12px",
        color: "#53CD94",
        fontSize: "25px",
        fontWeight: "bold",
        boxShadow: "none",
        width: "100%",
        marginBottom: "30px",
        overflow: "auto",
        [theme.breakpoints.down("sm")]: {
          padding: "25px",
        },
      },
      statusContainer:{
          display:"flex",
          flexDirection:"row",
          justifyContent:"space-between",
          [theme.breakpoints.down("sm")]: {
            flexDirection:"column",
          },
      },
      orderContainer:{
          borderBottom:"1px solid #e0e0e0",
          padding:"1rem 0"
      },
      statusTitle:{
        fontSize:"20px",
        lineHeight:"20px",
        fontWeight:"normal",
      },
      inProcessColor:{
          color:"#F2994A",
      },
      DeliveredColor:{
          color:"#000000"
      },
      statusDate:{
          color:"#616060",
          fontSize:"14px",
          lineHeight:"18.96px",
          fontWeight:"normal",
      },
      link:{
          color:"#53cd94",
          fontSize:"14px",
          lineHeight:"18.96px",
          fontWeight:"normal",
          margin:"0.25rem",
          textDecoration:"none",
          "&:hover": {
              color:"#333"
          }
      },
      statusDetailContainer:{
          backgroundColor:"#f1f2f2",
          borderRadius:"5px",
          padding:"1rem",
          [theme.breakpoints.down("xs")]: {
           flexDirection:"column",
           alignContent:"center"
          },
      },
      statusDetail:{
        [theme.breakpoints.down("xs")]: {
        borderBottom:"1px solid #e0e0e0",
           },
          " & h3":{
              fontSize:"16px",
              color:"#616060",
              lineHeight:"18.96px",
              fontWeight:"normal",
              margin:"0.25rem"

          },
          "& span":{
            fontSize:"16px",
            color:"#616060",
            lineHeight:"18.96px",
            fontWeight:"normal",
            margin:"0.25rem"
          }
      },
      order:{
          display:"flex",
          flexGrow:"1",
          textAlign:"end",
          [theme.breakpoints.down("xs")]: {
            textAlign:"start",
           },
      }
}))
