import { makeStyles } from "@material-ui/core";

export const myFormStyle = makeStyles((theme) => ({
  paper: {
    padding: "35px 55px",
    borderRadius: "12px",
    color: "#53CD94",
    fontSize: "25px",
    fontWeight: "bold",
    boxShadow: "none",
    width: "50%",
    marginBottom: "10px",
    overflow: "auto", 
    background:"white",
    margin:"auto",
    [theme.breakpoints.down("sm")]: {
      padding: "25px",
      width: "100%",
      margin:"0 5%",
    },
  },
  containerLogo:{
    display:"flex",
    justifyContent:"center",
  },
  logo: {
    width: '179px',
    margin:"1rem 4rem ",
    [theme.breakpoints.down('sm')]: {
        padding: '0px',
        marginBottom: '25px',
    }
  },
  container:{
    display:"flex",
    justifyContent:"center",
  },
  container2:{
    display:"flex",
    justifyContent:"space-between",
  },
  pageTitle: {
    color: "#333333",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "38px",
    alignItems: "baseline",
    flexGrow: 1,
    display:"flex",
    justifyContent:"center",
    fontFamily:"Rubik,sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
  subTitle:{
    color: "#333333",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "38px",
    alignItems: "baseline",
    flexGrow: 1,
    display:"flex",
    justifyContent:"center",
    fontFamily:"Rubik,sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: '11px',
  },
  },
  subTitle2:{
    color: "#333333",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "38px",
    alignItems: "baseline",
    flexGrow: 1,
    display:"flex",
    justifyContent:"left",
    fontFamily:"Rubik,sans-serif",
    paddingTop:"5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  label: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    color: "#808285",
    display: "flex",
    marginBottom: "10px",
    marginTop:"10px",
    minHeight: "16px",
  },
  label2: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    color: "#808285",
    display: "flex",
    marginBottom: "10px",
    marginTop:"25px",
    minHeight: "16px",
  },
  
  input: {
    display: "flex",
    fontFamily: '"Rubik",sans-serif',
    width: "100%",
    height: "36px",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    color: "#333333",
    borderRadius: "4px",
    border: "1px solid #E0E0E0",
    background: "#F1F2F2",
    padding: "10px 15px",
    marginBottom: "10px",
    "&:focus": {
      borderRadius: 4,
      outlineColor: "#53CD94",
    },
    [theme.breakpoints.down('sm')]: {
      "&::placeholder": {
        fontSize: "0.7em",
      },
    },
    [theme.breakpoints.up('sm')]: {
      "&::placeholder": {
        fontSize: "0.6em",
      },
    },
    [theme.breakpoints.up("lg")]: {
      "&::placeholder": {
        fontSize: '12px',
      },  
    },
  },
  errorColor: {
    color: "red",
    justifyContent:"center",
    fontSize:"9px",
  },
  colorLabel: {
    color: "#808285",
  },
  subimt: {
    height: "52px",
    width: "50%",
    marginTop: "40px",
    borderRadius: "8px",
    background: "#53CD94",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "21.33px",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      background: "#006D51",
    },
  },
  check: {
    width:"39px",
    marginTop: "25px",
    marginBottom:"25px",
},
}));
