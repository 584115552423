import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { isLogin } from "features/Auth/authSlice";

export default function AppGuard({ component: Component, ...rest }) {
  const isLoggedIn = useSelector(isLogin);
  const {location} = {...rest};
  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          isLoggedIn ||
          location.pathname === "/mercado" ||
          location.pathname === "/politicas-de-privacidad" ||
          location.pathname === "/terminos-y-condiciones" ||
          location.pathname === "/ayuda" ||
          location.pathname === "/compra-por-mayor" ||
          location.pathname === "/vende-con-nosotros"||
          location.pathname === "/recuperar-contraseña"||
          location.pathname === "/formulario-de-registro"||
          location.pathname === "/comisiones"||
          location.pathname.startsWith("/actualizar-contraseña") ||
          location.pathname.startsWith("/mercado_mayorista")||
          location.pathname.startsWith("/mercado")
          ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: props.location }}} />
          )
        }
      />
    </>
  );
}
