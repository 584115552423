import React, { useEffect, useState } from "react";
import axios from 'axios';
import PropTypes from "prop-types";
import {
  Typography,
  Box,
  Divider,
  Paper,
} from "@material-ui/core";
import {generalCommissionsEP} from '../../../endpoints';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import LayoutWrapper from "features/layout/wrapper";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: "100%",
      marginTop: "20px",
      [theme.breakpoints.down("sm")]: {
        margin: "8% 0 0 0",
        width: "100%",
      },
      [theme.breakpoints.up("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.up("lg")]: {
        width: "100%",
      },
    },
    paper: {
      padding: "35px 55px",
      borderRadius: "12px",
      color: "#53CD94",
      fontSize: "25px",
      fontWeight: "bold",
      boxShadow: "none",
      width: "90%",
      marginBottom: "30px",
      background: "white",
      margin:"2rem auto",
      [theme.breakpoints.down("sm")]: {
        padding: "25px",
      },
    },
    container: {
      display: "flex",
    },
    pageTitle: {
      color: "#333333",
      fontSize: "32px",
      fontWeight: 500,
      lineHeight: "38px",
      alignItems: "baseline",
      flexGrow: 1,
      [theme.breakpoints.down("sm")]: {
        fontSize: "23px",
      },
    },
    slide: {
      "&:hover": {
        color: "#53CD94",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px",
      },
    },
    heading: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    container2: {
      marginLeft: "0px",
      marginRight: "0px",
      [theme.breakpoints.down("sm")]: {
        width: 230,
      },
    },
    answer: {
      color:"black",  
      fontSize: "15px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
    boxAcordion: {
      "&:hover": {
        background: "#53CD94",
        borderRadius: "7px",
      },
    },
    accordionContainer:{
      marginBottom:"10px",
    },
  }));

export default function Commissions () {
    const classes = useStyles();
    const [allCommission, setAllCommission] = useState("");

    useEffect(() => {
      const allData = async() => {
        await axios.get(generalCommissionsEP).then((response) => {
          setAllCommission(response.data.sale_fee);
        })
      };
      allData();
    }, [])
    
    return (
      <LayoutWrapper>
        <Paper className={classes.paper}>
              <div className={classes.container}>
                <Typography
                  variant="h2"
                  component="h5"
                  gutterBottom
                  className={classes.pageTitle}
                >
                  Comisiones 
                </Typography>
              </div>
              <Divider />
              <div className={classes.root}>
                <Accordion className={classes.accordionContainer}>
                      <AccordionSummary
                        className={classes.boxAcordion}
                        expandIcon={<ExpandMoreIcon />}
                        arial-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          Comsión por venta
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className={classes.answer}>
                          <p>
                          Abrir tu tienda online en Whipay.pe es completamente gratis. Actualmente no 
                          cobramos por apertura ni mantenimiento de tu canal de venta, adicionalmente 
                          cuentas con publicaciones ilimitadas de productos completamente gratis. 
                          </p>
                          <p>
                          Una vez completada la venta Whipay cobrara <strong>una comisión del {allCommission}% +IGV </strong>del 
                          valor total facturado. 
                          </p>
                        </Typography>
                      </AccordionDetails>
                  </Accordion>
                  <Accordion>
                      <AccordionSummary
                        className={classes.boxAcordion}
                        expandIcon={<ExpandMoreIcon />}
                        arial-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                        Comisión por almacén
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className={classes.answer}>
                        Próximamente se habilitará el servicio de almacén para los mayoristas que 
                        requieran contratarlo.
                        </Typography>
                      </AccordionDetails>
                  </Accordion>
              </div>
            </Paper>
      </LayoutWrapper>
        
    );
}