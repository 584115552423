/*-- Libraries --*/
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
    Grid,
    Typography,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Carousel from 'react-multi-carousel';
import SwipeableViews from 'react-swipeable-views';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typed from 'react-typed';

/*-- styles,components,functions --*/
import MatureComponent from "features/maturityMeter";
import logoWhipay from 'assets/images/logoWhipay.png';
import timeIcon from 'assets/images/clock.png';
import coinIcon from 'assets/images/coin.png';
import verifiedIcon from 'assets/images/verifiedIcon.png';
import backgroundColor from 'assets/images/fondo-naranja.png';
import ranking from 'assets/images/ranking.png';
import sell from 'assets/images/sell.png';
import footer from 'assets/images/Grupo 21@2x.png';
import sn1 from 'assets/images/Grupo 22@2x.png';
import sn2 from 'assets/images/Grupo 23@2x.png';
import sn3 from 'assets/images/Grupo 24@2x.png';
import sn4 from 'assets/images/Grupo 25@2x.png';
import { offersHomeEP } from 'endpoints';
import media1 from "assets/images/landing/media1.png";
import media2 from "assets/images/landing/media2.png";
import media3 from "assets/images/landing/media3.png";
import media4 from "assets/images/landing/media4.png";
import media5 from "assets/images/landing/media5.png";
import media6 from "assets/images/landing/media6.png";
import ref1 from "assets/images/landing/ref1.png";
import ref2 from "assets/images/landing/ref2.png";
import './styles.css';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function AppHome() {

    const theme = useTheme();
    const [menu, setmenu] = useState(false);
    const [value, setValue] = useState(0);
    const [publishedSales, setpublishedSales] = useState([]);
    const tradeMarketVideo = `${process.env.REACT_APP_BASE_URL_MEDIA}homepage/videos/animacion_tarjetas_analisis.mp4`;
    const desktopVideo = `${process.env.REACT_APP_BASE_URL_MEDIA}homepage/videos/animacion_desktop_movil.mp4`;
    const mainBanner = `${process.env.REACT_APP_BASE_URL_MEDIA}homepage/images/main_front.png`;


    const handleChangeMenu = (event) => {
        setmenu(!menu);
    };
    const handleChangeClose = (event) => {
        setmenu(!menu);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    useEffect(() => {
        axios.get(offersHomeEP).then((response) => {
            setpublishedSales(response.data);
        });
        // return () => {
        // 	cleanup
        // }
    }, []);

    const responsive = {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024,
            },
            items: 1,
            partialVisibilityGutter: 40,
        },
        mobile: {
            breakpoint: {
                max: 500,
                min: 0,
            },
            items: 1,
            partialVisibilityGutter: 0,
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 501,
            },
            items: 1,
        },
    };
    const responsive1 = {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024,
            },
            items: 4,
            partialVisibilityGutter: 40,
        },
        mobile: {
            breakpoint: {
                max: 500,
                min: 0,
            },
            items: 2,
            partialVisibilityGutter: 0,
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 501,
            },
            items: 2,
        },
    };

    const recomendations = [
        {
            userName: 'Azucena Gutiérrez',
            position: 'Fundadora y CEO',
            jobPlace: 'Rita juguería inteligente',
            comment: 'Whipay nos ha simplificado el abastecimiento de nuestra planta. Me encanta la idea de comprar directo al productor o mayorista desde mi celular, ahorrándome tiempo.',
            image: ref1
        },
        {
            userName: 'Antoinette Romero',
            position: 'Gerente de Finanzas',
            jobPlace: 'Disfruta',
            comment: 'Genial poder comparar precios de cada oferta publicada por el mayorista y/o agricultor. Nos ahorra costos en el abastecimiento de nuestros locales y de nuestros franquiciados.',
            image: ref2
        },
    ];

    const business = [
        {
            name: 'Gestión',
            image: media1,
            media: 'https://gestion.pe/economia/empresas/nace-el-primer-marketplace-para-la-venta-mayorista-al-sector-horeca-noticia/?ref=gesr '
        },
        {
            name: 'PQS',
            image: media2,
            media: 'https://pqs.pe/emprendimiento/whipay-marketplace-conecta-mayoristas-sector-horeca/'
        },
        {
            name: 'Trome',
            image: media3,
            media: 'https://trome.pe/emprende-trome/casos-de-exito/emprende-trome-whipay-el-primer-mercado-mayorista-digital-del-peru-noticia/'
        },
        {
            name: 'El comercio',
            image: media4,
            media: 'https://trome.pe/emprende-trome/casos-de-exito/emprende-trome-whipay-el-primer-mercado-mayorista-digital-del-peru-noticia/'
        },
        {
            name: 'Logística360',
            image: media5,
            media:'https://www.logistica360.pe/www-logistica360-pe-nace-el-primer-marketplace-para-la-venta-mayorista-al-sector-horeca/'
        },
        {
            name: 'EcommerceNews',
            image: media6,
            media: 'https://www.ecommercenews.pe/lanzamientos-ecommerce/2021/whipay.html'
        },
    ];

    const maxCharacters = 35;

    const sliceCharacters = (word) => {
        return word.substring(0, maxCharacters);
    };

    return (
        <>
            <nav>
                <div className="container">
                    <div className="d-flex d-lg-none justify-content-between py-3 align-items-end px-3">
                        <img src={logoWhipay} className="img-nav" alt="logo" />
                        <button
                            className="btn btn-nav-celular"
                            onClick={handleChangeMenu}
                        >
                            <MenuIcon />
                            {/* <i className="fas fa-bars"></i> */}
                        </button>
                    </div>
                    <ul
                        style={{ listStyle: 'none' }}
                        className={
                            menu
                                ? 'list-absolute-nav list-absolute-nav-open text-center px-5'
                                : 'list-absolute-nav text-center px-5 '
                        }
                    >
                        <button className="btn btn-nav-celular btnt-close mt-3">
                            <CloseIcon onClick={handleChangeClose} />
                            <i className="fas fa-times"></i>
                        </button>
                        <li className="mb-5 li-nav-top">
                            <Link to="/formulario-de-registro" className="nav-color">
                                Proveedor Whipay
                            </Link>
                            {/* <a href="/formulario-de-registro" className="nav-color">

                            </a> */}
                        </li>
                        <li className="mb-5">
                            <a href="#works" className="nav-color">
                                ¿Cómo funciona?
                            </a>
                        </li>
                        <li className="mb-5">
                            <Link to="/ayuda" className="nav-color">
                            Preguntas frecuentes
                            </Link>
                            {/* <a href="/ayuda" >
                                Preguntas frecuentes
                            </a> */}
                        </li>
                        <li className="">
                            <Link to="/mercado" className="btn btn-primary btn-nav px-4">
                                Ingresar al mercado
                            </Link>
                            {/* <a
                                href="/mercado"
                                className="btn btn-primary btn-nav px-4"
                            >
                                Ingresar al mercado
                            </a> */}
                        </li>
                    </ul>
                    <ul
                        style={{ listStyle: 'none' }}
                        className="d-none d-lg-flex flex-wrap justify-content-between align-items-end"
                    >
                        <li className="d-flex">
                            <Link to="/formulario-de-registro" className="nav-color">
                                Proveedor Whipay
                            </Link>
                            {/* <a
                                href="/formulario-de-registro"
                                className="nav-color"
                            >
                                Proveedor Whipay
                            </a> */}
                            <a href="#works" className="ms-5 nav-color">
                                ¿Cómo funciona?
                            </a>
                        </li>
                        <li>
                            <img src={logoWhipay} alt="logo" className='navbar-logo' />
                        </li>
                        <li className="d-flex justify-content-end align-items-end">
                            <Link to="/ayuda" className="nav-color">
                                Preguntas frecuentes
                            </Link>
                            {/* <a href="/ayuda" className="nav-color">
                            Preguntas frecuentes
                            </a> */}
                            <Link to ="/mercado" className="btn ms-5 btn-nav px-3">
                                Ingresar al mercado
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
            <section>
                <div className="container div-conten-video">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-sm-12 ">
                            <h1 className="titulo my0">
                                Mercado mayorista <br /> digital{' '}
                            </h1>
                            <div className="typed-text">
                                <Typed
                                    className="my-3 p-cabecera w-100"
                                    strings={[
                                        'Facilitamos el abastecimiento de tu negocio.',
                                        'Los proveedores que necesitas para abastecer tu negocio en un solo lugar.',
                                        'Te conectamos directamente con mayoristas y productores previamente evaluados.',
                                        'Negocia, accede a subastas y genera licitaciones directo a productores y mayoristas de manera virtual.',
                                    ]}
                                    typeSpeed={30}
                                    backSpeed={10}
                                    backDelay={2000}
                                    loop
                                />
                            </div>
                            <div className="div-btn-register">
                                <Link to="/formulario-de-registro" className="btn btn-registrar">
                                    Regístrate
                                </Link>
                                {/* <a
                                    href="/formulario-de-registro"
                                    className="btn btn-registrar"
                                >
                                    Regístrate
                                </a> */}
                            </div>
                        </div>
                        <div className="col-lg-7 col-sm-12 text-center">
                            <img
                                src={mainBanner}
                                alt="banner"
                                className="img-celular"
                            />
                        </div>
                    </div>
                </div>
                <div className="container px-0 position-relative">
                    <div className="div-content-cuadrado">
                        <div className="div-verde">
                            <h2 className="subtitulo-blanco">
                                Digitaliza el mercado mayorista de alimentos{' '}
                            </h2>
                            <p className="p-descripcion-blanco mb-5">
                                ¡Tu tiempo vale mucho! <br />
                                <br />
                                Sabemos que las tareas repetitivas del
                                abastecimiento para tu negocio te quitan
                                recursos de tiempo y dinero que te serían mucho
                                mas útiles invertidas en hacer crecer tu negocio
                                y por eso creamos Whipay.
                                <br />
                                <br />
                                Sin intermediarios es más económico y directo.
                            </p>
                            <Link to="/mercado" className="btn btn-border-blanco">
                                Quiero comprar en Whipay
                            </Link>
                            {/* <a href="/mercado" className="btn btn-border-blanco">
                                {' '}
                                Quiero comprar en Whipay{' '}
                            </a> */}
                        </div>
                    </div>
                </div>
            </section>
            <section className="secc-top-uno">
                <div className="container px-sm-0">
                    <h2 className="text-center titulo">
                        Whipay es la nueva forma de abastecerte
                    </h2>
                    <div className="row mx-0 justify-content-center mt-4">
                        <div className="col-sm-10 col-lg-9 mb-5 mb-xl-3">
                            <p className="text-center p-verde-size-18">
                                <span className="span-medium">Compara precios</span>{' '}
                                entre mayoristas de productos frescos de
                                calidad. Conecta tu negocio directamente con{' '}
                                <span className="span-medium">
                                    los mejores proveedores
                                </span>{' '}
                                ahorrando costos y{' '}
                                <span className="span-medium">sin preocuparte</span>{' '}
                                de los traslados ni la logística.
                            </p>
                        </div>
                        <div className="col-sm-6 col-lg-4 d-flex flex-wrap justify-content-center mb-5 mb-lg-0 ">
                            <div className="div-conten-hojas position-relative">
                                <div className="div-hoja">
                                    <img
                                        src={coinIcon}
                                        className="img-icon-hoja"
                                        alt="icon"
                                    />
                                </div>
                            </div>
                            <div className="mt-4 px-lg-3 px-xl-5">
                                <h2 className="titulos-size-18-verde text-center">
                                    Ahorra dinero
                                </h2>
                                <p className="p-descripcion-verde-peque text-center">
                                    Compra directamente a productores y
                                    mayoristas, sin intermediarios ni
                                    revendedores
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 d-flex flex-wrap justify-content-center mb-5 mb-lg-0">
                            <div className="div-conten-hojas position-relative">
                                <div className="div-hoja">
                                    <img
                                        src={timeIcon}
                                        className="img-icon-hoja"
                                        alt="icon"
                                    />
                                </div>
                            </div>
                            <div className="mt-4 px-lg-3 px-xl-5">
                                <h2 className="titulos-size-18-verde text-center">
                                    Ahorra tiempo y esfuerzo
                                </h2>
                                <p className="p-descripcion-verde-peque text-center">
                                    Concéntrate en tu negocio y realiza tus
                                    compras en una sola plataforma, sin
                                    preocuparte de traslados y el tráfico.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 d-flex flex-wrap justify-content-center mb-5 mb-lg-0">
                            <div className="div-conten-hojas position-relative">
                                <div className="div-hoja">
                                    <img
                                        src={verifiedIcon}
                                        className="img-icon-hoja"
                                        alt="icon"
                                    />
                                </div>
                            </div>
                            <div className="mt-4 px-lg-3 px-xl-5">
                                <h2 className="titulos-size-18-verde text-center">
                                    Mayoristas comprometidos
                                </h2>
                                <p className="p-descripcion-verde-peque text-center">
                                    Proveedores debidamente evaluados para
                                    otorgarte los mejores productos
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="secc-top-uno secc-anaranjado-radius position-relative">
                <img
                    src={backgroundColor}
                    className="img-absolute-anaranjado"
                    alt="background"
                />
                <div className="container px-sm-0">
                    <div className="row mx-0 justify-content-center">
                        <div className="col-sm-6 col-lg-4 d-flex justify-content-center">
                            <div className="div-conten-hojas position-relative">
                                <div className="div-hoja">
                                    <img
                                        src={ranking}
                                        className="img-icon-hoja"
                                        alt="icon"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 text-center mt-3 mt-sm-0">
                            <h2 className="subtitulo-blanco text-center subtitulo-light">
                                Compara precios de productos
                            </h2>
                            <h2 className="subtitulo-blanco text-center">
                                en tiempo real
                            </h2>
                            <p
                                className="p-descripcion-blanco text-center"
                                style={{ color: '#ffff' }}
                            >
                                Concéntrate en tu negocio y realiza tus compras
                                en una sola <br /> plataforma a los mejores precios
                                del mercado de abastos.
                            </p>
                        </div>

                    </div>
                </div>

            </section>
            <div className="col-md-12 col-lg-12 my-3 " data-aos="fade-up">
                            <video
                                autoPlay
                                playsInline
                                muted
                                src={tradeMarketVideo}
                                loop
                                className="video-mobile"
                            />
                        </div>
            <section id="works" className="position-relative secc-padding-top">
                <div className="container-fluid px-sm-0 px-xl-5">
                    <div className="row mx-0 div-steps">
                        <div className="col-lg-7">
                            <div className="div-radius-amarrilo">
                                <h2 className="titulo-plomo">
                                    ¿Cómo funciona Whipay?
                                </h2>
                                <ol type="1" className="mt-4 mb-4">
                                    <li className="li-plomo mt-3">
                                        Revisa los{' '}
                                        <span className="span-medium">
                                            precios del mercado{' '}
                                        </span>
                                        y aprovecha de{' '}
                                        <span className="span-medium">
                                            las mejores ofertas en tiempo real.
                                        </span>
                                    </li>
                                    <li className="li-plomo mt-3">
                                        Mira la{' '}
                                        <span className="span-medium">
                                            variedad de productos
                                        </span>{' '}
                                        de un amplio catálogo de productores y
                                        mayoristas.
                                    </li>
                                    <li className="li-plomo mt-3">
                                        <span className="span-medium">
                                            Paga con la facilidad y seguridad
                                        </span>{' '}
                                        de nuestros distintos métodos de pago.
                                    </li>
                                    <li className="li-plomo mt-3">
                                        <span className="span-medium">Retira</span>{' '}
                                        tu compra o elige que lo entreguen en
                                        la <span>puerta de tu negocio</span>
                                    </li>
                                </ol>
                                {/* <a
                                    href="/"
                                    className="btn btn-border-blanco border-negro-color"
                                >
                                    Quiero vender en Whipay
                                </a> */}
                            </div>
                        </div>
                        <div className="col-lg-5 my-4">
                            <video
                                autoPlay
                                playsInline
                                muted
                                src={desktopVideo}
                                loop
                                className="video-mobile"
                            />
                        </div>

                    </div>
                </div>
            </section>
            <section className="secc-top-uno secc-before-verde position-relative">
                <div className="container px-sm-0">
                    <h2 className="titulo text-center">Ofertas destacadas</h2>
                    <div className="row mx-0 mt-5 justify-content-center">
                        <div className="col-md-10 col-lg-4 col-xl-3 text-center col-padding-top ps-0">
                            <h3 className="subtitulo-blanco text-lg-start">
                                ¿Quieres ver más productos?
                            </h3>
                            <p className="p-descripcion-blanco mt-4 text-lg-start">
                                Para ver todas las ofertas, crea tu cuenta ahora
                            </p>
                            <Link to="/formulario-de-registro" className="btn btn-regris-blanco px-4">
                                Regístrate <ArrowForwardIcon />{' '}
                            </Link>
                            {/* <a
                                href="/formulario-de-registro"
                                className="btn btn-regris-blanco px-4"
                            >
                                Regístrate <ArrowForwardIcon />{' '}
                            </a> */}
                        </div>
                        <div className="col-12 col-lg-8 col-xl-9 px-1 mt-5 mt-lg-0">
                            <div className="row mx-0 justify-content-center">
                                {publishedSales
                                    .slice(0, 3)
                                    .map((sale, index) => (
                                        <div className="col-sm-6 col-xl-4 px-1 mb-3 mb-xl-0" key={index}>
                                            <div className="div-card">
                                                <div className="card-img">
                                                    <img
                                                        src={sale.image}
                                                        className="card-img-cover"
                                                        alt="card"
                                                    />
                                                </div>
                                                <div className="card-content position-relative">
                                                    <span className="spna-bagde-verde">
                                                        {sale.grade}
                                                    </span>
                                                    <small className="small-verde text-uppercase">
                                                        {sale.seller.length >
                                                        maxCharacters
                                                            ? sliceCharacters(
                                                                  sale.seller
                                                              )
                                                            : sale.seller}
                                                    </small>
                                                    <h3 className="titulo-card-verde mt-2 mb-0">
                                                        {sale.product_name}
                                                    </h3>
                                                    <p
                                                        className="p-descripcion-verde-peque linheight-card"
                                                        style={{
                                                            height: '45px',
                                                        }}
                                                    >
                                                        {sale.short_description.padEnd(
                                                            140
                                                        )}
                                                    </p>
                                                    <div className="d-flex flex-wrap">
                                                        <div className="div-mitad">
                                                        <MatureComponent
                                                        maduration={sale.maduration}
                                                        maduration_description={sale.maduration_description}
                                                    />
                                                        </div>
                                                        <div className="div-mitad text-end">
                                                            <p className="my-0 p-precio-card-verde linheight-card">
                                                                S/ {sale.price}
                                                            </p>
                                                            <p className="p-descripcion-verde-peque my-0 linheight-card">
                                                                {
                                                                    sale.equivalency_kg
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="div-mitad mt-3">
                                                            <p className="my-0 p-descripcion-verde-peque linheight-card">
                                                                Stock:
                                                                <br />
                                                                Disponible
                                                            </p>
                                                        </div>
                                                        <div className="div-mitad text-end mt-3">
                                                            <Link
                                                                to={`/mercado/${sale.sale_slug}`}
                                                                className="btn btn-card-oferta"
                                                            >
                                                                Ver oferta
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="secc-top-dos secc-anaranjado py-5 pe-lg-5 pe-xl-0">
                <div className="row mx-0 align-items-center py-3 py-lg-5">
                    <div className="col-12 col-lg-5 col-xl-6 px-0">
                        <img src={sell} width="100%" alt="icon" />
                    </div>
                    <div className="col-lg-7 col-xl-5 ps-sm-5 mt-4 mt-lg-0 text-center text-lg-start">
                        <h3 className="subtitulo-blanco">¿Quieres aumentar tus canales de venta?</h3>
                        <p className="p-descripcion-blanco mt-4">
                            Publica tus ofertas en Whipay y transforma
                            la manera de hacer negocios de manera digital.
                            Acerca tus productos a la red de restaurantes, hoteles,
                            bares y minoristas de Whipay.
                        </p>
                        {/* <a href="/" className="btn btn-ver-blanco mt-5">
                            Quiero aumentar mis ventas
                        </a> */}
                    </div>
                </div>
            </section>
            <section className="secc-top-uno">
                <div className="container px-sm-0">
                    <div className="swiper mySwiper">
                        <div className="swiper-wrapper pb-5">
                            <Carousel
                                style={{ width: '100%' }}
                                infinite={true}
                                swipeable={true}
                                // autoPlay={true}
                                // autoPlaySpeed={2000}
                                showDots={true}
                                dotListClass="dashboard-offer-dot-style"
                                responsive={responsive}
                            >
                                {recomendations.map((recomend, index) => (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        key={index}
                                        id={'by-seller-slider'}
                                        style={{ margin: '0 15px' }}
                                    >
                                        <div className="swiper-slide position-relative">
                                            <div className="div-avatar-slider">
                                                <img
                                                    src={recomend.image}
                                                    className="avatar-circle-slider"
                                                    alt="imagen"
                                                />
                                            </div>
                                            <div className="div-verde-slide text-start">
                                                <h3 className="my-0 titulo-slider">
                                                    {recomend.userName}
                                                </h3>
                                                <small className="small-slider">
                                                    {recomend.jobPlace} — {recomend.position}
                                                </small>
                                                <p className="mb-0 mt-4 p-descripcion-blanco-slider">
                                                    {recomend.comment}
                                                </p>
                                            </div>
                                        </div>
                                    </Grid>
                                ))}
                            </Carousel>
                        </div>
                        <div className="swiper-button-next flechas-color-ancho"></div>
                        <div className="swiper-button-prev flechas-color-ancho"></div>
                        <div className="swiper-pagination"></div>
                    </div>
                </div>
            </section>

            <section className="secc-top-uno">
                <div className="container px-sm-0">
                    <h2 className="titulo text-center">Whipay en los medios</h2>
                    <div className="swiper mySwiperuno mt-5">
                        <div className="swiper-wrapper">
                            <Carousel
                                style={{ width: '100%' }}
                                arrows={false}
                                infinite={true}
                                swipeable={true}
                                autoPlay={true}
                                autoPlaySpeed={2000}
                                showDots={true}
                                dotListClass="dashboard-offer-dot-style"
                                responsive={responsive1}
                            >
                                {business.map((product, index) => (
                                    <div className="swiper-slide text-center" key={index}>
                                        <a target="_blank" rel="noopener noreferrer"  href={product.media} >
                                            <img
                                                src={product.image}
                                                className="img-logos-slider"
                                                alt="imagen"
                                            />
                                        </a>

                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>

            <section className="secc-top-dos">
                <div className="container px-sm-0">
                    <h2 className="text-center titulo mt-5">
                        Preguntas frecuentes
                    </h2>

                    <div className="row mx-0 justify-content-center mt-5">
                        <div className="col-md-8 px-0 text-center">
                            <div id="container-main" className="text-start">
                                <SwipeableViews
                                    axis={
                                        theme.direction === 'rtl'
                                            ? 'x-reverse'
                                            : 'x'
                                    }
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                >
                                    <TabPanel
                                        value={value}
                                        index={0}
                                        dir={theme.direction}
                                        //   className={classes.container2}
                                    >
                                        <Accordion>
                                            <AccordionSummary
                                                className="accordion-titulo1"
                                                expandIcon={<ExpandMoreIcon />}
                                                arial-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                {/* <a href="/">¿Cómo se realizan los despachos hoy?<span className="toggle-icon"></span></a> */}
                                                <p className="p-title-acordeon">
                                                    ¿Qué métodos de pago tienen disponible?
                                                </p>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <p className="p-descripcion-acordeon">
                                                    Ponemos a tu disposición facilidad de pagos con Tarjeta de Crédito o Débito Visa, MasterCard, American Express y Diners Club Internacional, de manera fácil y segura. También podrás realizar transferencias bancarias, directo desde tu entidad bancaria.
                                                </p>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion style={{ margin: '1rem 0' }}>
                                            <AccordionSummary
                                                className="accordion-titulo1"
                                                expandIcon={<ExpandMoreIcon />}
                                                arial-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                {/* <a href="/">¿Cómo se realizan los despachos hoy?<span className="toggle-icon"></span></a> */}
                                                <p className="p-title-acordeon">
                                                    ¿Entregan factura con mi compra?
                                                </p>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <p className="p-descripcion-acordeon">
                                                    Todos los mayoristas que venden a través de Whipay adjuntan sus facturas o boletas requeridas por el usuario en la plataforma. Podrás descargar las facturas de tus compras, desde la página “ver detalles” en Mis compras.
                                                </p>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion style={{ margin: '1rem 0' }}>
                                            <AccordionSummary
                                                className="accordion-titulo1"
                                                expandIcon={<ExpandMoreIcon />}
                                                arial-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                {/* <a href="/">¿Cómo se realizan los despachos hoy?<span className="toggle-icon"></span></a> */}
                                                <p className="p-title-acordeon">
                                                    ¿Cómo se realizan los despachos?
                                                </p>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <p className="p-descripcion-acordeon">
                                                    Los despachos se entregan dentro de las 24 horas siguientes, también podrás retirar tus compras directo en el mercado mostrando tu comprobante de compra digital en horario de 5am a 12pm.
                                                </p>
                                            </AccordionDetails>
                                        </Accordion>
                                    </TabPanel>
                                </SwipeableViews>
                            </div>
                            <Link to="/ayuda" className="btn btn-preguntas mt-4">
                                ¿Tienes más preguntas?
                            </Link>
                            {/* <a
                                href="/ayuda"
                                className="btn btn-preguntas mt-4"
                            >
                                ¿Tienes más preguntas?
                            </a> */}
                        </div>
                    </div>
                </div>
            </section>

            <footer className="secc-top-uno">
                <div className="container px-sm-0">
                    <div className="row mx-0">
                        <div className="col-sm-4 text-center text-sm-start">
                            <img
                                src={logoWhipay}
                                className="logo-footer mt-5"
                                alt="imagen"
                            />
                            <p className="p-descripcion-footer mt-3">
                                Primer marketplace B2B para la venta mayorista
                                de frescos. Integramos todos los servicios del
                                mercado de abastos en una sola plataforma.
                            </p>
                            <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                <a
                                    href="mailto:clientes@whipay.pe?Subject=Info: WhiPay - Mercado Mayorista"
                                    target="_top"
                                    className="link-sociales me-2"
                                >
                                    <img
                                        src={sn1}
                                        className="img-redes-sociles"
                                        alt="imagen"
                                    />
                                </a>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.facebook.com/whipay"
                                    className="link-sociales me-2"
                                >
                                    <img
                                        src={sn2}
                                        className="img-redes-sociles"
                                        alt="imagen"
                                    />
                                </a>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.linkedin.com/company/whipay"
                                    className="link-sociales me-2"
                                >
                                    <img
                                        src={sn3}
                                        className="img-redes-sociles"
                                        alt="imagen"
                                    />
                                </a>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.instagram.com/whipay.peru/"
                                    className="link-sociales"
                                >
                                    <img
                                        src={sn4}
                                        className="img-redes-sociles"
                                        alt="imagen"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-8 mt-3 mt-sm-0">
                            <img src={footer} width="100%" alt="imagen" />
                            <div className="row mx-0 px-2 px-lg-5 justify-content-between justify-content-md-center justify-content-lg-between mt-3">
                                {/* <div className="col-3 col-lg-3 px-0">
                                    <h3 className="p-descripcion-footer titulos-medium-footer">
                                        La tienda
                                    </h3>
                                    <ul
                                        style={{ listStyle: 'none' }}
                                        className="ps-0"
                                    >
                                        <li className="p-descripcion-footer li-plomo-footer">
                                            Frutas
                                        </li>
                                        <li className="p-descripcion-footer li-plomo-footer">
                                            Verduras
                                        </li>
                                        <li className="p-descripcion-footer li-plomo-footer">
                                            Quesos
                                        </li>
                                        <li className="p-descripcion-footer li-plomo-footer">
                                            {' '}
                                            Carnes
                                        </li>
                                        <li className="p-descripcion-footer li-plomo-footer">
                                            Pescado
                                        </li>
                                    </ul>
                                </div> */}
                                <div className="col-7 col-sm-6 col-md-6 col-lg-6 px-0">
                                    <h3 className="p-descripcion-footer titulos-medium-footer">
                                        Enlaces importantes
                                    </h3>
                                    <ul
                                        style={{ listStyle: 'none' }}
                                        className="ps-0"
                                    >
                                        {/* <li className="p-descripcion-footer li-plomo-footer">
                                            <a href="/">
                                                Quiero vender en Whipay
                                            </a>
                                        </li> */}
                                        <li className="p-descripcion-footer li-plomo-footer">
                                            <Link to="/ayuda">
                                                Preguntas frecuentes
                                            </Link>
                                            {/* <a href="/ayuda">
                                                Preguntas frecuente
                                            </a> */}

                                        </li>
                                        <li className="p-descripcion-footer li-plomo-footer">
                                            <Link to="/politicas-de-privacidad">
                                                Política de privacidad
                                            </Link>
                                            {/* <a href="/politicas-de-privacidad">
                                                Política de privacidad
                                            </a> */}
                                        </li>
                                        <li className="p-descripcion-footer li-plomo-footer">
                                        <a href="/terminos-y-condiciones">
                                            Términos y condiciones
                                            </a>

                                        </li>
                                        {/* <li className="p-descripcion-footer li-plomo-footer">
                                            Libro de reclamaciones
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 px-0">
                                    <h3 className="p-descripcion-footer titulos-medium-footer">
                                        Contacto
                                    </h3>
                                    <ul
                                        style={{ listStyle: 'none' }}
                                        className="ps-0"
                                    >
                                        <li className="p-descripcion-footer li-plomo-footer">
                                        <a  target="_blank"  rel="noopener noreferrer" href="https://api.whatsapp.com/send/?phone=%2B51913629462&text&app_absent=0" >
                                        WhatsApp
                                            </a>

                                        </li>
                                        <li className="p-descripcion-footer li-plomo-footer">
                                            <a href="mailto:clientes@whipay.pe?Subject=Info: WhiPay - Mercado Mayorista" target="_top" >
                                            clientes@whipay.pe
                                            </a>
                                        </li>
                                        <li className="p-descripcion-footer li-plomo-footer">
                                        <a  target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/@-12.0833193,-76.9688972,16z">
                                            Av. Circunvalación del Club Golf Los Incas
                                            134, Santiago de Surco 15023. Lima, Perú.
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between div-border-top mt-3 mt-sm-5 align-items-center py-3 flex-wrap">
                        <p className="p-descripcion-footer my-0 p-widht-footer text-center text-sm-start">
                            Todos los derechos reservados. Whipay {new Date().getFullYear()}
                        </p>
                        {/* <p className="p-descripcion-footer my-0 p-widht-footer text-center text-sm-start mt-3 mt-sm-0">
                            Hecho con por Whipay
                        </p> */}
                    </div>
                </div>
            </footer>
        </>
    );
}
