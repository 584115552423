import React,{ useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import FullLayout from "features/layout/full/full-layout";
import { theme } from "features/layout/theme";

import AppGuard from "features/Auth/Guard";
import {
  setUserData,
  isLoading,
  setLoading,
  // loadUser,
} from "features/Auth/authSlice";
import Loading from "./pages/Loading";

import "./App.css";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import setAuthToken from "./features/API";

import { hotjar } from 'react-hotjar';
import useHotjar from 'react-use-hotjar';
import AppHome from "pages/Home";
import Login from "pages/Login";

// import { createAxiosResponseInterceptor } from "features/API/index";

hotjar.initialize(process.env.REACT_APP_HOTJARID, 6);

function App() {

  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  const loginCheck = localStorage.getItem("token");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const routeBone = async () => {
    if (loginCheck) {
      dispatch(setUserData({ token: loginCheck }));
      setAuthToken(loginCheck);
      
      // await dispatch(loadUser());
    }
  };

  useEffect(() => {
    routeBone().then((r) => {
      dispatch(setLoading(false));
    });
  }, [dispatch, routeBone]);

  // useEffect(() => {
  //   createAxiosResponseInterceptor(history);

  // }, [history]);


  const myCustomLogger = console.info;
  const { initHotjar } = useHotjar();

  useEffect(() => {
    initHotjar(process.env.REACT_APP_HOTJARID, 6, false, myCustomLogger);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initHotjar]);


  return (
    <ThemeProvider theme={theme}>
      <div className="whipayApp">
        {loading ? (
          <Loading />
        ) : (
          <BrowserRouter>
            <Switch>
              <Route
                exact
                path={"/"}
                exec
                sidebar={true}
                render={(props) => <AppHome {...props} />}
              />
              <Route
                exact
                path={"/mercado"}
                exec
                sidebar={true}
                render={(props) => <FullLayout {...props} />}
              />
                <Route
                exact
                path={"/login"}
                exec
                sidebar={true}
                render={(props) =>
                <Login {...props} />}
              />

              <AppGuard component={FullLayout} />
            </Switch>
          </BrowserRouter>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
