/*-- Libraries --*/
import { useEffect } from "react";
import KRGlue from "@lyracom/embedded-form-glue";
import clsx from "clsx";
import {
  Dialog,
  CircularProgress,
  withStyles,
  Grid,
  IconButton,
  Typography,
  DialogContent,
} from "@material-ui/core";
import TimerIcon from "@material-ui/icons/Timer";
import CancelIcon from "@material-ui/icons/Cancel";

/*-- styles,components,functions --*/
import { PaymentGatewayStyles } from "./styles";
import CountDownTimer from "features/CountDownTimer";
import logo from "assets/images/logo.svg";

const Loader = withStyles((theme) => ({
  root: {
    height: "50px !important",
    width: "50px !important",
    color: "#53CD94 !important",
  },
}))(CircularProgress);

const DialogContentCustomized = withStyles({
  root:{
    padding:"0px"
  }
})(DialogContent);
const PaymentGateway = (props) => {
  const classes = PaymentGatewayStyles();
  const { open, payToken, close, loading, failLoad } = props;
  const publicKey = process.env.REACT_APP_IZI_PUBLIC_KEY;
  const endpoint = process.env.REACT_APP_IZI_ENDPOINT;
  const postUrl = process.env.REACT_APP_IZI_URL;

  useEffect(() => {
    if (payToken !== "") {
      const asyncFunction = async () => {
        await KRGlue.loadLibrary(
          endpoint,
          publicKey
        ) /* Load the remote library */
          .then(({ KR }) =>
            KR.setFormConfig({
              /* set the minimal configuration */
              formToken: payToken,
              "kr-language": "en-US",
              "kr-post-url-success": `${postUrl}` /* to update initialization parameter */,
            })
          )
          .then(({ KR }) =>
            KR.addForm("#myPaymentForm")
          ) /* add a payment form  to myPaymentForm div*/
          .then(({ KR, result }) => {
            KR.showForm(result.formId);
          })
          .then(({ KR }) => {
            return KR.onSubmit((response) => {});
          })
          .catch(() => {}); /* show the payment form */
      };

      asyncFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payToken]);

  return (
    <Dialog open={open} className={classes.addToCatrPopup} onClose={close}>
      <Grid
        container
        item
        justifyContent = "space-between"
        alignItems="baseline"
        className={classes.divider}
      >
        <Typography
          className={classes.dialogTitle}
          variant="h2"
          component="h5"
          gutterBottom
        >
          Proceso de pago
        </Typography>

        <IconButton className={classes.margin} onClick={close}>
          <CancelIcon className={classes.icon} />
        </IconButton>
      </Grid>
      <DialogContentCustomized>
        <Grid
          container
          item
          justifyContent = "center"
          className={clsx(classes.divider, classes.paymentForm, classes.height)}
        >

          {!loading ? (
            <>
              <div className={classes.container}>
                <img src={logo} className={classes.logo} alt={""} />
              </div>
              {!failLoad ? (
                <div id="myPaymentForm" className="kr-embedded"></div>
              ) : (
                <p className={clsx(classes.errorLabel, classes.errorColor)}>{failLoad} </p>
              )}
            </>
          ) : (
            <Loader />
          )}
        </Grid>
      </DialogContentCustomized>
      <Grid container item alignItems="center" className={classes.marginUpDown}>
        <span>Por favor no cerrar la ventana mientras se procesa el pago.</span>
        <span className={classes.countDownTimer}>
          <TimerIcon />
          <CountDownTimer />
        </span>
      </Grid>
    </Dialog>
  );
};

export default PaymentGateway;
