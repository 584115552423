import { makeStyles } from "@material-ui/core/styles";

export const shipMethodStyles = makeStyles((theme) => ({
  paper: {
    padding: "15px 25px",
    borderRadius: "12px",
    boxShadow: "none",
    width: "100%",
    paddingTop: "46px",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
    },
  },
  pageSectionTitle: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "23.7px",
    color: "#333",
    marginBottom: "40px",
  },
  summary: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  item:{
    display:"flex",
    flexDirection:"row",
    lineHeight:"30px",
  },
  summary_detail:{
    "& span": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#333333",
      marginTop: "10px",
    },
  },
  summary_subDetail:{
    "& span": {
      fontSize: "12px",
      fontWeight: "400",
      color: "#808285",
      marginTop: "10px",
    },
    "& img":{
      width:"30px",
      display:"flex",
      alignSelf: "center",
      marginLeft:"6%",
      [theme.breakpoints.down("sm")]: {
       marginLeft:"0%",
      },
    },
  },
  summary_discount:{
    fontSize: "12px",
    fontWeight: "400",
    color: "red",
    marginTop: "10px",
  },
  border: {
    borderTop: "1px solid #E0E0E0",
  },
  orderWrap: {
    display: "flex",
    "&:last-child": {
      border: "none",
      paddingBottom: "0px",
    },
  },
  pageProductDetails: {
    flexGrow: 1,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18.96px",
    color: "#333",
    padding: "0 10px",

    "& span": {
      display: "flex",
      fontSize: "10px",
      color: "#808285",
      marginTop: "10px",
    },
    "& p": {
      margin: "0",
      fontSize: "14px",
    },
  },
  priceDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18.96px",
    color: "#333",
    padding: "0",
    "& span": {
      fontSize: "10px",
      color: "#808285",
    },
  },
  pageProductTotal: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "18.96px",
    color: "#333",
    textAlign: "right",
  },
  img: {
    maxWidth: "70px",
    maxHeight: "100px",
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "60px",
      maxHeight: "80px",
    },
  },
  errorLabel: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    display: "flex",
    minHeight: "16px",
  },
  errorColor: {
    color: "red",
  },

  container2:{
    margin:"2% 0 2% 1%",
  },
  stateTitle:{
    margin:"2% 0 0 0",
    fontSize:"14px",
    color: "#333333",
    fontWeight:"400",
    "& span": {
      fontSize: "10px",
      color: "#808285",
    },
  },
  productCaption: {
    color: "red",
    fontSize:"11px",
    textAlign:"center"
  },


}));
