import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Base64 } from "js-base64";
import { createPaymentEP, bankTransferTypeEP } from "endpoints";
// import { updateVoucher } from "pages/MyOrders/slices";
export const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    payToken: "",
    loading: false,
    order_slug:"",
    errorTransfer:"",
    activeStep:0
  },
  reducers: {
    setPayToken: (state, action) => {
      state.payToken = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setOrderSlug: (state,action) => {
      state.order_slug = action.payload;
    },
    setErrorTranfer: (state,action) => {
      state.errorTransfer = action.payload;
    },
    setActiveStep:(state,action) => {
      state.activeStep = action.payload;
    }
  },
});

export const { setPayToken, setLoading,setOrderSlug, setErrorTranfer,setActiveStep } = paymentSlice.actions;
export const payToken = (state) => state.payment.payToken;
export const loading = (state) => state.payment.loading;
export const orderSlug = (state) => state.payment.order_slug;
export const errorTransfer = (state) => state.payment.errorTransfer;
export const activeStep = (state) => state.payment.activeStep;

export const updateStep = (action) => async (dispatch) => {
  dispatch(setActiveStep(action))
};
export const createPayment = (callback) => async (dispatch) => {
  dispatch(setLoading(true));
  await axios.get(createPaymentEP).then((response) => {

    const payToken = response.data.payToken;
    const decodeToken = Base64.decode(payToken);
    dispatch(setPayToken(JSON.parse(decodeToken).formToken));
    
  }).catch((error)=> {
    callback(error.response.data.detail);
  });
  dispatch(setLoading(false));
};

export const saveTransferData = (action) => (dispatch)=> {
  axios
    .post(bankTransferTypeEP, action)
    .then((response) => {
      if (response.status === 201) {
        dispatch(setOrderSlug(response.data.order_slug));
        dispatch(updateStep(1));
      }
    })
    .catch((error) => 
      // TO DO need a detail message whatever status
      dispatch(setErrorTranfer(error.response.data.detail)
    ));
};
export default paymentSlice.reducer;
