import clsx from "clsx";
import {
  Grid,
} from "@material-ui/core";
import { tableStyles } from "./styles";
import formatNumber from 'functions/regex';

// const Loader = withStyles(() => ({
//   root: {
//     height: "20px !important",
//     width: "20px !important",
//     color: "#53CD94 !important",
//   },
// }))(CircularProgress);

export default function TableComponent(props) {
  const classes = tableStyles();
  const { _graph } = props;

  return (
    <Grid>
      <Grid
        item
        container
        direction="row"
        className={clsx(classes.detailTitles, classes.notDisplay)}
      >
        <Grid item xs className={classes.rowTitle}>
          Indicadores
        </Grid>
        <Grid
          item
          xs
          className={classes.rowTitle}
          style={{ textAlign: "start" }}
        >
          Valores
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        className={clsx(classes.detailTitles, classes.notDisplay)}
      >
        <Grid item xs className={classes.columnTitle}>
          Precio promedio
        </Grid>
        <Grid item xs className={classes.columnTitle}>
          {_graph.dataExists ? (
            `S/${formatNumber(_graph.average_price)}`
          ) : (
            'No hay datos'
          )}
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        className={clsx(classes.detailTitles, classes.notDisplay)}
      >
        <Grid item xs className={classes.columnTitle}>
          Precio máximo
        </Grid>
        <Grid item xs className={classes.columnTitle}>   
          {_graph.dataExists ? `S/${formatNumber(_graph.max_price)}` : 'No hay datos'}
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        className={clsx(classes.detailTitles, classes.notDisplay)}
      >
        <Grid item xs className={classes.columnTitle}>
          Precio mínimo
        </Grid>
        <Grid item xs className={classes.columnTitle}>
          {_graph.dataExists? `S/${formatNumber(_graph.min_price)}` :'No hay datos'}
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        className={clsx(classes.detailTitles, classes.notDisplay)}
      >
        <Grid item xs className={classes.columnTitle}>
          Cantidad vendida
        </Grid>
        <Grid item xs className={classes.columnTitle}>
          {_graph.dataExists ? (
            `${_graph.sold}
          ${_graph.product_unit}`
          ) : (
            'No hay datos'
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
