import { configureStore } from "@reduxjs/toolkit";
import authSlice from "features/Auth/authSlice";
import layoutReducer from "features/layout/layoutSlice";
import cartSlice from "pages/Cart/cartSlice";
import dashboardSlice from 'pages/Dashboard/Slice';
import headerSlice from 'features/layout/common/header/Slice';
import productsSlice from 'features/product/list/Slice';
import myProductsSlice from 'pages/MyProducts/Slice';
import offersSlice from 'pages/MyOffers/Slice';
import searchSlice from 'features/layout/common/search/Slice';
import notificationSlice from 'features/layout/common/notificationPopup/Slice';
import filterSlice from 'features/product/common/filter/Slice';
import sellerSlice from 'pages/Seller/Slice';
import shipMethodSlice from 'pages/ShipMethod/slice';
import checkouttSlice from 'pages/Checkout/checkoutSlice';
import paymentSlice from 'pages/Payment/slice';
import mySalesSlice from "pages/MySales/Slice";
import shopSingleSlice from 'pages/ShopSingle/slice';
import myOrderSlice from 'pages/MyOrders/slices';
import registrationMethodSlice from 'pages/Formulario/slice';
import configurationSlice from 'pages/SettingProfile/slice';

export default configureStore({
  reducer: {
    auth: authSlice,
    layout: layoutReducer,
    cart: cartSlice,
    dashboard: dashboardSlice,
    header: headerSlice,
    products: productsSlice,
    myProducts: myProductsSlice,
    offers: offersSlice,
    search: searchSlice,
    notifications: notificationSlice,
    filter: filterSlice,
    seller: sellerSlice,
    shipData:shipMethodSlice,
    checkout:checkouttSlice,
    payment:paymentSlice,
    mySales: mySalesSlice,
    singleProduct:shopSingleSlice,
    myOrders: myOrderSlice,
    registrationData: registrationMethodSlice,
    configuration: configurationSlice
  },
});
