import {  useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

/*-- redux --*/
import { 
    getAllDistricts, 
    getShipData, 
    provinceArr,
    districtsArr,
    province,
    district,
    shipTo,
    id,
    phone,
    address,
    districtName 
} from "pages/ShipMethod/slice";

export default function useShippingInfo () {
    const dispatch = useDispatch()
    const phoneValue = useSelector(phone);
    const addressValue = useSelector(address);
    const shipToValue = useSelector(shipTo);
    const idValue = useSelector(id);
    const provinceList = useSelector(provinceArr);
    const districtList = useSelector(districtsArr);
    const provinceValue = useSelector(province);
    const districtValue = useSelector(district);
    const _districtName = useSelector(districtName);

    useEffect(() => {
        async function getDistricts() {
            await dispatch(getAllDistricts());
            await dispatch(getShipData());
        }
        getDistricts();
        
    }, [dispatch]);


    return {
        phoneValue,
        addressValue,
        shipToValue,
        idValue,
        provinceList,
        districtList,
        provinceValue,
        districtValue,
        _districtName
    };
}