import { makeStyles } from "@material-ui/core";

export const sellWithUsStyle = makeStyles((theme) => ({
    pageTitle: {
        color: "#53CD94",
        fontSize: "32px",
        fontWeight: 500,
        lineHeight: "38px",
        alignItems: "baseline",
        flexGrow: 1,
      },

}))