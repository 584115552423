
//  Auth
// export const authTokenEP = 'auth-token/' DEPRECATED
export const loginEP = 'auth/login/'
export const logOutEP = 'logout/'

// Password
export const recoverPasswordEP = 'auth/recover/'
export const changePasswordEP = 'auth/change/'

//  Landing
export const activeFiltersEP = 'landing/active_filters/'
export const activeSalesEP = 'landing/active_sales/'
export const tickerEP = 'landing/ticker/'
export const bannersEP = 'landing/get_main_banners/'
export const categoriesEP = 'landing/get_categories_banners/'

//  General
export const generalInfoEP = 'general/general_info/'
export const notificationsEP = 'general/notifications/'
export const productSearchListEP = 'general/product_search_list/'
export const documentTypesListEP = 'general/document_types/'
export const generalCommissionsEP = 'general/fees/'

//  Dashboard
export const indicatorsEP = 'dashboard/indicators/'
export const downPricesEP = 'dashboard/down_prices/'
export const upPricesEP = 'dashboard/up_prices/'
export const latestSalesEP = 'dashboard/latest_sales/'
export const tradedGoodIndexEP = 'dashboard/traded_goods_index/'
export const recomendationsEP = 'dashboard/recomendations/'

//  Checkout
export const cartEP = 'checkout/cart/'
export const paymentVoucherEP = 'checkout/payment_voucher/'
//  export const checkoutEP='checkout/cart_checkout/'   //  DEPRECATED

//  Profile
export const mySalesEP = 'profile/my_sales/'
export const mySaleDetailEP= '/sale/sale_detail/'
export const myPublishedSalesEP = 'profile/my_published_sales/'
export const profileEP = 'profile/profile/'

//  Sale
export const saleCreateEP = 'sale/create/'
export const saleGalleryEP = 'sale/gallery/'
export const saleExtraInfoEP = 'sale/extra_info/'
export const saleInvoiceEP = 'sale/invoice_s/'    //Invoice
export const singleActiveSaleEP = 'sale/sale/'
export const updateStateEP = 'sale/update_ss/'
export const updateSaleEP = 'sale/update_sale/'
//  Payment
export const createPaymentEP = 'payment/create_payment/'
export const billingDetailsEP = 'payment/billing_details/'
export const bankTransferTypeEP = 'payment/bank_transfer_type/'


//  Shipping
export const destinationsEP = 'shipping/destinations/'
export const shipToInfoEP = 'shipping/shipTo/'
export const shipMethodEP = 'shipping/shipMethod/'
export const shippingDetailsEP = 'shipping/shipping_details/'
export const shippingCostEP = 'shipping/shipping_delivery_cost/'

// Orders
export const orderListEP = 'order/my_orders/'
export const orderCommentsEP = 'order/submit_order_confirmation/'
export const orderDetailEP = 'order/order_details/'
export const orderHelpMessageEP = 'order/order_help_message/'

// Registration
export const registrationEP = 'registration/create/'

// Configuration

export const configProfileEP = 'configuration/profile/presentation/'
export const configDataProfileEP ='configuration/profile/data/'
export const checkUserProfileEP = 'configuration/profile/check_user/'
export const userAccountProfEP = 'configuration/profile/user_account/'
export const request_bankProfEP = 'configuration/profile/request_bank_change/'
export const notifConfigEP = 'configuration/profile/notification_config/'

// Homepage

export const offersHomeEP = 'homepage/offers/'
// Filters

export const categoryFilterEP = 'filters/categories/'
export const productsFiltersEP = 'filters/products/'
export const varietiesFiltersEP = 'filters/varieties/'
