import { makeStyles } from '@material-ui/core/styles';

export const myProductsStyles = makeStyles((theme) => ({
    container:{
        display: 'flex',
        width: '100%',
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            display: 'flow-root'
        }
    },
    pageTitle: {
        color: '#53CD94',
        fontSize: '32px',
        fontWeight: 500,
        lineHeight: '38px',
        alignItems: 'baseline',
        flexGrow: 1
    },
    button: {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '14px',
        height: '32px',
        width: '189px',
        background: '#F2994A',
        boxShadow: 'none',
        borderRadius: '8px'
    },
    paper: {
        padding: '55px',
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        minHeight:'400px',
        borderRadius: '12px',
        color: '#53CD94',
        fontSize: '25px',
        fontWeight: 'bold',
        boxShadow: 'none',
        width: '100%',
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            padding: '25px'
        }
    },
    table: {
        minWidth: '1000px',
        '& th': {
            borderBottom: '1px solid #E0E0E0',
            fontSize: '9px',
            fontWeight: 700,
            lineHeight: '10.67px',
            color: '#53CD94',
            textAlign: 'left'
        },
        '& td': {
            borderBottom: '1px solid #E0E0E0',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '16.59px',
            color: '#808285'
        }
    },
    prices: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '18.96px',
        color: '#53CD94',
        '& svg': {
            height: '12px',
            width: '12px',
            marginRight: '5px',
            color: '#53CD94'
        }
    },
    chart: {
        paddingTop: '0px',
        paddingBottom: '0px'
    },
    title: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16.59px',
        color: '#808285'
    },
    typeText: {
        '& span': {
            width: '73px',
            height: '25px',
            border: '1px solid #DDF5EA',
            borderRadius: '4px',
            display: 'flex',
            lineHeight: '25px',
            alignItems: 'center',
            flexDirection: 'column',
            textTransform: 'uppercase',
            fontSize: '11px',
            fontWeight: 400,
            color: '#53CD94',
            margin: '0 auto'
        }
    },
    status: {
        '& span': {
            width: '88px',
            height: '25px',
            border: '1px solid #F2C94C',
            color: '#F2C94C',
            fontSize: '11px',
            fontWeight: 400,
            borderRadius: '4px',
            display: 'flex',
            lineHeight: '25px',
            alignItems: 'center',
            flexDirection: 'column',
            textTransform: 'uppercase',
            margin: '0 auto'
        }
    },
    offerButton: {
        '& span': {
            height: '18px',
            width: '86px',
            color: '#FFF',
            fontSize: '8px',
            fontWeight: 500,
            borderRadius: '4px',
            background: '#53CD94',
            alignItems: 'center',
            flexDirection: 'column',
            textTransform: 'uppercase',
            margin: '0 auto',
            display: 'flex',
            lineHeight: '18px',
        }
    }
}));