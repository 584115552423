import { makeStyles } from "@material-ui/core/styles";
export const matureStyles = makeStyles((theme) => ({
    mature: {
        fontSize: "12px",
        display: "flex",
        alignItems: "center",
        textTransform: "uppercase",
      },
      matureState1: {
        color: "#32b354",
      },
      matureState2: {
        color: "#71ff00",
      },
      matureState3: {
        color: "#e3ff00",
      },
      matureState4: {
        color: "#ffaa00",
      },
      matureState5: {
        color: "#ff3900",
      },
    matureText : {
        color : "#53cd94"
    }
}));