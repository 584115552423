import { makeStyles } from '@material-ui/core/styles';

export const searchStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    search: {
        maxWidth: '481px',
        position: 'relative',
        backgroundColor: '#FFF',
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        '&:hover': {
            backgroundColor: '#FFF',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
        borderRadius: '40px',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#006D51',
        borderTopRightRadius: '18px',
        borderBottomRightRadius: '18px'
    },
    inputRoot: {
        color: 'inherit',
        width: `calc( 100% - 56px ) !important`
    },
    inputInput: {
        color: '#000',
        height: '40px',
        padding: theme.spacing(0),
        paddingRight: theme.spacing(1),
        fontSize: '14px',
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.down('md')]: {
        },
    },
    textWrap: {
        borderRadius: '50px'
    },
    text: {
        '& > span': {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '16.59px',
        }
    },
    fixed: {
        position: 'absolute !important',
        top: '30px !important'
    }
}));