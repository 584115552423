/*-- Libraries --*/
import React from "react";
import { Dialog} from "@material-ui/core";
export default function ModalComponent(props) {
    
    const {children, openModal,onClose, arialabelledby, ariaDescribedby}=props;

    return(
        <Dialog
        open={openModal}
        onClose={onClose}
        aria-labelledby={arialabelledby}
        aria-describedby={ariaDescribedby}
      >
          {children}
      </Dialog>
    )
}