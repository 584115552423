import { makeStyles } from '@material-ui/core/styles';

export const gradeDropdownStyles = makeStyles((theme) => ({
  block1: {
    border: '2px solid #DDF5EA',
    borderRadius: '4px',
    background: '#FFF',
    color: '#53CD94',
    height: '35px',
    '&:before': {
      border: 'none'
    },
    '&:after': {
      border: 'none'
    },
    '&:hover': {
      '&:before': {
        border: 'none !important'
      },
      '&:after': {
        border: 'none !important'
      }
    },
    '& select': {
      padding: '8px 20px',
      paddingRight: '40px !important',
      textTransform: 'uppercase',
      fontSize: '13px',
      fontWeight: 400,
      '&focus': {
        backgroundColor: '#FFF'
      },
    },
  }
}));
