
function formatNumber(num) {
  if(num){
    return num.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  } else {
    return 0
  }
    
  };

export default formatNumber;


