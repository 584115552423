/*-- Libraries --*/
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Button, 
  Paper 
} from "@material-ui/core";
import HttpsIcon from "@material-ui/icons/Https";

/*-- redux --*/
import { getCart } from "./cartSlice";

/*-- styles,components,functions --*/
import { cartStyles } from "./styles";
import AppStepper from "features/Stepper";
import EmptyMessage from "features/layout/emptyMessage";
import Item from "./item";
import formatNumber from 'functions/regex';
import LayoutWrapper from "features/layout/wrapper";


export default function Cart(props) {
  const classes = cartStyles();
  const data = useSelector(getCart);
  
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(_getCartItems());
  // }, []);

  const emptyCartData = {
    message: "No hay productos en tu carrito",
    linkTo: "/mercado",
    messageLink: "Ir al mercado",
  };

  return (
    <LayoutWrapper>
      <div className={classes.container}>
        <Typography
          variant="h2"
          component="h5"
          gutterBottom
          className={classes.pageTitle}
        >
          Carrito de compras{" "}
          <span>
            ({data.length} {data.length === 1 ? "Producto" : "Productos"})
          </span>
        </Typography>
        <AppStepper stepNumber={0} />
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={8}>
          <Paper className={classes.paper}>
            {data.length !== 0 ? (
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.hideOnMobile}>FOTO</TableCell>
                    <TableCell>PRODUCTO</TableCell>
                    <TableCell>PRECIO</TableCell>
                    <TableCell align="center">CANTIDAD</TableCell>
                    <TableCell align="right">TOTAL</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <Item key={row.id} data={row} {...props} error/>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <EmptyMessage data={emptyCartData} />
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Paper className={classes.paper}>
            <Typography
              variant="h2"
              component="h5"
              gutterBottom
              className={classes.pageText}
            >
              Resumen de tu pedido
            </Typography>
            <div className={classes.totalAmount}>
              <span>Sub-total</span>
              <span className={classes.totalStyle}>
                S/ {formatNumber(data.reduce((n, { total }) => n + total, 0))}
              </span>
            </div>
            <div className={classes.shipMessage}>
              <span className={classes.descriptionSeller}>
                El costo del envío aún no está incluído en el precio
              </span>
            </div>
            {data.length !== 0 ? (
              <Button
                variant="contained"
                component={Link}
                size="medium"
                color="primary"
                className={classes.checkcoutButton}
                to="/envio"
              >
                CONFIRMAR PEDIDO
              </Button>
            ) : (
              ""
            )}
            <div className={classes.lockDescription}>
              <HttpsIcon className={classes.pageLock} />
              <Typography
                variant="h2"
                component="h5"
                className={classes.textLock}
              >
                Compra segura y protegida
              </Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </LayoutWrapper>
  );
}
