import { makeStyles } from '@material-ui/core/styles';

export const skeletonStyles= makeStyles((theme) => ({
  paper: {
    padding: "24px 20px",
    borderTopLeftRadius: "25px !important",
    borderTopRightRadius: "25px !important",
    borderBottomLeftRadius: "25px !important",
    borderBottomRightRadius: "25px !important",
    fontSize: "12px",
    boxShadow: "none",
    border: "2px solid #E6E7E8",
    borderBottom: "0px",
    minHeight: "430px",
  },
  timeStructure: {
    display: "flex",
    width: "100%",
  },
  
  imageStructure: {
    maxWidth: "100%",
    maxHeight: "140px",
    display: "block",
    margin: "10px auto",
    height:"150px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "140px",
    }
  },
}))
