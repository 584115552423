import { makeStyles } from "@material-ui/core/styles";

export const tableStyles = makeStyles((theme) => ({
  rowTitle: {
    color: "#333",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18.96px",
    textTransform:"uppercase"
  },
  columnTitle:{
    color: "#808285",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18.96px",
    margin:"1rem 0"
  }
}));
