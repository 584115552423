import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Badge,
  Paper,
  Typography,
  Divider,
  TableCell,
  TableRow,
  withStyles,
  Button,
  Popover,
  CircularProgress,
} from "@material-ui/core";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import IconButton from "@material-ui/core/IconButton";
import { _getCartItems, getCart } from "pages/Cart/cartSlice";
import { minCartStyles } from "./styles";
import formatNumber from 'functions/regex';
import DeleteIcon from "@material-ui/icons/Delete";
import { deleteCartItem } from "pages/Cart/cartSlice";

const StyledBadge = withStyles({
  badge: {
    background: "#F2C94C",
    color: "#FFF",
  },
})(Badge);

export default function MiniCart(props) {
  const classes = minCartStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(0);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const data = useSelector(getCart);
  let total = 0;
  let count = data.length;

  data.forEach((item, index) => {
    total += item.total;
  });

  useEffect(() => {
    dispatch(_getCartItems());
  }, [dispatch]);

  const onHandleCheckout = () => {
    setAnchorEl(null);
    history.push("/mi-pedido");
  };
  const deleteItem = (id) => {
    setDeleting(id);
    dispatch(deleteCartItem(id));
    setDeleting(0);
  };

  return (
    <>
      <IconButton
        color="inherit"
        style={{
          height: "36px",
          width: "36px",
          marginLeft: "20px",
          background: "#DDF5EA",
          color: "#006D51",
        }}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        <StyledBadge badgeContent={count}>
          <ShoppingBasketIcon />
        </StyledBadge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ borderRadius: "12px" }}
      >
        <Paper className={classes.miniCartBox} >
          <>
            {data.length > 0 ? (
              <>
                <div className={classes.topBar}>
                  <ShoppingBasketIcon className={classes.icon} />
                  <Typography
                    variant="h4"
                    component="span"
                    gutterBottom
                    className={classes.topBarText}
                  >
                    Total: <span> S/ {formatNumber(total)} </span>
                  </Typography>
                </div>
                <Divider
                  light
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                />
                <table className={classes.orderTable}>
                  <tbody>
                    {data.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell
                          className={classes.productImageWrap}
                          align="center"
                        >
                          <img
                            src={row.image}
                            alt="Product 1"
                            className={classes.productImage}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="h4"
                            component="h5"
                            gutterBottom
                            className={classes.productTitle}
                          >
                            {row.name}
                          </Typography>
                          <Typography
                            variant="h4"
                            component="h5"
                            gutterBottom
                            className={classes.productGrade}
                          >
                            {row.qty} {row.unit}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="right"
                          valign={"top"}
                          style={{
                            verticalAlign: "top",
                            paddingTop: "15px",
                          }}
                        >
                          <Typography
                            variant="h4"
                            component="h5"
                            gutterBottom
                            className={classes.productGrade}
                          >
                            S/ {formatNumber(row.total)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {deleting === row.id ? (
                            <CircularProgress
                              className={classes.deleteIcon}
                              style={{ height: "15px", width: "15px" }}
                            />
                          ) : (
                            <DeleteIcon
                              className={classes.deleteIcon}
                              onClick={() => {
                                deleteItem(row.id);
                              }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </table>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={classes.checkcoutButton}
                  onClick={onHandleCheckout}
                >
                  IR AL CARRITO
                </Button>
              </>
            ) : (
              <Typography
                variant="h4"
                component="h5"
                gutterBottom
                className={classes.emptyCart}
              >
                No hay productos en tu carrito
              </Typography>
            )}
          </>
        </Paper>
      </Popover>
    </>
  );
}
