import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { generalInfoEP } from "endpoints";
import setAuthToken from "features/API";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loadingAuth: true,
    // loginPopup: false,
    loginError: false,
    loginErrorMessage: "",
    token: "",
    client: {},
  },
  reducers: {
    _setUser: (state, action) => {
      state.client = action.payload;
    },
    _setLoading: (state, action) => {
      state.loadingAuth = action.payload;
    },

    _setUserData: (state, action) => {
      if (typeof action.payload.token != "undefined") {
        state.token = action.payload.token;
      } else {
        state.loginError = true;
        state.loginErrorMessage = action.payload.non_field_errors;
      }
    },
  },
});

export const { _setUser, _setUserData , _setLoading } =
  authSlice.actions;

export const isLoading = (state) => state.auth.loadingAuth;
export const isLogin = (state) => state.auth.token;
export const loginError = (state) => state.loginError;
export const userData = (state) => state.auth.client;

export const setUserData = (data) => (dispatch) => {
  dispatch(_setUserData(data));
  setAuthToken(data.token);
};

export const logout = () => {
  localStorage.clear();
};

export const setLoading = (action) => (dispatch) => {
  dispatch(_setLoading(action));
};

export const loadUser = (action) => async (dispatch) => {
  const data = await axios.get(generalInfoEP).then((response) => {
    return response.data;
  });
  dispatch(_setUser(data));
};

export default authSlice.reducer;
