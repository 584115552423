import { makeStyles } from "@material-ui/core/styles";

export const withoutResultStyles = makeStyles((theme) => ({
  paper: {
    padding: "55px",
    borderRadius: "12px",
    color: "#53CD94",
    fontSize: "25px",
    fontWeight: "bold",
    boxShadow: "none",
    width: "100%",
    marginBottom: "30px",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "25px",
    },
  },
  modalContainer: {
    flexDirection: "column",
    padding: "3rem",
    alignItems: "center",
  },
  box: {
    margin: "1rem",
  },
  icon: {
    color: "#F2994A",
    fontSize: "60px",
  },
  alert: {
    fontSize: "20px",
    color: "#808285",
    textAlign:"center"
  },

  text: {
    fontSize: "16px",
    color: "#000000",
  },
}));
