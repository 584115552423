import Grid from "@material-ui/core/Grid";
import FacebookIcon from "@material-ui/icons/Facebook";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import InstagramIcon from "@material-ui/icons/Instagram";
import { footerStyles } from "./footerStyles";
import { Link } from "react-router-dom";
import logo from "assets/images/whipay_logo_blanco.svg";
import clsx from "clsx";

export default function Footer() {
  const classes = footerStyles();
  return (
    <>
      <div className={classes.root}>
        <Grid container  className={classes.container}>
          <Grid item xs={12} sm={6} md={3}>
            <img src={logo} className={classes.logo} alt="logo" />
            <p style={{width:"90%"}}>Primer marketplace B2B para la venta mayorista de frescos.</p>
            <p style={{width:"90%"}}>
              Integramos todos los servicios del mercado de abastos en una sola
              plataforma.
            </p>
            <div className={classes.footer_socialMedia}>
              <a href="mailto:clientes@whipay.pe?Subject=Info: WhiPay - Mercado Mayorista" target="_top">
                <MailOutlineIcon />
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/whipay">
                <FacebookIcon />
              </a>
              <a target="_blank"  rel="noopener noreferrer" href="https://www.linkedin.com/company/whipay">
                <LinkedInIcon />
              </a>
              <a target="_blank"  rel="noopener noreferrer" href="https://www.instagram.com/whipay.peru/">
                <InstagramIcon />
              </a>
            </div>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <h2 className={classes.subtitle}>Registro</h2>
            <ul className={classes.footer_list}>
              <li>
                <Link to={{pathname:"/compra-por-mayor"}} > Compra al por mayor </Link>
              </li>
              <li>
                <Link to={{pathname:"/vende-con-nosotros"}}> Vende con nosotros </Link>
              </li>
            </ul>
          </Grid> */}
          <Grid item xs={12} sm={6} md={3}>
            <h2 className={classes.subtitle}> Recursos </h2>
            <ul className={classes.footer_list}>
              <li>
                <Link to={{pathname: "/ayuda"}} > Preguntas Frecuentes </Link>
              </li>
              <li>
                <Link to={{pathname:"/politicas-de-privacidad"}}> Política de privacidad </Link>
              </li>
              <li>
                <Link to={{pathname:"/terminos-y-condiciones"}} > Términos y condiciones </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <h2 className={classes.subtitle}>Contacto</h2>
            <ul className={classes.footer_list}>
              <li>
                <a target="_blank"  rel="noopener noreferrer" href="https://api.whatsapp.com/send/?phone=%2B51913629462&text&app_absent=0" className={classes.iconsLinks}>
                  <WhatsAppIcon />
                  WhatsApp
                </a>
              </li>
              <li>
                <a href="mailto:clientes@whipay.pe?Subject=Info: WhiPay - Mercado Mayorista" target="_top" className={classes.iconsLinks}>
                  <MailOutlineIcon /> clientes@whipay.pe
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/@-12.0833193,-76.9688972,16z" className={classes.iconsLinks}>
                  <LocationOnIcon />
                  Av. Circunvalación del Club Golf Los Incas
                  134, Santiago de Surco 15023. Lima, Perú.
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>
      </div>
      <div className="copyright">
        <Grid container>
          <Grid
            item
            className={clsx(classes.footer__item, classes.copy)}
            md={12}
            style={{ flexDirection: "column" }}
          >
            <p>Whipay - Copyright - {new Date().getFullYear()}</p>
          </Grid>
        </Grid>
      </div>
      {/* <div className={classes.container}>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item md={3} className={classes.footer__item}>
              <h1>
                <img src={logo} className={classes.logo} alt={""} />
              </h1>
              <p>Marketplace B2B de venta de frescos al por mayor</p>
            </Grid>
            <Grid item className={classes.footer__item} md={3}
              style={{ flexDirection: "column" }}
            >
              <p>Av. Circunvalación del Club Golf Los Incas 134, Surco,</p>
              <p>Lima, Peru</p>
              <p>Teléfono: +51 969 333 143</p>
              <p>jarias@whipay.pe</p>
            </Grid>
            <Grid item md={3} className={classes.footer__item}>
              <a href="mailto:jarias@whipay.pe"><EmailIcon style={{ color: "#f1f1f1" }} /></a>
              <a href="https://www.facebook.com/whipay"><FacebookIcon style={{ color: "#f1f1f1" }} /></a>
              <a href="https://www.linkedin.com/company/whipay"><LinkedInIcon style={{ color: "#f1f1f1" }} /></a>
              <a href="https://www.instagram.com/whipay.peru/"><InstagramIcon style={{ color: "#f1f1f1" }} /></a>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="copyright">
        <Grid container>
          <Grid
            item
            className={(classes.footer__item, classes.copy)}
            md={12}
            style={{ flexDirection: "column" }}
          >
            <p>Whipay - Copyright - 2021</p>
          </Grid>
        </Grid>
      </div> */}
    </>
  );
}
