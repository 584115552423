import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { documentTypesListEP,billingDetailsEP } from "endpoints";
export const checkouttSlice = createSlice({
  name: "checkout",
  initialState: {
    loading: true,
    items: [],
    name: "",
    phone: "",
    email: "",
    docNumber: "",
    typeDocument: "1",
    billTo: "",
    ruc: "",
    businessName: "",
    address: "",
    documentsList: [],
    billingType:"2"
  },
  reducers: {
    _clearCart: (state, action) => {
      state.items = action.payload;
    },
    setDocumentsList: (state, action) => {
      state.documentsList = action.payload;
    },
    setFullName: (state, action) => {
      state.name = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setDocument: (state, action) => {
      state.typeDocument = action.payload;
    },
    setDocNumber: (state, action) => {
      state.docNumber = action.payload;
    },
    setName: (state, action) => {
      state.billTo = action.payload;
    },
    setRuc: (state, action) => {
      state.ruc = action.payload;
    },
    setBusinessName: (state, action) => {
      state.businessName = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setBillingType:(state, action)=>{
      state.billingType=action.payload;
    },
    setformData:(state, action)=>{

      state.name= action.payload.fullName;
      state.phone= action.payload.phone;
      state.email= action.payload.email;
      state.typeDocument= action.payload.typeDocument.toString();
      state.docNumber= action.payload.docNumber;
      state.billTo = action.payload.billTo;
      state.ruc = action.payload.ruc;
      state.businessName = action.payload.businessName;
      state.address = action.payload.address;
      state.billingType=action.payload.billType.toString();
    }
  },
});

export const {
  _clearCart,
  setFullName,
  setPhone,
  setEmail,
  setDocument,
  setDocNumber,
  setName,
  setRuc,
  setBusinessName,
  setAddress,
  setDocumentsList,
  setBillingType,
  setformData
} = checkouttSlice.actions;

export const fullName = (state) => state.checkout.name;
export const phone = (state) => state.checkout.phone;
export const email = (state) => state.checkout.email;
export const docNumber = (state) => state.checkout.docNumber;
export const typeDocument = (state) => state.checkout.typeDocument;
export const billTo = (state) => state.checkout.billTo;
export const ruc = (state) => state.checkout.ruc;
export const businessName = (state) => state.checkout.businessName;
export const address = (state) => state.checkout.address;
export const documentList = (state) => state.checkout.documentsList;
export const billingType=(state) => state.checkout.billingType;

// update Inputs
export const fullNameUpdate = (action) => (dispatch) => {

  dispatch(setFullName(action));
};
export const phoneUpdate = (action) => (dispatch) => {

  dispatch(setPhone(action));
};
export const emailUpdate = (action) => (dispatch) => {

  dispatch(setEmail(action));
};
export const updateDocument = (action) => (dispatch) => {

  dispatch(setDocument(action));
};
export const updateDocNumber = (action) => (dispatch) => {

  dispatch(setDocNumber(action));
};
export const updateName = (action) => (dispatch) => {

  dispatch(setName(action));
};
export const updateRucNumber = (action) => (dispatch) => {

  dispatch(setRuc(action));
};
export const updatebusinessName = (action) => (dispatch) => {

  dispatch(setBusinessName(action));
};
export const updateAddress = (action) => (dispatch) => {

  dispatch(setAddress(action));
};
export const updateBillingType = (action) => (dispatch) => {

  dispatch(setBillingType(action));
};
export const getDocumentsType = (action) => async dispatch => {

  const result = await axios.get(documentTypesListEP).then((response) => {
    return response.data
  });

  dispatch(setDocumentsList(result));
}
;
export const submitBillData = async (action, success)=> {

  await axios.post(billingDetailsEP,action).then((response) => {
    if(response.status === 201) {
      success (true)
    }
  });

  
}
export const getBillData= (action) => async dispatch => {

  await axios.get(billingDetailsEP).then((response) => {
      
    if(response.status===200){
        dispatch(setformData(response.data));
    }
  });
  
  
}
// export const addCheckout = (state) => async dispatch => {

//     const results = await axios.get(checkoutEp).then((response) => {
//         return response
//     })

//     dispatch ( _clearCart ([]) );

// }
export default checkouttSlice.reducer;