/*--Libraries--*/
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { CircularProgress, TableCell, TableRow, IconButton,Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteIcon from "@material-ui/icons/Delete";

/*-- redux --*/
import { deleteCartItem, updateCartItem } from "./cartSlice";

/*-- styles,components,functions --*/
import { cartStyles } from "./styles";
import formatNumber from "functions/regex";

export default function Item(props) {
  const dispatch = useDispatch();

  const classes = cartStyles();
  const row = props.data;
  const [qty, setQTY] = useState(row.quantity);
  const [deleting, setDeleting] = useState(0);

  const handleQtyA = () => {
    if (qty < row.stock) {
      setQTY(qty + 1);
    }
  };

  const handleQtyM = () => {
    if (qty > 1) {
      setQTY(qty - 1);
    }
  };

  const deleteItem = (id) => {
    setDeleting(id);

    dispatch(deleteCartItem(id));
  };

  const updateCart = async () => {
    await dispatch(updateCartItem({ id: props.data.id, quantity: qty }));
  };

 const deadlineDate = new Date(row.price_deadline);
  return (
    <TableRow key={row.id}>
      <TableCell className={classes.productImageWrap}>
        {row.mensajeError?<span className={clsx(classes.errorLabel,classes.errorColor)}>{row.mensajeError}</span>:null}
        <img src={row.image} alt="Product 1" className={classes.productImage} />
      </TableCell>
      <TableCell component="th" scope="row">
        <Link
          to={`/mercado/${row.sale_slug}`}
          style={{ textDecoration: "none" }}
        >
          <Typography
            variant="h4"
            component="h5"
            gutterBottom
            className={classes.productTitle}
          >
            {row.name}
          </Typography>
        </Link>
        <Typography
          variant="h4"
          component="h5"
          gutterBottom
          className={classes.productGrade}
        >
          {row.grade}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          gutterBottom
          // className={classes.productGrade}
        >
             S/ {formatNumber(row.price)}
        </Typography>
        {row.price_deadline && (deadlineDate > new Date()) ?
          <span className={classes.productCaption}>
            Precio válido hasta las <Moment format="hh:mm:ss A">{row.price_deadline}</Moment>
          </span> : null
        }

       </TableCell>
      <TableCell align="center">
        <div className={classes.qtyWrap}>
          <IconButton
            color="primary"
            aria-label="add to shopping cart"
            className={classes.qtyButtons}
            onClick={handleQtyM}
          >
            <RemoveIcon />
          </IconButton>
          <div className={classes.qtyInputWrap}>
            {qty} {row.unit}
          </div>
          <IconButton
            color="primary"
            aria-label="add to shopping cart"
            className={classes.qtyButtons}
            onClick={handleQtyA}
          >
            <AddIcon />
          </IconButton>
        </div>
        {row.quantity !== qty ? (
          <span
            className={classes.message}
            onClick={updateCart}
          >
            Actualizar
          </span>
        ) : (
          ""
        )}
      </TableCell>
      <TableCell align="right">S/ {formatNumber(row.price * qty)}</TableCell>
      <TableCell align="right">
        {deleting === row.id ? (
          <CircularProgress
            className={classes.deleteIcon}
            style={{ height: "15px", width: "15px" }}
          />
        ) : (
          <DeleteIcon
            className={classes.deleteIcon}
            onClick={() => {
              deleteItem(row.id);
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
}
