import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

export const logoStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    background: "#DDF5EA",

    left: "-72px",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbarIconShift: {
    width: drawerWidth + "px",
    left: "-" + drawerWidth + "px",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appLogo: {
    height: "40px",
    margin: "0 auto",
  },
}));
