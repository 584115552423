import React, { useEffect, useState } from 'react';
import { Paper, Typography, Grid, Button } from "@material-ui/core";
import { myFormStyle } from "./style";
import logo from "assets/images/logo.svg";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    NativeSelect,
    withStyles,
    InputBase,
} from '@material-ui/core';
import {
    updateProvince,
    updateDistrict,
    location,
    locations,
    district,
    getLocations,
    districts,
    updateIncomeRange,
    incomeRange,
    range,
    updateArrival,
    arrivalM,
    arrival,
    submitForm,
    setDistrict,
    setLocation,
    setRange,
    setArrival,

} from "./slice";
import { useDispatch, useSelector } from "react-redux";
import check from "assets/images/check.svg";
import LayoutWrapper from 'features/layout/wrapper';

const BootstrapInput = withStyles((theme) => ({
    root: {
        width: "100%",

        "label + &": {},
    },
    input: {
        borderRadius: 4,
        position: "relative",
        width: "100%",
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "7.5px 7px 7.5px 15px",
        backgroundColor: "#F1F2F2",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderRadius: 4,
            borderColor: "#53CD94",
            outlineColor: "#53CD94",
        },
    },
}))(InputBase);

const GreenRadio = withStyles({
    root: {
        color: "#F1F2F2",
        '&$checked': {
            color: "#53CD94",
        },
    },
    checked: {},
})((props) => <Radio {...props} />);

const StyledFormControlLabel = withStyles({
    label: {
        fontSize: '14px',
        color: "#808285",
    },
})(FormControlLabel);

export default function Formulario(props) {
    const classes = myFormStyle();
    const dispatch = useDispatch();

    const provinceValue = useSelector(locations);
    const locationValue = useSelector(location);
    const districtsValue = useSelector(districts);
    const districtValue = useSelector(district);
    const incomeRangesValue = useSelector(range);
    const incomeRangeValue = useSelector(incomeRange);
    const arrivalMValue = useSelector(arrivalM);
    const arrivalValue = useSelector(arrival);

    const [value, setValue] = useState('1');
    const [isBuyer, setIsBuyer] = useState(false);

    const [contactName, setcontactName] = useState("");
    const [contactLastName, setContactLastName] = useState("");
    const [professionalPosition, setProfessionalPosition] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [ruc, setRuc] = useState("");
    const [address, setAddress] = useState("");
    const [description, setDescription] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [untilDate, setUntilDate] = useState("");

    const [errorContactName, setErrorContactName] = useState(false);
    const [errorContactLastname, setErrorContactLastName] = useState(false);
    const [errorProfessionalPosition, setErrorProfessionalPosition] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorCompanyName, setErrorCompanyName] = useState(false);
    const [errorRuc, setErrorRuc] = useState(false);
    const [errorAddress, setErrorAddress] = useState(false);
    const [errorDescription, setErrorDescription] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [submittedForm, setSubmittedForm] = useState(false);
    const [notSelected1, setNotSelected1] = useState(false);
    const [notSelected2, setNotSelected2] = useState(false);
    const [notSelected3, setNotSelected3] = useState(false);
    const [notSelected4, setNotSelected4] = useState(false);
    const [notDate, setNotDate] = useState(false);
    const { state } = useLocation();
    const dataToUpdate = state;

    const provinceChange = (event) => {
        dispatch(updateProvince(event.target.value));
        dispatch(updateDistrict(""));
    };
    const districtChange = (event) => {
        dispatch(updateDistrict(event.target.value));
    };
    const incomeRangeChange = (event) => {
        dispatch(updateIncomeRange(event.target.value))
    };
    const arrivalChange = (event) => {
        dispatch(updateArrival(event.target.value))
    };

    useEffect(() => {
        async function createForm() {
            if (!dataToUpdate) {
                await dispatch(getLocations());
                setcontactName("");
                setProfessionalPosition("");
                setPhone("");
                setEmail("");
                setCompanyName("");
                setRuc("");
                setAddress("");
                setDescription("");
                setFromDate("");
                setUntilDate("");
            } else {
                if (provinceValue.length === 0 || districtsValue.length === 0 || incomeRangeValue.length === 0 || arrivalMValue === 0) {
                    await dispatch(getLocations());
                }
            }

        }
        createForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, dataToUpdate]);

    useEffect(() => {
        if (dataToUpdate) {
            if (provinceValue.length !== 0 && districtsValue.length !== 0 && incomeRangeValue.length !== 0 && arrivalMValue.length !== 0) {
                const indexProvince = provinceValue.findIndex(
                    (p) => dataToUpdate.province_name === p.province
                );
                const indexDistrict = districtsValue.find(
                    (district) => dataToUpdate.district === district.description
                ).id;
                const indexIncomeRange = incomeRangeValue.find(
                    (range) => dataToUpdate.range === range.description
                ).id;
                const indexArrival = arrivalMValue.find(
                    (arrival) => dataToUpdate.arrival === arrival.description
                ).id;

                setcontactName(dataToUpdate.contactName);
                setProfessionalPosition(dataToUpdate.professionalPosition)
                setPhone(dataToUpdate.phone);
                setEmail(dataToUpdate.email);
                setCompanyName(dataToUpdate.companyName);
                setRuc(dataToUpdate.ruc);
                setAddress(dataToUpdate.address);
                setDescription(dataToUpdate.description);
                setFromDate(dataToUpdate.fromDate);
                setUntilDate(dataToUpdate.untilDate);
                dispatch(updateProvince(indexProvince));
                dispatch(updateDistrict(indexDistrict));
                dispatch(updateIncomeRange(indexIncomeRange));
                dispatch(updateArrival(indexArrival));


            }
        }
    }, [dispatch, dataToUpdate, provinceValue, districtsValue, incomeRangeValue, arrivalMValue]);

    useEffect(() => {
        dispatch(getLocations());

    }, [dispatch]);

    const handleChange = (event) => {
        setValue(event.target.value);
        setIsBuyer(false);

        if (value === "1") {
            setIsBuyer(true);
        }
    };
    const contactNameChange = (event) => {
        setcontactName(event.target.value);
    };

    const contactLastNameChange = (event) => {
        setContactLastName(event.target.value);
    }

    const cargoChange = (event) => {
        setProfessionalPosition(event.target.value);
    };

    const phoneChange = (event) => {
        const regEx = /^$|^[0-9]{1,15}/;
        if (regEx.test(event.target.value)) {
            setPhone(event.target.value);
        };
    };

    const emailChange = (event) => {
        const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i;
        if (re.test(event.target.value)) {
            setInvalidEmail(false);
        } else {
            setInvalidEmail(true);
        };
        setEmail(event.target.value);
    };

    const companyNameChange = (event) => {
        setCompanyName(event.target.value);
    };

    const rucChange = (event) => {
        const reg = /^$|^[0-9\b]+.?([1-9]{1,2})?$/;
        if (reg.test(event.target.value)) {
            setRuc(event.target.value);
        }
    };

    const addressChange = (event) => {
        setAddress(event.target.value);
    };

    const descriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const fromDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const untilDateChange = (event) => {
        setUntilDate(event.target.value);
    };


    const onSubmit = async () => {
        let submit = true;

        if (!contactName) {
            submit = false;
            setErrorContactName(true);
        } else {
            setErrorContactName(false)
        }

        if (!contactLastName) {
            submit = false;
            setErrorContactLastName(true);
        } else {
            setErrorContactLastName(false);
        }

        if (!professionalPosition) {
            submit = false;
            setErrorProfessionalPosition(true);
        } else {
            setErrorProfessionalPosition(false)
        }

        if (!phone) {
            submit = false;
            setErrorPhone(true);
        } else {
            setErrorPhone(false);
        }

        if (!companyName) {
            submit = false;
            setErrorCompanyName(true);
        } else {
            setErrorCompanyName(false);
        }

        if (ruc === "" || ruc == null) {
            submit = false;
            setErrorRuc(true);
        } else {
            setErrorRuc(false);
        }

        if (address === "") {
            submit = false;
            setErrorAddress(true);
        } else {
            setErrorAddress(false);
        }

        if (description === "") {
            submit = false;
            setErrorDescription(true);
        } else {
            setErrorDescription(false);
        }

        if (!email) {
            submit = false;
            setErrorEmail(true);
        } else {
            setErrorEmail(false);
        }
        if (invalidEmail) {
            submit = false;
        }
        if (!locationValue) {
            setNotSelected1(true);
        } else {
            setNotSelected1(false)
        }

        if (!districtValue) {
            setNotSelected2(true);
        } else {
            setNotSelected2(false);
        }

        if (!incomeRangesValue) {
            setNotSelected3(true);
        } else {
            setNotSelected3(false);
        }
        if (!arrivalValue) {
            setNotSelected4(true);
        } else {
            setNotSelected4(false);
        }
        if (fromDate === "") {
            submit = false;
            setNotDate(true);
        } else {
            setNotDate(false);
        }
        if (untilDate === "") {
            submit = false;
            setNotDate(true);
        } else {
            setNotDate(false);
        }




        if (!dataToUpdate && submit) {
            const data = {
                name: contactName,
                last_name: contactLastName,
                title: professionalPosition,
                phone: phone,
                email: email,
                company: companyName,
                ruc,
                address: address,
                interests: description,
                timeFrom: fromDate,
                timeTo: untilDate,
                arrivalMethod: arrivalValue,
                buySaleRange: incomeRangesValue,
                district: districtValue,
                userType: parseInt(value),
            };
            await dispatch(submitForm(data, () => {
                setSubmittedForm(true);
                dispatch(setLocation(""));
                dispatch(setDistrict(""));
                dispatch(setRange(""));
                dispatch(setArrival(""));
            }));


        }
    }


    return (
        <LayoutWrapper>
            <Paper className={classes.paper}>
                <div className={classes.containerLogo}>
                    <img src={logo} className={classes.logo} alt="logo_whipay" />
                </div>
                {submittedForm ? (
                    <>
                        <div className={classes.containerLogo}>
                            <img src={check} className={classes.check} alt={""} />
                        </div>
                        <div className={classes.containerLogo}>
                            <Typography
                                variant="h2"
                                component="h5"
                                gutterBottom
                                className={classes.info}
                                style={{
                                    fontSize: "14px",
                                    fontWeight: 700,
                                    lineHeight: "23.7px",
                                    color: "#333333",
                                    marginBottom: "25px",
                                    display: "flex",
                                    textAlign: "justify",
                                    fontFamily: '"Rubik",sans-serif',
                                }}
                            >
                                El formulario se ha enviado correctamente
                            </Typography>

                        </div>
                    </>
                ) :
                    <>
                        <div className={classes.container}>
                            <Typography
                                variant="h2"
                                component="h5"
                                gutterBottom
                                className={classes.pageTitle}
                            >
                                Registro de usuarios
                            </Typography>
                        </div>
                        <div className={classes.container}>
                            <Typography
                                variant="h2"
                                component="h5"
                                gutterBottom
                                className={classes.subTitle}
                            >
                                Cuéntanos un poco sobre ti, ¿Quisieras vender o comprar en Whipay?
                            </Typography>
                        </div>
                        <div className={classes.container}>
                            <RadioGroup row aria-label="gender" name="gender1" value={value} onChange={handleChange} className={classes.itemOptions}>
                                <StyledFormControlLabel value="1" control={<GreenRadio />} label="Vender" />
                                <StyledFormControlLabel value="2" control={<GreenRadio />} label="Comprar" />
                            </RadioGroup>
                        </div>
                        <div>
                            <Typography
                                variant="h2"
                                component="h5"
                                gutterBottom
                                className={classes.subTitle2}
                            >
                                Datos de contacto
                            </Typography>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={3}>
                                <label className={classes.label}>
                                    Nombres
                                </label>
                                <input
                                    type={"text"}
                                    placeholder={"Ingresa tus nombres"}
                                    className={classes.input}
                                    onChange={contactNameChange}
                                    value={contactName}
                                />
                                {errorContactName ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor, ingresa tus nombres
                                    </span>
                                ) : ("")}
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <label className={classes.label}>
                                    Apellidos
                                </label>
                                <input
                                    type={"text"}
                                    placeholder={"Ingresa tus apellidos"}
                                    className={classes.input}
                                    onChange={contactLastNameChange}
                                    value={contactLastName}
                                />
                                {errorContactLastname ? (
                                    <span className={clsx(classes.errorColor, classes.errorColor.errorLabel)}>
                                        Por favor, ingresa tus apellidos
                                    </span>
                                ) : ("")}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label className={classes.label}>Cargo</label>
                                <input
                                    type={"text"}
                                    placeholder={"Ingresa tu cargo"}
                                    className={classes.input}
                                    onChange={cargoChange}
                                    value={professionalPosition}
                                />
                                {errorProfessionalPosition ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor ingresa tu cargo
                                    </span>
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <label className={classes.label}>
                                    Teléfono
                                </label>
                                <input
                                    type={"text"}
                                    // maxLength={15}
                                    placeholder={"Número de teléfono de contacto"}
                                    className={classes.input}
                                    onChange={phoneChange}
                                    value={phone}
                                />
                                {errorPhone ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor, ingresa tu teléfono
                                    </span>
                                ) : (
                                    ""
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label className={classes.label}>E-mail</label>
                                <input
                                    type={"text"}
                                    placeholder={"ejemplo@whipay.pe"}
                                    className={classes.input}
                                    onChange={emailChange}
                                    value={email}
                                />
                                {invalidEmail ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor, ingresa un e-mail válido
                                    </span>
                                ) : ("")}
                                {errorEmail ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor, ingresa tu e-mail
                                    </span>
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>

                        <div>
                            <Typography
                                variant="h2"
                                component="h5"
                                gutterBottom
                                className={classes.subTitle2}
                            >
                                Datos de la empresa
                            </Typography>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={8}>
                                <label className={classes.label}>
                                    Empresa
                                </label>
                                <input
                                    type={"text"}
                                    placeholder={"Ingresa el nombre de la empresa o persona natural con negocio"}
                                    className={classes.input}
                                    onChange={companyNameChange}
                                    value={companyName}
                                />
                                {errorCompanyName ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor, ingresa el nombre de la empresa o persona natural con negocio
                                    </span>
                                ) : (
                                    ""
                                )}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <label className={classes.label}>RUC</label>
                                <input
                                    type={"text"}
                                    placeholder={"Ingresa el RUC"}
                                    className={classes.input}
                                    onChange={rucChange}
                                    value={ruc}
                                />
                                {errorRuc ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor, ingresa el RUC
                                    </span>
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>
                        {isBuyer ? (
                            <Grid item xs={12} sm={12}>
                                <label className={classes.label}>Dirección</label>
                                <input
                                    type={"text"}
                                    placeholder={"Ingresa la ubicación donde recibirás tu pedido"}
                                    className={classes.input}
                                    onChange={addressChange}
                                    value={address}
                                />
                                {errorAddress ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor, ingresa la ubicación donde recibirás tu pedido
                                    </span>
                                ) : (
                                    ""
                                )}
                            </Grid>

                        ) : (
                            <Grid item xs={12} sm={12}>
                                <label className={classes.label}>Dirección</label>
                                <input
                                    type={"text"}
                                    placeholder={"Ingresa una ubicación de almacén o mercado donde vendes"}
                                    className={classes.input}
                                    onChange={addressChange}
                                    value={address}
                                />
                                {errorAddress ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor, ingresa una ubicación de almacén o mercado donde vendes
                                    </span>
                                ) : (
                                    ""
                                )}
                            </Grid>
                        )}


                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <label className={classes.label}>
                                    Provincia
                                </label>
                                {
                                    <NativeSelect
                                        id="demo-customized-select-native"
                                        input={<BootstrapInput />}
                                        value={locationValue}
                                        onChange={provinceChange}
                                    // disabled={isDisabled}
                                    >
                                        <option value="">Selecciona una provincia</option>

                                        {provinceValue.map((p, index) => (
                                            <option value={index} key={index}>
                                                {p.province}
                                            </option>
                                        ))}

                                    </NativeSelect>
                                }
                                {notSelected1 ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor, selecciona una provincia
                                    </span>
                                ) : (
                                    ""
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label className={classes.label}>Distrito</label>
                                {
                                    <NativeSelect
                                        id="demo-customized-select-native"
                                        input={<BootstrapInput />}
                                        value={districtValue}
                                        onChange={districtChange}
                                    // disabled={isDisabled}
                                    >
                                        <option value="" >Selecciona un distrito</option>
                                        {districtsValue.map((district) => (
                                            <option value={district.id} key={district.id}>
                                                {district.description}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                }
                                {notSelected2 ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor, selecciona un distrito
                                    </span>
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>
                        {isBuyer ? (
                            <Grid item xs={12} sm={12}>
                                <label className={classes.label}>¿Qué productos te gustaría comprar?</label>
                                <textarea
                                    placeholder={
                                        "Cuéntanos sobre los productos que quieres comprar en Whipay"
                                    }
                                    maxLength={500}
                                    className={classes.input}
                                    style={{ height: "auto" }}
                                    rows={6}
                                    onChange={descriptionChange}
                                    value={description}
                                ></textarea>
                                {errorDescription ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor, ingresa un comentario
                                    </span>
                                ) : (
                                    ""
                                )}

                            </Grid>
                        ) : <Grid item xs={12} sm={12}>
                            <label className={classes.label}>¿Qué productos te gustaría publicar?</label>
                            <textarea
                                placeholder={
                                    "Cuéntanos sobre los productos que quieres publicar en Whipay"
                                }
                                maxLength={500}
                                className={classes.input}
                                style={{ height: "auto" }}
                                rows={6}
                                onChange={descriptionChange}
                                value={description}
                            ></textarea>
                            {errorDescription ? (
                                <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                    Por favor, ingresa un comentario
                                </span>
                            ) : (
                                ""
                            )}

                        </Grid>
                        }

                        <Grid container spacing={2}>

                            {isBuyer ? (
                                <Grid item xs={12} sm={6}>
                                    <label className={classes.label}>
                                        Nivel de compras mensuales
                                    </label>
                                    {
                                        <NativeSelect
                                            id="demo-customized-select-native"
                                            input={<BootstrapInput />}
                                            value={incomeRangesValue}
                                            onChange={incomeRangeChange}
                                        // disabled={isDisabled}
                                        >
                                            <option value="" >Selecciona un rango</option>
                                            {incomeRangeValue.map((range) => (
                                                <option value={range.id} key={range.id}>
                                                    {range.description}
                                                </option>
                                            ))}
                                        </NativeSelect>
                                    }
                                </Grid>

                            ) : <Grid item xs={12} sm={6}>
                                <label className={classes.label}>
                                    Nivel de ventas mensuales
                                </label>
                                {
                                    <NativeSelect
                                        id="demo-customized-select-native"
                                        input={<BootstrapInput />}
                                        value={incomeRangesValue}
                                        onChange={incomeRangeChange}
                                    // disabled={isDisabled}
                                    >
                                        <option value="" >Selecciona un rango</option>
                                        {incomeRangeValue.map((range) => (
                                            <option value={range.id} key={range.id}>
                                                {range.description}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                }
                                {notSelected3 ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor, selecciona un rango
                                    </span>
                                ) : (
                                    ""
                                )}
                            </Grid>
                            }

                            <Grid item xs={12} sm={6}>
                                <label className={classes.label}>¿Cómo llegaste a Whipay?</label>
                                {
                                    <NativeSelect
                                        id="demo-customized-select-native"
                                        input={<BootstrapInput />}
                                        value={arrivalValue}
                                        onChange={arrivalChange}
                                    // disabled={isDisabled}
                                    >
                                        <option value="" >Selecciona una opción</option>
                                        {arrivalMValue.map((arrival) => (
                                            <option value={arrival.id} key={arrival.id}>
                                                {arrival.description}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                }
                                {notSelected4 ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor, selecciona una opción
                                    </span>
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {isBuyer ? (
                                <>
                                    <label className={classes.label2}>Horario de entrega a clientes</label>
                                </>
                            ) :
                                <label className={classes.label2}>Horario de despacho de mayoristas</label>
                            }

                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <label className={classes.label}>
                                    Desde
                                </label>
                                <input
                                    type={"time"}
                                    className={classes.input}
                                    onChange={fromDateChange}
                                    value={fromDate}
                                />
                                {notDate ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor, selecciona una fecha
                                    </span>
                                ) : ("")
                                }

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label className={classes.label}>Hasta</label>
                                <input
                                    type={"time"}
                                    className={classes.input}
                                    onChange={untilDateChange}
                                    value={untilDate}
                                />
                                {notDate ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor, selecciona una fecha
                                    </span>
                                ) : ("")
                                }

                            </Grid>
                            <small
                                className={classes.label}
                                style={{ marginTop: "0px", marginLeft: "10px", fontSize: "10px" }}
                            >
                                Debe tener un mínimo de 3 horas de diferencia.
                            </small>
                        </Grid>
                        <div className={classes.container}>
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                className={classes.subimt}
                                onClick={onSubmit}
                            >
                                ENVIAR SOLICITUD
                            </Button>
                        </div>
                    </>}

            </Paper>

        </LayoutWrapper>
    );
}
