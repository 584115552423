import React from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Paper,
  ClickAwayListener,
  Typography,
  MenuList,
  Divider,
  Popover,
  MenuItem,
} from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import StoreIcon from "@material-ui/icons/Store";
import { useEffect, useState } from "react";
import { _actionPopAction, actionPop } from "../header/Slice";
import { setUserData, userData, loadUser } from "features/Auth/authSlice";
import { getLogOutData } from "features/layout/common/sidebar/Slice";
import SettingsIcon from '@material-ui/icons/Settings';
import setAuthToken from "features/API/index";
import { actionPopStyles } from "./styles";

export default function ActionPop(props) {
  const [isSeller, setIsSeller] = useState(false);
  const classes = actionPopStyles();
  const dispatch = useDispatch();
  const anchorRef = React.useRef(null);
  const _actionPop = useSelector(actionPop);
  let history = useHistory();
  const _user = useSelector(userData);
  let avatar = "assets/images/avatar.svg";
  const loginUser = useSelector((state) => state.auth.client);
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      dispatch(_actionPopAction(!_actionPop));
    }
  }
  // TO DO: CUSTOM HOOKS
  useEffect(() => {
    if (loginUser) {
      if (
        // loginUser.user_category === "Normal" &&
        loginUser.can_publish
      ) {
        setIsSeller(true);
      }
    }
  }, [loginUser]);

  useEffect (() => {
    dispatch(loadUser());
  }, [dispatch])

  const logoutCall = () => {
    dispatch(getLogOutData()); //  Call endpoint to delete token
    dispatch(setUserData({ token: '' }));
    setAuthToken(false);  //  Delete axios header
    localStorage.clear();
    history.push("/");
  };

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <>
            <Avatar
              src={typeof _user.avatar != "undefined" ? _user.avatar : avatar}
              ref={anchorRef}
              aria-controls={_actionPop ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              {...bindTrigger(popupState)}
              style={{
                cursor: "pointer",
                marginLeft: "20px",
                height: "40px",
                width: "40px",
              }}
            />
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              style={{ borderRadius: "12px" }}
            >
              <Paper
                style={{
                  width: "220px",
                  padding: "25px",
                  borderRadius: "12px",
                  boxShadow: "none",
                }}
              >
                <ClickAwayListener
                  onClickAway={() => dispatch(_actionPopAction(!_actionPop))}
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                >
                  <>
                    <Typography
                      component="span"
                      gutterBottom
                      className={classes.popUsername}
                      style={{ textTransform: "uppercase" }}
                    >
                      <Link
                        to={`/vendedor/${_user.slug}`}
                        className={classes.visited}
                      >
                        {" "}
                        {_user.full_name}{" "}
                      </Link>

                      <span> USUARIO {_user.user_category} </span>
                    </Typography>
                    <Divider light style={{ marginTop: "20px" }} />
                    <MenuList
                      autoFocusItem={_actionPop}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >

                      {isSeller ? (
                        <MenuItem
                          className={classes.logOut}
                          component={Link}
                          to="/mis-ventas"
                        >
                          <StoreIcon
                            style={{ color: "#006D51", marginRight: "10px" }}
                          />
                          Mis Ventas
                        </MenuItem>

                      ) : <MenuItem
                        className={classes.logOut}
                        component={Link}
                        to="/mis-ordenes"
                      >
                        <StoreIcon
                          style={{ color: "#006D51", marginRight: "10px" }}
                        />
                        Mis Compras
                      </MenuItem>}

                      <MenuItem
                        className={classes.logOut}
                        component={Link}
                        to="/configuraciones"
                      >
                        <SettingsIcon
                          style={{ color: "#006D51", marginRight: "10px" }}
                        />
                        Configuración
                      </MenuItem>
                      <MenuItem
                        onClick={() => logoutCall()}
                        className={classes.logOut}
                      >
                        <ExitToAppOutlinedIcon
                          style={{ color: "#006D51", marginRight: "10px" }}
                        />
                        Cerrar sesión
                      </MenuItem>
                    </MenuList>
                  </>
                </ClickAwayListener>
              </Paper>
            </Popover>
          </>
        )}
      </PopupState>
    </>
  );
}
