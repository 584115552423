import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

export const sidebarStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: "sticky",
    whiteSpace: "nowrap",
    height: "100vh",
    width: drawerWidth,
    overflow: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sideBarButton: {
    fontSize: "12px",
    boxShadow: "none",
    color: "#FFF",
    borderRadius: "8px",
    marginTop: "30px",
    width: "100%",
    "&:hover": {
      boxShadow: "none",
      color:"#333"
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
    },
  },
  vendorButtonWrap: {
    paddingRight: "6px",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "12px",
    },
  },
  vendorButton: {
    background: "#F2994A",
  },
  compairButton: {
    background: "#53CD94",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
    },
  },
  sideBarButtonHide: {
    visibility: "hidden",
  },

}));
