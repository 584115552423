/*-- Libraries --*/

import { Typography } from "@material-ui/core";
import { useParams } from 'react-router-dom';

/*-- redux --*/


/*-- styles,components,functions --*/
import { marketsStyles } from "./styles"
import AppProductList from 'features/product/list';
// import { useLocation } from "react-router";

export default function Markets(props) {
    const classes = marketsStyles();
    let { market } = useParams();

    return (
        <section className={classes.markets}>
            <div className={classes.title_container}>
                <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.title_text}
                >
                    {`Mercado Mayorista de ${market}`}
                </Typography>
            </div>
            <AppProductList />
        </section>
    );
}
