/*-- Libraries --*/
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import {
  Grid,
  Paper,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import AppStepper from "features/Stepper";
import OrderSummary from "features/checkout/orderSummary";

/*-- redux --*/
import {
  saveTransferData,
  createPayment,
  payToken,
  loading,
  orderSlug,
  errorTransfer,
  updateStep,
  activeStep,
} from "./slice";
import {
  estevedoreCost,
  shippingCost,
  getDeliveryCost,
  updateShippingCost,
} from "pages/ShipMethod/slice";
import {
  _setCartItems,
  getDiscount,
  _getCartItems
} from "pages/Cart/cartSlice";
import { updateVoucher } from "pages/MyOrders/slices";

/*-- styles,components,functions --*/
import { paymentStyles } from "./styles";
import product_1 from "assets/images/image-gallery.svg";
import BBVA from "assets/images/BBVA.svg";
import BCP from "assets/images/BCP.svg";
import PaymentGateway from "./paymentGateway";
import useShippingMethod from "hooks/useShippingMethod";
import LayoutWrapper  from "features/layout/wrapper";

function getSteps() {
  return [
    "Selecciona el tipo de tranferencia",
    "Sube el comprobante y escribe el número de transacción",
  ];
}

const Uploader = (props) => {
  const classes = paymentStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { order_slug } = props;
  const inputFile = useRef(null);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [errorImage, setErrorImage] = useState(false);
  const [numTran, setNumTran] = useState("");
  const [errorNumTran, setErrorNumTran] = useState(false);
  const [failUpload, setfailUpload] = useState("");

  const fileChange = (event) => {
    setFilesToUpload([...filesToUpload, event.target.files[0]]);
    event.target.value = null;
  };

  const openSelectFile = () => {
    inputFile.current.click();
  };

  const numTranChange = (event) => {
    setNumTran(event.target.value);
  };

  const submitFiles = () => {
    let submit = true;

    if (numTran) {
      setErrorNumTran(false);
    } else {
      submit = false;
      setErrorNumTran(true);
    }
    if (!filesToUpload.length) {
      submit = false;
      setErrorImage(true);
    } else {
      setErrorImage(false);
    }
    const formData = new FormData();
    formData.append("numTran", numTran);
    formData.append("order_slug", order_slug);

    filesToUpload.forEach((file) => {
      formData.append("file", file);
    });
    if (submit) {
      dispatch(
        updateVoucher(
          formData,
          () => {
            dispatch(updateStep(2));
            dispatch(
              _setCartItems({
                coupons: [],
                products: [],
              })
            );
            history.push(`/thanks`);
            dispatch(updateStep(0));
          },
          (error) => {
            setfailUpload(error);
          }
        )
      );
    }
  };

  return (
    <>
      <div className={classes.uploadFilesWrap}>
        <div className={classes.uploadFilesButtonWrap}>
          <input
            type="file"
            id="file"
            accept="image/pdf/*"
            ref={inputFile}
            style={{ display: "none" }}
            onChange={fileChange}
          />
          <AddIcon
            onClick={openSelectFile}
            style={{
              height: "48px",
              width: "48px",
              borderRadius: "50px",
              background: "#E0E0E0",
              color: "#FFF",
              cursor: "pointer",
              margin: "0 auto",
            }}
          />
          <p className={classes.filesListHeading}>
            Adjunta el comprobante de transferencia
          </p>
          <ul className={classes.filesList}>
            {filesToUpload.length > 0
              ? filesToUpload.map((file, index) => (
                  <li key={index}>
                    <img src={product_1} alt="image_product" />
                    <span>{file.name}</span>
                    <DeleteIcon
                      style={{ color: "#53CD94" }}
                      onClick={() =>
                        setFilesToUpload(
                          filesToUpload.filter((_file, i) => i !== index)
                        )
                      }
                    />
                  </li>
                ))
              : null}
          </ul>
        </div>
      </div>
      {errorImage ? (
        <span className={clsx(classes.errorColor, classes.errorImagen)}>
          Por favor agregue su comprobante de pago
        </span>
      ) : (
        ""
      )}
      <Grid container item xs={12} spacing={3}>
        <div className={classes.transactionNumber}>
          <label className={classes.label}>
            Ingrese el número de transacción
          </label>
          <input
            type={"text"}
            placeholder={"Ingrese el número de transacción"}
            className={classes.input}
            value={numTran}
            onChange={numTranChange}
          />
          {errorNumTran ? (
            <span className={clsx(classes.errorColor, classes.errorLabel)}>
              Por favor ingrese el número de transacción.
            </span>
          ) : (
            ""
          )}
        </div>
      </Grid>
      {failUpload ? (
        <span className={clsx(classes.errorColor, classes.errorLabel)}>
          {failUpload}
        </span>
      ) : (
        ""
      )}
      <Button
        variant="contained"
        onClick={() => submitFiles()}
        size="medium"
        color="primary"
        className={clsx(classes.button, classes.wireTransfer_button)}
      >
        Enviar comprobante
      </Button>
    </>
  );
};

const RadioButtonComp = (props) => {
  const classes = paymentStyles();
  const dispatch = useDispatch();
  const { failProcess } = props;
  const [typeTranfer, setTypeTranfer] = useState("1");
  const [interTranfer, setInterTranfer] = useState("1");
  // const [failProcess, setfailProcess] = useState("");

  const handleWireTransfer = (event) => {
    setTypeTranfer(event.target.value);
  };

  const handleInterTransfer = (event) => {
    setInterTranfer(event.target.value);
  };

  const submitTransferData = () => {
    const transferData = {
      tType: parseInt(typeTranfer),
      subType: parseInt(interTranfer),
    };
    dispatch(saveTransferData(transferData));
  };

  return (
    <Grid container item xs={12} spacing={3}>
      <div className={classes.transactionNumber}>
        <RadioGroup
          className={classes.typeTransferRadio}
          value={typeTranfer}
          onChange={handleWireTransfer}
        >
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="Pagas desde una cuenta BBVA"
          />
          <div>
            <FormControlLabel
              value="2"
              control={<Radio/>}
              label="Transferencia Interbancaria"
            />
            {typeTranfer === "2" ? (
              <RadioGroup
                className={classes.interTranferRadio}
                value={interTranfer}
                onChange={handleInterTransfer}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Inmediata"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="Por Horario"
                />
              </RadioGroup>
            ) : (
              ""
            )}
          </div>
        </RadioGroup>
        {failProcess ? (
          <span className={clsx(classes.errorColor, classes.errorLabel)}>
            {failProcess}
          </span>
        ) : (
          ""
        )}
        <Button
          variant="contained"
          onClick={() => submitTransferData()}
          size="medium"
          color="primary"
          className={clsx(classes.button, classes.wireTransfer_button)}
        >
          Guardar
        </Button>
      </div>
    </Grid>
  );
};

export default function Payment(props) {
  const classes = paymentStyles();
  const dispatch = useDispatch();

  const slugValue = useSelector(orderSlug);
  const errorTransferVal = useSelector(errorTransfer);
  const discountValue = useSelector(getDiscount);
  const estevedoreCostValue = useSelector(estevedoreCost);
  const payTokenValue = useSelector(payToken);
  const loadingValue = useSelector(loading);
  const shipDeliveryValue = useSelector(shippingCost);
  const activeStepValue = useSelector(activeStep);

  const [selectedOpen, setSelectedOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("1");
  const [timerId, setTimerId] = useState(null);
  const [failLoad, setFailLoad] = useState("");

  const { shipMethod, cartData } = useShippingMethod();

  const steps = getSteps();

    // GET CART LIST
  // METHOD: GET
  // ENDPOINT: checkout/cart/
  useEffect(() => {
    dispatch(_getCartItems());
  }, [dispatch]);

  useEffect(() => {
    if (shipMethod === "1") {

      dispatch(getDeliveryCost());

    } else {
      const deliveryData = {
        updateDelivery: 0,
        updateStevedore: 0,
      };
      dispatch(updateShippingCost(deliveryData));
    }
  }, [dispatch, shipMethod]);

  const selectIzyMethod = () => {
    dispatch(createPayment((callback)=>{
      setFailLoad(callback);
    }));
    setSelectedOpen(!selectedOpen);

    const timeout = setTimeout(() => {
      setSelectedOpen(selectedOpen);
    }, parseInt(process.env.REACT_APP_PAYMENT_MODAL_TIME));
    setTimerId(timeout);
  };

  const onClose = () => {
    setSelectedOpen(false);
    clearTimeout(timerId);
  };

  const handleChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  return (
    <LayoutWrapper>
      <div className={classes.container}>
        <Typography
          variant="h2"
          component="h5"
          gutterBottom
          className={classes.pageTitle}
        >
          Pago <span>( {cartData.length} Producto )</span>
        </Typography>
        <AppStepper {...props} stepNumber={2} />
      </div>
      <Grid
        container
        spacing={3}
        style={{ flexDirection: "row", justifyContent: "space-evenly" }}
      >
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <Paper className={classes.paper}>
            <RadioGroup value={paymentMethod} onChange={handleChange}>
              <Grid
                container
                spacing={2}
                className={clsx(classes.summaryAccordeon)}
              >
                <div style={{ display: "flex" }}>
                  <FormControlLabel value="1" control={<Radio />} />
                  <p>Tarjeta crédito o débito</p>
                </div>
              </Grid>
              <Grid
                style={{ margin: "1rem auto" }}
                className={paymentMethod !== "1" ? classes.show : ""}
              >
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={classes.button}
                  onClick={selectIzyMethod}
                >
                  Proceder con el pago
                </Button>
                <PaymentGateway
                  open={selectedOpen}
                  payToken={payTokenValue}
                  loading={loadingValue}
                  close={onClose}
                  failLoad={failLoad}
                />
              </Grid>

              <Grid container spacing={2} className={classes.summaryAccordeon}>
                <div style={{ display: "flex" }}>
                  <FormControlLabel value="2" control={<Radio />} />
                  <p>Transferencia Bancaria</p>
                </div>
                <div>
                  <img src={BBVA} alt="BBVA-CARD" />
                  <img src={BCP} alt="BCP-CARD" />
                </div>
              </Grid>

              <Grid
                style={{ margin: "0.5rem 0" }}
                className={paymentMethod !== "2" ? classes.show : ""}
              >
                <section className={clsx(classes.wireTransferSection)}>
                  <div className={classes.wireTransfer_account}>
                    <p>Banco Continental - BBVA</p>
                    <p>Nombre: JW TECHNOLOGIES SAC | ruc: 20604427151</p>
                    <p>
                      Cuenta corriente en soles | número 0011-0354-0100037268
                    </p>
                  </div>
                  <div className={classes.wireTransfer_account}>
                    <p>Banco de Crédito del Perú - BCP</p>
                    <p>Nombre: JW TECHNOLOGIES SAC | ruc: 20604427151</p>
                    <p>
                      Cuenta corriente en soles | número 193-9620364-0-92
                    </p>
                    <p>
                      CCI corriente en soles | número 002-19300962036409213
                    </p>
                  </div>

                  <div className={classes.wireTransfer_account}>
                    <p>
                      1. Realiza la transferencia y adjunta el comprobante de
                      pago.
                    </p>
                  </div>
                  <div className={classes.wireTransfer_account}>
                    <p>
                      2. Enviaremos el detalle de tu compra al correo. También
                      podrás rastrear tu envío desde la sección “mis pedidos”.
                    </p>
                  </div>
                  <div className={classes.wireTransfer_account}>
                    <p>
                      3. Tu pedido no se procesará hasta que se haya recibido el
                      importe en nuestra cuenta.
                    </p>
                  </div>
                  <Stepper activeStep={activeStepValue} orientation="vertical">
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                          {index === 0 ? (
                            <RadioButtonComp failProcess={errorTransferVal} />
                          ) : (
                            <Uploader order_slug={slugValue} />
                          )}
                          <div className={classes.actionsContainer}>
                            <div>
                              {/* <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.button}
                              >
                                Back
                              </Button> */}
                              {/* <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className={classes.button}
                              >
                                {activeStep === steps.length - 1
                                  ? "Finish"
                                  : "Next"}
                              </Button> */}
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </section>
              </Grid>
            </RadioGroup>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <OrderSummary
            cartData={cartData}
            price={shipDeliveryValue}
            discount={discountValue}
            estevedore={estevedoreCostValue}
            shipMethod={shipMethod}
          />
        </Grid>
      </Grid>
    </LayoutWrapper>
  );
}
