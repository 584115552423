/*-- Libraries --*/
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
    CircularProgress,
    ListItem,
    ListItemText,
    Popover,
    withStyles,
    List,
    Paper,
    InputBase
  } from "@material-ui/core";

/*-- Redux --*/
import { isloading, search, list, close } from "./Slice";
import { getRelatedProducts } from "features/product/common/filter/Slice";

/*-- styles,components,functions --*/
import { searchStyles } from "./styles";

// import { searchStyles } from "./styles";

const StyledBadge = withStyles({
  paper: {
    marginLeft: "17px",
  },
})(Popover);

export default function Search(props) {
  const classes = searchStyles();
  const dispatch = useDispatch();


  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dropDown, setDropDown] = React.useState(false);
  const [searchText, changeText] = React.useState("");
  const _isloading = useSelector(isloading);
  const _list = useSelector(list);

  React.useEffect(() => {
    const dropDownOpen = _list.length && searchText.length >= 1 ? true : false;
    setDropDown(dropDownOpen);
  }, [_list, searchText.length]);

  const handleClick = (event) => {

    const word = event.target.value;
    const firstWordChar = word.charAt(0).toUpperCase() + word.slice(1);
    changeText(firstWordChar);
    if (word.length >= 1) {
      dispatch(search(firstWordChar));
      setAnchorEl(event.currentTarget);
    } else {
        dispatch(getRelatedProducts({ category: '', product: '', variety: '' }));
      dispatch(close());
    }
  };

  const _handleClose = () => {
    // setAnchorEl(null);
    dispatch(close());
  };

  const selectProduct = (selectedProduct) => {

    setDropDown(false);
    dispatch(getRelatedProducts({ category: parseInt(selectedProduct.category_id), product: parseInt(selectedProduct.product_id), variety: parseInt(selectedProduct.variety_id)}));
    // if (location.pathname === "/"){
    //   history.push("/", { selectedProduct });
    // }else{
    //   history.push("/mercado", { selectedProduct });
    // }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div className={classes.title}>
        <div className={classes.search} style={{ margin: "0 auto" }}>
          <InputBase
            placeholder="Busca el producto que necesitas"
            value={searchText}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            // inputProps={{'aria-label': 'search'}}
            onChange={handleClick}
            // aria-describedby={id}
          />
          <div
            className={classes.searchIcon}
            aria-describedby={id}
            onClick={handleClick}
          >
            {_isloading > 0 && searchText.length >= 1 ? (
              <CircularProgress
                style={{ height: "24px", width: "24px" }}
              />
            ) : (
              <SearchIcon />
            )}
          </div>
        </div>
        <StyledBadge
          disableAutoFocus={true}
          disableEnforceFocus={true}
          id={id}
          open={dropDown}
          anchorEl={anchorEl}
          onClose={_handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{ marginLeft: "10px", maxHeight: "300px" }}
        >
          <Paper
            style={{ width: "481px", padding: "25px", borderRadius: "12px" }}
          >
            <ClickAwayListener onClickAway={_handleClose}>
              {_isloading > 0 ? (
                <>Cargando</>
              ) : (
                <List component="nav">
                  {_list.length > 0 ? (
                    _list.map((product, index) => (
                      <ListItem
                        button
                        className={classes.textWrap}
                        key={index}
                        onClick={() => selectProduct(product)}
                      >
                        <ListItemText
                          className={classes.text}
                          primary={product.name}
                        />
                      </ListItem>
                    ))
                  ) : (
                    <>No products found</>
                  )}
                </List>
              )}
            </ClickAwayListener>
          </Paper>
        </StyledBadge>
      </div>
    </>
  );
}
