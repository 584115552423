import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { registrationEP } from "endpoints";

export const registrationMethodSlice = createSlice({
    name: "registrationData",
    initialState: {
        incomeRange: [],
        arrivalM: [],
        locations: [],
        location: "",
        district: "",
        districts: [],
        range: "",
        arrival:"",
    },
    reducers: {
        setLocations: (state, action) => {
            state.locations = action.payload.locations;
            state.incomeRange = action.payload.incomeRange;
            state.arrivalM = action.payload.arrivalM;
        },
        setLocation: (state, action) => {
            state.location = action.payload;
            if (state.location && state.location >= 0){
                state.districts = state.locations[state.location].districts;
            }else{
                state.districts = []
            }
        },
        setDistrict: (state,action) => {
            state.district = action.payload;
        },
        setRange: (state,action) => {
            state.range = action.payload;
        },
        setArrival: (state,action) => {
            state.arrival = action.payload;
        },
       
    }

});
export const {
    setLocations,
    setLocation,
    setDistrict,
    setRange,
    setArrival,
} = registrationMethodSlice.actions;

export const locations = (state) => state.registrationData.locations;
export const location = (state) => state.registrationData.location;
export const districts = (state) => state.registrationData.districts;
export const district = (state) => state.registrationData.district;
export const incomeRange = (state) => state.registrationData.incomeRange;
export const range = (state) => state.registrationData.range;
export const arrivalM = (state) => state.registrationData.arrivalM;
export const arrival = (state) => state.registrationData.arrival;

export const getLocations = (action) => async (dispatch) => {
    const data = await axios.get(registrationEP).then((response) => {
        return response.data;
    });
    dispatch(setLocations(data));
};

export const submitForm = (action, success) => async () => {
    await axios.post(registrationEP, action).then((response) => {
        success(response.data);
    });
};


export const updateProvince = (action) => (dispatch) => {
    dispatch(setLocation(action));
};
export const updateDistrict = (action) => (dispatch) => {
    dispatch(setDistrict(action));
};
export const updateIncomeRange = (action) => async (dispatch) => {
    dispatch(setRange(action));
};
export const updateArrival = (action) => async (dispatch) => {
    dispatch(setArrival(action));
}
export default registrationMethodSlice.reducer;


