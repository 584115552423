import React  from 'react';
import clsx from 'clsx';
import NativeSelect from '@material-ui/core/NativeSelect';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { DropdownStyles } from './styles';

export default function Dropdown( props ) {

  const classes = DropdownStyles();
  const extraClass = false;
  const data = props.data;
  const width = typeof props.width != 'undefined' ? props.width : '92px';

  return (
    <NativeSelect value={props.value} id="grouped-native-select" IconComponent = {ExpandMoreIcon} className={clsx( classes.block1, extraClass && props.extraClass )} style={{ width: width, height: '30px' }} onChange={props.onChnage}>
      <option value="">{ data.name }</option>
      {
        props.name === 'category' ?
            data.data.map( ( item ) => (
                <option value={ item.id } key={item.id}> { item.description } </option>
            )) : ''

      }

      {
        props.name === 'product' ?
            data.data.map( ( item, index ) => (
                <option value={ item.id } key={ item.id }> { item.description } </option>
            )) : ''

      }

      {

        props.name === 'variation' ?
            data.data.map( ( item ) => (
                <option value={ item.id } key={item.id}> { item.description } </option>
            )) : ''

      }

      {

        props.name === 'grade' ?
            data.data.map ( ( item ) => (
                <option  value={ item.id } key={item.id}> { item.description} </option>
            ) ) : ''

      }

    </NativeSelect>
  )

}
