/*-- Libraries --*/
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid,Typography, Paper } from "@material-ui/core";

/*-- redux --*/
import TradedGoodCard from "features/offersSldier/tradedGoodCard";

/*-- styles,components,functions --*/
import { myProductsStyles } from './styles';
import LayoutWrapper from "features/layout/wrapper";
import { getSliderOffers, sliderOffer, loadingOffer } from "pages/Dashboard/Slice";
import LoadingData from "features/loader";
export default function MyProducts ( props ) {

    const classes = myProductsStyles();
    const dispatch = useDispatch();
    const _offers = useSelector ( sliderOffer );
    const _loadingSlideOffer = useSelector( loadingOffer );

    useEffect(() => {
        // if ( _offers.length === 0  ) {
            dispatch(getSliderOffers())
        // }
       
    }, [dispatch])

    return (
        <LayoutWrapper>
            <div className={classes.container}>
                <Typography variant="h2" component="h5" gutterBottom className={classes.pageTitle}>
                    Índice de bienes comercializados
                </Typography>
            </div>
            <Grid container spacing={3} id={'by-seller-slider'}>
            {  _loadingSlideOffer? ( Array.from(new Array(6)).map((el, index) => (
                        <Grid item xs={12} sm={6} md={3} lg={4}>
                            <Paper className = {classes.paper}>
                                <LoadingData key={index} />
                            </Paper>
                        </Grid>
                ))): _offers.map ( ( product, index ) => (
                <Grid item xs={12} sm={6} md={3} lg={4} key={index}>
                    <TradedGoodCard  _product={product} key={index} {...props} />
                </Grid>
            )) }
            </Grid>
        </LayoutWrapper>
    )
}