import { makeStyles } from '@material-ui/core/styles';

export const productListItemStyles = makeStyles((theme) => ({
  paper: {
    // padding: "24px 20px 10px 24px",
    borderTopLeftRadius: "25px !important",
    borderTopRightRadius: "25px !important",
    borderBottomLeftRadius: "0px !important",
    borderBottomRightRadius: "0px !important",
    fontSize: "12px",
    boxShadow: "none",
    border: "2px solid #E6E7E8",
    borderBottom: "0px",
    minHeight: "430px",
  },
  block1: {
    display: "flex",
    justifyContent:"space-between",
    width: "100%",
    alignItems:"center"
  },
  block2A: {
    width: "20px",
    height: "20px",
    borderRadius: "4px",
    background: "#E0E0E0",
  },
  block2B: {
    background: "#53CD94",
    width: "20px",
    height: "20px",
    borderRadius: "4px",
  },
  block2C: {
    background: "#F2c94c",
    width: "20px",
    height: "20px",
    borderRadius: "4px",
  },
  block3: {
    fontSize: "20px",
    color: "#FFF",
    "& a": {
      color: "#006D51",
    },
    "& a:visited": {
      color: "#006D51",
    },
  },
  block4: {
    fontSize: "14px",
    color: "#808285",
    textAlign: "right",
    flexGrow: 1,
    alignSelf: "center",
    margin: "0 auto",
  },
  block5: {
    width: '100%',
    borderTopLeftRadius: '23px !important',
    borderTopRightRadius: '23px !important'
  },
  block6: {
    textAlign: "left",
    textTransform: "uppercase",
    color: "#006d51",
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "11.85px",
    "& a": {
      color: "#006D51",
      textDecoration: "none",
      "&:hover": {
        boxShadow: "none",
        color: "#53CD94",
      },
    },
    "& a:visited": {
      color: "#006D51",
    },
  },
  block7: {
    textAlign: "left",
    color: "#333",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "26px",
    marginTop: "10px",
    marginBottom: "0px",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  block8: {
    textAlign: "left",
    color: "#808285",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "14.22px",
    marginTop: "10px",
  },
  block9: {
    flexGrow: 1,
  },
  block10: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "21.33px",
    marginBottom: "5px",
    color:"#f2994A",
  },
  block10a:{
    color:"#333"
  },
  block11: {
    textAlign: "right",
    marginLeft: "0.5rem",
    "& b":{
      fontWeight: 700,
      fontSize:"18px",

    },
  },
  block12: {
    width: "100%",
    boxShadow: "none",
    background: "#006D51",
    color: "#FFF",
    height: "51.33px",
    borderRadius: "0px 0px 25px 25px",
    fontSize: "18px",
    lineHeight: "21.33px",
    fontWeight: 500,
    "&:hover": {
      boxShadow: "none",
      background: "#4BB985",
    },
  },
  visited: {
    textDecoration: "none",
    "&:visited": {
      textAlign: "left",
      textTransform: "uppercase",
      color: "#53CD94",
      fontSize: "10px",
      fontWeight: 500,
      lineHeight: "11.85px",
      textDecoration: "none",
    },
  },
}));
