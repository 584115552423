/*-- Libraries --*/
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Paper,
  withStyles,
  NativeSelect,
  Button,
  InputBase,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

/*-- redux --*/
import {
  fullNameUpdate,
  phoneUpdate,
  emailUpdate,
  updateDocument,
  updateDocNumber,
  updateName,
  updateRucNumber,
  updatebusinessName,
  updateAddress,
  updateBillingType,
  fullName,
  phone,
  email,
  docNumber,
  typeDocument,
  billTo,
  ruc,
  businessName,
  address,
  documentList,
  billingType,
  getDocumentsType,
  submitBillData,
  getBillData,
} from "./checkoutSlice";
import {
  estevedoreCost,
  shippingCost,
  getDeliveryCost,
  updateShippingCost,
} from "pages/ShipMethod/slice";
import { getDiscount,_getCartItems } from "../Cart/cartSlice";

/*-- styles,components,functions --*/
import { checkOutStyles } from "./styles";
import AppStepper from "features/Stepper";
import OrderSummary from "features/checkout/orderSummary";
import useShippingMethod from "hooks/useShippingMethod";
import LayoutWrapper  from "features/layout/wrapper";

const BootstrapInput = withStyles((theme) => ({
  root: {
    width: "100%",
    "label + &": {},
  },
  input: {
    borderRadius: 4,
    position: "relative",
    width: "100%",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "7.5px 7px 7.5px 15px",
    backgroundColor: "#F1F2F2",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#53CD94",
      outlineColor: "#53CD94",
    },
  },
}))(InputBase);

export default function CheckOut(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const discountValue = useSelector(getDiscount);
  const estevedoreCostValue = useSelector(estevedoreCost);
  const fullNameValue = useSelector(fullName);
  const phoneValue = useSelector(phone);
  const emailValue = useSelector(email);
  const docNumberValue = useSelector(docNumber);
  const typeDocumentValue = useSelector(typeDocument);
  const billToValue = useSelector(billTo);
  const rucValue = useSelector(ruc);
  const businessNameValue = useSelector(businessName);
  const addressValue = useSelector(address);
  const documentsListValues = useSelector(documentList);
  const billingTypeValue = useSelector(billingType);
  const shipDeliveryValue = useSelector(shippingCost);
  // const shippingMethod = useSelector(shipMethod);
  //const shipCostValue = useSelector(shipCost);

  const classes = checkOutStyles();
  const [errorFullName, setErrorFullName] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorDocNumber, setErrorDocNumber] = useState(false);
  const [errorBillTo, setErrorBillTo] = useState(false);
  const [errorRuc, setErrorRuc] = useState(false);
  const [errorBusinessName, setErrorBusinessName] = useState(false);
  const [errorAddress, setErrorAddress] = useState(false);

  const { shipMethod,cartData} = useShippingMethod();

  // GET CART LIST
  // METHOD: GET
  // ENDPOINT: checkout/cart/
  useEffect(() => {
    dispatch(_getCartItems());
  }, [dispatch]);

  useEffect(() => {
    if (shipMethod === "1") {

      dispatch(getDeliveryCost());

    } else {
      const deliveryData = {
        updateDelivery: 0,
        updateStevedore: 0,
      };
      dispatch(updateShippingCost(deliveryData));
    }
  }, [dispatch, shipMethod]);

  useEffect(() => {
    dispatch(getDocumentsType());
    dispatch(getBillData());
  }, [dispatch]);

  const handleChange = (event) => {
    dispatch(updateBillingType(event.target.value));
  };

  const fullNameChange = (event) => {
    dispatch(fullNameUpdate(event.target.value));
  };
  const phoneChange = (event) => {
    const re = /^9([0-9]{1,8})?$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      dispatch(phoneUpdate(event.target.value));
    }
  };
  const emailChange = (event) => {
    dispatch(emailUpdate(event.target.value));
  };
  const documentChange = (event) => {
    dispatch(updateDocument(event.target.value));
  };
  const docNumberChange = (event) => {
    //TO DO : CHECK RE TEST
    const re = /^[0-9\b]+.?([1-9]{1,2})?$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      dispatch(updateDocNumber(event.target.value));
    }
  };
  const nameChange = (event) => {
    const re = /^[a-zA-Z\s]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      dispatch(updateName(event.target.value));
    }
  };
  const rucNumberChange = (event) => {
    //TO DO : CHECK RE TEST
    const re = /^[0-9\b]+.?([1-9]{1,2})?$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      dispatch(updateRucNumber(event.target.value));
    }
  };
  const businessNameChange = (event) => {
    dispatch(updatebusinessName(event.target.value));
  };
  const addressChange = (event) => {
    dispatch(updateAddress(event.target.value));
  };

  const submitForm = () => {
    let submit = true;
    let submitInvoice = true;
    let submitBill = true;

    if (fullNameValue == null || fullNameValue === "") {
      submit = false;
      setErrorFullName(true);
    } else {
      setErrorFullName(false);
    }
    if (phoneValue == null || phoneValue === "") {
      submit = false;
      setErrorPhone(true);
    } else {
      setErrorPhone(false);
    }
    if (emailValue == null || emailValue === "") {
      submit = false;
      setErrorEmail(true);
    } else {
      setErrorEmail(false);
    }
    if (docNumberValue == null || docNumberValue === "") {
      submitBill = false;
      setErrorDocNumber(true);
    } else {
      setErrorDocNumber(false);
    }
    if (billToValue == null || billToValue === "") {
      submitBill = false;
      setErrorBillTo(true);
    } else {
      setErrorBillTo(false);
    }
    if (rucValue == null || rucValue === "") {
      submitInvoice = false;
      setErrorRuc(true);
    } else {
      setErrorRuc(false);
    }
    if (businessNameValue == null || businessNameValue === "") {
      submitInvoice = false;
      setErrorBusinessName(true);
    } else {
      setErrorBusinessName(false);
    }
    if (addressValue == null || addressValue === "") {
      submitInvoice = false;
      setErrorAddress(true);
    } else {
      setErrorAddress(false);
    }

    if (submit && submitInvoice && billingTypeValue === "2") {
      const info = {
        billType: parseInt(billingTypeValue),
        fullName: fullNameValue,
        phone: phoneValue,
        email: emailValue,
        ruc: rucValue,
        businessName: businessNameValue,
        address: addressValue,
      };
      submitBillData(info, (data) => {
        if (data) {
          history.push("/pago");
        }
      });
    }
    if (submit && submitBill && billingTypeValue === "1") {
      const info = {
        billType: parseInt(billingTypeValue),
        fullName: fullNameValue,
        phone: phoneValue,
        email: emailValue,
        docNumber: docNumberValue,
        typeDocument: parseInt(typeDocumentValue),
        billTo: billToValue,
      };
      submitBillData(info, (data) => {
        if (data) {
          history.push("/pago");
        }
      });
    }
  };

  return (
    <LayoutWrapper>
      <div className={classes.container}>
        <Typography
          variant="h2"
          component="h5"
          gutterBottom
          className={classes.pageTitle}
        >
          Pago <span>( {cartData.length} Producto )</span>
        </Typography>
        <AppStepper {...props} stepNumber={2} />
      </div>
      <Grid container spacing={3} style={{ flexDirection: "row" }}>
        <Grid item xs={12} sm={12} md={8}>
          <Paper className={classes.paper}>
            <Typography
              variant="h2"
              component="h5"
              gutterBottom
              className={classes.pageSectionTitle}
            >
              Información de pago
            </Typography>
            <Grid container spacing={3} style={{ padding: "2rem 0" }}>
              <Grid item xs={12} sm={6}>
                <label className={classes.label}>Nombre Completo</label>
                <input
                  type={"text"}
                  placeholder={"Nombre"}
                  value={fullNameValue}
                  onChange={fullNameChange}
                  className={classes.input}
                />
                {errorFullName ? (
                  <span className={classes.errorLabel}>
                    Por favor ingrese su nombre completo.
                  </span>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={classes.label}>Teléfono móvil</label>
                <input
                  type={"text"}
                  placeholder={"Ej. 956785768"}
                  value={phoneValue}
                  onChange={phoneChange}
                  className={classes.input}
                />
                {errorPhone ? (
                  <span className={classes.errorLabel}>
                    Por favor ingrese su número móvil.
                  </span>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <label className={classes.label}>Correo electrónico</label>
                <input
                  type={"text"}
                  placeholder={"Ingresa tu correo electrónico"}
                  value={emailValue}
                  onChange={emailChange}
                  className={classes.input}
                />
                {errorEmail ? (
                  <span className={classes.errorLabel}>
                    Por favor ingrese su correo electrónico.
                  </span>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Typography
              variant="h2"
              component="h5"
              gutterBottom
              className={classes.pageSectionTitle}
            >
              Información de facturación
            </Typography>
            <Grid container spacing={3} style={{ padding: "2rem 0" }}>
              <Grid item xs={12} sm={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    className={classes.radioGroup}
                    value={billingTypeValue}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Boleta"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="Factura"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {billingTypeValue === "1" ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <label className={classes.label}>Documento</label>
                    <NativeSelect
                      input={<BootstrapInput />}
                      value={typeDocumentValue}
                      onChange={documentChange}
                    >
                      <option value="">Selecciona el documento</option>
                      {documentsListValues.map((doc) => (
                        <option value={doc.id} key={doc.id}>
                          {doc.document}
                        </option>
                      ))}
                    </NativeSelect>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label className={classes.label}>Número</label>
                    <input
                      type={"text"}
                      placeholder={"Ingrese el N° de su documento"}
                      value={docNumberValue}
                      onChange={docNumberChange}
                      className={classes.input}
                    />
                    {errorDocNumber ? (
                      <span className={classes.errorLabel}>
                        Por favor ingrese el número del documento.
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <label className={classes.label}>Nombre</label>
                    <input
                      type={"text"}
                      placeholder={"Ingrese su nombre"}
                      value={billToValue}
                      onChange={nameChange}
                      className={classes.input}
                    />
                    {errorBillTo ? (
                      <span className={classes.errorLabel}>
                        Por favor ingrese su nombre.
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6}>
                    <label className={classes.label}>RUC</label>
                    <input
                      type={"text"}
                      placeholder={"Ingrese el N° de RUC"}
                      value={rucValue}
                      onChange={rucNumberChange}
                      className={classes.input}
                    />
                    {errorRuc ? (
                      <span className={classes.errorLabel}>
                        Por favor ingrese su número de RUC.
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label className={classes.label}>Razón Social</label>
                    <input
                      type={"text"}
                      placeholder={"Nombre legal de la empresa"}
                      value={businessNameValue}
                      onChange={businessNameChange}
                      className={classes.input}
                    />
                    {errorBusinessName ? (
                      <span className={classes.errorLabel}>
                        Por favor ingrese su razón social.
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <label className={classes.label}>Dirección fiscal</label>
                    <input
                      type={"text"}
                      placeholder={"ej.Av Minerales 234"}
                      value={addressValue}
                      onChange={addressChange}
                      className={classes.input}
                    />
                    {errorAddress ? (
                      <span className={classes.errorLabel}>
                        Por favor ingrese su dirección fiscal.
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                variant="contained"
                onClick={() => submitForm()}
                //component={Link}
                size="medium"
                color="primary"
                className={classes.button}
              >
                CONTINUAR
              </Button>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <OrderSummary
            cartData={cartData}
            price={shipDeliveryValue}
            discount={discountValue}
            estevedore={estevedoreCostValue}
            shipMethod={shipMethod}
          />
        </Grid>
      </Grid>
    </LayoutWrapper>
  );
}
