/*-- Libraries --*/
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import {
    Typography,
    Button,
    Paper,
    Grid,
    CircularProgress,
    NativeSelect,
    withStyles,
    InputBase,
    DialogActions,
    DialogTitle,
    DialogContent
} from "@material-ui/core";
// import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

/*-- redux --*/
import {
    getCategories,
    getProductList,
    getVarietyList,
    setVarieties,
    categories,
    products,
    varieties,
    loading,
    category,
    product,
    variety,
    grade,
    mature,
    updateOffer,
    updateCategory,
    updateProduct,
    updateVerity,
    updateGrade,
    submitProduct,
    files,
    getFile,
    updateFiles,
    deleteFile,
    updateOffers,
    getGraph,
    graph,
    updateMature,
    cleanFiles,
} from "../Slice";

/*-- styles,components,functions --*/
import { newOfferStyles } from "./styles";
import product_1 from "assets/images/image-gallery.svg";
import TableComponent from "features/userOffers/table";
import ModalComponent from "features/modal";
import JustCropper from "features/cropper";
import LayoutWrapper from "features/layout/wrapper";
import LoadingData from "features/loader";

const BootstrapInput = withStyles((theme) => ({
    root: {
        width: "100%",

        "label + &": {},
    },
    dialog: {
        position: "absolute",
        left: 10,
        top: 50,
    },
    input: {
        borderRadius: 4,
        position: "relative",
        width: "100%",
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "7.5px 7px 7.5px 15px",
        backgroundColor: "#F1F2F2",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderRadius: 4,
            borderColor: "#53CD94",
            outlineColor: "#53CD94",
        },
    },
}))(InputBase);

const UpLoader = withStyles(() => ({
    root: {
        height: "20px !important",
        width: "20px !important",
        color: "#53CD94 !important",
    },
}))(CircularProgress);

export default function NewOffer(props) {
    const classes = newOfferStyles();
    const dispatch = useDispatch();
    const inputFile = useRef(null);
    const _categories = useSelector(categories);
    const _products = useSelector(products);
    const _varieties = useSelector(varieties);
    //   const _grades = useSelector(grades);
    //const _loading = useSelector(loading);
    const _loadingGraph = useSelector(loading);
    const _category = useSelector(category);
    const _product = useSelector(product);
    const _variety = useSelector(variety);
    const _grade = useSelector(grade);
    const files_ = useSelector(files);
    const _mature = useSelector(mature);
    const _graph = useSelector(graph);
    const history = useHistory();

    const [grades, setGrades] = useState([]);
    const [measureUnit, setMeasureUnit] = useState("");
    const [matureList, setMatureList] = useState([]);
    const [price, setPrice] = useState("");
    const [amount, setAmount] = useState("");
    const [unit, setUnit] = useState("");
    const [description, setDescription] = useState("");
    const [_files] = useState([files_]);
    const [errorGrade, setErrorGrade] = useState(false);
    const [errorMature, setErrorMature] = useState(false);
    const [errorPrice, setErrorPrice] = useState(false);
    const [errorAmount, setErrorAmount] = useState(false);
    const [errorShort, setErrorShort] = useState(false);
    const [errorLong, setErrorLong] = useState(false);
    const [errorImage, setErrorImage] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    // const [readyToUpdate, setReadyToupdate] = useState(false);
    // const [readyProps, setReadyProps] = useState(false);
    const [failUpdate, setFailUpdate] = useState("");

    const [open, setOpen] = useState(false);
    const [upImg, setUpImg] = useState();
    const [croppedPhoto, setcroppedPhoto] = useState("");
    const [imageName, setimageName] = useState("");
    const { state } = useLocation();
    const dataToUpdate = state;

    const categoryChange = (event) => {
        dispatch(updateCategory(event.target.value));
    };
    const productChange = (event) => {
        dispatch(updateProduct({ product: event.target.value, status: 0 }));
    };

    useEffect(() => {
        async function createOffer() {
            if (!dataToUpdate) {
                dispatch(updateCategory(''));
                dispatch(updateProduct({ product: '', status: 0 }));
                dispatch(updateVerity(''));
                dispatch(updateGrade(''));
                dispatch(updateMature(''));
                dispatch(setVarieties([]));
                setGrades([]);
                setMatureList([]);
                await dispatch(getCategories());
                dispatch(cleanFiles());
                setIsDisabled(false);
                setPrice("");
                setAmount("");
                setUnit("");
                setDescription("");
            }
            // await setReadyToupdate(true);
        }
        createOffer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, dataToUpdate]);

    useEffect(() => {

        if (!dataToUpdate && _category) {

            dispatch(getProductList({ id: _category }));
            dispatch(updateProduct({ product: '', status: 0 }));
            dispatch(updateVerity(''));
            dispatch(updateGrade(''));
            dispatch(updateMature(''));
            dispatch(setVarieties([]));
            setGrades([]);
            setMatureList([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_category, dispatch]);

    useEffect(() => {

        if (!dataToUpdate && _product) {

            dispatch(getVarietyList({ id: _product, maduration: true }));
            dispatch(updateVerity(''));
            dispatch(updateGrade(''));
            dispatch(updateMature(''));
            setGrades([]);
            setMatureList([]);

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_product, dispatch]);

    // useEffect(() => {
    //     if ( !dataToUpdate) {
    //       dispatch(getVarietyList({ id:_product }))
    //     }
    // }, [_category, dispatch]);

    const priceChange = (event) => {
        const re = /^[0-9\b]{1,3}\.?([0-9]{1,2})?$/;
        if (event.target.value === "" || re.test(event.target.value)) {
            setPrice(event.target.value);
        }
    };



    useEffect(() => {
        if (dataToUpdate) {

            updateOffer({ id: dataToUpdate.id }, (response) => {

                setGrades(response.grades);
                setMatureList(response.maduration);
            });
            dispatch(getFile({ id: dataToUpdate.id }));

            setIsDisabled(true);
            setPrice(dataToUpdate.price);
            setAmount(parseInt(dataToUpdate.quantity));
            dispatch(updateVerity(dataToUpdate.product_id));
            // dispatch(updateProduct({ product: indexProduct, status: 1 }));
            dispatch(updateMature(parseInt(dataToUpdate.maduration)));
            dispatch(updateGrade(parseInt(dataToUpdate.grade_id)));
            setUnit(dataToUpdate.short_description);
            setDescription(dataToUpdate.long_description);
            // dispatch(updateGrade(indexGrade));
            // setReadyProps(true);
            // setReadyToupdate(true);
        }
        // }
    }, [dispatch, dataToUpdate, _products,]);

    useEffect(() => {

        if (_variety && _grade) {
            dispatch(getGraph({ verity: _variety, grade: _grade }));
        }
    }, [_category, _grade, _variety, dispatch]);


    const ref = React.useRef();
    useEffect(() => {
        ref.current = files_;
    }, [_files, files_]);

    const findItemById = (array, value) => {
        const filteredData = array.filter(item => (
            item.id === parseInt(value)
        ))
        return filteredData;
    };

    const verityChange = (event) => {

        dispatch(updateVerity(event.target.value));

        setGrades(findItemById(_varieties, event.target.value)[0].grades);
        setMeasureUnit(findItemById(_varieties, event.target.value)[0].measure_unit);
        setMatureList(findItemById(_varieties, event.target.value)[0].maduration);
    };

    const gradeChange = (event) => {
        dispatch(updateGrade(event.target.value));
    };

    const matureChange = (event) => {
        dispatch(updateMature(event.target.value));
    };

    const amountChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === "" || re.test(event.target.value)) {
            setAmount(event.target.value);
        }
    };

    const unitChange = (event) => {
        setUnit(event.target.value);
    };

    const descriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const fileChange = (e) => {

        if (e.target.files && e.target.files.length > 0) {
            setimageName(e.target.files[0].name);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                var img = new Image();
                img.onload = function () {
                    if (img.width >= 300 && img.height >= 200) {
                        setOpen(!open);
                        setUpImg(reader.result);
                    } else {
                        alert("Las dimensiones de la foto debe ser de 300x200 como mínimo, intenta con otra foto");
                    }
                };
                img.src = reader.result;
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleSubmit = () => {
        let formData = new FormData();

        if (!dataToUpdate) {
            formData.append("image", croppedPhoto);
            formData.append("id", 0);
            dispatch(updateFiles(formData, files_, () => {
                setUpImg("");
                setcroppedPhoto("");
            }));
        } else {
            formData.append("image", croppedPhoto);
            formData.append("id", dataToUpdate.id);
            dispatch(updateFiles(formData, files_, () => {
                setUpImg("");
                setcroppedPhoto("");
            }));
        }


        setOpen(!open);
        inputFile.current.value = "";
    };

    const openSelectFile = () => {
        inputFile.current.click();
    };

    const onSubmit = async () => {
        let submit = true;

        if (!_grade) {
            submit = false;
            setErrorGrade(true);
        } else {
            setErrorGrade(false);
        }
        if (!_mature) {
            submit = false;
            setErrorMature(true);
        } else {
            setErrorMature(false);
        }

        if (!price) {
            submit = false;
            setErrorPrice(true);
        } else {
            setErrorPrice(false);
        }

        if (!amount) {
            submit = false;
            setErrorAmount(true);
        } else {
            setErrorAmount(false);
        }

        if (!unit) {
            submit = false;
            setErrorShort(true);
        } else {
            setErrorShort(false);
        }

        if (!description) {
            submit = false;
            setErrorLong(true);
        } else {
            setErrorLong(false);
        }

        if (files_.length > 0) {
            setErrorImage(false);
        } else {
            submit = false;
            setErrorImage(true);
        }
        const uploadingFiles = files_.filter((el) => el.id === "").length >= 1;

        if (!dataToUpdate && submit && !uploadingFiles) {
            const data = {
                product: _variety,
                grade: parseInt(_grade),
                price: price,
                quantity: parseInt(amount),
                short_description: unit,
                long_description: description,
                maduration: parseInt(_mature),
            };
            await submitProduct(data, (succes) => {
                props.handleOpen("success", succes.detail);
                history.push("/ofertas");
            });
        }
        if (dataToUpdate && submit && !uploadingFiles) {
            const data = {
                id: dataToUpdate.id,
                price: price,
                quantity: amount,
                short_description: unit,
                long_description: description,
                grade: _grade,
                maduration: parseInt(_mature),
            };
            updateOffers(
                data,
                () => {
                    props.handleOpen("success", "Se actualizó la data");
                    history.push("/ofertas");
                },
                (fail) => {
                    setFailUpdate(fail);
                }
            );
        }
    };

    const onCancelUpdate = () => {
        history.push("/ofertas");
    };
    const closeModal = () => {
        setOpen(!open);
        inputFile.current.value = "";
    };

    return (
        <LayoutWrapper>
            <div className={classes.container}>
                <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.pageTitle}
                >
                    {dataToUpdate ? "Actualizar oferta" : "Publicar oferta"}
                </Typography>
            </div>
            <Grid container>
                <Grid item xs={12} sm={12} md={7} className={classes.paddingRight}>
                    <Paper className={classes.paper}>
                        <Typography
                            variant="h2"
                            component="h5"
                            gutterBottom
                            className={classes.subHeading}
                        >
                            Selecciona un producto
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                <label className={classes.label}>Categoría</label>
                                {dataToUpdate ?
                                    <input
                                        type={"text"}
                                        // placeholder={"Ingresa un monto"}
                                        className={classes.input}
                                        // onChange={priceChange}
                                        value={dataToUpdate.category}
                                        disabled={isDisabled}
                                    /> :
                                    <NativeSelect
                                        id="demo-customized-select-native"
                                        input={<BootstrapInput />}
                                        value={_category}
                                        onChange={categoryChange}
                                        disabled={isDisabled}
                                    >
                                        <option value=""> Selecciona categoría </option>
                                        {_categories.map((category) => (
                                            <option value={category.id} key={category.id}>
                                                {category.description}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <label className={classes.label}>Producto</label>
                                {
                                    dataToUpdate ?
                                        <input
                                            type={"text"}
                                            // placeholder={"Ingresa un monto"}
                                            className={classes.input}
                                            // onChange={priceChange}
                                            value={dataToUpdate.product_name}
                                            disabled={isDisabled}
                                        /> :
                                        <NativeSelect
                                            id="demo-customized-select-native"
                                            input={<BootstrapInput />}
                                            value={_product}
                                            onChange={productChange}
                                            disabled={isDisabled}
                                        >
                                            <option value=""> Selecciona el producto </option>
                                            {_products.map((product) => (
                                                <option value={product.id} key={product.id}>
                                                    {product.description}
                                                </option>
                                            ))}
                                        </NativeSelect>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <label className={classes.label}>Variedad</label>
                                {dataToUpdate ?
                                    <input
                                        type={"text"}
                                        // placeholder={"Ingresa un monto"}
                                        className={classes.input}
                                        // onChange={priceChange}
                                        value={dataToUpdate.product_description}
                                        disabled={isDisabled}
                                    /> :
                                    <NativeSelect
                                        id="demo-customized-select-native"
                                        input={<BootstrapInput />}
                                        value={_variety}
                                        onChange={verityChange}
                                        disabled={isDisabled}
                                    >
                                        <option value=""> Selecciona la variedad</option>
                                        {_varieties.map((product) => (
                                            <option value={product.id} key={product.id}>
                                                {product.description}
                                            </option>
                                        ))}
                                    </NativeSelect>}
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <label className={classes.label}>
                                    ¿A qué precio quieres vender?
                                </label>
                                <input
                                    type={"text"}
                                    placeholder={"Ingresa un monto"}
                                    className={classes.input}
                                    onChange={priceChange}
                                    value={price}
                                />
                                {errorPrice ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor ingresa el precio
                                    </span>
                                ) : (
                                    ""
                                )}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <label className={classes.label}>Cantidad</label>
                                <input
                                    type={"text"}
                                    placeholder={"Cantidad"}
                                    className={classes.input}
                                    onChange={amountChange}
                                    value={amount}
                                />
                                {errorAmount ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor ingresa una cantidad
                                    </span>
                                ) : (
                                    ""
                                )}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <label className={classes.label}>Medida</label>
                                <input
                                    type={"text"}
                                    placeholder={
                                        dataToUpdate
                                            ? dataToUpdate.product_unit
                                            : measureUnit
                                    }
                                    className={classes.input}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <label className={classes.label}>Calibre</label>
                                <NativeSelect
                                    id="demo-customized-select-native"
                                    input={<BootstrapInput />}
                                    onChange={gradeChange}
                                    value={_grade}
                                >
                                    <option value=""> Selecciona el calibre </option>
                                    {grades.map((grade) => (
                                        <option value={grade.id} key={grade.id}>
                                            {grade.description}
                                        </option>

                                    ))}
                                </NativeSelect>
                                {errorGrade ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Selecciona el calibre
                                    </span>
                                ) : (
                                    ""
                                )}
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <label className={classes.label}>
                                    ¿Qué tan madura está tu fruta?
                                </label>
                                <NativeSelect
                                    id="demo-customized-select-native"
                                    input={<BootstrapInput />}
                                    onChange={matureChange}
                                    value={_mature}
                                >
                                    <option value=""> Selecciona el nive de maduración </option>
                                    {matureList.map((mature, index) => (
                                        <option value={mature.id} key={mature.id}>
                                            {mature.description}
                                        </option>
                                    ))}
                                </NativeSelect>
                                {errorMature ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Selecciona el nivel de maduración
                                    </span>
                                ) : (
                                    ""
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <label className={classes.label}>Descripción corta</label>
                                <textarea
                                    maxLength={140}
                                    placeholder={"Describe brevemente el producto en oferta"}
                                    className={classes.input}
                                    style={{ height: "auto" }}
                                    rows={4}
                                    onChange={unitChange}
                                    value={unit}
                                ></textarea>
                                <small
                                    className={classes.label}
                                    style={{ marginTop: "12px", fontSize: "10px" }}
                                >
                                    Máximo 140 caracteres. Sugerimos insertar un texto de rápida
                                    lectura (1 línea), ya que aparecerá en las tarjetas de ofertas.
                                </small>
                                {errorShort ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor ingresa la descripción corta
                                    </span>
                                ) : (
                                    ""
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <label className={classes.label}>Descripción</label>
                                <textarea
                                    placeholder={
                                        "Ingresa las características adicionales del producto"
                                    }
                                    maxLength={500}
                                    className={classes.input}
                                    style={{ height: "auto" }}
                                    rows={6}
                                    onChange={descriptionChange}
                                    value={description}
                                ></textarea>
                                <small
                                    className={classes.label}
                                    style={{ marginTop: "12px", fontSize: "10px" }}
                                >
                                    Máximo 500 caracteres.
                                </small>
                                {errorLong ? (
                                    <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                        Por favor ingresa la descripción
                                    </span>
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>
                        <div className={classes.uploadFilesWrap}>
                            <div className={classes.uploadFilesButtonWrap}>
                                <input
                                    type="file"
                                    id="file"
                                    ref={inputFile}
                                    style={{ display: "none" }}
                                    onChange={fileChange}
                                />
                                <AddIcon
                                    onClick={openSelectFile}
                                    style={{
                                        height: "48px",
                                        width: "48px",
                                        borderRadius: "50px",
                                        background: "#E0E0E0",
                                        color: "#FFF",
                                        cursor: "pointer",
                                        margin: "0 auto",
                                    }}
                                />
                                <ModalComponent
                                    openModal={open}
                                    onClose={closeModal}
                                >
                                    <DialogTitle id="alert-dialog-title">{"Establece el área de recorte de la imagen"}</DialogTitle>
                                    <DialogContent>
                                        <JustCropper
                                            aspect={4 / 3}
                                            width={150}
                                            upImg={upImg}
                                            imgName={imageName}
                                            cropImage={setcroppedPhoto}
                                            // handleSubmit={handleSubmit}
                                            {...props}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        {
                                            croppedPhoto ?
                                                <Button style={{ color: "#53cd94" }} onClick={handleSubmit}>
                                                    Seleccionar la foto
                                                </Button>
                                                : < LoadingData />
                                        }

                                    </DialogActions>
                                </ModalComponent>
                                <span className={classes.filesListHeading}>
                                    Agrega las imágenes del producto. Máximo 5MB por imagen
                                </span>
                                <span className={classes.filesListHeading}>
                                    Agrega hasta 5 fotos como máximo.
                                </span>
                                <Files
                                    toUpdate={dataToUpdate}
                                    files={files_}
                                    {...props}
                                    //fileRemove={fileRemove}
                                    error={errorImage}
                                />
                            </div>
                        </div>
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            className={classes.subimt}
                            onClick={onSubmit}
                        >
                            {dataToUpdate ? "ACTUALIZAR OFERTA" : "PUBLICAR OFERTA"}
                        </Button>
                        {failUpdate ? (
                            <span className={clsx(classes.errorColor, classes.errorLabel)}>
                                {failUpdate}
                            </span>
                        ) : (
                            ""
                        )}
                        {dataToUpdate ? (
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                className={classes.subimt}
                                onClick={onCancelUpdate}
                            >
                                CANCELAR
                            </Button>
                        ) : (
                            ""
                        )}
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={5} className={classes.paddingLeft}>
                    <Paper className={classes.paper}>
                        <Typography
                            variant="h2"
                            component="h5"
                            gutterBottom
                            className={classes.subHeading}
                            style={{ fontWeight: 400 }}
                        >
                            Indice de bienes comercializados
                        </Typography>
                        <>
                            <span
                                style={{
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    lineHeight: "14.22px",
                                    color: "#808285",
                                    marginTop: "39px",
                                }}
                            >
                                Datos históricos
                            </span>
                            <TableComponent _graph={_graph} _loading={_loadingGraph} />
                        </>
                    </Paper>
                </Grid>
            </Grid>
        </LayoutWrapper>
    );
}

function Files(props) {
    const dispatch = useDispatch();
    const classes = newOfferStyles();
    const files = props.files;

    return (
        <>
            <ul className={classes.filesList}>
                {files.map((file, index) => (
                    <li key={file.id}>
                        <img
                            src={file.url === "Uploading..." ? product_1 : file.url}
                            alt={file.url
                                .replace(/^.*[\\/]/, "")
                                .substring(0, 15)
                                .padEnd(15, "-")}
                        />
                        <span>
                            {file.url
                                .replace(/^.*[\\/]/, "")
                                .substring(0, 15)
                                .padEnd(15, "-")}
                        </span>
                        {file.uploading !== undefined && file.uploading !== 100 ? (
                            <UpLoader value={file.uploading} variant="determinate" />
                        ) : (
                            <>
                                {file.deleting ? (
                                    <UpLoader />
                                ) : (
                                    <DeleteIcon
                                        style={{ color: "#53CD94" }}
                                        onClick={() =>
                                            dispatch(deleteFile({ key: index, data: file }))
                                        }
                                    />
                                )}
                            </>
                        )}
                    </li>
                ))}
            </ul>
            {props.error ? (
                <span className={clsx(classes.errorColor, classes.errorLabel)}>
                    Por favor agrega una imagen
                </span>
            ) : (
                ""
            )}
        </>
    );
}
