import {auhtorStyles} from './styles';
import Paper from "@material-ui/core/Paper";
import React from "react";

import image from "assets/images/image_placeholder.svg";

import StarOutlineIcon from '@material-ui/icons/StarOutline';
import StarIcon from '@material-ui/icons/Star';
import PlaceIcon from '@material-ui/icons/Place';

export default function Author( props ) {

    const classes   = auhtorStyles ();
    const user      = props.user;
    const rating    = ( parseFloat ( user.rating ) * 100 ) / 5;

    return (
        <>
            <Paper className={classes.paper}>
                {
                    user.backgound !== '' ? <img src={user.background} alt={user.name} className={classes.profileImage} /> : <img src={image} alt={user.name} className={classes.profileImage} />
                }
                <div className={classes.contentWrap}>
                    <img src={user.avatar} alt={''} className={classes.logoImage} />
                    <div className={classes.ratingWrap}>
                        <div className={classes.ratingTop} style={{width: rating + '%'}}>
                            <span><StarIcon /></span>
                            <span><StarIcon /></span>
                            <span><StarIcon /></span>
                            <span><StarIcon /></span>
                            <span><StarIcon /></span>
                        </div>
                        <div className={classes.ratingBottom}>
                            <StarOutlineIcon />
                            <StarOutlineIcon />
                            <StarOutlineIcon />
                            <StarOutlineIcon />
                            <StarOutlineIcon />
                        </div>
                    </div>
                    <h2 className={classes.authorName}> { user.name } </h2>
                    <span className={classes.authorInfo} style={{flexDirection: 'inherit'}}> <span style={{margin: '0 auto', alignItems: 'center', display: 'flex'}}><PlaceIcon style={{marginRight: '5px', color: '#53CD94'}} />{ user.address }</span> </span>
                    <p className={classes.aboutAuther}>{ user.description }</p>
                    {/* <EmailIcon style={{color: '#53CD94', margin: '0 auto', display: 'flex', marginTop: '10px'}} /> */}
                </div>
            </Paper>
        </>
    );
}