/*-- Libraries --*/
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  AppBar,
  Tabs,
  Tab,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Paper,
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SwipeableViews from "react-swipeable-views";
import { useTheme, withStyles } from "@material-ui/core/styles";
import LayoutWrapper from "features/layout/wrapper";
import { helpStyles } from "./styles";

/*-- styles,components,functions --*/
// import { helpStyle } from "pages/Help/style";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #53cd94",
  },
  indicator: {
    backgroundColor: "#53CD94",
  },
})(Tabs);

export default function FullWidthTabs() {
  const classes = helpStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <LayoutWrapper>
      <Paper className={classes.paper}>
        <div className={classes.container}>
          <Typography
            variant="h2"
            component="h5"
            gutterBottom
            className={classes.pageTitle}
          >
            Preguntas Frecuentes
          </Typography>
        </div>
        <Divider />
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <AntTabs
              value={value}
              onChange={handleChange}
              // indicatorColor="primary"
              // textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
              className={classes.slideBox}
            >
              <Tab className={classes.slide} label="Compras" {...a11yProps(0)} />
              <Tab className={classes.slide} label="Registro" {...a11yProps(1)} />
              <Tab
                className={classes.slide}
                label="Sugerencia"
                {...a11yProps(2)}
              />
            </AntTabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel
              value={value}
              index={0}
              dir={theme.direction}
              className={classes.container2}
            >
              <Accordion>
                <AccordionSummary
                  className={classes.boxAcordion}
                  expandIcon={<ExpandMoreIcon />}
                  arial-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    ¿Cómo realizo una compra?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ol type="1" className={classes.answer}>
                      <li>
                        Cuando busques un producto, lee la descripción consignada
                        por el vendedor y verifica sea la variedad, calibre y
                        maduración que deseas.{" "}
                      </li>
                      <li>
                        Si ya encontraste el producto que estabas buscando,
                        selecciona la cantidad y agrégalo al carrito de compras
                        haciendo clic en “Agregar al Carrito”.{" "}
                      </li>
                      <li>
                        Una vez que hayas agregado todos los productos de tu
                        interés, ingresa a tu carrito, verifica el resumen de tu
                        compra y haz clic en continuar.{" "}
                      </li>
                      <li>
                        Selecciona cualquiera de las dos opciones para el método
                        de envío: (i) retirar tu compra en el mercado
                        correspondiente o (ii) contratar los servicios de
                        transporte de carga Whipay. En este último caso, verifica o
                        modifica la dirección donde recibirás los bienes/productos
                        adquiridos.{" "}
                      </li>
                      <li>
                        En Datos de Facturación, elige si deseas boleta o factura.
                        Recuerda llenar todos los campos requeridos.
                      </li>
                      <li>
                        Elige el método de pago y haz clic en “Confirmar pedido”.
                      </li>
                      <li>
                        Finalmente, recibirás una confirmación de tu compra al
                        correo electrónico registrado en tu cuenta, mediante el
                        cual podrás ver el detalle y el estado de tu compra.
                        También puedes revisar el módulo “Mis Compras”.{" "}
                      </li>
                    </ol>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  className={classes.boxAcordion}
                  expandIcon={<ExpandMoreIcon />}
                  arial-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    ¿Qué métodos de pago tienen disponibles?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.answer}>
                    Por tu seguridad y comodidad ponemos a tu disposición los
                    siguientes medios de pagos:
                    <ol type="1" className={classes.answer}>
                      <li>Tarjeta de Crédito o Débito</li>
                      <Typography className={classes.answer}>
                        Con esta opción puedes pagar utilizando tus tarjetas Visa,
                        MasterCard, American Express y Diners Club Internacional,
                        de manera fácil y segura. Una vez que hayas realizado el
                        pago con tu tarjeta, recibirás un correo de confirmación
                        de compra. Te recomendamos que, antes de realizar un pago
                        con tu tarjeta de crédito o débito, te asegures que la
                        misma se encuentre habilitada para transacciones por
                        internet.
                      </Typography>

                      <li>Transferencia bancaria</li>
                      <Typography className={classes.answer}>
                        Accede directamente a la plataforma de tu entidad bancaria
                        de preferencia y realiza la transferencia a la cuenta BBVA
                        o BCP de Whipay (JW TECHNOLOGIES S.A.C.). Acto seguido,
                        deberás adjuntar el comprobante de tu pago para que
                        nosotros lo validemos a la brevedad. El tiempo de
                        validación de la transferencia, dependerá si el método de
                        transferencia fue realizado de manera inmediata o por
                        horarios.
                      </Typography>
                    </ol>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  className={classes.boxAcordion}
                  expandIcon={<ExpandMoreIcon />}
                  arial-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    ¿Entregan facturas por mi compra?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.answer}>
                    Todos los mayoristas que venden a través de Whipay adjuntan
                    sus facturas o boletas requeridas por el usuario en la
                    plataforma. Podrás descargar las facturas de tus compras,
                    desde la página “ver detalles” en Mis compras.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  className={classes.boxAcordion}
                  expandIcon={<ExpandMoreIcon />}
                  arial-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    ¿Cuáles son los horarios para hacer pedido?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.answer}>
                    Recibimos pedidos a través de nuestra plataforma, las 24 horas
                    del día y los 7 días de la semana, incluyendo feriados. Sin
                    embargo, aquellos pedidos validados luego de la 1:00 pm y se
                    haya seleccionado envío por delivery, se entregarán hasta 48
                    horas posteriores a dicha validación.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  className={classes.boxAcordion}
                  expandIcon={<ExpandMoreIcon />}
                  arial-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    ¿Puedo retirar mis compras directamente en el mercado?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.answer}>
                    Sí, puedes retirar tus compras directamente en el puesto de
                    mercado mostrando tu comprobante de compra digital o impreso
                    al encargado de cada puesto. Este último verificará el número
                    de orden y te hará la entrega de tu pedido.
                    <Typography className={classes.answer}>
                      Por tratarse de bienes frescos, tienes un plazo máximo de 48
                      horas para retirar los productos en el mercado
                      correspondiente, de lunes a domingos desde 5am hasta la 1pm.
                    </Typography>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  className={classes.boxAcordion}
                  expandIcon={<ExpandMoreIcon />}
                  arial-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    ¿Cuándo recibiré mis compras con envío a domicilio?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.answer}>
                    <p>
                      Los pedidos validados, de lunes a domingos hasta las 13:00
                      horas, serán entregados al día siguiente. Todos los pedidos
                      validados después de las 13:00 horas, serán despachados
                      dentro de las 48 horas siguientes.{" "}
                    </p>
                    <p>
                      Ten en cuenta que las compras pagadas por transferencia
                      interbancaria pueden tardar hasta 72 horas para verificar el
                      pago y proceder a realizar el envío.{" "}
                    </p>
                    <p>
                      Los transportistas contratados para el despacho, solo
                      realizarán la entrega del pedido en la puerta principal de
                      local comercial, planta o edificio. No está autorizado a
                      ingresar al domicilio.{" "}
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  className={classes.boxAcordion}
                  expandIcon={<ExpandMoreIcon />}
                  arial-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    ¿A cuáles distritos hacen envíos?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.answer}>
                    Contamos con cobertura completa de distritos en Lima
                    metropolitana y Callao.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  className={classes.boxAcordion}
                  expandIcon={<ExpandMoreIcon />}
                  arial-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    ¿Cuál es el proceso de devolución del monto pagado?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.answer}>
                    <p>
                      Luego que el reembolso es aprobado y ejecutado, el tiempo de
                      procesamiento varía según el método de pago usado.
                    </p>
                    <p>
                      Para una compra con tarjeta de crédito, débito o métodos que
                      permitan la devolución del dinero a través de una cuenta
                      asociada, se hará el reverso a la tarjeta o a la cuenta
                      asociada.
                    </p>
                    <p>
                      Para métodos por depósito bancario o pagos en efectivo, se
                      hará un depósito bancario.
                    </p>
                    <p>Tiempos de procesamiento: </p>
                    <p>
                      <ul>
                        <li>
                          Reverso a la tarjeta: El tiempo del reembolso a una
                          tarjeta puede ser hasta quince (15) días hábiles. El
                          tiempo de procesamiento es responsabilidad de la entidad
                          financiera que emitió la tarjeta y es contado desde la
                          ejecución del reembolso.
                        </li>
                        <li>
                          Depósito bancario: Para recibir el dinero en una cuenta
                          bancaria, el titular de la cuenta debe ser el mismo que
                          realizó la compra en https://www.whipay.pe. El tiempo de
                          procesamiento es de siete (7) días hábiles desde su
                          ejecución. La información bancaria proporcionada por el
                          cliente debe ser correcta para evitar retrasos en la
                          atención. De no ser así los tiempos de ejecución y
                          procesamiento se prolongarán.
                        </li>
                      </ul>
                    </p>
                    <p>Los datos necesarios son: </p>
                    <p>
                      <ul>
                        <li>Razón social/Denominación social</li>
                        <li>RUC</li>
                        <li>
                          Nombre y apellido del representante (si fuera el caso)
                        </li>
                        <li>
                          DNI, Carnet de extranjería o Pasaporte del representante
                        </li>
                        <li>Número de pedido</li>
                        <li>
                          Correo electrónico registrado en https://www.whipay.pe
                        </li>
                        <li>Datos de la cuenta bancaria</li>
                      </ul>
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  className={classes.boxAcordion}
                  expandIcon={<ExpandMoreIcon />}
                  arial-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Quiero reportar un problema con una compra ¿Qué debo hacer?{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.answer}>
                    Los canales para reportar un problema en el proceso de compra
                    son: vía email o por whatsapp. El equipo de atención al
                    cliente, una vez recibido el aviso, iniciará un ticket de
                    atención a efectos de atender -a la brevedad posible- cada
                    solicitud.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <Accordion>
                <AccordionSummary
                  className={classes.boxAcordion}
                  expandIcon={<ExpandMoreIcon />}
                  arial-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Quiero vender al por mayor ¿Cuáles son los requisitos y cómo
                    registrarme?{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.answer}>
                    Para iniciar tu proceso de inscripción en la plataforma como
                    usuario vendedor, por favor, llena el <a rel="noopener noreferrer" href="/formulario-de-registro">formulario de registro</a> y
                    nos comunicaremos contigo en un plazo máximo de 24 horas.
                    Tener en cuenta que, durante el proceso de inscripción, Whipay
                    podrá requerirte documentos certificados. Es importante
                    mencionar que, por el momento, solo registramos a usuarios que
                    tengan almacenes cerca o adentro de los principales mercados
                    mayoristas de Lima.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  className={classes.boxAcordion}
                  expandIcon={<ExpandMoreIcon />}
                  arial-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Quiero comprar al por mayor ¿Cómo hago para registrarme?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.answer}>
                    Puedes solicitar tu acceso llenando el <a rel="noopener noreferrer" href="/formulario-de-registro">formulario de registro</a>
                    y, tan pronto validemos la información brindada, te daremos de
                    alta como usuario comprador. Whipay podría requerirte
                    información y/o documentos adicionales.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  className={classes.boxAcordion}
                  expandIcon={<ExpandMoreIcon />}
                  arial-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    ¿Cómo modifico mis datos de registro?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.answer}>
                    Para modificar los datos de registro de su perfil de empresa
                    es necesario comunicarte con un asesor al correo{" "}
                    <a
                      href="mailto:clientes@whipay.pe?Subject=Info: WhiPay - Mercado Mayorista"
                      target="_top"
                    >
                      clientes@whipay.pe
                    </a>
                    .
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  className={classes.boxAcordion}
                  expandIcon={<ExpandMoreIcon />}
                  arial-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    ¿Cómo puedo cambiar o recuperar mi contraseña?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.answer}>
                    <p>
                      Si quieres recuperar tu contraseña o quieres cambiarla,
                      podrás hacerlo con los siguientes pasos:{" "}
                    </p>
                    <p>
                      <ol type="1">
                        <li>
                          Al iniciar sesión, selecciona la opción “¿Olvidaste tu
                          contraseña?”.
                        </li>
                        <li>
                          Escribe tu correo electrónico y presiona “solicitar
                          nueva contraseña” y te llegará un correo de
                          verificación.
                        </li>
                        <li>
                          Abre tu correo y pulsa la opción “Cambiar Contraseña”.
                        </li>
                        <li>
                          Escribe tu nueva contraseña. Recuerda que esta debe
                          contener, como mínimo, ocho caracteres, con al menos una
                          mayúscula y una minúscula.
                        </li>
                        <li>
                          Finalmente, presiona el botón “Continuar” para que
                          puedas iniciar sesión con tu nueva contraseña.{" "}
                        </li>
                      </ol>
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  className={classes.boxAcordion}
                  expandIcon={<ExpandMoreIcon />}
                  arial-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    No logro entrar a mi cuenta, ¿Qué debo hacer?{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.answer}>
                    <p>
                      Si tienes un problema con el acceso a tu cuenta, pese haber
                      restablecido tu contraseña, por favor, comunícate con
                      nosotros la correo{" "}
                      <a
                        href="mailto:clientes@whipay.pe?Subject=Info: WhiPay - Mercado Mayorista"
                        target="_top"
                      >
                        clientes@whipay.pe
                      </a>{" "}
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <Accordion>
                <AccordionSummary
                  className={classes.boxAcordion}
                  expandIcon={<ExpandMoreIcon />}
                  arial-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    ¿Qué puedo hacer si pierdo o me roban mi dispositivo donde
                    tengo acceso a Whipay?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.answer}>
                    <p>
                      Si pierdes tu teléfono o computador con las contraseñas
                      guardadas, te recomendamos ingresar desde otro dispositivo y
                      cambiar la clave de acceso lo antes posible, para evitar
                      cualquier compra o movimiento a tu nombre.{" "}
                    </p>
                    <p>
                      Recuerda que todos los movimientos de compra y venta
                      realizados en tu cuenta siempre se te notificaran a tu
                      correo electrónico. Si existe algún movimiento que no
                      reconozcas, por favor, comunícate con nosotros a{" "}
                      <a
                        href="mailto:clientes@whipay.pe?Subject=Info: WhiPay - Mercado Mayorista"
                        target="_top"
                      >
                        clientes@whipay.pe
                      </a>{" "}
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </TabPanel>
          </SwipeableViews>
        </div>
      </Paper>
    </LayoutWrapper>
  );
}
