import { CircularProgress, withStyles } from "@material-ui/core";
import React from "react";

const Loader = withStyles((theme) => ({
  root: {
    height: "30px !important",
    width: "30px !important",
    color: "#006D51 !important",
    marginTop: "30px",
  },
}))(CircularProgress);

export default function LoadingData() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "baseline",
        justifyContent: "center",
        margin: "auto",
      }}
    >
      <Loader style={{ margin: "0 1rem" }} />
      <span
        style={{ fontSize: "16px", fontWeight: 400, lineHeight: "16.79px" }}
      >
        Cargando...
      </span>
    </div>
  );
}
