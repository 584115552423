import { makeStyles } from '@material-ui/core/styles';

export const DropdownStyles = makeStyles((theme) => ({
  block1: {
    border: '2px solid #E0E0E0',
    borderRadius: '100px',
    background: '#E0E0E0',
    color: '#333333',
    height: '30px',
    '&:before': {
      border: 'none'
    },
    '&:after': {
      border: 'none'
    },
    '&:hover': {
      '&:before': {
        border: 'none !important'
      },
      '&:after': {
        border: 'none !important'
      }
    },
    '& select': {
      padding: '3px 15px',
      paddingRight: '40px !important',
      // textTransform: 'uppercase',
      fontSize: '13px',
      fontWeight: 400,
      height: '100%',
      '&:focus': {
        backgroundColor: 'transparent',
        borderRadius: '100px'
      },
    },
    '& > div': {
      paddingLeft: '15px !important',
      borderRadius: '100px',
    }
  }
}));
