import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { Drawer, Grid, List, Button } from "@material-ui/core";

import { isLogin } from "features/Auth/authSlice";
import { sidebarStatus, toggleSidebar } from "features/layout/layoutSlice";

import { sidebarStyles } from "./SidebarStyles";

import MainListItems from "./listItems";
import AppLogo from "features/layout/common/logo";

export default function AppSidebar(props) {
  const dispatch = useDispatch();
  const [isSeller, setIsSeller] = useState(false);

  const _sidebarStatus = useSelector(sidebarStatus);
  const sidebarClasses = sidebarStyles();
  const _isLogin = useSelector(isLogin);

  const loginUser = useSelector((state) => state.auth.client);

  useEffect(() => {
    if (loginUser) {
      if (
        // loginUser.user_category === "Normal" &&
        loginUser.can_publish === true
      ) {
        setIsSeller(true);
      }
    }
  }, [loginUser]);

  return (
    <>
      {!_isLogin ? (
        <></>
      ) : (
        <>
          <Drawer
            anchor="left"
            open={_sidebarStatus}
            onClose={() => dispatch(toggleSidebar())}
            classes={{
              paper: clsx(
                sidebarClasses.drawerPaper,
              ),
            }}
          >
            <AppLogo {...props} />
            <Grid
              container
              spacing={3}
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "25px",
                paddingBottom: "20px",
                justifyContent: "center",
              }}
            >
              {isSeller ? (
                <Grid
                  item
                  xs={10}
                  sm={6}
                  className={sidebarClasses.vendorButtonWrap}
                >
                  <Button
                    variant="contained"
                    onClick={() => dispatch(toggleSidebar())}
                    component={Link}
                    className={clsx(
                      sidebarClasses.sideBarButton,
                      sidebarClasses.vendorButton,
                      !_sidebarStatus && sidebarClasses.sideBarButtonHide
                    )}
                    to={"/publicar-oferta"}
                  >
                    VENDER
                  </Button>
                </Grid>
              ) : null}

              <Grid item xs={10} sm={6}>
                <Button
                  component={Link}
                  variant="contained"
                  onClick={() => dispatch(toggleSidebar())}
                  className={clsx(
                    sidebarClasses.sideBarButton,
                    sidebarClasses.compairButton,
                    !_sidebarStatus && sidebarClasses.sideBarButtonHide
                  )}
                  to="/mercado"
                >
                  COMPRAR
                </Button>
              </Grid>
            </Grid>
            <List>
              <MainListItems />
            </List>
          </Drawer>
        </>
      )}{" "}
    </>
  );
}
