/*-- Libraries --*/
import { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Typography,
  // Grid,
  Paper,
  //makeStyles,
  //Popover,
} from "@material-ui/core";

// import LocalShippingIcon from "@material-ui/icons/LocalShipping";
// import PhoneIcon from "@material-ui/icons/Phone";
import OrderStepper from "features/order/stepper";
//import ErrorIcon from "@material-ui/icons/Error";
// import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { getShipData } from "../slices";

/*-- styles,components,functions --*/
import { trackingStyles } from "./styles";
import LayoutWrapper from "features/layout/wrapper";


// const useStyles = makeStyles((theme) => ({
//   paper: {
//     padding: theme.spacing(1),
//     width: "200px",
//   },
// }));

export default function Tracking(props) {
  const classes = trackingStyles();
  //const classes1 = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  //const [anchorEl, setAnchorEl] = useState(null);
  const [shipDataValue, setshipData] = useState({});
  const orderSlug = props.match.params.orderSlug;
  
  // const handlePopoverOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handlePopoverClose = () => {
  //   setAnchorEl(null);
  // };

  useEffect(() => {
    getShipData({ order_slug: orderSlug }, (data) => {
      setshipData(data);
      setActiveStep(data.deliveryStatus);
    });
  }, [orderSlug]);

  //const open = Boolean(anchorEl);
  
  return (
    <LayoutWrapper>
      <div className={classes.container}>
        <Typography
          variant="h2"
          component="h5"
          gutterBottom
          className={classes.pageTitle}
        >
          Rastrear envío
        </Typography>
      </div>
      <Paper className={classes.paper}>
        <Typography
          variant="h2"
          component="h5"
          gutterBottom
          className={clsx(classes.statusTitle, classes.inProcessColor)}
          style={{ fontWeight: 400 }}
        >
          {shipDataValue.orderStatus}
        </Typography>
        {/* <span className={classes.subtitle}>
          Hora estimada de entrega 2:00 pm - 3:00 pm
          <ErrorIcon
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            className={classes.inProcessColor}
            style={{ margin: "0 1rem" }}
          />
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes1.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography className={classes.popoverText}>
              Los plazos de entrega estimados pueden cambiar debido al tráfico y
              otras condiciones.
            </Typography>
          </Popover>
        </span> */}
        <div className={classes.stepper}>
          <OrderStepper activeStep={activeStep} />
          <span style={{ marginBottom: "1rem" }}>
            {shipDataValue.shipStatus}
          </span>
        </div>
        {/* <Grid item xs container className={classes.driverInfoContainer}>
          {shipDataValue.driver ? (
            <>
              <div className={classes.driverInfoRow}>
                <LocalShippingIcon
                  className={clsx(classes.icon, classes.icons)}
                />
                <div className={classes.driverInfoRow1}>
                  <span className={classes.text}> {shipDataValue.driver}</span>
                  <span className={classes.text}>
                    {shipDataValue.truckDescription}
                  </span>
                </div>
              </div>

              <div className={classes.driverInfoRow}>
                <PhoneIcon className={clsx(classes.phoneIcon, classes.icons)} />
                <span className={classes.text}>
                  {shipDataValue.driverPhone}
                </span>
              </div>
            </>
          ) : (
            <Grid className={classes.box}>
   
                <HourglassEmptyIcon className={classes.icon1} />

                <Typography
                  className={classes.alert}
                  variant="h2"
                  component="h5"
                  gutterBottom
                >
                  Aún no se ha asignado transporte
                </Typography>
  
            </Grid>
          )}
        </Grid> */}
      </Paper>
    </LayoutWrapper>
  );
}
