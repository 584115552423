import React  from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, IconButton, Button } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import { toggleSidebar } from "features/layout/layoutSlice";
import ActionPop from "features/layout/common/actionPop";
import MiniCart from "features/layout/common/miniCart";
import NotificationPopup from "features/layout/common/notificationPopup";
import Search from "features/layout/common/search";

import logo_white from "assets/images/logo_white.png";
import logo_small from "assets/images/logo_small.svg";

import { headerStyles } from "./headerStyle";

export default function AppHeader(props) {
  const dispatch = useDispatch();
  const headerClasses = headerStyles();
  const loginCheck = localStorage.getItem("token");


  return (
    <>
      <AppBar position="fixed" className={clsx(headerClasses.appBar)}>
        <Toolbar className={headerClasses.toolbar}>
          {loginCheck ? (
            <IconButton
              aria-label="delete"
              title="Menú"
              className={clsx(headerClasses.menu, headerClasses.hideDesktop)}
              onClick={() => dispatch(toggleSidebar())}
            >
              <MenuIcon />
            </IconButton>
          ) : null}
          <Link to={"/mercado"}>
            <div className={headerClasses.logo}>
              <img
                src={logo_white}
                className={clsx(
                  headerClasses.largeLogo,
                  headerClasses.hideDesktop
                )}
                alt="logo"
              />
              <img
                src={logo_small}
                className={clsx(
                  headerClasses.smallLogo,
                  headerClasses.hideMobile
                )}
                alt="logo"
              />
            </div>
          </Link>
          <Search {...props} />
          {loginCheck ? (
            <div className={headerClasses.container}>
              {/* <Wallet {...props} /> */}
              <NotificationPopup {...props} />
              <MiniCart {...props} />
              <ActionPop {...props} />
            </div>
          ) : (
            <>
                <Button
                    variant="outlined"
                    color="primary"
                    component={Link}
                    to="/login"
                    // onClick={() => dispatch(loginPopupAction_(true))}
                    className={headerClasses.loginButton}
                >
                    INICIAR SESIÓN
                </Button>
            </>
          )}
          {loginCheck ? (
            <IconButton
              aria-label="delete"
              title="Menú"
              className={clsx(headerClasses.menu, headerClasses.hideMobile)}
              onClick={() => dispatch(toggleSidebar())}
            >
              <MenuIcon />
            </IconButton>
          ) : null}
        </Toolbar>
      </AppBar>
    </>
  );
}
