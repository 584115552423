import { makeStyles } from '@material-ui/core/styles';

export const listStyles= makeStyles((theme) => ({
  market_banner:{
    width:"100%"
  },
  market_banner_item: {
    width:"100%",
    position:"relative",
    "& button": {
        color:"#fff",
        background: "#F2994A",
        position: "absolute",
        top:"70%",left:"15%",
        textAlign:"center",
        transform: "translate(-50%, -50%)"
    }
  },
  market_banner_body:{
    margin:"1rem 3rem"
  },

  market_banner_body_category_item:{
      width:"100%",
  },
  category__img: {
    width: "100%",
    borderRadius:"12px",
    "&:hover": {
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      },
},
category__img_disabled: {
    opacity:0.5
}
}))
