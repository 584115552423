/*-- Libraries --*/
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { Link } from "react-router-dom";
import {
  Paper,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  InputBase,
  Grid,
  Divider,
  Typography
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Rating from "@material-ui/lab/Rating";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CheckIcon from "@material-ui/icons/Check";
//import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
//import ForumIcon from "@material-ui/icons/Forum";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

/*-- redux --*/
import {
  singleProduct,
  getSingleProduct,
  loadingOffer,
  // getRelatedProducts,
} from "./slice";
import { getGraph, graph, loadingGraph } from "pages/MyOffers/Slice";
import { user, sellerProductsList } from "pages/Seller/Slice";
import { products, getProductsBySeller } from "pages/Dashboard/Slice";
import {
  addToCart,
  // getErrorDetail,
  // getIsSuccess,
  // getId,
} from "pages/Cart/cartSlice";

/*-- styles,components,functions --*/
import { AppShopSingleStyles } from "./styles";
import Visa from "assets/images/Visa.svg";
import Master from "assets/images/Master.svg";
import Amex from "assets/images/Amex.svg";
import bbva from "assets/images/BBVA.svg";
import bcp from "assets/images/BCP.svg";
import MatureComponent from "features/maturityMeter";
import ProductSlider from "features/product/slider";
import CardProduct from "features/product/list/card";
import Loader from "features/loader";
import formatNumber from 'functions/regex';
import isNotUndefined from 'functions/reusableFunctions';

// const Loader = withStyles((theme) => ({
//   root: {
//     height: "50px !important",
//     width: "50px !important",
//     color: "#53CD94 !important",
//   },
// }))(CircularProgress);

function StyledRadio(props) {
  const classes = AppShopSingleStyles();
  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      style={{ display: "none" }}
      {...props}
    />
  );
}
export default function AppShopSingle(props) {
  const classes = AppShopSingleStyles();

  const dispatch = useDispatch();
  const productItem = useSelector(singleProduct);
  const loadingOfferValue = useSelector(loadingOffer);
  const sellerInfo = useSelector(user);
  const sellerProducts = useSelector(sellerProductsList);
  const recomendedProducts = useSelector(products);
  const _graph = useSelector(graph);
  const loadingGraphValue = useSelector(loadingGraph);
  // const errorDetailValue = useSelector(getErrorDetail);
  // const isSuccesValue = useSelector(getIsSuccess);
  // const idValue = useSelector(getId);

  const product = productItem.sale;
  const isSaleOwner = productItem.my_sale;

  const [qty, setQty] = useState(10);
  const [loading, setLoading] = useState(false);
  const [added, setAdded] = useState(false);
  // const [relatedProducts, setRelatedProducts] = useState([]);
  const [iscompleted, setiscompleted] = useState(false);
  const [isThereStock, setIsThereStock] = useState(false);
  // const [isSuccess, setIsSuccess] = useState(false);
  const [errorDetailValue, setErrorDetailValue] = useState("");

  const saleSlug = props.match.params.product_slug;

  const [image, setImage] = useState(0);
  const rating = parseInt(sellerInfo.rating);

  // const id = parseInt(saleSlug.substring(0, saleSlug.indexOf(":")));

  useEffect(() => {

    const abortController = new AbortController();
    const signal = abortController.signal;

    async function getProduct() {

      await dispatch(getSingleProduct(saleSlug, { signal: signal }));
      await setiscompleted(true);
      dispatch(getProductsBySeller({ signal: signal }));
    }

    getProduct();

    return () => {
        abortController.abort();
      };
  }, [dispatch, saleSlug]);


  useEffect(() => {
    if (iscompleted) {
      // getRelatedProducts({ product: product.product_name }, (data) => {
      //   setRelatedProducts(data);
      // });

      dispatch(
        getGraph({ verity: product.product_id, grade: product.grade_id })
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iscompleted, dispatch]);

  // useEffect(() => {
  //   if (isSuccesValue && id === idValue) {
  //     props.handleOpen("success", "Producto agregado exitosamente");
  //   }
  // }, [id, idValue, isSuccesValue, props]);

  useEffect(() => {
    if (product.quantity < 10) {
      setQty(product.quantity);
    } else {
      setQty(10);
    }
  }, [product.quantity]);

  const qty10 = qty === 10 ? true : false;
  const qty20 = qty === 20 ? true : false;
  const qty30 = qty === 30 ? true : false;

  const handleQtyChange = (qty) => {
    if (qty < product.quantity) {
      setQty(qty);
    } else {
      setIsThereStock(true);
      setTimeout(() => {
        setIsThereStock(false);
      }, 800);
    }
  };

  const handleQtyA = () => {
    if (qty < product.quantity) {
      setQty(qty + 1);
    } else {
      setIsThereStock(true);
      setTimeout(() => {
        setIsThereStock(false);
      }, 800);
    }
  };

  const handleQtyM = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };

  const success = () => {
    props.handleOpen("Success", "Se agregó exitosamente al carrito");
    // setIsSuccess(true);
  }
  
  const fail = (message) => {
    setErrorDetailValue(message);
  }  

  const submit = async () => {
    setLoading(true);
    await dispatch(addToCart({ id: product.id, quantity: qty }, success, fail));
    setLoading(false);
    setAdded(true);
  };

  const changeImage = (index) => {
    setImage(index);
  };
  const handleQuantity = (event) => {
    const value = parseInt(event.target.value);
    const validation = value <= product.quantity ? value : "";
    setQty(validation);
  };

  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 5,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: {
        max: 500,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 501,
      },
      items: 3,
    },
  };
  const responsive1 = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1501,
      },
      items: 4,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: {
        max: 1500,
        min: 464,
      },
      items: 3,
    },
  };
  const variablePrice = isNotUndefined(product.price);
  const averagePrice = isNotUndefined(_graph.average_price);
  const variation = isNotUndefined(_graph.price_variation);

  // const tags = ['Sin pepa', 'Dulce', 'Orgánica', 'Morocha'];

  return (
    <section className={classes.singlePage}>
      <Paper className={classes.paper}>
          <Grid container className={classes.singlePage__wrapper}>
            <Grid
              container
              item
              lg={6}
              md={6}
              sm={12}
              className={classes.images}
            >
              <div className={classes.images__bigImage}>
              {loadingOfferValue ? (
                <Loader />
              ) : typeof product.gallery[0] != "undefined" ? (
                <img
                  src={product.gallery[image]}
                  alt="Product 1"
                  className={classes.images__image}
                />
              ) : (
                <img
                  src={product.image}
                  alt="Product 1"
                  className={classes.images__image}
                />
              )}
              </div>
              <div className={classes.images__carousel}>
                {product.gallery.map((item, index) => (
                      <img
                        key={"img" + index}
                        src={item}
                        alt="Product 1"
                        className={classes.images__smallImage}
                        onClick={() => {
                          changeImage(index);
                        }}
                      />
                    ))}
              </div>

            </Grid>
            <Grid
              item
              lg={5}
              md={5}
              sm={12}
              className={classes.details}
            >
              {loadingGraphValue && loadingOfferValue ? (
                <Skeleton width="80%" />
              ) : (
                <span
                  className={clsx(
                    classes.details__variation,
                    _graph.price_variation > 0 && classes.iconBoxUp,
                    _graph.price_variation < 0 && classes.iconBoxDown,
                    _graph.price_variation === 0 && classes.iconBoxFlat
                  )}
                >
                  {_graph.price_variation > 0 ? (
                    <TrendingUpIcon className={classes.icon} />
                  ) : _graph.price_variation < 0 ? (
                    <TrendingDownIcon className={classes.icon} />
                  ) : (
                    <TrendingFlatIcon className={classes.icon} />
                  )}
                  PRECIO PROMEDIO S/ {formatNumber(averagePrice)} - VARIACIÓN -
                  S/ {variation.toFixed(2)}
                </span>
              )}
              <div className={classes.details__detail}>
                {loadingOfferValue ? (
                  <Skeleton width="80%" height="60px" />
                ) : (
                  <h1>
                    {product.product_description}
                  </h1>
                )}
                <div className={classes.details__characteristics}>
                  <div className={classes.details__characteristic}>
                    <span className={classes.singlePage__captions}> Punto de maduración </span>
                    {loadingOfferValue ? (
                      <Skeleton width="40%" />
                    ) : (
                      <MatureComponent
                        maduration={product.maduration}
                        maduration_description={product.maduration_description}
                      />

                    )}
                  </div>
                  <div className={classes.details__characteristic}>
                    <span className={classes.details__captions}> Calibre </span>
                    {loadingOfferValue ? (
                      <Skeleton width="40%" />
                    ) : (
                      <span className={classes.details__characteristic_mod}>{product.grade} </span>
                    )}
                  </div>
                </div>
                <Divider/>
                  {loadingOfferValue ? (
                    <Skeleton width="40%" />
                  ) : (
                    <Typography
                    variant="h2"
                    component="h2"
                    gutterBottom
                    className={classes.details__price}
                  >
                    <b>S/ {formatNumber(variablePrice)}</b> x {product.product_unit}
                  </Typography>
                  )}

                {loadingOfferValue ? (
                  <Skeleton width="30%" />
                ) : (
                  <span className={classes.details__captions}>
                    {product.equivalency_kg}
                  </span>
                )}
                {/* {loadingOfferValue ? (
                  <Skeleton width="90%" height="80px" />
                ) : (
                  <p className={classes.body2}>{product.long_description}</p>
                )} */}
              <Grid container className={classes.details__options} >
                <Grid item xs={12} sm={7}>
                  {/* <span className={classes.singlePage__captions}>Selecciona la cantidad</span> */}
                  {loadingOfferValue ? (
                    <Skeleton width="90%" height="80px" />
                  ) : (
                    <FormControl
                      component="fieldset"
                      className={classes.details__cartOptions}
                    >
                      <RadioGroup
                        value={qty}
                        aria-label="gender"
                        name="customized-radios"
                      >
                        <FormControlLabel
                          value="10"
                          control={<StyledRadio />}
                          label={`10 ${product.product_unit}`}
                          className={clsx(qty10 && classes.details__selected)}
                          onClick={() => {
                            handleQtyChange(10);
                          }}
                        />
                        <FormControlLabel
                          value="20"
                          control={<StyledRadio />}
                          label={`20 ${product.product_unit}`}
                          className={clsx(qty20 && classes.details__selected)}
                          onClick={() => {
                            handleQtyChange(20);
                          }}
                        />
                        <FormControlLabel
                          value="30"
                          control={<StyledRadio />}
                          label={`30 ${product.product_unit}`}
                          className={clsx(qty30 && classes.details__selected)}
                          onClick={() => {
                            handleQtyChange(30);
                          }}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                  {loadingOfferValue ? (
                    <Skeleton width="40%" />
                  ) : (
                    <span className={classes.details__stock}>
                      Stock disponible: {product.quantity} {product.product_unit}
                    </span>
                  )}
                <span className={clsx(classes.details__errorColor, classes.details__errorLabel)}>{isThereStock ? "Stock Insuficiente" : ""}</span>
                </Grid>
                <Grid item xs={12} sm={5}>
                  {/* <span className={classes.singlePage__captions}>Ingresa la cantidad</span> */}
                  {loadingOfferValue ? (
                    <Skeleton width="90%" height="80px" />
                  ) : (
                    <div className={classes.details__qtySelectWrap}>
                      <span className={classes.details__qtySelectIconWrap}>
                        <RemoveIcon
                          className={classes.details__qtySelectIcon}
                          onClick={handleQtyM}
                        />
                      </span>

                        <InputBase
                          value={qty}
                          className={classes.details__qtySelectIconWrap}
                          type="number"
                          inputProps={{ min: 10, max: 1000 }}
                          onChange={handleQuantity}
                          style={{
                            margin: "0px",
                            textAlign: "center",
                            lineHeight: "22px",
                          }}
                        />
                        <span>{product.product_unit}</span>

                      <span className={classes.details__qtySelectIconWrap}>
                        <AddIcon
                          className={classes.details__qtySelectIcon}
                          onClick={handleQtyA}
                        />
                      </span>
                    </div>
                  )}

                  {loadingOfferValue ? (
                    <Skeleton width="50%" />
                  ) : (
                    <h2 className={classes.details__cartTotal}>
                      S/ {formatNumber(product.price * (isNaN(qty) ? 0 : qty))}
                    </h2>
                  )}

                  <h3 className={classes.details__cartTotalText}> TOTAL DEL PEDIDO </h3>
                </Grid>
              </Grid>

              {isSaleOwner === false ? (
                <div className={classes.details__buttons}>
                  <Button
                    variant="contained"
                    className={clsx(
                      classes.details__addToCartButton,
                      classes.details__addToCartColor
                    )}
                    onClick={submit}
                  >
                    {added ? (
                      <CheckIcon
                        style={{
                          height: "22px",
                          width: "22px",
                          color: "#FFF",
                          marginRight: "15px",
                          fontSize:"14px",
                        }}
                      />
                    ) : (
                      <ShoppingBasketIcon className={classes.details__buttonIcons} />
                    )}
                    Agregar al carrito
                    {loading ? (
                      <CircularProgress
                        style={{
                          height: "15px",
                          width: "15px",
                          color: "red",
                          marginLeft: "20px",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Button>
                  {/* <Button
                    variant="outlined"
                    to="/chat?"
                    component={Link}
                    className={clsx(
                      classes.details__addToCartButton,
                      classes.details__negotiateColor
                    )}
                  >
                    <ForumIcon className={classes.details__buttonIcons} />
                    Negociar
                  </Button> */}
                </div>
              ) : (
                <Button
                  variant="contained"
                  component={Link}
                  to={{
                    pathname: "/actualizar-oferta",
                    state: product,
                  }}
                  className={classes.details__updateButton}
                >
                  Editar publicación
                </Button>
              )}
              {errorDetailValue ? (
                <span
                  className={clsx(
                    errorDetailValue && classes.details__errorColor,
                    classes.details__errorLabel
                  )}
                >
                  {errorDetailValue}
                </span>
              ) : (
                ""
              )}
                      </div>
            </Grid>

      </Grid>
      <Grid container className={classes.singlePage__wrapper}>
        <Grid
                container
                item
                lg={6}
                md={6}
                sm={12}
                // className={classes.singlePage__images}
        >
          <div className={classes.carousel}>
            <span >
                Más publicaciones del vendedor
            </span>
            <Carousel
              style={{ width: "fit-content" }}
              infinite={true}
              showDots={true}
              dotListClass="dashboard-offer-dot-style"
              responsive={responsive1}
              className={classes.carousel__carousel}
            >
              {sellerProducts.map((item) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ margin: "0px 10px" }}
                  key={item.id}
                >
                  <CardProduct data={item} {...props} />
                </Grid>
              ))}
            </Carousel>
            <Link
              to={{ pathname: `/vendedor/${product.seller_slug}` }}
              className={classes.link}
            >
              VER MÁS PUBLICACIONES
            </Link>
          </div>
          <div className={classes.summary}>
            <span >
                  Vista rápida de detalles
            </span>
            <div className={classes.summary__table}>
                <div className={classes.summary__row}>
                  <span>Calibre</span>
                  <span>{product.grade}</span>
                </div>
                <div className={classes.summary__row}>
                  <span>Punto de maduración</span>
                  <span>{product.maduration_description}</span>
                </div>
                <div className={classes.summary__row}>
                  <span>Stock disponible</span>
                  <span>{product.quantity} {product.product_unit}</span>
                </div>
                <div className={classes.summary__row}>
                  <span>Kg x Caja</span>
                  <span>{product.equivalency_kg}</span>
                </div>
            </div>
          </div>
          <div className={classes.description}>
            <span >
                Descripción
            </span>
            <span>{product.long_description}</span>
            {/* <div className={classes.description__tags}>
              {tags.map((tag,index) => <span>{tag}</span> )}
            </div> */}
          </div>
        </Grid>
        <Grid
              item
              lg={5}
              md={5}
              sm={12}
              className={classes.singlePage__extraInfo}
        >
          <div className={classes.seller}>
            <span className={classes.extraInfo__title}>{sellerInfo.name}</span>
            <div className={classes.seller__icons}>
              <span><CheckCircleIcon/>{sellerInfo.sellingFrom} </span>
              <span> <LocationOnIcon/> {sellerInfo.address}</span>
            </div>
            <div className={classes.seller__data}>
              {/* <div> */}
                <span>
                  <b>{sellerInfo.published_orders}</b>Productos publicados
                </span>
                <span className={classes.seller__data_center}>
                  <b>{sellerInfo.closed_orders}</b> Ventas concretadas
                </span>
                <span>
                  <b><Rating name="read-only" value={rating} readOnly /></b> Calificación del vendedor
                </span>
              </div>
              <div className={classes.seller__goStore}>
                <button>
                  <Link
                    to={`/vendedor/` + product.seller_slug}
                  >
                  Visitar tienda
                  </Link>
                </button>
              </div>
          </div>
          <div className={classes.payment}>
          <span className={classes.extraInfo__title}>Métodos de pago</span>
          <div className={classes.payment__cards}>
            <span>Tarjetas de crédito y débito</span>
            <Grid container item className={classes.payment__creditCards}>
                    <img
                      src={Visa}
                      alt="credit cards"
                    />
                    <img
                      src={Master}
                      alt="credit cards"
                    />
                    <img
                      src={Amex}
                      alt="credit cards"
                    />
                  </Grid>
          </div>
          <div className={classes.payment__cards}>
            <span>Transferencia bancaria</span>
            <Grid container item className={classes.payment__transfers}>
                    <img
                      src={bbva}
                      alt="bbva"
                    />
                    <img
                      src={bcp}
                      alt="bcp"
                    />
                  </Grid>
          </div>
          </div>
          <div className={classes.shipping}>
            <span className={classes.extraInfo__title}>Opciones de envío</span>
            <div className={classes.shipping__method}>
              <LocalShippingIcon />
              <span><b>Despacho a domicilio:</b> cobertura completa en distritos de Lima y Callao.</span>
            </div>
            <div className={classes.shipping__method}>
              <LocationOnIcon />
              <span><b>Retira tu compra:</b> si cuentas con transporte propio, podrás retirar directamente en el mercado.</span>
            </div>
          </div>
        </Grid>
      </Grid>
    </Paper>
          <Grid container spacing={2} className={classes.sellerContainer}>
        <Paper className={clsx(classes.paper, classes.marginPaper)}>
          <Grid container item md={12} sm={12}>
            <span className={classes.subtitle1}>
              También te puede interesar
            </span>
            <Carousel
              style={{ width: "100%" }}
              infinite={true}
              showDots={true}
              dotListClass="dashboard-offer-dot-style"
              responsive={responsive}
            >
              {recomendedProducts.map((product, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ margin: "0 15px" }}
                  id={"by-seller-slider"}
                  key={index}
                >
                  <ProductSlider _product={product} {...props} />
                </Grid>
              ))}
            </Carousel>
          </Grid>
        </Paper>
      </Grid>
        {/* <Paper className={clsx(classes.paper, classes.marginPaper)}>
        <Grid
          container
          spacing={2}
          style={{ flexDirection: "row-reverse", margin: "1rem 0" }}
        >
          <Grid container item md={5} sm={12} className={classes.section1}>
            <div className={classes.shipBox}>
              <span className={classes.shipBoxTitle}>Métodos de pago</span>
              <div>
                <p className={classes.body2} >
                  Tarjetas de débito y crédito
                </p>
                <Grid container item>
                  <img
                    src={Visa}
                    alt="credit cards"
                    className={classes.cardsImages}
                  />
                  <img
                    src={Master}
                    alt="credit cards"
                    className={classes.cardsImages}
                  />
                  <img
                    src={Amex}
                    alt="credit cards"
                    className={classes.cardsImages}
                  />
                </Grid>
                <p className={classes.body2}>Transferencia Bancaria</p>
              </div>
            </div>
            <div className={classes.shipBox}>
              <span className={classes.shipBoxTitle}>Envío</span>
              <div>
                <p className={classes.body2} >
                  Puedes seleccionar envío por delivery o retirar directamente en el mercado.
                </p>
              </div>
            </div>
            <div className={classes.shipBox}>
              <span className={classes.shipBoxTitle}>Vendedor</span>
              <div className={classes.shipBoxDetail}>
                <div style={{marginTop:"0.5rem"}}>
                  <span className={classes.subtitle2}>
                    <Link
                      to={`/vendedor/` + product.seller_slug}
                      className={classes.visited}
                    >
                      {sellerInfo.name}
                    </Link>
                  </span>

                  <Rating name="read-only" value={rating} readOnly />
                </div>
                <span className={classes.caption}>
                  Activo desde : {sellerInfo.sellingFrom}
                </span>
                <div>
                  <LocationOnIcon className={classes.antiguatyIcon} />
                  <span className={classes.body2}>{sellerInfo.address}</span>
                </div>
                <div>
                  <LocalShippingIcon className={classes.color} />
                  <span
                    className={clsx(classes.body2, classes.color)}
                    style={{ margin: "0 0.5rem" }}
                  >
                    Ventas concretadas
                  </span>
                  <span className={classes.body2}>
                    {sellerInfo.closed_orders}
                  </span>
                </div>
                <div>
                  <StoreIcon className={classes.color} />
                  <span
                    className={clsx(classes.body2, classes.color)}
                    style={{ margin: "0 0.5rem" }}
                  >
                    Productos Publicados
                  </span>
                  <span className={classes.body2}>
                    {sellerInfo.published_orders}
                  </span>
                </div>
                <Link
                  to={{ pathname: `/vendedor/${product.seller_slug}` }}
                  className={classes.link}
                >
                  VER PERFIL
                </Link>
              </div>
            </div>
          </Grid>
          <Grid container item md={7} sm={12}>
            <span className={classes.subtitle1}>
              Más publicaciones del vendedor
            </span>
            <Carousel
              style={{ width: "100%" }}
              infinite={true}
              showDots={true}
              dotListClass="dashboard-offer-dot-style"
              responsive={responsive1}
            >
              {sellerProducts.map((item) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ margin: "0px 10px" }}
                  key={item.id}
                >
                  <CardProduct data={item} {...props} />
                </Grid>
              ))}
            </Carousel>
            <Link
              to={{ pathname: `/vendedor/${product.seller_slug}` }}
              className={classes.link}
            >
              VER MÁS PUBLICACIONES
            </Link>
          </Grid>
        </Grid>
      </Paper> */}
      {/* <Grid container spacing={2} className={classes.sellerContainer}>
        <Paper className={clsx(classes.paper, classes.marginPaper)}>
          <Grid container item md={12} sm={12}>
            <span className={classes.subtitle1}>Publicaciones Similares</span>
            <Carousel
              style={{ width: "100%" }}
              infinite={true}
              showDots={true}
              dotListClass="dashboard-offer-dot-style"
              responsive={responsive}
            >
              {relatedProducts.map((item) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ margin: "0px 10px" }}
                  key={item.id}
                >
                  <CardProduct data={item} {...props} />
                </Grid>
              ))}
            </Carousel>
          </Grid>
        </Paper>
      </Grid> */}
    </section>
  );
}
