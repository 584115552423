import { makeStyles } from '@material-ui/core/styles';

export const stepperStyles = makeStyles((theme) => ({

    stepper: {
        flexGrow: 1,
        background: 'transparent',
        paddingRight: '0px',
        paddingLeft: '0px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    stepLabel: {
        color: 'red',
        
    },
}));