import React from 'react';
import {Typography} from "@material-ui/core";
import { wholesalePurchaseStyle } from "pages/Resources/WholesalePurchase/style";
import LayoutWrapper from 'features/layout/wrapper';

export default function WholesalePurchase(props) {
    const classes = wholesalePurchaseStyle();
    return (
        <LayoutWrapper>
            <Typography
                variant="h2"
                component="h5"
                gutterBottom
                className={classes.pageTitle}
            >
                Compra al por mayor
            </Typography>
        </LayoutWrapper>
    )
}
