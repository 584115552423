// import React, {useEffect} from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Grid, Paper } from "@material-ui/core";
import TradedGoodCard from "./tradedGoodCard";
import {useSelector} from "react-redux";
import { sliderOffer, loadingOffer } from 'pages/Dashboard/Slice';
import LoadingData from "features/loader";
import {offerSlideStyles} from "./styles";
// Renders index of traded goods sections

export default function TradedGoodsList ( props ) {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const _offers = useSelector ( sliderOffer );
    const _loadingSlideOffer = useSelector( loadingOffer );
    const classes = offerSlideStyles();

    return (
        <>
            <Carousel
                style={{width: '100%'}}
                responsive={responsive}
                infinite={false}
                showDots={true}
                dotListClass="dashboard-offer-dot-style"
                swipeable={false}
                draggable={false}
            >
                { _loadingSlideOffer?(
                    Array.from(new Array(3)).map((el, index) => (
                        <Grid item xs={12} sm={12} style={{ margin: "0px 10px" }} key={index}>
                            <Paper className = {classes.paper}>
                                <LoadingData key={index} />
                            </Paper>
                        </Grid>
                ))): _offers.map ( ( product, index ) => (
                    <TradedGoodCard _product={product} key={index} {...props} />
                )) }
            </Carousel>
        </>
    );

}
