import { createSlice } from '@reduxjs/toolkit';
import { setProducts } from 'features/product/list/Slice';
import axios from "axios";

import {activeSalesEP,categoryFilterEP, productsFiltersEP, varietiesFiltersEP} from 'endpoints'

export const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        categories:[],
        products: [],
        varieties: [],
        grades: [],
        category: "",
        product: "",
        variety: "",
        grade: "",
        isUpdatingCat: false,
        isLoadingResult: false,
    },
    reducers: {
        setCategories: (state,action) => {
            state.categories = action.payload;
        },
        setProductList: (state,action) => {

            state.products = action.payload;
        },
        setVarieties:(state,action) => {
            state.varieties = action.payload;
        },
        setCategory: (state, action) => {
            state.category = action.payload;
        },
        setProduct: (state, action) => {
            state.product = action.payload;
        },
        setVerity: (state, action) => {
            state.variety = action.payload;
        },
        setGrade: (state, action) => {
            state.grade = action.payload;
        },
        setLoadingProduct: (state,action) => {
            state.isLoadingResult = action.payload
        }
        // setFiltersData: ( state, action ) => {
        //     if ( typeof action.payload.products !== "undefined" ) {
        //         state.products = action.payload.products;
        //     }
        //     if ( typeof action.payload.grades !== "undefined" ) {
        //         state.grades = action.payload.grades;
        //     }
        // }
    }
});

export const { setCategories, setProductList, setVarieties, setCategory, setProduct,  setVerity, setGrade, setLoadingProduct} = filterSlice.actions;

export const categories = state => state.filter.categories;
export const products = state => state.filter.products;
export const varieties = state => state.filter.varieties;
export const grades = state => state.filter.grades;
export const category = (state) => state.filter.category;
export const product = (state) => state.filter.product;
export const variety = (state) => state.filter.variety;
export const grade = (state) => state.filter.grade;
export const isUpdatingCat = (state) => state.filter.isUpdatingCat;
export const loadingResult = (state) => state.filter.isLoadingResult;

export const getCategories = (callback) => {

   axios.get(categoryFilterEP).then((response) => {

        callback(response.data)
    })

};

export const getProductList = (action, callback) => {

    axios.put(productsFiltersEP,action).then((response) => {

        callback(response.data)
    })

}
export const getVarietyList = (action, callback) => {

   axios.put(varietiesFiltersEP,action).then((response) => {

       callback(response.data);
    })

}

export const updateCategory = (action) => (dispatch) => {

    dispatch(setCategory(action));

};
export const updateProduct = (action) => (dispatch) => {
    dispatch(setProduct(action));
};

export const updateVerity = (action) => (dispatch) => {
    dispatch(setVerity(action));
};

export const updateGrade = (action) => (dispatch) => {
    dispatch(setGrade(action));
};

export const getProducts = ( action ) => async dispatch => {
    const data = await axios.get(activeSalesEP).then((response) => {
        return response.data;
    });
    dispatch(setProducts(data));
}

export const getRelatedProducts = ( _data ) => async dispatch => {
    dispatch(setLoadingProduct(true));
    if ( ! _data.category ) {
        delete _data.category;
    }
    if ( !_data.variety ) {
        delete _data.variety;
    }

    if ( !_data.product ) {
        delete _data.product;
    }

    if ( ! _data.grade ) {
        delete _data.grade;
    }

    const data = await axios.post(activeSalesEP, _data ).then((response) => {
        return response.data;
    });

    dispatch(setProducts( data ));
    dispatch(setLoadingProduct(false));
}

export default filterSlice.reducer;
