import { makeStyles } from "@material-ui/core/styles";

export const myOffersStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      display: "flow-root",
    },
  },
  pageTitle: {
    color: "#53CD94",
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "38px",
    alignItems: "baseline",
    flexGrow: 1,
  },
  button: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "14px",
    height: "32px",
    width: "189px",
    background: "#F2994A",
    boxShadow: "none",
    borderRadius: "8px",
    "&:hover": {
      background: "#e0e0e0",
      boxShadow: "none",
      color:"#333"
    },
  },
  paper: {
    padding: "55px",
    borderRadius: "12px",
    color: "#53CD94",
    fontSize: "25px",
    fontWeight: "bold",
    boxShadow: "none",
    width: "100%",
    marginBottom: "30px",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "25px",
    },
  },
  img: {
    maxWidth: "100px",
    maxHeight: "120px",
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80px",
      maxHeight: "80px",
    },
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #e0e0e0",
    padding: "1rem 0",
  },
  inactive: {
    opacity: "0.5",
  },
  descriptionBox: {
    minWidth: "100px",
    alignContent: "flex-start",
    margin: "auto",
    paddingLeft: "0.5rem",
    "& span": {
      color: "#616060",
      fontSize: "14px",

    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      padding: "0",
    },
  },
  date: {
    color: "#616060",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  title: {
    width: "90%",
    fontSize: "18px",
    color: "#000000",
    margin: "0.5rem 0",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  typeText: {
    width: "73px",
    height: "25px",
    borderRadius: "4px",
    display: "flex",
    lineHeight: "25px",
    alignItems: "center",
    flexDirection: "column",
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: 400,
    color: "rgb(83, 205, 148)",
    background: "rgb(221, 245, 234)",
  },
  gradeBox: {
    justifyContent:"center",
    "& span": {
      fontSize: "12px",
      margin: "0 auto",
      padding: "0 4px",
      textAlign:"center",
      // height: "25px",
      borderRadius: "4px",
      // lineHeight: "25px",
      textTransform: "uppercase",
      fontWeight: 400,
      color: "rgb(83, 205, 148)",
      background: "rgb(221, 245, 234)",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
  },
  text: {
    fontSize: "16px",
    color: "#000000",
  },
  textStock:{
    fontSize:"12px",
    color:"#616060"
  },
  margin: {
    margin: "0 auto",
    width: "50%",
    "&:hover": {
      color: "#000000",
    },
  },
  listItem: {
    color:"rgba(0, 0, 0, 0.87)",
    "&:hover": {
      background: "rgba(128, 130, 133, 0.2)",
      cursor: "pointer"
    },
  },
  box: {
    minWidth: "60px",
    [theme.breakpoints.down("sm")]: {
      margin: "0.5rem auto",
    },
  },

  alignText: {
    textAlign: "center",
  },
  chart: {
    maxWidth: "140px",
    flexDirection: "column",
    margin: "auto",
  },
  marginBox: {
    padding: 0,
  },
  toggleBox: {
    "& li": {},
  },
  popoverPaper: {
    width:"150px",
    [theme.breakpoints.down("sm")]: {
      width:"120px",
    },
  },
  modal: {
    flexDirection: "column",
    padding: "3rem",
    alignItems: "center",
  },
  modal__icon: {
    color: "#F2994A",
    fontSize: "60px",
  },
  modal__box: {
    margin: "1rem",
  },
  modal__alert: {
    fontSize: "20px",
    color: "#808285",
  },
  modal__message:{
    fontSize: "18px",
    color: "#808285",
    opacity:"0.5"
  },
  modal__button: {
    fontSize: "12px",
    color:"#ffffff",
    fontWeight: 500,
    lineHeight: "14px",
    height: "32px",
    width: "189px",
    background: "#4BB985",
    boxShadow: "none",
    borderRadius: "8px",
    "&:hover": {
      background: "#e0e0e0",
      boxShadow: "none",
    },
  },
  modal__listItem:{
    '&:hover': {
      background: "rgba(128, 130, 133, 0.3)",
      cursor: "pointer"
    }
  },
}));
