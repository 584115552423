import { makeStyles } from "@material-ui/core/styles";

export const footerStyles = makeStyles((theme) => ({

  container: {
    width: "100%",
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    paddingTop: "32px",
    paddingBottom: "32px",
    background: "#006D51",
    // display: "flex",
    // alignItems: "center",
    // alignSelf: "flex-end",
    color: "#fff",
    justifyContent:"space-between",
  },
  
  logo: {
    // width: "100%",
    // margin: "0.5rem",
    maxHeight: "40px",

  },
  footer__item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    "& p": {
      margin: "0",
      padding: "0",
      fontSize: ".8rem",
    },
  },
  copy: {
    background: "#002d20",
    color: "#fff",
    textAlign: "center",
    fontSize: "0.7rem",
    width: "100%",
    "& p": {
      margin: ".5rem",
      padding: "0",
    },
  },
  footer_socialMedia: {
    display: "flex",
    "& svg": {
      color: "#f1f1f1",
      margin: "0.5rem",
      fontSize: "30px",
    },
  },
  footer_list: {
    padding: "0px",
    "& li": {
      listStyle: "none",
      marginBottom: "1rem",
      "& a": {
        textDecoration: "none",
        color: "#fff",
      },
      },
    },

    iconsLinks: {
      display: "flex",
      alignItems: "center",
    },
  
    subtitle: {
      fontSize: "18px",
      fontWeight: "500",
      color: "#53cd94",
      lineHeight: "21.33px",
  
    },
}))
  
