import { makeStyles } from "@material-ui/core/styles";

export const newOfferStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    paddingBottom: theme.spacing(5),
  },
  paddingLeft: {
    paddingLeft:"1rem",
  },
  paddingRight:{
    paddingRight:"1rem"
  },
  pageTitle: {
    color: "#53CD94",
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "38px",
    alignItems: "baseline",
    flexGrow: 1,
  },
  paper: {
    padding: "55px",
    borderRadius: "12px",
    color: "#53CD94",
    fontSize: "25px",
    fontWeight: "bold",
    boxShadow: "none",
    width: "100%",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      padding: "25px",
    },
  },
  subHeading: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "23.7px",
    color: "#808285",
    marginBottom: "32px",
  },
  label: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    color: "#808285",
    display: "flex",
    marginBottom: "10px",
    minHeight: "16px",
  },
  errorLabel: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    display: "flex",
    marginTop: "10px",
    minHeight: "16px",
  },
  errorColor: {
    color: "red",
  },
  colorLabel: {
    color: "#808285",
  },
  input: {
    display: "flex",
    fontFamily: '"Rubik",sans-serif',
    width: "100%",
    height: "36px",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    color: "#333333",
    borderRadius: "4px",
    border: "1px solid #E0E0E0",
    background: "#F1F2F2",
    padding: "10px 15px",
    "&:focus": {
      borderRadius: 4,
      outlineColor: "#53CD94",
    },
  },
  slideHeading: {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "21.33px",
    color: "#333",
  },
  chart: {
    flexGrow: 1,
    display: "flex",
    marginTop: "-20px",
    "& > div": {
      "& > div": {
        width: "100% !important",
        "& > svg": {
          width: "100%",
        },
      },
    },
  },
  uploadFilesWrap: {
    display: "flex",
    border: "1px dashed #E0E0E0",
    padding: "15px",
    marginTop: "24px",
    boxSizing: "border-box",
    borderRadius: "4px",
  },
  uploadFilesButtonWrap: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  //   filesList: {
  //     display: "flex",
  //     flexDirection: "row",
  //   },
  //   filesListItem: {
  //     display: "flex",
  //     flexDirection: "column",
  //     '& img': {
  //         width:'70px',
  //     height:'50px'
  // }
  //   },
  filesList: {
    listStyle: "none",
    margin: "0 auto",
    width: "80%",
    padding: "0px",
    "& li": {
      border: "1px solid #53CD94",
      padding: "15px",
      borderRadius: "4px",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "14.22px",
      color: "#333",
      display: "flex",
      alignItems: "center",
      marginBottom: "15px",
      justifyContent:"space-evenly",
      "& img": {
        marginRight: "20px",
        width: "60px",
      },
      "& span": {
        [theme.breakpoints.down("xs")]: {
          display:"none"
        },
      },
      "& svg": {
        color: "#53CD94",
        cursor: "pointer",
      },
    },
  },
  filesListHeading: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    color: "#808285",
    width: "100%",
    textAlign: "center",
    marginTop: "15px",
    marginBottom: "15px",
  },
  subimt: {
    height: "52px",
    width: "100%",
    marginTop: "36px",
    borderRadius: "8px",
    background: "#53CD94",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "21.33px",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      background: "#006D51",
    },
  },
  cropper: {
    "& img": {
      maxWidth: "700px",
      margin: "auto",
    },
  },
}));
