/*-- Libraries --*/
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import GaugeChart from "react-gauge-chart";
import Moment from "react-moment";
import {
    List,
    ListItem,
    Grid,
    Typography,
    Button,
    Paper,
    Popover,
    Box,
} from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import MenuIcon from "@material-ui/icons/Menu";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

/*-- redux --*/
import {
    offers,
    getOffers,
    deleteOffer,
    updateStateSale,
    //   getProducts,
    loadingOffers,
} from "./Slice";

/*-- styles,components,functions --*/
import { myOffersStyles } from "./styles";
import EmptyMessage from "features/layout/emptyMessage";
import LoadingData from "features/loader";
import formatNumber from 'functions/regex';
import LayoutWrapper from "features/layout/wrapper";
import ModalComponent from "features/modal"

export default function MyOffers(props) {
    const classes = myOffersStyles();
    const [setAnchorEl] = React.useState(null);
    const [open, setOpen] = useState(false);

    const _offers = useSelector(offers);
    const loading = useSelector(loadingOffers);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAccept = (index, data) => {
        dispatch(deleteOffer({ data: data, key: index }));
        setOpen(false);
    };

    useEffect(() => {
        dispatch(getOffers());
        // dispatch(getProducts());
    }, [dispatch]);

    const handleUpdate = (data) => {
        history.push({
            pathname: "/actualizar-oferta",
            state: data.data,
        });
    };
    const emptyOffersData = {
        message: "No hay publicaciones",
        linkTo: "/publicar-oferta",
        messageLink: "Publica tu producto",
    };

    return (
        <LayoutWrapper>
            <div className={classes.container}>
                <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.pageTitle}
                >
                    Mis publicaciones
                </Typography>
                <Button
                    variant="contained"
                    component={Link}
                    color="primary"
                    className={classes.button}
                    to="/publicar-oferta"
                >
                    VENDER
                </Button>
            </div>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    {loading ? (
                        <LoadingData />
                    ) : _offers.length !== 0 ? (
                        _offers.map((offer, index) => (
                            <Grid
                                key={offer.id}
                                container
                                className={
                                    offer.status === "Inactiva" || offer.quantity === 0
                                        ? clsx(classes.row, classes.inactive)
                                        : classes.row
                                }
                            >
                                <Grid className={classes.box} item>
                                    <img
                                        className={classes.img}
                                        alt={offer.product}
                                        src={offer.image}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm
                                    container
                                    className={classes.descriptionBox}
                                >
                                    <Grid
                                        item
                                        container
                                        direction="column"
                                        spacing={2}
                                        className={classes.descriptionBox}
                                    >
                                        {offer.status === "Inactiva" ? (
                                            <Typography
                                                className={classes.date}
                                                variant="h2"
                                                component="h5"
                                                gutterBottom
                                            >
                                                {offer.status}| Pausaste la publicación
                                            </Typography>
                                        ) : offer.quantity === 0 ? (
                                            <Typography
                                                className={classes.date}
                                                variant="h2"
                                                component="h5"
                                                gutterBottom
                                            >
                                                No tienes stock
                                            </Typography>
                                        ) : (
                                            <Typography
                                                className={classes.date}
                                                variant="h2"
                                                component="h5"
                                                gutterBottom
                                            >
                                                <Moment format="YYYY-MM-DD HH:mm" >
                                                    {offer.date}
                                                </Moment>
                                            </Typography>
                                        )}

                                        <Typography
                                            className={classes.title}
                                            variant="h2"
                                            component="h5"
                                        >
                                            {offer.product_description}
                                        </Typography>
                                        <Typography
                                            className={classes.date}
                                            variant="h2"
                                            component="h5"
                                        >
                                            {offer.quantity_sold} Ventas
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm container className={classes.box}>
                                    <Grid item container className={classes.gradeBox}>
                                        <span>{offer.grade}</span>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm container className={classes.box}>
                                    <Grid
                                        item
                                        container
                                        direction="column"
                                        spacing={2}
                                        className={classes.descriptionBox}
                                    >
                                        <Typography
                                            className={classes.title}
                                            variant="h2"
                                            component="h5"
                                        >
                                            S/ {formatNumber(offer.price)}
                                        </Typography>
                                        <Typography
                                            className={classes.textStock}
                                            variant="h2"
                                            component="h5"
                                        >
                                            Precio x {offer.product_unit}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm container className={classes.box}>
                                    <Grid
                                        item
                                        container
                                        direction="column"
                                        spacing={2}
                                        className={classes.descriptionBox}
                                    >
                                        <Typography
                                            className={classes.title}
                                            variant="h2"
                                            component="h5"
                                        >
                                            {offer.quantity} {offer.product_unit}
                                        </Typography>
                                        <Typography
                                            className={classes.textStock}
                                            variant="h2"
                                            component="h5"
                                        >
                                            Stock
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm container className={classes.box}>
                                    <Grid
                                        item
                                        container
                                        direction="column"
                                        alignItems="center"
                                        spacing={2}
                                        className={classes.chart}
                                    >
                                        <GaugeChart
                                            id="gauge-chart3"
                                            nrOfLevels={5}
                                            arcWidth={0.3}
                                            percent={(offer.maduration * 2 - 1) / 10}
                                            animate={false}
                                            hideText={true}
                                        />
                                        <span
                                            style={{ fontWeight: 400 }}
                                            className={classes.textStock}
                                        >
                                            {offer.maduration_description}
                                        </span>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm container alignself="flex-start">
                                    <Grid
                                        item
                                        container
                                        direction="column"
                                        className={clsx(classes.toggleBox, classes.alignText)}
                                    >
                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                            {(popupState) => (
                                                <div>
                                                    <Button
                                                        aria-label="delete"
                                                        className={classes.margin}
                                                        onClick={handleClick}
                                                        {...bindTrigger(popupState)}
                                                    >
                                                        <MenuIcon />
                                                    </Button>
                                                    <Popover
                                                        classes={{
                                                            paper: classes.popoverPaper,
                                                        }}
                                                        {...bindPopover(popupState)}
                                                        anchorOrigin={{
                                                            vertical: "bottom",
                                                            horizontal: "center",
                                                        }}
                                                        transformOrigin={{
                                                            vertical: "top",
                                                            horizontal: "center",
                                                        }}
                                                    >
                                                        <Box p={2} className={classes.marginBox}>
                                                            <List>
                                                                {offer.status === "Inactiva" ||
                                                                    offer.quantity === 0 ? (
                                                                    ""
                                                                ) : (
                                                                    <ListItem
                                                                        className={classes.listItem}
                                                                        component={Link}
                                                                        to={`/mercado/` + offer.sale_slug}
                                                                    >
                                                                        Ver Publicación
                                                                    </ListItem>
                                                                )}
                                                                <ListItem
                                                                    className={classes.listItem}
                                                                    onClick={() =>
                                                                        handleUpdate({ key: index, data: offer })
                                                                    }
                                                                >
                                                                    Modificar
                                                                </ListItem>
                                                                {offer.quantity !== 0 ? (
                                                                    <ListItem
                                                                        className={classes.listItem}
                                                                        onClick={() =>
                                                                            dispatch(
                                                                                updateStateSale({ id: offer.id })
                                                                            )
                                                                        }
                                                                    >
                                                                        {offer.status === "Inactiva"
                                                                            ? "Reactivar"
                                                                            : "Pausar"}
                                                                    </ListItem>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <ListItem
                                                                    className={classes.listItem}
                                                                    onClick={handleClickOpen}
                                                                >
                                                                    Eliminar
                                                                </ListItem>
                                                                <ModalComponent
                                                                    openModal={open}
                                                                    onClose={handleClose}
                                                                    // deleteInfo={{ key: index, data: offer }}
                                                                    // deleteFunction={deleteOffer}
                                                                    {...props}
                                                                >
                                                                    <Grid className={classes.modal} container>
                                                                        <Grid className={classes.modal__box} item>
                                                                            <ErrorOutlineIcon className={classes.modal__icon} />
                                                                        </Grid>
                                                                        <Grid className={classes.modal__box} item>
                                                                            <Typography
                                                                                className={classes.modal__alert}
                                                                                variant="h2"
                                                                                component="h5"
                                                                                gutterBottom
                                                                            >
                                                                                Estas por eliminar tu publicación
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid className={classes.modal__box} item>
                                                                            <Typography
                                                                                className={classes.modal__message}
                                                                                variant="h2"
                                                                                component="h5"
                                                                                gutterBottom
                                                                            >
                                                                                Ten en cuenta que si confirmas ya no podrás recuperarla
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid className={classes.modal__box}>
                                                                            <Button onClick={() => handleAccept(index, offer)} className={classes.modal__button}>
                                                                                Confirmar
                                                                            </Button>
                                                                        </Grid>
                                                                        <Grid className={classes.modal__box}>
                                                                            <Button onClick={handleClose} className={classes.modal__button} autoFocus>
                                                                                Cancelar
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </ModalComponent>
                                                            </List>
                                                        </Box>
                                                    </Popover>
                                                </div>
                                            )}
                                        </PopupState>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                    ) : (
                        <EmptyMessage data={emptyOffersData} />
                    )}
                </Grid>
            </Paper>
        </LayoutWrapper>
    );
}
