import React from "react";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import { skeletonStyles } from "./styles";

const SkeletonList = () => {
  const classes =skeletonStyles();
  return (
    <>
      <Grid item xs={12} sm={6} md={3} xl={2}>
        <Paper className={classes.paper}>
          <div className={classes.timeStructure}>
            <Skeleton width="40%" />
          </div>
          <Skeleton variant="rect" width="100%" className={classes.imageStructure} />
          <Grid container justifyContent="space-between">
            <Skeleton width="30%" />
            <Skeleton width="20%" />
          </Grid>
          <Skeleton width="70%" height="65px" />
          <Grid container justifyContent="space-between">
              <Skeleton width="30%" height="45px"/>
              <Skeleton width="30%" height="45px"/>
          </Grid>
          <Skeleton width="30%" height="35px" />
          <Skeleton width="100%" height="35px" />
        </Paper>
      </Grid>
    </>
  );
};

export default SkeletonList;
