/*-- Libraries --*/
import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar, CssBaseline, Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

/*-- redux --*/
import { getTickerData } from "../layoutSlice";
import { setUserData } from "../../Auth/authSlice";

/*-- styles,components,functions --*/
import setAuthToken from "features/API/index";
import AppHeader from "features/layout/common/header/Header";
import AppSidebar from "features/layout/common/sidebar/Sidebar";
import Footer from "../common/footer";
import RecoverPassword from "features/Auth/LoginPopup/RecoverPassword";
import UpdatePassword from "features/Auth/LoginPopup/UpdatePassword";
import TickerComponent from "../ticker";

import PrivacyPolicy from "pages/Resources/privacyPolicy";
import TermsAndConditions from "pages/Resources/termsAndConditions";
import AppDashboard from "pages/Dashboard";
// import UserDashboard from "pages/Dashboard/UserDashboard";
import AppShop from "pages/Shop";
import AppShopSingle from "pages/ShopSingle";
import AppHome from "pages/Home";
import Cart from "pages/Cart";
import APP_404 from "pages/404";
import APP_401 from "pages/401";
import CheckOut from "pages/Checkout";
import ThankYou from "pages/ThankYou";
import MyOffers from "pages/MyOffers";
import NewOffer from "pages/MyOffers/addNew";
import MyProducts from "pages/MyProducts";
import Seller from "pages/Seller";
import MySales from "pages/MySales";
import ShipMethod from "pages/ShipMethod";
import Payment from "pages/Payment";
import SaleDetail from "pages/MySales/SaleDetail/index.js";
import MyOrders from "pages/MyOrders";
import ProductReceipt from "pages/MyOrders/productReceipt";
import OrderDetail from "pages/MyOrders/detail";
import NeedHelp from "pages/MyOrders/needHelp";
import Tracking from "pages/MyOrders/tracking";
import Help from "pages/Resources/Help";
import WholesalePurchase from "pages/Resources/WholesalePurchase";
import SellWithUs from "pages/Resources/SellWithUs";
import TransferState from "pages/MyOrders/voucher";
import { useScrollToTop } from "hooks/useScrollToTop";
import { FullLayoutStyles } from "./styles";
import Formulario from "pages/Formulario";
import Commissions from "../../../pages/Resources/commissions"
import Markets from "pages/markets";
import Setting from "pages/SettingProfile"
import Login from "pages/Login";

export default function FullLayout(props) {
  const classes = FullLayoutStyles();
  const [isSeller, setIsSeller] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertVeriety, setAlertVeriety] = useState("success");
  const [alertMessage, setAlertMessage] = useState(
    "This is a success message!"
  );
  const dispatch = useDispatch();

  const loginUser = useSelector((state) => state.auth.client);
  const loginCheck = localStorage.getItem("token");

  // const { location } = props;

  useEffect(() => {
    if (loginCheck == null) {
      dispatch(setUserData({ token: "" }));
      setAuthToken(false);
    }
  }, [dispatch, loginCheck]);

  useEffect(() => {
    if (loginUser && loginUser.can_publish) {
      setIsSeller(true);
    }
  }, [loginUser]);

  useEffect(() => {
    dispatch(getTickerData());
  }, [dispatch]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleOpen = (veriety, message) => {
    setAlertVeriety(veriety);
    setAlertMessage(message);
    setOpen(true);
  };

  useScrollToTop();

  return (
    <div className={classes.root}>
      <div className={classes.dialog}>
        <Snackbar
          classes={{
            anchorOriginTopRight: classes.snackbarStyleTopRight,
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={alertVeriety}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </div>
      <CssBaseline />
      <AppHeader {...props} />
      <AppSidebar {...props} />
      <main className={classes.content}>
        <div
          style={{
            height: "38px",
            background: "#FFF",
            whiteSpace: "nowrap",
            width: "100%",
          }}
        >
          <TickerComponent />
        </div>
        <Grid >
          {/* <ScrollToTop /> */}
          <Switch>
            <Route
              path="/"
              sidebar={false}
              render={(props) => <AppHome {...props} />}
              exact
            />
            <Route
              path="/login"
              sidebar={false}
              render={(props) => <Login {...props} />}
              exact
            />
            <Route
              path="/recuperar-contraseña"
              render={(props) => (
                <RecoverPassword {...props} handleOpen={handleOpen} />
              )}
              exact
            />
            <Route
              path="/actualizar-contraseña/:token_slug"
              render={(props) => (
                <UpdatePassword {...props} handleOpen={handleOpen} />
              )}
              exact
            />
            <Route
              path="/dashboard"
              render={(props) => <AppDashboard {...props} handleOpen={handleOpen} />}
              exact
            />
            <Route
              path="/mercado"
              render={(props) => <AppShop {...props} handleOpen={handleOpen} />}
              exact
            />
            <Route
              path="/mercado_mayorista/:market/:market_id"
              render={(props) => <Markets {...props} handleOpen={handleOpen} />}
              exact
            />
            <Route
              path="/mercado/:product_slug"
              render={(props) => (
                <AppShopSingle {...props} handleOpen={handleOpen} />
              )}
              exact
            />
            <Route
              path="/mi-pedido"
              render={(props) => <Cart {...props} handleOpen={handleOpen} />}
              exact
            />
            <Route
              path="/envio"
              render={(props) => (
                <ShipMethod {...props} handleOpen={handleOpen} />
              )}
              exact
            />
            <Route
              path="/checkout"
              render={(props) => (
                <CheckOut {...props} handleOpen={handleOpen} />
              )}
              exact
            />
            <Route
              path="/pago"
              render={(props) => <Payment {...props} handleOpen={handleOpen} />}
              exact
            />
            <Route
              path={["/thankyou", "/thanks"]}
              render={(props) => (
                <ThankYou {...props} handleOpen={handleOpen} />
              )}
              exact
            />
            <Route
              path="/indice-de-bienes"
              render={(props) => (
                <MyProducts {...props} handleOpen={handleOpen} />
              )}
              exact
            />
            <Route
              path="/vendedor/:sellerName"
              render={(props) => <Seller {...props} handleOpen={handleOpen} />}
              exact
            />
            <Route
              path="/configuraciones"
              render={(props) => <Setting {...props} handleOpen={handleOpen} />}
              exact
            />
            <Route
              path="/mis-ordenes"
              render={(props) => (
                <MyOrders {...props} handleOpen={handleOpen} />
                )}
                exact
            />
            <Route
              path="/mis-ordenes/recepcion/:orderSlug"
              render={(props) => (
                <ProductReceipt {...props} handleOpen={handleOpen} />
                )}
              exact
              />
            <Route
              path="/mis-ordenes/detalle/:orderSlug"
              render={(props) => (
                <OrderDetail {...props} handleOpen={handleOpen} />
                )}
              exact
            />
            <Route
              path="/mis-ordenes/detalle/:orderSlug/ayuda"
              render={(props) => (
                <NeedHelp {...props} handleOpen={handleOpen} />
                )}
                exact
            />
            <Route
              path="/mis-ordenes/voucher/:orderSlug"
              render={(props) => (
                <TransferState {...props} handleOpen={handleOpen} />
                )}
              exact
            />
            <Route
              path={[
                "/mis-ordenes/:orderSlug/tracking",
                "/mis-ordenes/detalle/:orderSlug/tracking",
              ]}
              render={(props) => (
                <Tracking {...props} handleOpen={handleOpen} />
                )}
              exact
            />
            <Route
              path="/terminos-y-condiciones"
              render={(props) => <TermsAndConditions {...props} />}
              exact
            />
            <Route
              path="/politicas-de-privacidad"
              render={(props) => (
                <PrivacyPolicy {...props} handleOpen={handleOpen} />
                )}
              exact
              />
            <Route
              path="/ayuda"
              render={(props) => <Help {...props} handleOpen={handleOpen} />}
              exact
              />
            <Route
              path="/formulario-de-registro"
              render={(props) => (
                <Formulario {...props} handleOpen={handleOpen} />
                )}
                exact
            />
            <Route
              path="/comisiones"
              render={(props) => (
                <Commissions {...props} handleOpen={handleOpen} />
                )}
              exact
            />
            <Route
            path="/compra-por-mayor"
            render={(props) => (
              <WholesalePurchase {...props} handleOpen={handleOpen} />
              )}
              exact
            />
            <Route
              path="/vende-con-nosotros"
              render={(props) => (
                <SellWithUs {...props} handleOpen={handleOpen} />
                )}
              exact
            />
            <Route
              path="/comisiones"
              render={(props) => (
                <Commissions {...props} handleOpen={handleOpen} />
              )}
              exact
            />
            <Route
              path="/no-autorizado"
              render={(props) => (
                <APP_401 {...props} handleOpen={handleOpen}/>
                )}
                component={APP_401}
                exact
            />
            {isSeller ? (
              <>
              <Route
                path="/ofertas"
            
                render={(props) => (
                  <MyOffers {...props} handleOpen={handleOpen} />
                  )}
                exact
                />
                
                <Route
                  path="/actualizar-oferta"
            
                  render={(props) => (
                    <NewOffer {...props} handleOpen={handleOpen} />
                    )}
                  exact
                />
                <Route
                  path="/publicar-oferta"
                  render={(props) => (
                    <NewOffer {...props} handleOpen={handleOpen} />
                    )}
                  exact
                />
                <Route
                  path="/mis-ventas/detail/:sale_slug"
                  render={(props) => (
                    <SaleDetail {...props} handleOpen={handleOpen} />
                    )}
                  exact
                />
                <Route
                  path="/mis-ventas"
                  render={(props) => <MySales {...props} handleOpen={handleOpen} />}
                  exact
                />
              </>
            ) : (<></>)
            }
            <Route component={APP_404} />
          </Switch>
        </Grid>
        <Footer />
      </main>
    </div>
  );
}
