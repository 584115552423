import { makeStyles } from "@material-ui/core/styles";

export const emptySaleStyles = makeStyles((theme) => ({
  modalContainer: {
    flexDirection: "column",
    padding: "3rem",
    alignItems: "center",
  },
  box: {
    margin: "1rem",
  },
  icon: {
    color: "#F2994A",
    fontSize: "60px",
  },
  alert: {
    fontSize: "20px",
    color: "#808285",
  },

  button: {
    fontSize: "12px",
    color:"#ffffff",
    fontWeight: 500,
    lineHeight: "14px",
    height: "32px",
    width: "189px",
    background: "#4BB985",
    boxShadow: "none",
    borderRadius: "8px",
    "&:hover": {     
      background: "#e0e0e0",
      boxShadow: "none",
    },
  },

  text: {
    fontSize: "16px",
    color: "#000000",
  },
link:{
    textDecoration: "none",
    color:'#ffffff'
}

}));