import React  from 'react';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { gradeDropdownStyles } from './styles';

export default function GradeDropdown( props ) {

  const classes = gradeDropdownStyles();
  const extraClass = false;
  const data = props.data.grades;

  if ( typeof props.extraClass != 'undefined' ) {
  }


  return (
      // <span style={{color: '#53CD94', background: "#DDF5EA", fontSize: "12px", fontWeight: 500, lineHeight: "11.85px", padding: '5px', borderRadius: '4px', textTransform: "uppercase"}}>EXTRA</span>
    <Select native defaultValue={props._default} id="grouped-native-select" IconComponent = {ExpandMoreIcon} className={clsx( classes.block1, extraClass && props.extraClass )} onChange={props._onChange}>
      { data.map(( data, key) => (
          <option value={key} key={key}>{data.grade}</option>
      ))}
    </Select>
  )

}
