import React from 'react'
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import Marquee from "react-double-marquee";
import { useSelector } from "react-redux";
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TrendingFlatSharpIcon from "@material-ui/icons/TrendingFlatSharp";

import {tickerStyles} from './styles'
import { ticker } from "../layoutSlice";
import formatNumber from 'functions/regex';

const TickerComponent = (props) => {
  let history = useHistory();
    const classes=tickerStyles();
    const _ticker = useSelector(ticker);
    const selectTickerProduct = (row) => {
        if (row) {
          if (row.id !== undefined) {
            history.push ( `/mercado/${row.sale_slug}` );
          } 
        }
      };
    return (
        <div
        style={{
          height: "38px",
          background: "#FFF",
          whiteSpace: "nowrap",
          width: "100%",
        }}
        >
        <Marquee direction="left">
            {_ticker.map((data, index) => (
              <div
                className={clsx(classes.newsListBox)}
                onClick={() => selectTickerProduct(data)}
                key={index}
              >
                <div
                  className={clsx(
                    classes.iconBox,
                    data.price_variation > 0 && classes.iconBoxUp,
                    data.price_variation < 0 && classes.iconBoxDown
                  )}
                >
                  {data.price_variation > 0 ? (
                    <TrendingFlatSharpIcon className={classes.icon} />
                  ) : data.price_variation < 0 ? (
                    <TrendingFlatSharpIcon className={classes.icon && classes.iconDown} />
                  ) :  (
                    <TrendingFlatIcon className={classes.iconFlat} />
                  )}
                </div>
                {data.product_description}
                <span
                  style={{
                    marginLeft: "10px",
                    fontSize: "12px",
                    color: "rgb(83, 205, 148)",
                    background: "rgb(221, 245, 234)",
                    padding: "5px",
                    borderRadius: "4px",
                    lineHeight: "11.85px",
                    fontWeight: 500,
                    textTransform: "uppercase",
                  }}
                >
                  {data.grade}
                </span>{" "}
                <span
                  style={{ marginLeft: "10px" }}
                  className={clsx(
                    classes.textbox,
                    data.price < 0 && classes.textboxDown
                  )}
                >
                  S/ {formatNumber(data.price)}{" "}
                </span>
              </div>
            ))}
          </Marquee>
          {/* {selectedTicker && Object.keys(selectedTicker).length > 0 && (
            <AddToCart
              open={selectedOpen}
              handleOpen
              close={selectTickerProduct}
              data={selectedTicker}
              {...props}
            />
          )} */}
        </div>
    )
}

export default TickerComponent
