/*-- Libraries --*/
import React from 'react';

/*-- redux --*/


/*-- styles,components,functions --*/
import {Paper, Typography, Divider} from "@material-ui/core";
import { myPrivacyPolicyStyle } from "../privacyPolicy/style";
import LayoutWrapper from 'features/layout/wrapper';

export default function PrivacyPolicy(props) {
    const classes = myPrivacyPolicyStyle();
    return (
        <LayoutWrapper>
            <Paper className={classes.paper}>
                <div className={classes.container}>
                    <Typography
                        variant="h2"
                        component="h5"
                        gutterBottom
                        className={classes.pageTitle}
                    >
                    Políticas de Privacidad y Datos Personales
                    </Typography>
                    <p>Ultima actualización:<strong style={{color:"black"}}> 09/09/21</strong></p>
                </div>
                <Divider/>
                <div className={classes.container2}>
                    <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.subTitulos}
                    >
                    1.	Introducción
                    </Typography>
                    <p>
                    <strong>JW TECHNOLOGIES S.A.C.</strong> con RUC N° 20604427151, con domicilio en calle Circunvalación del Golf los Incas 134, piso 13, distrito de Santiago de Surco, provincia y departamento de Lima (en adelante, <strong>“WHIPAY”</strong>), recoge en este documento su Política de Privacidad.
                    </p>
                    <p>
                    <strong>WHIPAY</strong> asegura la máxima reserva y protección de aquellos datos de carácter personal que los usuarios o potenciales usuarios, con la finalidad de hacer uso de los servicios, nos proporcionen mediante nuestro sitio Web (https://www.whipay.pe) y otros documentos o formularios.
                    </p>
                    <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.subTitulos}
                    >
                    2.	Objetivo y finalidad
                    </Typography>
                    <p>
                    <strong>WHIPAY</strong> es consciente de la importancia que tiene la privacidad de todos nuestros usuarios y de todas aquellas personas que se interesan por los servicios ofrecidos. Siendo coherentes con esta consideración, tenemos el compromiso de respetar su privacidad y proteger la confidencialidad de su información privada y datos personales. Por tal motivo, mediante el presente documento, damos a conocer a nuestros usuarios, la manera en que se recogen, tratan y se protegen los datos personales brindados en <strong>WHIPAY</strong>.
                    </p>
                    <p>
                    La información personal privada no incluye información que está disponible a través de fuentes accesibles al público. Se entiende por tales los medios de comunicación electrónica, óptica y de otra tecnología concebidos para facilitar información al público y abiertos a la consulta general, las guías telefónicas, los diarios y revistas, los medios de comunicación social, las listas profesionales, los repertorios de jurisprudencia anonimizados, los Registros Públicos administrados por la Superintendencia Nacional de Registros Públicos así como todo otro registro o banco de datos calificado como público conforme a ley, las entidades de la Administración Pública en relación a la información que deba ser entregada en aplicación de la Ley Nº 27806, Ley de Transparencia y Acceso a la Información Pública.
                    </p>
                    <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.subTitulos}
                    >
                    3.	Legislación
                    </Typography>
                    <p>
                    De acuerdo con la Ley N° 29733 – Ley de Protección de Datos Personales (en adelante, la “Ley”) y su Reglamento, aprobado por el Decreto Supremo No. 003-2013-JUS, se entiende por datos personales toda información sobre una persona natural que la identifica o hace identificable a través de medios que pueden ser razonablemente utilizados.
                    </p>
                    <p>
                    Por otro lado, por tratamiento de datos personales se entiende como cualquier operación o procedimiento técnico, automatizado o no, que permite la recopilación, registro, organización, almacenamiento, conservación, elaboración, modificación, extracción, consulta, utilización, bloqueo, supresión, comunicación por transferencia o por difusión o cualquier otra forma de procesamiento que facilite el acceso, correlación o interconexión de los datos personales.
                    </p>
                    <p>
                    <strong>WHIPAY</strong> desarrolla su Política de tratamiento de datos personales en atención a los principios rectores establecidos en la Ley mencionados a continuación:
                    </p>
                    <ol type="a" className={classes.lista}>
                        <li>Principio de legalidad: se rechaza la recopilación de los datos personales de nuestros usuarios por medios fraudulentos, desleales o ilícitos.</li>
                        <li>Principio de consentimiento: en el tratamiento de los datos personales de nuestros usuarios mediará su consentimiento.</li>
                        <li>Principio de finalidad: Los datos personales de nuestros usuarios se recopilarán para una finalidad determinada, explícita y lícita, y no se extenderá a otra finalidad que no haya sido la establecida de manera inequívoca como tal al momento de su recopilación.</li>
                        <li>Principio de proporcionalidad: todo tratamiento de datos personales de nuestros usuarios será adecuado, relevante y no excesivo a la finalidad para la que estos hubiesen sido recopilados.</li>
                        <li>Principio de calidad: Los datos personales serán veraces, exactos y, en lo posible, actualizados, necesarios, pertinentes y adecuados respecto de la finalidad para la que fueron recopilados.</li>
                        <li>Principio de seguridad: se conservarán de forma tal que se garantice su seguridad y solo por el tiempo necesario para cumplir con la finalidad del tratamiento.</li>
                    </ol>
                    <p>
                    <strong>WHIPAY</strong> y, en su caso, los encargados de tratamiento, cuentan con las medidas de seguridad apropiadas y acordes con el tratamiento que se vaya a efectuar, así como adoptarán las medidas técnicas, organizativas y legales necesarias para garantizar confidencialidad de los datos personales.
                    </p>
                    <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.subTitulos}
                    >
                    4.	Información sobre datos personales
                    </Typography>
                    <p>
                    El usuario queda informado y autoriza libre y voluntariamente a WHIPAY para recolectar, almacenar, conservar, acceder, usar, suprimir o actualizar sus Datos Personales para sus actividades en el Perú. En ese sentido, los datos personales a los que <strong>WHIPAY</strong> tenga acceso como consecuencia de los servicios que brinda o mediante el llenado de diferentes formularios, se incorporarán al correspondiente “Banco de Datos Personales de Usuarios Web”, conformando el conjunto organizado de datos personales de usuarios, creado por <strong>WHIPAY</strong> y registrado en el Registro Nacional de Protección de Datos Personales.
                    </p>
                    <p>
                    Los datos personales facilitados por los usuarios serán tratados con total confidencialidad y sólo podrán ser conocidos y manejados por el personal de <strong>WHIPAY</strong> que necesite conocer dicha información para brindar los servicios ofrecidos. <strong>WHIPAY</strong> se compromete a guardar secreto profesional respecto de los mismos y garantiza el deber de guardarlos adoptando todas las medidas de seguridad necesarias. No será necesaria la autorización cuando los datos personales sean necesarios para la ejecución de una relación contractual en la que el Titular de Datos Personales sea parte.
                    </p>
                    <p>
                    Si los datos registrados resultaran inexactos, en todo o en parte, o incompletos, <strong>WHIPAY</strong> podrá actualizarlos y/o sustituirlos por los correspondientes datos rectificados o completados.
                    </p>
                    <p>
                    Los datos personales solicitados a los usuarios mediante diferentes formularios del sitio Web o físicos, son datos básicos de contacto y son adecuados, pertinentes y no excesivos en relación con la finalidad para los que se recogen.
                    </p>
                    <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.subTitulos}
                    >
                    5.	Fuentes de recopilación de los Datos Personales
                    </Typography>
                    <p>
                    <strong>WHIPAY</strong> recopila los Datos Personales de distintas fuentes públicas y privadas, de acuerdo con lo permitido por la Normativa de Protección de Datos Personales. De esa manera, <strong>WHIPAY</strong> recopila Datos Personales de diversas fuentes de información, las que incluyen, pero no se limitan a:
                    </p>
                    <ul className={classes.lista}>
                    <li>Registro Nacional de Identificación y Estado Civil (RENIEC).</li>
                    <li>Información recopilada por el uso de la plataforma/página web.</li>
                    <li>Información suministrada de manera voluntaria por el Usuario a través de cualquier medio, físico o electrónico (por ejemplo, correos electrónicos, aplicaciones, móviles, llamadas telefónicas, video llamadas, fotografías, mensajes de texto (SMS) o instantáneos, etc.).</li>
                    <li>Información suministrada de manera voluntaria por el Usuario mediante el redireccionamiento desde plataformas de terceros con los que <strong>WHIPAY</strong> tiene relación comercial.</li>
                    <li>Bureaus de Crédito como Infocorp, Equifax, Sentinel, la SBS, Experian (Datacrédito), Xchange, entre otros.</li>
                    <li>Redes sociales (Facebook, Instagram, Twitter, entre otros).</li>
                    <li>Ficheros con información sobre sitios web visitados, así como los motores de búsqueda utilizados.</li>
                    <li>Información de acceso público.</li>
                    </ul>
                    <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.subTitulos}
                    >
                    6.	Finalidad del tratamiento de los datos personales
                    </Typography>
                    <p>
                    El Usuario autoriza expresamente a <strong>WHIPAY</strong> al uso de sus datos para procesar pedidos, entregar productos y servicios, procesar pagos, mantener y actualizar nuestros archivos y sus cuentas con nosotros, hacer disponible contenido tales como listas de deseos y comentarios de los clientes, recomendaciones de productos y servicios de interés para usted. Asimismo, utilizamos esta información para poder brindar estos servicios y funciones en nuestro propio nombre o por parte de terceros, tales como Usuarios oferentes en el Sitio, para mejorar nuestros servicios, características y plataforma, prevenir o detectar fraude o abuso, permitir a terceros prestar servicios técnicos, logístico o de otro tipo en nuestro nombre, adaptar nuestras comunicaciones y, en general, desarrollo de acciones comerciales, contractuales, marketing, publicitarias, promocionales, administrativas y para el soporte a usuarios, sean de carácter general o adaptado a sus características personales. Dichas acciones podrán ser realizadas por correo electrónico u otro medio de comunicación equivalente.
                    </p>
                    <p>
                    Los datos personales serán cancelados cuando hayan dejado de ser necesarios para las finalidades para las cuales han sido recogidos. Sin embargo, se conservarán durante el tiempo en qué pueda exigirse algún tipo de responsabilidad a <strong>WHIPAY</strong> derivada de esta relación con los usuarios. No se considerará incompatible el tratamiento posterior con fines históricos, estadísticos o científicos.
                    </p>
                    <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.subTitulos}
                    >
                    7.	Libro de Reclamaciones
                    </Typography>
                    <p>
                    Asimismo, el usuario autoriza expresamente a <strong>WHIPAY</strong> a que, cuando haga uso del formulario virtual que se encuentra en la URL https://whipay.pe/claims, sus datos sean tratados con la finalidad de atender las necesidades propias del procedimiento de atención de los reclamos que presenten los usuarios a través del Libro de Reclamaciones, así como para, una vez culminado el procedimiento de atención del reclamo, atender las necesidades derivadas de cualquier proceso o procedimiento judicial, administrativo o arbitral que a futuro se derive o surja en relación con el reclamo que el usuario haya presentado, atender cualquier fiscalización de la autoridad competente en materia de tratamiento de datos personales.
                    </p>
                    <p>
                    Los datos recopilados a través del Libro de Reclamaciones se almacenarán en el banco de datos “Quejas y Reclamos” de titularidad de <strong>WHIPAY</strong>.
                    </p>
                    <p>
                    Como consecuencia de las finalidades descritas, el plazo de almacenamiento de los datos será de forma indefinida. Para todo efecto, se entenderá que este plazo máximo de almacenamiento y de tratamiento de los datos personales es un derecho de <strong>WHIPAY</strong> y no una obligación, por lo que <strong>WHIPAY</strong> podrá optar por almacenarlos y/o tratarlos por plazos menores. Esto no perjudica en absoluto los derechos de los usuarios para revocar el consentimiento de sus datos personales en cualquier momento, conforme la Ley se lo permite y <strong>WHIPAY</strong> lo detalla en la presente Política de Privacidad.
                    </p>
                    <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.subTitulos}
                    >
                    8.	Chat en línea
                    </Typography>
                    <p>
                    Los usuarios autorizan a <strong>WHIPAY</strong> a tratar sus datos personales con la finalidad de brindar las respuestas a las consultas realizadas a través del Whatsapp de <strong>WHIPAY</strong> o “Chat en línea”, por lo que se aplicarán las medidas adaptadas en la presente Política de Privacidad.
                    </p>
                    <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.subTitulos}
                    >
                    9.	Transferencia de datos personales a nivel nacional o internacional
                    </Typography>
                    <p>
                    El usuario autoriza la transferencia de sus datos a las empresas a nivel nacional o internacional (flujo transfronterizo) relacionadas y/o asociadas a <strong>WHIPAY</strong> para poder gestionar adecuadamente los servicios solicitados por los usuarios y las gestiones que se deriven posteriormente, en relación a la finalidad indicada en los apartados anteriores, extendiendo para ello a éstas la autorización expresa para su uso. <strong>WHIPAY</strong> garantiza el nivel adecuado de protección de los datos personales de los usuarios para la transferencia de datos personales, de acuerdo a una protección equiparable a lo previsto por esta Ley o por los estándares internacionales en la materia.
                    </p>
                    <p>
                    En tal sentido, los datos personales, así como la información que se genere a partir de ellos de acuerdo a los tratamientos de datos personales señalados en el numeral anterior, serán transferidos a las empresas en <a  target="_blank" rel="noopener noreferrer" href="https://intranet.whipay.pe/policies/seller_list/">este link</a>, con los cuales <strong>WHIPAY</strong> tiene una relación contractual para realizar la entrega de productos o servicios, procesar pagos, conocer los niveles de satisfacción de sus productos y servicios, reciba quejas, sugerencias y reclamos del usuario, así como para incorporar los datos personales en su base de datos y realizar las finalidades señaladas el punto 5. anterior.
                    </p>
                    <p>
                    <strong>WHIPAY</strong> utiliza servidores de terceros para el almacenamiento de bancos de datos, por lo que se realizará flujo transfronterizo con la empresa Amazon Web Services, Inc., empresa prestadora de servicios de alojamiento web ubicada en Ohio, Estados Unidos de América). Asimismo, <strong>WHIPAY</strong> le encarga a las empresas y personas naturales descritas en <a target="_blank" rel="noopener noreferrer" href="https://intranet.whipay.pe/policies/transport_list/">este link</a>, empresas ubicadas en Lima, Perú, los servicios de distribución y entrega de mercadería, por lo que le hace entrega de los datos personales necesarios para dicho servicio. <strong>WHIPAY</strong> también le encarga a las empresas de <a target="_blank" rel="noopener noreferrer" href="https://intranet.whipay.pe/policies/company_list/">este link</a>, empresas ubicadas en Lima, Perú, los servicios de procesamiento de pagos, por lo que le hace entrega de ciertos datos personales necesarios para dicho servicio.
                    </p>
                    <p>
                    Estos datos personales también pueden ser comunicados a las entidades administrativas, autoridades judiciales y/o policiales, siempre y cuando esté establecido por Ley.
                    </p>
                    <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.subTitulos}
                    >
                    10.	Seguridad de los datos personales
                    </Typography>
                    <p>
                    En cumplimiento de la normativa vigente, la Directiva de Seguridad de la Información aprobada por la Resolución Directoral No. 019-2013-JUS/DGPDP de la Autoridad Nacional de Protección de Datos Personales, <strong>WHIPAY</strong> ha adoptado las medidas técnicas y organizativas de seguridad y confidencialidad apropiadas a la categoría de los datos personales, necesarias para mantener el nivel de seguridad requerido, con el objetivo de evitar en la medida de lo posible la alteración, pérdida o el tratamiento o acceso no autorizado que puedan afectar afecten a la integridad, confidencialidad y disponibilidad de la información.
                    </p>
                    <p>
                    Sin embargo, la transmisión de información mediante las redes de comunicación y de Internet no es totalmente segura; por eso, y a pesar de que <strong>WHIPAY</strong> realizará sus mejores esfuerzos para proteger los datos personales, no podrá garantizar la seguridad de los mismos durante el tránsito hasta el sitio Web. Toda la información que los usuarios de Internet faciliten por este medio, se enviará por su cuenta y riesgo.
                    </p>
                    <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.subTitulos}
                    >
                    11.	Ejercicio de los Derechos de Acceso, Rectificación, Cancelación y Oposición
                    </Typography>
                    <p>
                    Los Usuarios de Internet que hayan facilitado datos personales a <strong>WHIPAY</strong> pueden dirigirse a esta, con el fin de poder ejercer sus derechos de información, de acceso, de actualización, inclusión, rectificación y supresión, de impedir el suministro de sus datos personales, de oposición al tratamiento o de tratamiento objetivo de los datos, en los términos recogidos en la legislación vigente. Dichos derechos solo podrán ser ejercidos por el titular de los datos personales o su apoderado, de conformidad con la ley.
                    </p>
                    <p>
                    Para poder ejercer estos derechos, los Usuarios deberán dirigirse a la siguiente dirección de correo electrónico clientes@whipay.pe con la referencia “Protección de Datos Personales”, especificando sus datos, acreditando su identidad y los motivos de su solicitud. Quien suscribe la solicitud deberá anexar copia simple de los documentos que acrediten la identidad del titular (Documento Nacional de Identidad o documento equivalente) o en su caso, la del apoderado, así como copia simple del poder otorgado por vía notarial cuando corresponda.
                    </p>
                    <p>
                    Asimismo, <strong>WHIPAY</strong> informa a sus usuarios la existencia de la vía administrativa para hacer valer sus derechos a ejercitar ante la Autoridad Nacional de Protección de Datos o jurisdiccionales ante el Poder Judicial a los efectos de la correspondiente acción de habeas data.
                    </p>
                    <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.subTitulos}
                    >
                    12.	Otros
                    </Typography>
                    <p>
                    En tanto se trata de una herramienta de tipo virtual, que no permite el trato físico directo entre <strong>WHIPAY</strong> y el titular de los datos personales, <strong>WHIPAY</strong> recopilará y tratará los datos personales partiendo de la presunción de buena fe de quien los facilita es mayor de edad ante la ley, es hábil en el idioma español, se encuentra en pleno uso de sus facultades mentales, ostenta plena capacidad civil y es titular de los datos personales o actúa en calidad de representante del titular de los datos que facilita, en caso de que sean de titularidad de terceros.
                    </p>
                    <p>
                    Para realizar cualquier tipo de consulta respecto a esta Política puede dirigirse a la siguiente dirección de correo electrónico: clientes@whipay.pe.
                    </p>
                    <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.subTitulos}
                    >
                    13.	Vigencia y modificación de la presente Política de Protección de Datos Personales
                    </Typography>
                    <p>
                    La Política de Protección de Datos Personales de <strong>WHIPAY</strong> es actualizada constantemente, por lo que <strong>WHIPAY</strong> se reserva el derecho a modificar su Política en el supuesto de que exista un cambio en la legislación vigente, doctrinal, jurisprudencial o por criterios propios empresariales. Si se introdujera algún cambio en esta Política, el nuevo texto se publicará en este mismo sitio Web.
                    </p>
                    <p>
                    Se recomienda a todos los usuarios que accedan periódicamente a esta Política de Privacidad que encontraran en el sitio Web https://www.whipay.pe.
                    </p>


                </div>
            </Paper>
        </LayoutWrapper>
    )
}
