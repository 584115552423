import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  destinationsEP,
  shipToInfoEP,
  shipMethodEP,
  shippingCostEP,
} from "endpoints";
export const shipMethodSlice = createSlice({
  name: "shipData",
  initialState: {
    id: "",
    name: "",
    phone: "",
    addressDetail: "",
    province: "",
    district: "",
    provinceName: "",
    districtName: "",
    provinces: [],
    districts: [],
    shipToFlag: false,
    provinceFlag: false,
    districtFlag: false,
    deliveryCost: 0,
    stevedoreCost:0,
    shippingCost: 0,
  },
  reducers: {
    setAllProvinces: (state, action) => {
      state.provinces = action.payload;
      state.provinceFlag = true;
    },

    setProvinces: (state, action) => {
      state.province = action.payload;
      state.districtFlag = true;
      state.districts = state.provinces.filter((prov) => prov.province_id === parseInt(action.payload))[0].districts;
      state.district = state.districts[0].id;
      state.districtName = state.districts[0].district;
      // state.districts = state.provinces[state.province-1].districts;
    },

    setDistrict: (state, action) => {
      state.district = action.payload.districtId;
      state.districtName = action.payload.districtName;
    },

    setShipToData: (state, action) => {
      state.id = action.payload.id;
      state.name = action.payload.shipTo;
      state.phone = action.payload.phone;
      state.addressDetail = action.payload.address;
      state.phone = action.payload.phone;
      state.provinceName = action.payload.province;
      state.districtName = action.payload.district;
      state.province = action.payload.province_id;
      state.district = action.payload.district_id;
      state.shipToFlag = true;
      state.districts = state.provinces.filter((prov) => prov.province_id === parseInt(action.payload.province_id))[0].districts;
    },

    setShipTo: (state, action) => {
      state.name = action.payload;
    },

    setId: (state, action) => {
      state.id = action.payload;
    },

    setPhone: (state, action) => {
      state.phone = action.payload;
    },

    setAddress: (state, action) => {
      state.addressDetail = action.payload;
    },

    setDeliveryCost: (state, action) => {
      state.deliveryCost = action.payload.tPrice;
      state.stevedoreCost = action.payload.sPrice;
    },

    setShipMethod: (state, action) => {
      state.shipMethod = action.payload;
    },

    setShippingCost: (state, action) => {
      state.deliveryCost = action.payload.updateDelivery;
      state.stevedoreCost = action.payload.updateStevedore;
    },
    
  },
});
export const {
  setAllProvinces,
  setProvinces,
  setDistrict,
  setShipToData,
  setShipTo,
  setPhone,
  setAddress,
  setDeliveryCost,
  setShipMethod,
  setShippingCost,
} = shipMethodSlice.actions;
export const provinceArr = (state) => state.shipData.provinces;
export const districtsArr = (state) => state.shipData.districts;
export const province = (state) => state.shipData.province;
export const district = (state) => state.shipData.district;
export const shipTo = (state) => state.shipData.name;
export const id = (state) => state.shipData.id;
export const phone = (state) => state.shipData.phone;
export const address = (state) => state.shipData.addressDetail;
export const provinceName = (state) => state.shipData.provinceName;
export const districtName = (state) => state.shipData.districtName;
export const shipToFlag = (state) => state.shipData.shipToFlag;
export const provinceFlag = (state) => state.shipData.provinceFlag;
export const districtFlag = (state) => state.shipData.districtFlag;
export const deliveryCost = (state) => state.shipData.deliveryCost;
export const shipMethod = (state) => state.shipData.shipMethod;
export const shippingCost = (state) => state.shipData.deliveryCost;
export const estevedoreCost = (state) => state.shipData.stevedoreCost;

export const getAllDistricts = (action) => async (dispatch) => {
  const data = await axios.get(destinationsEP).then((response) => {
    return response.data;
  });
  dispatch(setAllProvinces(data));
};

//update selects
export const updateShippingCost = (action) => (dispatch) => {
  dispatch(setShippingCost(action));
};
export const updateProvince = (action) => (dispatch) => {
  dispatch(setProvinces(action));
};
export const updateDistrict = (action) => (dispatch) => {
  dispatch(setDistrict(action));
};
export const updateShipTo = (action) => (dispatch) => {
  dispatch(setShipTo(action));
};
export const updatePhone = (action) => (dispatch) => {
  dispatch(setPhone(action));
};
export const updateAddress = (action) => (dispatch) => {
  dispatch(setAddress(action));
};
// get info user

export const getShipData = (action) => async (dispatch) => {
  const data = await axios.get(shipToInfoEP).then((response) => {
    return response.data;
  });
  dispatch(setShipToData(data));
};
export const getDeliveryCost = (action) => async (dispatch) => {
  const data = await axios.get(shippingCostEP).then((response) => {
    return response.data;
  });
  dispatch(setDeliveryCost(data));
};

export const submitData = async (action, success, fail) => {
  await axios.patch(shipToInfoEP, action).then((response) => {
    success(response.data.detail);
    return response.data;
  }).catch((error) => {
    fail(error.response.data.detail);
  });
};

// submitShippingMethod

export const updateShipMethod = (action,callback) => {
  axios.post(shipMethodEP, action).then(() => {
      callback()
    });
};
export const getShipMethod = async (callback)=> {
 
  const data = await axios.get(shipMethodEP).then((response)=> {
    return response.data
  })
  callback(data);
}
export default shipMethodSlice.reducer;
