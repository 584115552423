import React from "react";
import { Link } from "react-router-dom";
import { Grid, Typography, Button } from "@material-ui/core";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { emptySaleStyles  } from "./styles";
export default function EmptyMessage(props) {
  const classes = emptySaleStyles ();
  return (
    <Grid className={classes.modalContainer} container>
      <Grid className={classes.box} item>
        <HourglassEmptyIcon className={classes.icon} />
      </Grid>
      <Grid className={classes.box} item>
        <Typography
          className={classes.alert}
          variant="h2"
          component="h5"
          gutterBottom
        >
          {props.data.message}
        </Typography>
      </Grid>
      <Grid className={classes.box} item>
        <Button className={classes.button}>
          <Link className={classes.link} to={props.data.linkTo}>
            {props.data.messageLink}
          </Link>
        </Button>
      </Grid>
    </Grid>
  );
}