/*-- Libraries --*/
import React, { useState } from 'react';
import axios from 'axios';
import clsx from "clsx";
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Grid,
    Typography,
    Fade,
    Button,
    CircularProgress,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

/*-- redux --*/
import {
    setLoading,
    setUserData,
    // loadUser,
} from 'features/Auth/authSlice';

/*-- styles,components,functions --*/
// import setAuthToken from 'features/API/index';
import { loginEP } from 'endpoints';
import { toggleSidebar } from 'features/layout/layoutSlice';
import { loginPopupStyles } from './styles';
import logo from 'assets/images/logo.svg';

export default function Login(props) {
    const classes = loginPopupStyles();
    const dispatch = useDispatch();
    let history = useHistory();
    let location = useLocation();
    //   const classes = loginPopupStyles();
    let { from } = location.state || { from: { pathname: '/mercado' } };
    const baseMediaURL = process.env.REACT_APP_BASE_URL_MEDIA;
    
    const backgrounds = [];
    backgrounds.push(baseMediaURL + "login/background_fruits.jpg");
    backgrounds.push(baseMediaURL + "login/background_vegetables.jpg");
    
    const randomBg = backgrounds[Math.round(Math.random())];

    const [loading, _setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [userError, setUserError] = useState(false);
    const [values, setValues] = useState({ email: '', password: '', showPassword: false });

    // const [background, setBackground] = useState(randomBg);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues({ ...values, [name]: value });
        setUserError(false);
        setError(false);
    };

    const handleShowPassword = () => {

        setValues({ ...values, showPassword: !values.showPassword });
    }

    const closeModal = () => {
        // dispatch(loginPopupAction_(false));
        history.push('/recuperar-contraseña');
    };
    const closeModalforRegister = () => {
        // dispatch(loginPopupAction_(false));
        history.push('/formulario-de-registro');
    };

    const login = async () => {
        let submit = true;

        if (!values.email || !values.password) {
            submit = false;
            setError(true);
        } else {
            setError(false);
        }

        if (!error && submit) {
            _setLoading(true);

            await axios
                .post(loginEP, {
                    username: values.email,
                    password: values.password,
                })
                .then((response) => {
                    _setLoading(false);
                    dispatch(setUserData(response.data));
                    // dispatch(loginPopupAction_(false));
                    // setAuthToken(response.data.token);
                    localStorage.setItem('token', response.data.token);
                    dispatch(setLoading(true));
                    // dispatch(loadUser());
                    dispatch(toggleSidebar());
                    history.replace(from);
                })
                .catch(function (error) {
                    setUserError(true);
                    _setLoading(false);
                });
        }
    };
    return (
        <Grid container className={classes.login} style={{backgroundImage : `url(${randomBg})`}}>
            <Grid
                item sm={6} xs={10} md={4} lg={3} className={classes.login__container}
            >
                <div style={{margin:"1.5rem"}}>
                <div className={classes.login__wrap}>
                    <img
                        src={logo}
                        className={classes.logo}
                        alt="logo_whipay"
                    />
                    <Typography
                        variant="h2"
                        component="h5"
                        gutterBottom
                        className={classes.loginTitle}
                        style={{
                            fontSize: '20px',
                            fontWeight: 500,
                            lineHeight: '23.7px',
                            color: '#53CD94',
                            marginBottom: '25px',
                        }}
                    >
                        Bienvenido a whipay
                    </Typography>
                </div>
                {error ? (
                    <Typography
                        variant="h2"
                        component="span"
                        gutterBottom
                        className={classes.error}
                        style={{ fontSize: '14px', marginBottom: '10px' }}
                    >
                        ¡Por favor llena todos los espacios!
                    </Typography>
                ) : userError ? (
                    <Typography
                        variant="h2"
                        component="span"
                        gutterBottom
                        className={classes.error}
                        style={{ fontSize: '14px', marginBottom: '10px' }}
                    >
                        Por favor revisa la contraseña o el nombre de usuario
                    </Typography>
                ) : (
                    <></>
                )}
                <form>
                    <div>
                        <label className={classes.label}>
                            Nombre de usuario
                        </label>
                        <input
                            type={'text'}
                            placeholder={'Nombre de usuario'}
                            className={classes.input}
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            id={'username'}
                            autoComplete="off"
                            autoFocus
                            onKeyPress={(event) =>
                                event.key === 'Enter' && login()
                            }
                        />
                    </div>
                    <div>
                        <label className={classes.label}>Contraseña</label>
                        <div className= {classes.passwordWrap}>
                        <input
                            type={ values.showPassword ? "text" : "password"}
                            placeholder={'Ingresa tu contraseña'}
                            className={clsx(classes.input, classes.iconInput)}
                            onChange={handleChange}
                            value={values.password}
                            name="password"
                            autoComplete="off"
                            id={'password'}
                            onKeyPress={(event) =>
                                event.key === 'Enter' && login()
                            }
                        />
                                        {values.showPassword ?
                    <VisibilityOff className= {classes.iconPassword} onClick={handleShowPassword}/>
                    : <Visibility className= {classes.iconPassword} onClick={handleShowPassword} />
                }
                        </div>

                    </div>

                    <div className={classes.termsWrap}>
                        <div
                            className={classes.termsBlock}
                            style={{ display: 'flex', margin: 'auto' }}
                        >
                            <Typography
                                variant="h2"
                                component="a"
                                gutterBottom
                                className={classes.termsText}
                                // href={"/recuperar-contraseña"}
                                onClick={closeModal}
                            >
                                ¿Olvidaste tu contraseña?
                            </Typography>
                        </div>
                    </div>
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classes.button}
                        onClick={login}
                        disabled={loading}
                        // type="submit"
                        // value="Submit"
                    >
                        <Fade in={loading} unmountOnExit>
                            <CircularProgress
                                style={{
                                    height: '20px',
                                    width: '20px',
                                    margin: '10px',
                                }}
                            />
                        </Fade>
                        INICIAR SESIÓN
                    </Button>
                </form>
                <div style={{ padding: '0 5px 15px 5px' }}>
                    <span
                        style={{
                            color: '#808285',
                            fontSize: '9px',
                            fontWeight: 400,
                            lineHeight: '14.22px',
                            cursor: 'pointer',
                        }}
                    >
                        Al iniciar sesión, el usuario acepta tanto los{' '}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="/terminos-y-condiciones"
                            className={classes.linkRef}
                        >
                            términos y condiciones
                        </a>{' '}
                        como las{' '}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="/politicas-de-privacidad"
                            className={classes.linkRef}
                        >
                            políticas de privacidad
                        </a>{' '}
                        de la plataforma
                    </span>
                </div>
                <div className={classes.login__wrap}>
                    <Typography
                        variant="h2"
                        component="span"
                        gutterBottom
                        onClick={closeModalforRegister}
                        style={{ fontSize: '14px', color:"#808285" }}
                    >
                        ¿No tienes cuenta? {' '}
                        <Typography
                            variant="h2"
                            component="a"
                            gutterBottom
                            className={classes.termsText}
                            // href={"/recuperar-contraseña"}
                            onClick={closeModalforRegister}
                        >
                            Crear cuenta
                        </Typography>
                    </Typography>
                </div>
                </div>

            </Grid>
        </Grid>
    );
}
