import { makeStyles } from '@material-ui/core/styles';
export const loginPopupStyles = makeStyles((theme) => ({
    "@keyframes fadeIn": {
        "0%": {
            backgroundImage: `url("https://djsaqul9tqjw8.cloudfront.net/login/background_vegetables.jpg")`,
            right:"15px"
        },
        "50%": {
            backgroundImage:`url("https://djsaqul9tqjw8.cloudfront.net/login/background_fruits.jpg")`

        },
        "100%": {
            backgroundImage:`url("https://djsaqul9tqjw8.cloudfront.net/login/background_vegetables.jpg")`
        }
      },
    login: {
        // backgroundImage: `url(${background})`,
        backgroundPosition:"center",
        backgroundRepeat:"no-repeat",
        backgroundSize:"cover",
        height:"100vh",
        animation: "$fadeIn 15s infinite"
    },
    login__container: {
        margin: 'auto',
        marginTop: '4rem',
        border:"1px solid #e0e0e0",
        borderRadius:"20px",
        backgroundColor:"#fff"
    },
    login__wrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    login__link: {
        color: '#53CD94',
        textDecoration: 'none',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14.22px',
        cursor: 'pointer',
    },
    popupWrap: {
        '& > .MuiDialog-container > div': {
            width: '400px',
            borderRadius: '25px',
            padding: '30px',
            [theme.breakpoints.down('sm')]: {
                padding: '0 0 10px 0',
            },
        },
    },
    loginButton: {
        background: '#006D51',
        color: '#FFF',
        borderColor: '#006D51',
        '&:hover': {
            borderColor: '#FFF',
        },
    },
    passwordIcon: {
        position: 'absolute',
        top: '8px',
        right: '10px',
        zIndex: 1000,
        cursor: 'pointer',
    },
    popupContent: {
        textAlign: 'center',
    },
    logo: {
        width: '140px',
        margin: '1rem 4rem ',
        [theme.breakpoints.down('sm')]: {
            padding: '0px',
            marginBottom: '25px',
        },
    },
    loginTitle: {
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '23.7px',
        color: '#53CD94',
        marginBottom: '25px',
    },
    label: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16.59px',
        color: '#808285',
        display: 'flex',
        marginBottom: '10px',
        minHeight: '16px',
    },
    input: {
        display: 'flex',
        width: '100%',
        height: '36px',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16.59px',
        color: '#333333',
        borderRadius: '4px',
        border: '1px solid #E0E0E0',
        background: '#F1F2F2',
        padding: '10px 15px',
        minWidth: '100%',
        marginBottom: '17px',
        '&:focus': {
            borderRadius: 4,
            outlineColor: '#53CD94',
        },
    },
    passwordWrap : {
        position:"relative",
        marginBottom:"2rem"
    },
    iconInput: {
        position:"absolute",
    },
    iconPassword : {
        position:"relative",
        top:"0.4rem",
        left:"90%"
    },
    passwordBox:{
        display:"flex",
        justifyContent:"space-between",
    },

    termsWrap: {
        display: 'flex',
    },
    termsBlock: {
        alignItems: 'center',
        '& > span': {
            fontSize: '12px',
            display: 'inline-flex',
            '&:first-child': {
                marginLeft: '-10px',
                marginTop: '-3px',
            },
        },
    },
    termsText: {
        color: '#53CD94',
        textDecoration: 'none',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14.22px',
        cursor: 'pointer',
        "&:hover": {
            color:"#53CD94"
        }
    },
    warning: {
        color: '#EB5757',
        width: '250px',
        margin: 'auto',
    },
    button: {
        width: '100%',
        height: '52px',
        borderRadius: '8px',
        background: '#F2994A',
        boxShadow: 'none',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '14.22px',
        marginTop: '15px',
        marginBottom: '5px',
        '&:hover': {
            background: '#006D51',
        },
    },
    error: {
        fontSize: '14px',
        color: '#ba3939',
        background: '#ffe0e0',
        border: '1px solid #a33a3a',
        padding: '10px',
        display: 'flex',
        marginBottom: '30px',
        borderRadius: '8px',
    },
    linkRef: {
        textDecoration: 'none',
        cursor: 'pointer',
        color: '#0000FF',
        '&:hover': {
            color: '#53CD94',
        },
    },
}));
