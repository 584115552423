import { makeStyles } from "@material-ui/core/styles";

export const tickerStyles= makeStyles((theme) => ({
    root: {
      display: "flex",
      background: "#F1F2F2",
      fontSize: "12px",
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: "none",
    },
  
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
      paddingTop: "64px",
      overflowX: "hidden",
      [theme.breakpoints.down("xs")]: {
        paddingTop: "56px",
      },
    },
    container: {
      padding: theme.spacing(5),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    fixedHeight: {
      height: 240,
    },
  
    newsListBox: {
      cursor: "pointer",
      display: "inline-flex",
      color: "#808285",
      height: "30px",
      alignItems: "center",
      padding: "4px 20px",
      borderRight: "1px solid #E0E0E0",
      margin: "4px 0px",
      fontSize: "14px",
      fontWeight: 500,
      textTransform: "uppercase",
      "&:first-child": {},
    },
    iconBox: {
      background: "#E0E0E0",
      color: "#FFF",
      height: "16px",
      width: "16px",
      marginRight: "10px",
      borderRadius: "4px",
      display: "inline-flex",
    },
    iconBoxDown: {
      background: "#F2C94C",
    },
    iconBoxUp: {
      background: "#53CD94",
    },
    iconDown: {
      transform: "rotate(45deg)",
      height: "16px",
      width: "16px",
      color: "#FFF",
    },
    textbox: {
      color: "#53CD94"
    },

    icon:{
      transform: "rotate(-45deg)",
      height: "16px",
      width: "16px",
      color: "#FFF",
      fontWeight: "bold",
    },
    iconFlat:{
      height: "16px",
      width: "16px",
      color: "#FFF",
      fontWeight: "bold",
    },
    textboxDown: {
      color: "#F2C94C",
    },
  }));