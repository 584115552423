import { makeStyles } from '@material-ui/core/styles';

export const thankYouStyles = makeStyles((theme) => ({
    container:{
        display: 'flex',
        width: '100%',
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            paddingBottom: "1rem",
        }
    },
    pageTitle: {
        color: '#53CD94',
        fontSize: '32px',
        lineHeight: '38px',
        // textAlign: 'center',
        flexGrow: 1,
        '& span' :{
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '15px',
            color: '#808285'
        }
    },
    pageTitle__center: {
        textAlign:"center"
    },
    paper: {
        padding: '15px 25px',
        borderRadius: '12px',
        color: '#53CD94',
        fontSize: '25px',
        fontWeight: 'bold',
        boxShadow: 'none',
        margin: '0 auto',
        width: '100%',
        paddingTop: '46px',
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            overflow: 'auto'
        }
    },
    pageSectionTitle: {
        fontSize: '25px',
        fontWeight: 500,
        textAlign: "center",
        lineHeight: '23.7px',
        color: '#808285',
        marginBottom: '40px'
    },
    label: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16.59px',
        color: '#808285',
        display: "flex",
        marginBottom: '10px',
        minHeight: '16px'
    },
    input: {
        display: "flex",
        width: '100%',
        height: '36px',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16.59px',
        color: '#333333',
        borderRadius: '4px',
        border: '1px solid #E0E0E0',
        background: '#F1F2F2',
        padding: '10px 15px',
        '&:focus': {
            borderRadius: 4,
            outlineColor: '#53CD94',
        }
    },
    orderWrap: {
        display: 'flex',
        paddingBottom: '30px',
        paddingTop: '30px',
        borderBottom: '1px solid #E0E0E0',
        '&:last-child': {
            border: 'none',
            paddingBottom: '0px'
        }
    },
    pageProductDetails: {
        flexGrow: 1,
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '18.96px',
        color: '#333',
        paddingRight: '30px',
        '& span': {
            display: 'flex',
            fontSize: '14px',
            color: '#808285',
            marginTop: '10px'
        }
    },
    pageProductTotal: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '18.96px',
        color: '#333',
        textAlign: 'right'
    },
    pageHeading: {
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '23.7px',
        color: '#808285',
        marginTop: '25px',
        marginBottom: '20px'
    },
    paymentWrap: {
        display: 'flex',
        height: '60px',
        background: '#F1F2F2',
        alignItems: 'center',
        padding: '20px 25px',
        border: '1px solid #53CD94',
        borderRadius: '4px',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 15px',
        }
    },
    paymentLabel: {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '22.33px',
        color: '#53CD94',
        marginTop: '0px',
        marginBottom: '0px',
        flexGrow: 1
    },
    button: {
        width: '30%',
        height: '36px',
        borderRadius: '8px',    
        background: '#53CD94',
        margin: '50px 35%',
        boxShadow: 'none',
        fontSize: '12px',
        fontWeight: 400,
        textAlign: 'center',
        lineHeight: '14.22px',
        marginTop: '45px',
        '&:hover': {
            background: '#006D51',
            boxShadow: 'none',
        }
    },
    generalText: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16.59px',
        color: '#808285'
    },
    paymentImage: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}));