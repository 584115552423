import { makeStyles } from "@material-ui/core/styles";

export const lastPublicationStyles = makeStyles((theme) => ({
    orderTableTitle: {
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: "16.59px",
        color: "#333333",
        textAlign: "left",
        marginBottom: "10px",
        cursor: "pointer",
      },
      orderTableSubTitle: {
        fontSize: "11px",
        fontWeight: 400,
        lineHeight: "11.85px",
        color: "#F2994A",
        textAlign: "left",
        display: "flex",
        alignItems: "center",
        "& > span": {
          fontWeight: 800,
          marginLeft: "5px",
          marginRight: "5px",
          textTransform: "uppercase",
        },
      },
      orderTableSubTitleUp: {
        color: "#53CD94",
      },
      iconBox: {
        height: "16px",
        width: "16px",
        background: "#f1f1f1",
        marginRight: "10px",
        borderRadius: "4px",
        display: "inline-flex",
      },
      iconBoxDown: {
        background: "#F2C94C",
      },
      iconBoxUp: {
        background: "#53CD94",
      },
      icon: {
        //transform: "rotate(-45deg)",
        height: "16px",
        width: "16px",
        color: "#FFF",
        fontWeight: "bold",
      },
      orderType: {
        height: "25px",
        width: "73px",
        background: "rgb(221, 245, 234)",
        color: "rgb(83, 205, 148)",
        textAlign: "center",
        lineHeight: "25px",
        fontSize: "12px",
        fontWeight: 400,
        borderRadius: "4px",
        textTransform: "uppercase",
      },
      orderTableTitle1: {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "16.59px",
        color: "#53CD94",
      },
}));
