/*-- Libraries --*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";
import clsx from "clsx"
import {
  Typography,
  Button,
  Paper,
  Grid,
  CircularProgress,
  Box,
  FormGroup,
  Switch,
  FormControlLabel,
  Popover
} from "@material-ui/core";
import { withStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import { teal } from "@material-ui/core/colors";

/*-- redux --*/
import {
  getAllSales,
  allSales,
  loadingSales,
  updateIsPickedUp,
  updateIsDelivered,
} from "./Slice";

/*-- styles,components,functions --*/
import { mySalesStyles } from "./styles";
import LoadingData from "features/loader";
import EmptyMessage from "features/layout/emptyMessage";
import MatureComponent from "features/maturityMeter/index";
import formatNumber from 'functions/regex';
import LayoutWrapper from "features/layout/wrapper";

const theme = createTheme({
  overrides: {
    MuiCircularProgress: {
      svg: {
        color: "#4bb985",
      },
    },
  },
});
function CircularProgressWithLabel(props) {
  return (
    <ThemeProvider theme={theme}>
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="#4BB985"
        >
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
const GreenSwitch = withStyles({
  switchBase: {
    color: teal[300],
    "&$checked": {
      color: teal[500],
    },
    "&$checked + $track": {
      backgroundColor: teal[500],
    },
  },
  checked: {},
  track: {},
})(Switch);


export default function MySales(props) {
  const classes = mySalesStyles();
  const dispatch = useDispatch();
  const _getAllSales = useSelector(getAllSales);
  const loading = useSelector(loadingSales);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);
  const [isDelivered, setIsDelivered] = useState(false);
  const [isPickedUp, setisPickedUp] = useState(false);

  const handlePopoverOpen = (event, popoverId) => {
    setAnchorEl(event.currentTarget);
    setOpenedPopoverId(popoverId);
  };

  const changeIsPickedUp = (index, slug, event) => {
    dispatch(
      updateIsPickedUp({
        updateState: {
          id: index,
          value: event.target.checked,
          sales: _getAllSales,
        },
        updateEP: {
          sale_slug: slug,
          isReadyToPickup: event.target.checked,
        },
      })
    );
    setTimeout(() => setisPickedUp(event.target.checked), 800);
  };
  const changeIsDelivered = (index, slug, event) => {
    dispatch(
      updateIsDelivered({
        updateState: {
          id: index,
          value: event.target.checked,
          sales: _getAllSales,
        },
        updateEP: {
          sale_slug: slug,
          isShipped: event.target.checked,
        },
      })
    );
    setTimeout(() => setIsDelivered(event.target.checked), 800);
  };
  useEffect(() => {
    dispatch(allSales());
  }, [dispatch, isDelivered, isPickedUp]);

  const emptySaleData = {
    message: "Por el momento no tienes ventas concretadas.",
    linkTo: "/publicar-oferta",
    messageLink: "Publica tu producto",
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  return (
    <LayoutWrapper>
      <div className={classes.container}>
        <Typography
          variant="h2"
          component="h5"
          gutterBottom
          className={classes.pageTitle}
        >
          Mis Ventas
        </Typography>
        <Button
          variant="contained"
          component={Link}
          color="primary"
          className={classes.button}
          to="/publicar-oferta"
        >
          VENDER
        </Button>
      </div>
      <Paper className={classes.paper}>
          <Grid container spacing={2} direction="column">
          {
            loading ? <LoadingData/> : _getAllSales.length !== 0 ?
            ( _getAllSales.map((sale, index) => (
                <Grid className={classes.sale__item} key={index}>
                  <div className={classes.sale__status}>
                    <div className={classes.sale__flex} style={{paddingTop:"15px"}}>
                          <Typography
                            variant="h6"
                            component="h5"
                            gutterBottom

                            className={classes.sale__leftTitle}
                          >
                            {sale.status}

                            <span style={{marginLeft:'0.2rem'}}>
                              | <Moment format="YYYY-MM-DD HH:mm">
                                  {sale.date}
                              </Moment>
                            </span>
                          </Typography>
                          <div>
                            <span className={classes.state__circularProgress}>{`${sale.n_status}%`}</span>
                          </div>

                    </div>
                    <div className={classes.sale__flex} style={{paddingTop:"15px"}}>
                          <Typography
                            variant="h6"
                            component="h5"
                            gutterBottom
                            className={classes.sale__title}
                          >
                            PEDIDO N° {sale.order}{" "}
                            <span>{`| ${
                              sale.canBeShipped
                                ? " Envío a local del cliente"
                                : " Recojo en tienda"
                            }`}</span>
                          </Typography>
                    </div>
                  </div>
                  <div className={classes.sale__status}>
                        <div className={classes.sale__flex}>
                          <Typography
                            variant="h2"
                            component="h5"
                            gutterBottom
                            className={classes.sale__date}
                          >
                            {sale.status_description}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="h2"
                            component="h5"
                            gutterBottom
                            className={classes.sale__title}
                          >
                            <Link
                              to={`/mis-ventas/detail/${sale.sale_slug}`}
                              className={classes.sale__link}
                            >
                              Ver detalle
                            </Link>
                            <span
                              className={classes.sale__link}
                              onMouseEnter={(event) =>
                                handlePopoverOpen(event, sale.sale_slug)
                              }
                              onMouseLeave={handlePopoverClose}
                            >
                              | { sale.billToData.type_id === 1 ? 'Datos de la boleta' : 'Datos de la factura'}
                            </span>
                          </Typography>
                          {sale.isUploaded ? (
                            ""
                          ) : (
                            <Typography
                              variant="h6"
                              component="h5"
                              gutterBottom
                              className={classes.sale__voucher}
                            >
                              { sale.billToData.type_id === 1 ? 'Boleta pendiente' : 'Factura pendiente'}
                            </Typography>
                          )}
                        </div>
                  </div>
                  <Grid item xs={12} sm container  key={index} className={classes.listProducts}>
                    <Grid item xs container  direction="row" >
                        <Grid item xs container direction="column" className={classes.products}>
                          {
                            sale.products.map((item, index) =>
                              <Grid item  xs container  direction="row" className={classes.product} key={index}>
                                <Grid item className={classes.product__row}>
                                  <img
                                    src={item.image}
                                    alt={item.product_description}
                                    className={classes.product__image}
                                  />
                                </Grid>
                                <Grid item className={classes.product__row}>
                                <Typography
                                        variant="h2"
                                        component="h6"
                                        gutterBottom
                                        className={classes.product__title}
                                      >
                                        {item.product_description}
                                      </Typography>
                                      <MatureComponent
                                        maduration={item.maduration}
                                        maduration_description={
                                          item.maduration_description
                                        }
                                      />
                                  </Grid>
                                  <Grid item className={classes.product__row}>
                                  <Typography
                                          variant="h2"
                                          component="h6"
                                          gutterBottom
                                          className={classes.product__grade}
                                        >
                                          {item.grade}
                                        </Typography>
                                  </Grid>
                                  <Grid item className={classes.product__row}>
                                  <Typography
                                          variant="h2"
                                          component="h6"
                                          gutterBottom
                                          className={classes.product__textBold}
                                        >
                                          S/ {formatNumber(item.price)}
                                        </Typography>
                                  </Grid>
                                  <Grid item className={classes.product__row}>
                                  <Typography
                                          variant="h2"
                                          component="h6"
                                          gutterBottom
                                          className={classes.product__textBold}
                                        >
                                          {item.quantity} {item.product_unit}
                                        </Typography>
                                        <span className={classes.product__textSpan}>
                                          Vendido
                                        </span>
                                  </Grid>
                                  <Grid item className={classes.product__row}>
                                  <Typography
                                    variant="h2"
                                          component="h6"
                                          gutterBottom
                                          className={classes.product__textBold}
                                        >
                                          S/{formatNumber(item.quantity * item.price)}
                                        </Typography>
                                        <span className={classes.product__textSpan}>Total</span>
                                  </Grid>
                                </Grid>
                            )
                          }
                      </Grid>
                      </Grid>
                      <div style={{padding:"1rem 0.5rem"}} className= {classes.listProducts__circularPogress}>
                        <CircularProgressWithLabel
                              value={sale.n_status}
                              // className={classes.progressBarBox}
                            />
                      </div>
                      <Grid className={classes.order}>
                          <Grid
                            item
                            xs
                            container
                            direction="column"
                            className={classes.statusDetail}
                          >
                            <div>
                              <Popover
                                id="mouse-over-popover"
                                className={clsx(classes.popover)}
                                classes={{
                                  paper: classes.popover__paper,
                                }}
                                open={openedPopoverId === sale.sale_slug}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                              >
                                <Typography className={classes.popover__text}>
                                  Razón social : {sale.billToData.businessName}.
                                </Typography>
                                <Typography className={classes.popover__text}>
                                  RUC : {sale.billToData.ruc}
                                </Typography>
                              </Popover>
                            </div>

                            <FormGroup
                              component="row"
                              className={classes.formGroupContainer}
                            >
                              <FormControlLabel
                                control={
                                  <GreenSwitch
                                    checked={sale.readyToPickup}
                                    disabled={sale.readyToPickup}
                                    onChange={(event) =>
                                      changeIsPickedUp(
                                        index,
                                        sale.sale_slug,
                                        event
                                      )
                                    }
                                  />
                                }
                                label="Listo para despachar"
                              />

                              {sale.canBeShipped ? (
                                <FormControlLabel
                                  control={
                                    <GreenSwitch
                                      checked={sale.isDelivered}
                                      disabled={
                                        !sale.readyToPickup || sale.isDelivered
                                      }
                                      onChange={(event) =>
                                        changeIsDelivered(
                                          index,
                                          sale.sale_slug,
                                          event
                                        )
                                      }
                                    />
                                  }
                                  label="Pedido enviado"
                                />
                              ) : (
                                ""
                              )}
                            </FormGroup>
                          </Grid>
                        </Grid>
                  </Grid>
                </Grid>
              ))): <EmptyMessage data={emptySaleData} />
          }
        </Grid>
      </Paper>
    </LayoutWrapper>
  );
}
