import { makeStyles } from '@material-ui/core/styles';

export const marketsStyles= makeStyles((theme) => ({
  markets:{
    margin:"2rem"
  },
  title_container:{
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flow-root",
    },
  },
  title_text: {
    color: "#53CD94",
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "38px",
    alignItems: "baseline",
    flexGrow: 1,
  },
}))