import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

export const headerStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    // width: `calc(100% - 72px)`,
    background: "#4BB985",
    boxShadow: "none",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // [theme.breakpoints.down('xs')]: {
    //   width: `calc(100% - 55px)`,
    // }
  },
  toolbar: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
    },
  },

  title: {
    flexGrow: 1,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShiftHide: {
    marginLeft: "0px",
    width: `100%`,
  },
  search: {
    maxWidth: "481px",
    position: "relative",
    backgroundColor: "#FFF",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    "&:hover": {
      backgroundColor: "#FFF",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
    borderRadius: "40px",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#006D51",
    borderTopRightRadius: "18px",
    borderBottomRightRadius: "18px",
  },
  inputRoot: {
    color: "inherit",
    width: `calc( 100% - 56px ) !important`,
  },
  inputInput: {
    color: "#000",
    height: "40px",
    padding: theme.spacing(0),
    paddingRight: theme.spacing(1),
    fontSize: "14px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    transit3ion: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {},
  },
  menuButton: {
    backgroundColor: "#DDF5EA",
    color: "rgb(0, 109, 81)",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  logo: {
    [theme.breakpoints.down("xs")]: {
      display: "block",
      background: "#DDF5EA",
    },
  },
  menu: {
    color: "#FFF",
    "&:hover": {
      borderColor: "#FFF",
      backgroundColor: "rgb(0, 109, 81)",
    },
  },
  hideDesktop: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  hideMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  largeLogo: {
    width: "150px",
    marginBottom: "0.3rem",
    marginTop: "0.2rem",
    [theme.breakpoints.up("lg")]: {
      marginLeft: "2rem",
    },
  },
  smallLogo: {
    [theme.breakpoints.down("xs")]: {
      background: "#DDF5EA",
      padding: "0.5rem",
    },
  },
  loginButton: {
    background: '#006D51',
    color: '#FFF',
    borderColor: '#006D51',
    '&:hover': {
        borderColor: '#FFF',
        color:"#fff"
    }
},
}));
