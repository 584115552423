import { makeStyles } from "@material-ui/core";

export const myRecoverPasswordStyle = makeStyles((theme) => ({
    paper: {
        padding: "35px 55px",
        borderRadius: "12px",
        color: "#53CD94",
        fontSize: "25px",
        fontWeight: "bold",
        boxShadow: "none",
        width: "35%",
        margin:"4rem auto",
        overflow: "auto", 
        background:"white",
        
        [theme.breakpoints.down("md")]: {
            width: "50%",
            margin:"3rem auto",
        },
        [theme.breakpoints.down("sm")]: {
            width: "60%",
            margin:"3rem auto",
        },
        [theme.breakpoints.down("xs")]: {
          width: "90%",
          margin:"2rem auto",
        },
    },
    logo: {
        width: '140px',
        marginBottom: '25px',
    },
    label: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16.59px',
        color: '#808285',
        display: "flex",
        marginBottom: '10px',
        minHeight: '16px',
        textAlign:"justify",
    },
    list:{
        fontSize: '14px',
        fontWeight: 400,
        color: '#808285',
        display: "flex",
        textAlign:"justify",
        "& lo":{
            "& li":{
                paddingBottom: "15px",
            }
            
        }
    },
    input: {
        display: "flex",
        width: '100%',
        height: '36px',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16.59px',
        color: '#333333',
        borderRadius: '4px',
        border: '1px solid #E0E0E0',
        background: '#F1F2F2',
        padding: '10px 15px',
        minWidth: '100%',
        marginBottom: '17px',
        '&:focus': {
            borderRadius: 4,
            outlineColor: '#53CD94',
        }
    },
    button: {
        width: '100%',
        height: '52px',
        borderRadius: '8px',
        background: '#F2994A',
        boxShadow: 'none',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '14.22px',
        marginTop: '15px',
        marginBottom: '30px',
        '&:hover': {
            background: '#006D51',
        }
    },
    container:{
        display:"flex",
        justifyContent:"center",
    },
   
    termsText: {
        display:"flex",
        justifyContent:"center",
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14.22px',
        color: '#808285',
        marginBottom: '0px',
    },
    error: {
        fontSize: '14px',
        color: '#ba3939',
        background: '#ffe0e0',
        border: '1px solid #a33a3a',
        padding: '10px',
        display: 'flex',
        marginBottom: '30px',
        borderRadius: '8px',
        justifyContent:"center",
    },
    check: {
        width:"39px",
        margin: "0 45%",
    }
  
    
}))