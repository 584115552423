/*-- Libraries --*/
import { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  Button,
  Radio,
  Paper,
  FormControlLabel,
  RadioGroup,
  Box,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";

/*-- redux --*/
import { receivedConfirmation } from "../slices";

/*-- styles,components,functions --*/
import { receiptStyles } from "./styles";
import LayoutWrapper from "features/layout/wrapper";

export default function ProductReceipt(props) {
  const classes = receiptStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const order = props.location.state;
  const [isReceived, setIsReceived] = useState("true");
  const [value, setValue] = useState(0);
  const [comment, setComment] = useState("");
  const [feedback, setFeedback] = useState("");
  const [errorComment, setErrorComment] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState(false);

  const handleChange = (event) => {
    setIsReceived(event.target.value);
  };

  const commentChange = (event) => {
    setComment(event.target.value);
  };
  const feedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const onSubmit = () => {
    let submitIsNotReceived = true;
    if (comment == null || comment === "") {
      submitIsNotReceived = false;
      setErrorComment(true);
    } else {
      setErrorComment(false);
    }

    let submitIsReceived = true;
    if (feedback == null || feedback === "") {
      submitIsReceived = false;
      setErrorFeedback(true);
    } else {
      setErrorFeedback(false);
    }

    if (isReceived === "true" && submitIsReceived) {
      const data = {
        confirmed: "true",
        cart: order,
        rating: value,
        ratingFeedback: feedback,
      };

      dispatch(receivedConfirmation(data));
      history.push(`/mis-ordenes`);
    } else if (isReceived === "false" && submitIsNotReceived) {
      const data = {
        confirmed: "false",
        cart: order,
        comment: comment,
      };
      history.push(`/mis-ordenes`);
      dispatch(receivedConfirmation(data));
    }
  };

  return (
    <LayoutWrapper>
      <div className={classes.container}>
        <Typography
          variant="h2"
          component="h5"
          gutterBottom
          className={classes.pageTitle}
        >
          Confirmación de recibido
        </Typography>
      </div>
      <Paper className={classes.paper}>
        <Typography className={classes.title}>
          ¿Ya recibiste los productos?
        </Typography>
        <RadioGroup
          value={isReceived}
          onChange={handleChange}
          className={classes.radioGroup}
        >
          <Grid item container direction="row" className={classes.radioButton}>
            <FormControlLabel value="true" control={<Radio />} />
            <p>¡Sí, ya tengo mi pedido!</p>
          </Grid>
          <Grid item container direction="row" className={classes.radioButton}>
            <FormControlLabel value="false" control={<Radio />} />
            <p>No, quiero notificar un problema</p>
          </Grid>
        </RadioGroup>
        {isReceived === "false" ? (
          <>
            <Typography className={classes.subtitle}>
              Describe el inconveniente presentado
            </Typography>
            <textarea
              placeholder={"Cuéntanos el incoveniente"}
              className={classes.input}
              style={{ height: "auto" }}
              rows={6}
              onChange={commentChange}
              value={comment}
            ></textarea>
            {errorComment ? (
              <span className={classes.errorLabel}>
                Por favor coméntanos el incoveniente
              </span>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <Box borderColor="transparent">
              <Typography component="legend" className={classes.subtitle}>
                Por favor califica tu experiencia comprando en Whipay
              </Typography>
              <Rating
                style={{ margin: "1rem 0" }}
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              />
            </Box>
            <span className={classes.caption}>
              Para nosotros es muy importante tener usuarios satisfechos, por lo
              que te invitamos a dejar tu sugerencia de mejora.
            </span>
            <textarea
              placeholder={"Escribe aquí"}
              className={classes.input}
              style={{ height: "auto" }}
              rows={6}
              onChange={feedbackChange}
              value={feedback}
            ></textarea>
            {errorFeedback ? (
              <span className={classes.errorLabel}>
                Por favor registra tu comentario
              </span>
            ) : (
              ""
            )}
          </>
        )}
        <Grid item container direction="row">
          <Button
            variant="contained"
            className={classes.submit}
            onClick={onSubmit}
          >
            ENVIAR
          </Button>
          <Button
            className={classes.cancelButton}
            component={Link}
            to="/mis-ordenes"
          >
            Cancelar
          </Button>
        </Grid>
      </Paper>
    </LayoutWrapper>
  );
}
