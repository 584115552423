/*-- Libraries --*/
import { useState, useEffect } from "react";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import clsx from "clsx";
import Moment from "react-moment";
import { Grid, Typography, Button, Paper } from "@material-ui/core";

/*-- redux --*/
//import GetAppIcon from "@material-ui/icons/GetApp";
import { getDetailsPerOrder } from "../slices";

/*-- styles,components,functions --*/
import { orderDetailStyles } from "./styles";
import LoadingData from "features/loader";
import formatNumber from 'functions/regex';
import LayoutWrapper from "features/layout/wrapper";

export default function OrderDetail(props) {
  const classes = orderDetailStyles();
  const orderSlug = props.match.params.orderSlug;
  const history = useHistory();

  let { url } = useRouteMatch();
  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (detail) {
      getDetailsPerOrder({ order_slug: orderSlug }, (data) => {
        setDetail(data);
      },(isLoading)=>{
        setLoading(isLoading)
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSlug]);

  const trackShip = () => {
    history.push(`/mis-ordenes/detalle/${orderSlug}/tracking`);
  };
  const sumCosts = (v1, v2) => {
    return v1 + v2;
  };
  
  return (
    <LayoutWrapper>
      <div className={classes.container}>
        <Typography
          variant="h2"
          component="h5"
          gutterBottom
          className={classes.pageTitle}
        >
          Detalles de mi compra
        </Typography>
      </div>
      <Paper className={classes.paper}>
        {loading? 
          < LoadingData /> : 
        <>
          <Grid item container direction="row" justifyContent="space-between">
            <Grid>
              <Typography
                variant="h2"
                component="h5"
                gutterBottom
                className={clsx(classes.statusTitle, classes.inProcessColor)}
                style={{ fontWeight: 400 }}
              >
                Pedido N° {detail.orderNumber} realizado el <Moment format="YYYY-MM-DD HH:mm">{detail.date}</Moment>
              
              </Typography>
              <span className={classes.subtitle}>
              {detail.status} : {detail.status_date}
              </span>
            </Grid>
            <Grid className={classes.column}>
              <Button
                variant="outlined"
                className={classes.secondaryButton}
                onClick={trackShip}
              >
                Rastrear envío
              </Button>
              <Link
                to={{
                  pathname: `${url}/ayuda`,
                  state: orderSlug,
                }}
                className={classes.hiperLink}
              >
                Necesito ayuda
              </Link>
            </Grid>
          </Grid>

        <Grid container className={classes.summaryContainer}>
          <Grid
            item
            xs
            container
            direction="column"
            className={classes.summaryRow}
          >
            <h3>Dirección de envío</h3>
            <span>{detail.shipTo}</span>
            <span>{detail.address}</span>
            <span>{detail.district}</span>
          </Grid>
          <Grid
            item
            xs
            container
            direction="column"
            className={classes.summaryRow}
          >
            <h3>Método de pago</h3>
            <span>{detail.cardNumber}</span>
          </Grid>
          <Grid
            item
            xs
            container
            direction="column"
            className={classes.summaryRow}
            style={{ flexGrow: "1" }}
          >
            <h3>Resumen del pedido</h3>
            <Grid
              item
              xs
              container
              direction="row"
              //className={classes.statusDetail}
            >
              <span style={{ flexGrow: "1" }}>Productos:</span>
              <span>
                S/ {formatNumber(!detail.subTotal ? 0 : detail.subTotal)}
              </span>
            </Grid>
            <Grid item xs container direction="row">
              <span style={{ flexGrow: "1" }}>Envío a domicilio:</span>
              <span>
                S/{formatNumber(!detail.shipToCost ? 0 : detail.shipToCost)}
              </span>
            </Grid>
            <Grid item xs container direction="row">
              <h3 style={{ flexGrow: "1" }}>Total: </h3>
              <h3>
                S/{formatNumber(sumCosts(detail.shipToCost, detail.subTotal))}
              </h3>
            </Grid>
          </Grid>
        </Grid>
        </>
				}
      </Paper>
			<Paper className={classes.paper}>
        <Typography
          variant="h2"
          component="h5"
          gutterBottom
          style={{ fontWeight: 400,fontSize:"20px" }}
        >
          Lista de órdenes
        </Typography>
				<Grid container spacing={2} direction="column">
					{	!detail.orders ? (
							<p>No Hay datos</p>
						) : (
							detail.orders.map((order, index) => (
								<Grid item xs={12} sm container className={classes.order__perSeller} key={index}>
									<Grid item xs container  direction="column"  spacing={2}>
										<Grid item xs>
											<Typography gutterBottom variant="subtitle1">
												<Link
													to={`/vendedor/${order.seller_slug}`}
													className={classes.hiperLink}
												>
													{order.seller}
												</Link>
											</Typography>
											<Typography variant="body2">
													<span className={clsx(classes.notDisplay, classes.invoice)}>
														{!order.invoice ? (
															"Factura pendiente"
														) : (
															<a href={order.invoice}    
															target="_blank"
															rel="noopener noreferrer">Factura</a>
														)}
                  				</span>
												</Typography>
										</Grid>
										{order.product_list.map((item, index) => (
											<Grid item  xs container  direction="row" className={classes.order__items} key={index}>
											<Grid item className={classes.order__item}>
												<Typography variant="subtitle1" >
													{item.product}
												</Typography>
												<Typography variant="body2" color="textSecondary">
												{item.maduration}
											</Typography>
											</Grid>
											<Grid item className={classes.order__item}>
												<Typography variant="body2" color="textSecondary">
													{item.grade}
												</Typography>
											</Grid>
											<Grid item className={classes.order__item}>
													<Typography variant="body2" color="textSecondary" className={classes.order__itemPrice}>
                             <span> S/ {formatNumber(item.price)}</span> x {item.measureUnit}
													</Typography>
											</Grid>
											<Grid item className={classes.order__item}>
                        <Typography variant="body2" color="textSecondary">
                          Cantidad: {item.quantity}
                        </Typography>
											</Grid>
											<Grid item className={classes.order__item}>
												<Typography variant="body2" color="textSecondary">
												S/ {formatNumber(item.price * parseInt(item.quantity))}
												</Typography>
											</Grid>
										</Grid>
										))}
									</Grid>
									<Grid item>
										<Typography variant="subtitle1">S/ {formatNumber(order.product_list.reduce((n, item ) => n + (item.price*item.quantity), 0))}</Typography>
								</Grid>
								</Grid>
						) ))}
				</Grid>
			</Paper>
			{/* <Grid
          item
          container
          direction="row"
          className={clsx(classes.detailTitles, classes.notDisplay)}
        >
          <Grid
            item
            xs
            className={clsx(classes.detailTitle, classes.notDisplay)}
          >
            Mayorista
          </Grid>
          <Grid
            item
            xs
            className={clsx(classes.detailTitle, classes.notDisplay)}
            style={{ textAlign: "start" }}
          >
            Producto
          </Grid>
          <Grid
            item
            xs
            className={clsx(classes.detailTitle, classes.notDisplay)}
            style={{ textAlign: "center" }}
          >
            Calibre
          </Grid>
          <Grid
            item
            xs
            className={clsx(classes.detailTitle, classes.notDisplay)}
            style={{ textAlign: "center", paddingLeft: "1.5rem" }}
          >
            Precio
          </Grid>
          <Grid
            item
            xs
            className={clsx(classes.detailTitle, classes.notDisplay)}
            style={{ textAlign: "center" }}
          >
            Cantidad
          </Grid>

          <Grid
            item
            xs
            className={clsx(classes.detailTitle, classes.notDisplay)}
            style={{ textAlign: "center" }}
          >
            Total
          </Grid>
          <Grid
            item
            sm
            className={clsx(classes.detailTitle, classes.notDisplay)}
            style={{ textAlign: "center" }}
          >
            Factura
          </Grid>
        </Grid> */}
        {/* <Grid
          item
          container
          direction="column"
          className={classes.detailContainer}
        >
          {!detail.orders ? (
            <p>No Hay datos</p>
          ) : (
            detail.orders.map((order, index) => (
              <Grid
                item
                container
                direction="row"
                key={index}
                className={classes.detailRow}
              >
                <Grid item className={classes.seller} style={{ width: "15%" }}>
                  <Link
                    to={`/vendedor/${order.seller_slug}`}
                    className={classes.hiperLink}
                  >
                    {order.seller}
                  </Link>
                </Grid>
                <Grid item xs container direction="column">
                  {order.product_list.map((item, index) => (
                    <Grid
                      item
                      container
                      direction="row"
                      key={index}
                      justify="space-between"
                      className={classes.detailItem}
                    >
                      <span style={{ width: "120px",display:"flex",flexDirection:"column"}}>{item.product} 
                      <span style={{fontSize :" 12px"}}>({item.maduration})</span>
                      </span>
                      <span
                        style={{ width: "120px" }}
                        className={classes.notDisplay}
                      >
                       {item.grade}
                      </span>
                      <span
                        style={{ width: "100px" }}
                        className={classes.notDisplay}
                      >
                        S/ {formatNumber(item.price)}
                      </span>
                      <span
                        style={{ width: "100px" }}
                        className={classes.notDisplay}
                      >
                        {item.quantity} {item.measureUnit}
                      </span>
                      <span style={{ width: "100px" }}>
                        S/
                        {formatNumber(item.price * parseInt(item.quantity))}
                      </span>
                    </Grid>
                  ))}
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  className={clsx(classes.seller, classes.invoiceRow)}
                  style={{ width: "13%" }}
                >
                  <span className={clsx(classes.notDisplay, classes.invoice)}>
                    {!order.invoice ? (
                      "Factura pendiente"
                    ) : (
                      <a href={order.invoice}    
                       target="_blank"
                      rel="noopener noreferrer">Factura</a>
                    )}
                  </span>
                </Grid>
              </Grid>
            ))
          )}
        </Grid>
        <Grid className={classes.sumContainer}>
          <span>Total</span>
          <span>
            S/{formatNumber(sumCosts(detail.shipToCost, detail.subTotal))}
          </span>
        </Grid>
        <span className={classes.downloadBox}>
          <Link to={{ pathname: "https://www.google.com/" }} target="_blank" rel="noopener noreferrer">
            Descargar detalle
          </Link>
        </span> */}
    </LayoutWrapper>
  );
}
