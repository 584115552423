import { Paper } from "@material-ui/core";
import React, { useState } from "react";
import { myUpdatePasswordStyle } from "../UpdatePassword/styles";
import logo from "assets/images/logo.svg";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useLocation } from "react-router";
import { changePasswordEP } from "endpoints";
import axios from "axios";
import check from "assets/images/check.svg";
import LayoutWrapper from "features/layout/wrapper";

export default function UpdatePassword () {
    const classes = myUpdatePasswordStyle();
    const location= useLocation();
    const {pathname}  = location;

    const [error, setError] = useState(false);
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [userError, setUserError] = useState(false);
    const [messagePassword, setMessagePassword] = useState(false)
    const [changeNewPassword, setChangeNewPassword] = useState(false);
    const [messagePasswordInvalid, setMessagePasswordInvalid] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);

    const newPasswordChange = (event) => {
        setError(false);
        setPassword(event.target.value);
        setUserError(false);
        setMessagePasswordInvalid(false);
        setInvalidPassword(false);
    }

    const repeatNewPassword = (event) => {
        setError(false);
        setRepeatPassword(event.target.value);
        setUserError(false);
        setMessagePasswordInvalid(false);
        setInvalidPassword(false);
    }

    const updatePassword = async () => {
        var isShowed = false;
        setMessagePassword(false);
        setError(false);
        setMessagePasswordInvalid(false);
        setInvalidPassword(false);

        if (!password){
            setError(true);
            isShowed = true;
            return false;
        }

        if (password !== repeatPassword ){
            setMessagePassword(true);
            isShowed = true;
            return false;
        }

        
        const re = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/;
        if (!isShowed && !re.test(password)){
             setMessagePasswordInvalid(true);
             return false;
         }
        
        await axios.post(changePasswordEP, {
            token: pathname.split("/")[2],
            password1: password,
            password2: repeatPassword,
        }).then ((response) => {
            setChangeNewPassword(true);
        }).catch(function(error) {
            setInvalidPassword(true);
        })
    }

    return (
        <LayoutWrapper>
            <Paper className={classes.paper}>
                <div className={classes.container}>
                <img src={logo} className={classes.logo} alt={""} />
                </div>
                {changeNewPassword ? (
                    <>
                        <img src={check} className={classes.check} alt={""}/>
                        <Typography
                            variant="h2"
                            component="h5"
                            gutterBottom
                            className={classes.info}
                            style={{
                                fontSize: "14px",
                                fontWeight: 700,
                                lineHeight: "23.7px",
                                color: "#333333",
                                marginBottom: "25px",
                                display:"flex",
                                textAlign:"justify",
                                fontFamily: '"Rubik",sans-serif',
                                paddingTop:"25px",
                            }}
                        >
                        Tu contraseña se ha cambiado, ya puedes iniciar sesión con tu nueva contraseña.
                        </Typography>
                    </>
                ): 
                    <>
                    <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.loginTitle}
                    style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        lineHeight: "23.7px",
                        color: "#333333",
                        marginBottom: "25px",
                        display:"flex",
                        justifyContent:"center",
                        
                    }}
                >
                Cambiar Contraseña
                </Typography>
                {messagePassword ? (
                      <Typography
                      variant="h2"
                      component="span"
                      gutterBottom
                      className={classes.error}
                      style={{ fontSize: "14px", marginBottom: "10px" }}
                    >
                      !Las contraseñas no coinciden!
                    </Typography>
                ): userError}
                {error ? (
                    <Typography
                    variant="h2"
                    component="span"
                    gutterBottom
                    className={classes.error}
                    style={{ fontSize: "14px", marginBottom: "10px" }}
                  >
                    ¡Por favor, ingresa una nueva contraseña!
                  </Typography>
                ): userError}
                {invalidPassword ? (
                      <Typography
                      variant="h2"
                      component="span"
                      gutterBottom
                      className={classes.error}
                      style={{ fontSize: "14px", marginBottom: "10px" }}
                    >
                      ¡Error en la conexión!
                    </Typography>
                ) : userError}
                <div style={{paddingTop:"0px"}}>
                <label className={classes.label}>Escribe una nueva contraseña</label>
                <input
                    type={"password"}
                    required
                    placeholder={"contraseña"}
                    className={classes.input}
                    id={"password"}
                    value={password}
                    onChange={newPasswordChange}
                    autoFocus
                />
                <p className={classes.label2}>Entre 8 a 16 caracteres, incluye una letra mayúscula y un número.</p>
                </div>
                <div style={{paddingTop:"25px"}}>
                <label className={classes.label}>Vuelva a escribir la contraseña</label>
                <input
                    type={"password"}
                    required
                    placeholder={"confirmación"}
                    className={classes.input}
                    id={"password"}
                    value={repeatPassword}
                    onChange={repeatNewPassword}
                    autoFocus
                />
                {messagePasswordInvalid ? (
                    <Typography
                    variant="h2"
                    component="span"
                    gutterBottom
                    className={classes.error}
                    style={{ fontSize: "14px", marginBottom: "10px" }}
                  >
                    ¡La contraseña debe contener, como mínimo, ocho caracteres, con al menos una letra mayúscula, una minúscula y un número!
                  </Typography>
                ): null}
                </div>
                <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className={classes.button}
                    onClick={updatePassword}
                >
                CONTINUAR
                </Button>
                    </>
                }
                
            </Paper>
        </LayoutWrapper>
    )
}