import { createSlice } from '@reduxjs/toolkit';
import axios from "axios";

import {
    // activeSalesEP, 
    bannersEP, 
    categoriesEP} 
from 'endpoints';

export const productsSlice = createSlice({
    name: 'products',
    initialState: {
        list: [],
    },
    reducers: {
        setProducts: ( state, action ) => {
            state.list = action.payload;
        },
    }
});

export const { setProducts } = productsSlice.actions;
export const products = state => state.products.list;


// export const getProducts = ( state ) => async dispatch => {

//     const data = await axios.get(activeSalesEP).then((response) => {

//         return response.data;

//     });

//     dispatch(setProducts(data));
// }


export const getBannerImages = (callback) => {
    axios.get(bannersEP).then((response)=>{
        callback(response.data)
    })
}

export const getCategoriesImages = (callback) => {
    axios.get(categoriesEP).then((response)=>{
        callback(response.data)
    })
}
export default productsSlice.reducer;
