import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { singleActiveSaleEP, profileEP, activeSalesEP } from "endpoints";
import { setData } from "pages/Seller/Slice";

export const shopSingleSlice = createSlice({
  name: "singleProduct",
  initialState: {
    singleOffer: {
      sale: {
        id: "",
        date: "",
        gallery: [],
        grade: "",
        image: "",
        long_description: "",
        price: "",
        price_variation: 0,
        product_description: "",
        product_name: "",
        product_unit: "",
        quantity: 0,
        seller: "",
        product_slug: "",
        short_description: "",
        starred: false,
        status: "",
      },
    },
    loadingOffer: false,
  },
  reducers: {
    setProductData: (state, action) => {
      state.singleOffer = action.payload;
      state.loadingData = false;
    },
    setLoadingOffer: (state,action) => {
      state.loadingOffer = action.payload;
    }
  },
});

export const { setProductData,setLoadingOffer } = shopSingleSlice.actions;

export const singleProduct = (state) => state.singleProduct.singleOffer;
export const loadingOffer = (state) => state.singleProduct.loadingOffer;

export const getSingleProduct = (state, cancel) => async (dispatch) => {

  dispatch(setLoadingOffer(true));

  const data = await axios
    .post(singleActiveSaleEP, { sale_slug: state }, cancel)
    .then((response) => {
      // TODO:reuse getData from seller
      return response.data;
    });

  dispatch(setProductData(data));
  dispatch(setLoadingOffer(false));

  const sellerData = await axios
    .post(profileEP, {
      seller_slug: data.sale.seller_slug,
    })
    .then((response) => {
      return response.data;
    });
  dispatch(setData(sellerData));
};

export const getRelatedProducts = async (action, callback) => {
  const data = await axios.post(activeSalesEP, action).then((response) => {
    return response.data;
  });
  callback(data);
};
export default shopSingleSlice.reducer;
