import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import {notificationsEP} from 'endpoints'


export const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    loading: true,
    list: [],
    unseen: 0,
  },
  reducers: {
    setNotification: (state, action) => {
      state.list = action.payload.list;
      state.unseen = action.payload.unseen;
      state.loading = false;
    },
    setAsSeen: (state) => {
      state.unseen = 0;
    },
  },
});

export const { setNotification, setAsSeen } = notificationSlice.actions;

export const isloading = (state) => state.notifications.loading;
export const list = (state) => state.notifications.list;
export const unseen = (state) => state.notifications.unseen;

export const getNotifications = (text) => async (dispatch) => {
  const data = await axios.get(notificationsEP).then((response) => {
    return response.data;
  });

  dispatch(setNotification(data));
};

export const markAsSeen = (id, success) => async (dispatch) => {
    await axios.patch(notificationsEP,{
      id:id
    }).then((response) => {
      if(response.status === 202){
        success(true)
      dispatch(setNotification(response.data.noti));
      }
    } 
    );  
};


export default notificationSlice.reducer;
