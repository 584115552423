import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    warning: { main: '#F2C94C' }
  },
  typography: {
    fontFamily: [
      '"Rubik"',
      'sans-serif'
    ].join(','),
  }
});
