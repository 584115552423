/* eslint-disable react-hooks/exhaustive-deps */
/*-- Libraries --*/
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { Grid } from '@material-ui/core';
import FilterListSharpIcon from '@material-ui/icons/FilterListSharp';

/*-- redux --*/
import {
    getCategories,
    getProductList,
    getVarietyList,
    getRelatedProducts,
    getProducts
} from './Slice';

/*-- styles,components,functions --*/

import Dropdown from 'features/product/common/dropdown';
import { filterStyles } from './styles';

export default function

ShopFilter() {
    let { market_id } = useParams();
    const dispatch = useDispatch();
    const classes = filterStyles();
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [varieties, setVarieties] = useState([]);
    const [product, setProduct] = useState('');
    const [category, setCategory] = useState('');
    const [variety, setVariety] = useState('');
    const [grade, setGrade] = useState('');
    const [filter, setFilter] = useState(false);
    const [grades, setGrades] = useState([]);

    useEffect(() => {


        if (market_id) {
            setCategory(market_id);
            setProduct('');
            setVariety('');
            setGrade('');
            setGrades([]);

            getProductList({ id: market_id },
                (response) => { setProducts(response);
            });
        } else {
            // dispatch(getProducts());
            getCategories((response) => {
                setCategories(response);
            });
            setCategory('');
        }
    }, [market_id]);

    const categoryChange = (event) => {

        setCategory(event.target.value);

        if (event.target.value) {
            getProductList({ id: event.target.value }, (response) => { setProducts(response) });

        } else {
            setProducts([]);
            setVarieties([]);
        }
        setProduct('');
        setVariety('');
        setGrade('');
        setGrades([]);

    };

    const productChange = (event) => {
        setProduct(event.target.value);

        if (event.target.value) {

            getVarietyList({ id: event.target.value }, (response) => {
                setVarieties(response);
            });
        } else {
            setVarieties([]);
        }
        setVariety('');
        setGrade('');
        setGrades([]);
    };

    const findItemById = (array, value) => {
        const filteredData = array.filter(
            (item) => item.id === parseInt(value)
        );
        return filteredData;
    };

    const verityChange = (event) => {
        setVariety(event.target.value);

        setVariety(event.target.value);

        if (event.target.value) {
            setGrades(findItemById(varieties, event.target.value)[0].grades);
        } else {
            setGrades([]);
        }
        setGrade('');
    };

    const gradeChange = (event) => {
        setGrade(event.target.value);
    };

    const categorySect = {
        name: 'Categoría',
        data: categories,
        selected: category
    };

    const pro = {
        name: 'Producto',
        data: products,
        selected: product
    };

    const variations = {
        name: 'Variedad',
        data: varieties,
        selected: variety
    };

    const types = {
        name: 'Calibre',
        data: grades,
        selected: grade
    };

    const toggleFilter = () => {
        setFilter(!filter);
    }

    useEffect(() => {
        if (category) {
            dispatch(getRelatedProducts({ category: parseInt(category), product: parseInt(product), variety: parseInt(variety), grade: parseInt(grade) }));
        } else {
            dispatch(getProducts());
        }

    }, [dispatch, category, product, variety, grade])

    return (
        <>
            <div className={classes.container1}>
                <div
                    className={clsx(
                        classes.firstBlock,
                        filter && classes.showFilter
                    )}
                >
                    <FilterListSharpIcon
                        className={classes.filterIcon}
                        onClick={toggleFilter}
                    />
                    <span className={classes.filterTitle}>
                        Filtrar productos{' '}
                    </span>
                </div>
                <Grid container>
                    <Grid item xs={12} sm={6} lg={3}>
                        {
                            !market_id ? <div className={clsx(classes.dropDown, classes.hideFilter, filter && classes.showFilter)}>
                                <Dropdown extraClass={classes.dropDown} data={categorySect} width='98%' value={category} onChnage={categoryChange} name={'category'} />
                            </div> : null
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <div className={clsx(classes.dropDown, classes.hideFilter, filter && classes.showFilter)}>
                            <Dropdown extraClass={classes.dropDown} data={pro} width='98%' value={product} onChnage={productChange} name={'product'} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <div className={clsx(classes.dropDown, classes.hideFilter, filter && classes.showFilter)}>
                            <Dropdown extraClass={classes.dropDown} data={variations} width='98%' value={variety} onChnage={verityChange} name={'variation'} />
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3}>
                        <div className={clsx(classes.dropDown, classes.hideFilter, filter && classes.showFilter)}>
                            <Dropdown extraClass={classes.dropDown} data={types}  width='98%' value={grade} onChnage={gradeChange} name={'grade'} />
                        </div>
                    </Grid>

                </Grid>

            </div>
        </>
    );
}
