import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { thankYouStyles } from "./styles";
import { Link, useLocation } from "react-router-dom";
import AppStepper from "features/Stepper";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getCart } from "pages/Cart/cartSlice";
import LayoutWrapper from "features/layout/wrapper";

export default function ThankYou({ props }) {
  const classes = thankYouStyles();
  const [cartData, setCartData] = useState([]);
  const { pathname } = useLocation();

  const data = useSelector(getCart);

  useEffect(() => {
    if (data.length > 0) {
      setCartData(data);
    }
  }, [data]);

  return (
    <LayoutWrapper>
      <div className={classes.container}>
        <Typography
          variant="h2"
          component="h5"
          gutterBottom
          className={classes.pageTitle}
        >
          Checkout <span>( {cartData.length} Producto )</span>
        </Typography>
        <AppStepper {...props} stepNumber={3} />
      </div>
      <Grid className={classes.paper} item xs={12} sm={12} md={8}>
        <Paper className={classes.paper}>
          <Typography
            variant="h2"
            component="h5"
            gutterBottom
            className={clsx(classes.pageTitle,classes.pageTitle__center)}
          >
            Gracias
          </Typography>
          {pathname === "/thankyou" ? (
            <Typography
              variant="h2"
              component="h5"
              gutterBottom
              className={classes.pageSectionTitle}
              style={{ marginBottom: "0px" }}
            >
              Tu pedido ha sido confirmado
            </Typography>
          ) : (
            <>
              <Typography
                variant="h2"
                component="h5"
                gutterBottom
                className={classes.pageSectionTitle}
                style={{ marginBottom: "0px" }}
              >
                Estamos validando tu pago.
              </Typography>
              <Typography
                variant="h2"
                component="h5"
                gutterBottom
                className={classes.pageSectionTitle}
                style={{ marginBottom: "0px" }}
              >
                En breve, te confirmaremos por correo electrónico.
              </Typography>
            </>
          )}
          <Button
            variant="contained"
            component={Link}
            size="medium"
            color="primary"
            className={classes.button}
            to="/mercado"
          >
            CONTINUAR COMPRANDO
          </Button>
        </Paper>
      </Grid>
    </LayoutWrapper>
  );
}
