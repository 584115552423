import React from 'react';
import {Typography, Divider, Paper} from "@material-ui/core";
import { myTermsAndConditionsStyle } from "./style";
import LayoutWrapper from 'features/layout/wrapper';

export default function TermsAndConditions(props) {
    const classes = myTermsAndConditionsStyle();  
    return (
        <LayoutWrapper>
            <Paper className={classes.paper}>
                <div className={classes.container}>
                    <Typography
                    variant="h2"
                    component="h5"
                    gutterBottom
                    className={classes.pageTitle}
                    >
                    Términos y Condiciones Generales
                    </Typography>
                    <p>Ultima actualización:<strong style={{color:"black"}}> 09/09/21</strong></p>
                </div>
                <Divider/>
                <div className={classes.container2}>
                    <p>
                    El presente documento describe los términos y condiciones generales (los "Términos y Condiciones Generales") aplicables al acceso y uso de los servicios 
                    (en adelante, "los Servicios") ofrecidos por <strong>JW TECHNOLOGIES S.A.C.</strong> (en adelante, <strong>“WHIPAY”</strong>) dentro del sitio web https://www.whipay.pe, 
                    sus subdominios y/u otros dominios (URLs) vinculados y/o relacionados (en adelante, el "Sitio"), en donde éstos Términos y Condiciones se encuentren. 
                    Cualquier persona que desee acceder y/o suscribirse y/o usar el Sitio o los Servicios podrá hacerlo sujetándose a los Términos y Condiciones Generales, 
                    junto con todas las demás políticas, normativas, comunicados y principios que rigen https://www.whipay.pe y que son incorporados al presente directamente o 
                    por referencia o que son explicados y/o detallados en otras secciones del Sitio. En consecuencia, todas las visitas, todas las transacciones que se realicen en este Sitio, 
                    así como sus efectos jurídicos, quedarán regidos por estas reglas y sometidos a la legislación aplicable en la República del Perú.
                    </p>
                    <p>
                    Los Términos y Condiciones contenidos en el presente documento, se aplicarán y se entenderán como parte integral de todos los actos y contratos que se celebren o ejecuten 
                    mediante los sistemas de oferta y negociaciones comprendidos en este sitio, entre los usuarios de este sitio y por cualquiera de las otras sociedades o empresas que sean 
                    filiales o vinculadas y que hagan uso de este sitio, a las cuales se las denominará -en adelante- también en forma indistinta como el “Usuario” o los “Usuarios", según 
                    convenga al sentido del texto.
                    </p>
                    <p>Cualquier persona que no acepte estos términos y condiciones generales y las políticas de privacidad, los cuales tienen un carácter obligatorio y vinculante, 
                        deberá abstenerse de utilizar el sitio y/o los servicios.</p>
                    <p>El Usuario debe leer, entender y aceptar todas las condiciones establecidas en los Términos y Condiciones Generales y en las Políticas de Privacidad de <strong>WHIPAY</strong>, 
                        así como en los demás documentos incorporados a los mismos por referencia, previo a su registro como Usuario en www.whipay.pe y/o a la adquisición de productos y/o 
                        entrega de cualquier dato con cualquier fin</p>
                </div>
                <div className={classes.index}>
                    <h2>Indice:</h2>
                    <ul>
                        <li> <a href="#section1">1. Capacidad legal</a> </li>
                        <li> <a href="#section2">2. Registro y Uso del Sitio </a></li>
                        <li> <a href="#section3">3. Modificaciones del acuerdo</a> </li>
                        <li> <a href="#section4">4. Procedimiento para hacer uso de este sitio de Internet</a> </li>
                        <li> <a href="#section5">5. Medios de pago que se podrán utilizar en el Sitio </a></li>
                        <li> <a href="#section6">6. Formación del consentimiento en los contratos celebrados a través de este Sitio</a></li>
                        <li> <a href="#section7">7. Plazo de validez de la oferta y precio</a> </li> 
                        <li> <a href="#section8">8. Promociones y Campañas</a></li>
                        <li> <a href="#section9">9. Despacho de los productos</a></li>
                        <li> <a href="#section10">10.Política de Devolución o Cambio por derecho de devolución</a></li>
                        <li> <a href="#section11">11.Reembolsos</a></li>
                        <li> <a href="#section12">12.Propiedad Intelectual</a></li>
                        <li> <a href="#section13">13.Propiedad Intelectual DE TERCEROS</a></li>
                        <li> <a href="#section14">14.Responsabilidad de WHIPAY</a> </li> 
                        <li> <a href="#section15">15.Indemnización</a></li>
                        <li> <a href="#section16">16.Términos de Ley</a></li>
                        <li> <a href="#section17">17.Notificaciones</a></li>
                        <li> <a href="#section18">18.Jurisdicción y Ley aplicable</a></li>
                    </ul>
                </div>
                <div className={classes.container3}>
                <section id="section1">
                <Typography
                 variant="h2"
                 component="h5"
                 gutterBottom
                 className={classes.subTitulos}
                 >
                    1. Capacidad legal
                 </Typography>
                 <p>
                 Los Servicios sólo están disponibles para personas que tengan capacidad legal para contratar. 
                 No podrán utilizar los servicios las personas que no tengan esa capacidad entre estos los menores de edad o Usuarios de 
                 https://www.whipay.pe que hayan sido suspendidos temporalmente o inhabilitados definitivamente. 
                 Los actos que los menores realicen en este sitio serán responsabilidad de sus padres, tutores, encargados o curadores, y -por tanto- se 
                 considerarán realizados por éstos en ejercicio de la representación legal con la que cuentan.
                 </p>
                 <p>
                 Quien registre un Usuario como empresa afirmará que (i) cuenta con capacidad para contratar en representación de tal entidad y 
                 de obligar a la misma en los términos de este Acuerdo, (ii) la dirección señalada en el registro es el domicilio principal de dicha entidad, y 
                 (iii) cualquier otra información presentada a <strong>WHIPAY</strong> será considerada verdadera, precisa, actualizada y completa
                 </p>
                 <p>
                 Así, cada Usuario -al momento del registro- deberá adjuntar los todos los documentos legales solicitados en el formulario de registro y/o en 
                 los demás documentos que el equipo de registro de <strong>WHIPAY</strong> solicite. Estos documentos adjuntos, se deberán mantener actualizados permanentemente 
                 por el Usuario, bajo su propia responsabilidad. Sin perjuicio de lo anterior, <strong>WHIPAY</strong> podrá solicitar o requerir al Usuario cualquier otra documentación 
                 que estime conveniente y en cualquier momento, incluso luego del registro del Usuario.
                 </p>
                 </section>
                 <section id="section2">
                 <Typography
                 variant="h2"
                 component="h5"
                 gutterBottom
                 className={classes.subTitulos}
                 >
                     2. Registro y Uso del Sitio
                </Typography>
                 <p>
                 El Usuario estará obligado a completar el formulario de registro en todos sus campos con datos válidos para que pueda convertirse en Usuario autorizado de https://www.whipay.pe, de esta manera, podrá acceder a los servicios ofrecidos en este sitio. El futuro Usuario deberá completar el formulario de registro con su información personal y/o de la sociedad, de manera exacta, precisa y verdadera ("Datos Personales") y asume el compromiso de actualizar los Datos Personales conforme resulte necesario. <strong>WHIPAY</strong> podrá utilizar diversos medios para identificar a los Miembros del Sitio, pero NO se responsabiliza ni se responsabilizará por la certeza de los Datos Personales provistos por sus Usuarios. Los Usuarios garantizan y responden, en cualquier caso, de la exactitud, veracidad, vigencia y autenticidad de los Datos Personales ingresados. En ese sentido, la declaración realizada por los Usuarios al momento de registrarse se entenderá como una Declaración Jurada.
                 </p>
                <p>
                Cada miembro sólo podrá ser titular de 1 (una) cuenta del Sitio, no pudiendo acceder a más de 1 (una) cuenta con distintas direcciones de correo electrónico o falseando, modificando y/o alterando sus datos personales de cualquier manera posible. En caso se detecte esta infracción, <strong>WHIPAY</strong> a través de https://www.whipay.pe o por correo electrónico comunicará al cliente informándole que todas sus cuentas serán agrupadas en una (01) sola cuenta anulándose todas sus demás o procederá con su inhabilitar permanentemente su calidad de usuario, según el criterio exclusivo de <strong>WHIPAY</strong>.
                 </p>
                 <p>
                 Si se verificara o sospechara algún uso fraudulento y/o malintencionado y/o contrario a estos Términos y Condiciones, y/o contrarios a la buena fe y en general a las normas aplicables, WHIPAY tendrá el derecho inapelable de dar de baja las cuentas y/o perfiles otorgados, incluso a accionar judicialmente o administrativamente contra los responsables.
                 </p>
                 <p>
                 <strong>WHIPAY</strong> podrá realizar controles que crea convenientes para verificar la veracidad de la información dada por el Usuario. En ese sentido, se reserva el derecho de solicitar algún comprobante y/o dato adicional a efectos de corroborar los Datos Personales, así como de suspender temporal o definitivamente a aquellos Usuarios cuyos datos no hayan podido ser confirmados. En estos casos de inhabilitación, WHIPAY podrá dar de baja la compra efectuada, sin que ello genere derecho alguno a resarcimiento, pago y/o indemnización alguna a favor del Usuario.
                 </p>
                 <p> 
                 El Usuario, una vez registrado, dispondrá de su dirección de email y una clave secreta (en adelante, la "Clave") que le permitirá el acceso personalizado, confidencial y seguro. Con estas credenciales, el Usuario tendrá la posibilidad de cambiar las claves de acceso, para lo cual deberá sujetarse al procedimiento establecido en el sitio respectivo. El Usuario se obliga a mantener la confidencialidad de las claves de acceso, asumiendo totalmente la responsabilidad por el mantenimiento de la confidencialidad de sus claves secretas otorgadas en este sitio web, la cual le permite efectuar transacciones y solicitar todos los demás servicios ofrecidos en la plataforma. Dichas claves son de uso personal, y su entrega a terceros no involucra responsabilidad de WHIPAY o de las empresas en caso de utilización indebida, negligente y/o incorrecta.
                 </p>  
                 <p>
                 El Usuario será responsable por todas las operaciones efectuadas en y desde su Cuenta, pues el acceso a las mismas está restringido al ingreso y uso de las claves secretas otorgadas, de conocimiento exclusivo del Usuario. El Usuario se compromete a notificar a <strong>WHIPAY</strong> en forma inmediata y por medio idóneo y fehaciente, respecto a cualquier uso no autorizado de su Cuenta y/o Clave, así como el ingreso por terceros no autorizados a la misma. Se aclara que está prohibida la venta, cesión, préstamo o transferencia de la Clave y/o Cuenta bajo ningún título.
                 </p>
                 </section>
                 <section id="section3">
                 <Typography
                 variant="h2"
                 component="h5"
                 gutterBottom
                 className={classes.subTitulos}
                 >
                     3. Modificaciones del acuerdo
                 </Typography>
                 <p>
                 WHIPAY podrá modificar los Términos y Condiciones Generales en cualquier momento, haciendo público en el Sitio los términos modificados. Todos los términos modificados entrarán en vigor a los 07 (siete) días hábiles después de su publicación. Dentro de los 05 (cinco) días hábiles siguientes a la publicación de las modificaciones introducidas, el Usuario se deberá comunicar por e-mail a la siguiente dirección: clientes@whipay.pe si no acepta las mismas; en ese caso quedará disuelto el vínculo contractual y será inhabilitado como Miembro durante las próximas cuarenta y ocho (48) horas hábiles. Vencido este plazo, se considerará que el Usuario acepta los nuevos términos y el contrato continuará vinculando a ambas partes.
                 </p>
                 </section>
                 <section id="section4">
                 <Typography
                 variant="h2"
                 component="h5"
                 gutterBottom
                 className={classes.subTitulos}
                 >
                     4. Procedimiento para hacer uso de este sitio de Internet
                 </Typography>
                 <p>
                 Para las transacciones efectuadas a través del Sitio, <strong>WHIPAY</strong> informará los pasos que deberán seguirse para celebrarlos, e informará, cuando corresponda, si el documento electrónico en que se formalice el contrato será archivado y si éste será accesible al Usuario. El sólo hecho de seguir los pasos que para tales efectos se indiquen en este sitio para efectuar una transacción, equivale a aceptar que efectivamente ha dado cumplimiento a las condiciones contenidas en este apartado. Indicará, además, su dirección fiscal o electrónico y los medios técnicos a disposición del Usuario para identificar y corregir errores en el envío o en sus datos.
                 </p>
                 </section>
                 <section id="section5">
                 <Typography
                 variant="h2"
                 component="h5"
                 gutterBottom
                 className={classes.subTitulos}
                 >
                     5. Medios de pago que se podrán utilizar en el Sitio
                 </Typography>
                 <p>
                 Los productos y servicios ofrecidos en este sitio, salvo que se señale una forma diferente de pago para casos particulares u ofertas de determinados bienes o servicios, podrán ser cancelados utilizando los siguientes medios de pago permitidos en este sitio:
                 </p>
                 <p> a.	Tarjetas de Crédito o Débito. La opción de pago con tarjetas de crédito o débito, está habilitada para tarjetas tipo VISA y MasterCard. Asimismo, los pagos con tarjeta de crédito o débito están sujetas a la aprobación del banco emisor de la tarjeta y a las validaciones internas de las pasarelas de pago encargadas de procesar la transacción. En los casos donde el pago no es aprobado o rechazado, la orden de compra asociada será cancelada de manera automática por <strong>WHIPAY</strong>.</p>
                 <p> Por otro lado, el Usuario podrá elegir la opción de pagar con su tarjeta de crédito en cuotas, siempre que el banco correspondiente lo permita. Sin embargo, es responsabilidad exclusiva del Usuario seleccionar de manera correcta las cuotas a pagar al momento de procesar el pago de su pedido, así como de verificar en la confirmación de compra que las cuotas elegidas son las correctas. </p>
                 <p> b.	Transferencias bancarias. El Usuario tendrá la opción de realizar sus pagos a través de transferencias bancarias a las cuentas de <strong>WHIPAY</strong>. <strong>WHIPAY</strong> verificará que los montos sean correctos, según el detalle del voucher que el Usuario cargue en el módulo correspondiente. El tiempo de revisión por parte de <strong>WHIPAY</strong> dependerá si la transferencia se realizó desde el banco BBVA o BCP, así como si la transferencia se realizó de manera inmediata o por horarios. <strong>WHIPAY</strong> no se responsabiliza por la demora en el procesamiento del pago por parte del banco, sea del banco de origen como del banco de destino. Sin perjuicio de lo anterior, en caso el Usuario realice un desembolso erróneo, <strong>WHIPAY</strong> procederá a anular la venta, sin que ello genere algún tipo de indemnización o algún pago por parte de <strong>WHIPAY</strong>.</p>
                 </section>
                 <section id="section6">
                 <Typography
                 variant="h2"
                 component="h5"
                 gutterBottom
                className={classes.subTitulos}
                 >
                    6. Formación del consentimiento en los contratos celebrados a través de este Sitio
                 </Typography>
                 <p>
                 A través de El Sitio, los Usuarios realizarán transacciones que podrán ser aceptadas a través de la aceptación expresa, a través de los mecanismos que el mismo sitio ofrece para ello. 
                 </p>
                 <p>
                 Aviso Legal: La venta es responsabilidad de cada uno de los Usuarios que se constituyan como oferentes. Los Usuarios oferentes serán responsables de la calidad de los productos vendidos.
                 </p>
                 </section>
                 <section id="section7">
                 <Typography
                 variant="h2"
                 component="h5"
                 gutterBottom
                 className={classes.subTitulos}
                 >
                    7.  Plazo de validez de la oferta y precio
                 </Typography>
                <p>
                El plazo de validez de la oferta es aquella que el Usuario Vendedor haya definido y que aparecerá en la publicación de El Sitio que culminará en virtud del agotamiento de las cantidades de los bienes disponibles para esa oferta debidamente informados al Usuario, o mientras la oferta se mantenga disponible, el menor de éstos plazos. Cuando no se indique una fecha de terminación de la publicación, se entenderá que el plazo de vigencia es indefinido o hasta acabar el stock, a partir de su publicación.
                 </p>
                 <p>
                 Los precios de los bienes disponibles en el Sitio, mientras aparezcan en él, solo tendrán vigencia y aplicación en éste y no serán aplicables a otros canales de venta utilizados por los Usuarios Vendedores, tales como puntos de venta físicas, venta telefónica, otros sitios de venta por vía electrónica u otros. Los precios de los bienes ofrecidos en el Sitio están expresados en soles peruanos, salvo que se manifieste lo contrario. Los precios ofrecidos corresponden exclusivamente al valor de los bienes ofrecidos y no incluyen gastos de transporte, manejo o envío.
                 </p>
                 <p>
                 Los Usuarios Vendedores podrán modificar cualquier información contenida en este Sitio, incluyendo las relacionadas con mercaderías, precios, existencias y condiciones, en cualquier momento y sin previo aviso, hasta el momento de recibir una aceptación de compra.
                </p>
                <p>
                Cuando un Usuario agrega uno o varios bienes al carrito de compras (en adelante, el “Carrito de Compras”), el precio se mantendrá por un tiempo de 30 minutos. Pasado dicho plazo, los precios de los bienes que se encuentren en el carrito, se actualizarán con el nuevo precio que el Usuario Vendedor haya consignado en una modificación posterior; esta última modificación se mantendrá por otros 30 minutos más y, luego, se repetirá nuevamente el proceso. Asimismo, el Usuario acepta y reconoce que si durante el tiempo entre que se agregue al carrito uno o varios bienes y el pago de los mismos, el stock de los bienes podrá haberse acabado o que se haya desactivado la publicación; en este caso, <strong>WHIPAY</strong> informará al Usuario lo ocurrido en la plataforma o, en caso se hubiera pagado pese a ello, <strong>WHIPAY</strong> podrá efectuar sin solicitud alguna o el Usuario solicitará a <strong>WHIPAY</strong> –lo que suceda primero- el extorno del monto indebidamente pagado a favor del Usuario.
                </p>
                 </section>
                 <section id="section8">
                 <Typography
                 variant="h2"
                 component="h5"
                 gutterBottom
                 className={classes.subTitulos}
                 >
                    8. Promociones y Campañas
                 </Typography>
                 <p>
                    El Sitio somete sus promociones y actividades promocionales al cumplimiento de las normas vigentes.
                 </p>
    
                 </section>
                 <section id="section9">
                 <Typography
                 variant="h2"
                 component="h5"
                 gutterBottom
                 className={classes.subTitulos}
                 >
                   9. Despacho de los productos
                 </Typography>
                 <p>
                 Los productos adquiridos a través del Sitio se sujetarán a las condiciones de despacho y entrega/direcciones elegidas por los Usuarios y disponibles en el Sitio. La información del lugar de envío es de exclusiva responsabilidad de los Usuarios participantes de cada transacción. En el caso que el Usuario haya seleccionado la opción de envío por delivery, los plazos para el despacho y entrega, se computan desde que El Sitio valida la orden de compra y el medio de pago utilizado, considerándose días hábiles para el cumplimiento de dicho plazo. Asimismo, El Sitio notificará a través de la plataforma a los Usuarios, los datos para que pueda realizar el seguimiento del estado del despacho.
                </p>
                 <p>Sobre la Conformidad del Paquete Recibido:</p>
                 <p>
                 Cuando el Usuario adquirente recibe los bienes, deberá revisar que la carga, peso y demás condiciones, sean las pactadas al momento de la compra. En caso de haber disconformidad en la compra, podrá (i) aceptar el pedido en su totalidad, (ii) aceptar el pedido de manera parcial o (iii) rechazar el pedido. Sin perjuicio de lo anterior, el Usuario adquirente podrá manifestar su disconformidad en El Sitio, bajo los canales respectivos. En caso que los productos fueran recibidos en las condiciones pactadas, el Usuario procederá a informar, a través del a plataforma, dejando así conformidad de la entrega. 
                 </p>
                 <p>
                 Al recibir la entrega de los bienes, y está conforme, el Usuario Adquirente procederá a confirmar la recepción de los bienes que se le habilitará en El Sitio Luego de la aceptación de los productos, el Usuario adquirente no podrá presentar reclamo alguno por cualquier defecto en los bienes o faltante del mismo, sólo se atenderán reclamos.
                 </p>
                 <p>
                 En caso de recibir una cantidad en exceso, es obligación y responsabilidad del Usuario informar de ello a <strong>WHIPAY</strong> para que el área de logística procederá a coordinar el recojo de los mismo. Asimismo, el Usuario acepta que <strong>WHIPAY</strong> no será responsable en caso exista algún tipo de pérdida, destrucción, siniestro o deterioro de los bienes, salvo que exista entre las partes se pacte previamente para cada pedido algún tipo de seguro que cubra los bienes por los eventos antes descritos.
                 </p>
                 </section>
                 <section id="section10">
                 <Typography
                 variant="h2"
                component="h5"
                 gutterBottom
                className={classes.subTitulos}
                 >
                   10. Política de Devolución o Cambio por derecho de devolución
                 </Typography>
                 <p>
                 Todos los Usuarios Vendedores que venden en https://www.whipay.pe están comprometidos a que el Usuario adquirente se encuentre completamente satisfecho durante toda su experiencia de compra. Sin embargo, somos conscientes de que en ciertas ocasiones es posible que se realicen devoluciones o rechazos en los bienes comercializados. En ese sentido, los Usuarios Vendedores tienen la responsabilidad exclusiva de cumplir con la calidad y cantidad ofrecidos. Asimismo, es responsabilidad de cada Usuario adquirente, revisar las calidades y cantidades ofrecidas antes de realizar la compra de bienes ofertados, al momento de la entrega.
                 </p>
                <p>Las solicitudes de cambios y devoluciones serán recibidas y atendidas por nuestro equipo de Atención al Cliente, que tiene como principal objetivo garantizar una respuesta rápida y oportuna, velando en todo momento que los Usuarios Vendedores cumplan con entregar un óptimo servicio de pre y post venta.</p>
                 </section>
                 <section id="section11">
                 <Typography
                 variant="h2"
                 component="h5"
                 gutterBottom
                 className={classes.subTitulos}
                 >
                   11.  Reembolsos
                 </Typography>
                 <p>Luego que el reembolso es aprobado y ejecutado, el tiempo de procesamiento varía según el método de pago usado.</p>
                 <p>Para una compra con tarjeta de crédito, débito o métodos que permitan la devolución del dinero a través de una cuenta asociada, se hará el reverso a la tarjeta o a la cuenta asociada.</p>
                 <p>Para métodos por depósito bancario o pagos en efectivo, se hará un depósito bancario.</p>
                 <p>Tiempos de procesamiento:</p>
                 <p>
                 
                 <li>Reverso a la tarjeta: El tiempo del reembolso a una tarjeta puede ser hasta quince (15) días hábiles. El tiempo de procesamiento es responsabilidad de la entidad financiera que emitió la tarjeta y es contado desde la ejecución del reembolso.</li>
                 
                 
                 <li>Depósito bancario: Para recibir el dinero en una cuenta bancaria, el titular de la cuenta debe ser el mismo que realizó la compra en https://www.whipay.pe. El tiempo de procesamiento es de siete (7) días hábiles desde su ejecución. La información bancaria proporcionada por el cliente debe ser correcta para evitar retrasos en la atención. De no ser así los tiempos de ejecución y procesamiento se prolongarán.</li>
                 </p>
                 <p>
                 Los datos necesarios son:
                 </p>
                 <p>
                     <li>Razón social/Denominación social</li>
                     <li>RUC</li>
                     <li>Nombre y apellido del representante (si fuera el caso)</li>
                     <li>DNI, Carnet de extranjería o Pasaporte del representante</li>
                     <li>Número de pedido</li>
                     <li>Correo electrónico registrado en https://www.whipay.pe</li>
                     <li>Datos de la cuenta bancaria</li>
                </p>
                 </section>
                 <section id="section12">
                 <Typography
                 variant="h2"
                 component="h5"
                 gutterBottom
                 className={classes.subTitulos}
                 >
                   12.  Propiedad Intelectual
                 </Typography>
                 <p>
                 Todo el contenido incluido o puesto a disposición del Usuario en el Sitio, incluyendo textos, gráficas, logos, íconos, imágenes, archivos de audio, descargas digitales y cualquier otra información (el "Contenido"), es de propiedad de <strong>WHIPAY</strong> o ha sido licenciada a ésta por los Usuarios oferente y adquirentes, en virtud del presente documento. La compilación del Contenido es propiedad exclusiva de <strong>WHIPAY</strong> y, en tal sentido, el Usuario debe abstenerse de extraer y/o reutilizar partes del Contenido sin el consentimiento previo y expreso de la Empresa.
                 </p>
                 <p>
                 Además del Contenido, las marcas, denominativas o figurativas, marcas de servicio, diseños industriales y cualquier otro elemento de propiedad intelectual que haga parte del Contenido (la "Propiedad Industrial"), son de propiedad de <strong>WHIPAY</strong> o de las Usuarios oferentes y, por tal razón, están protegidas por las leyes y los tratados internacionales de derecho de autor, marcas, patentes, modelos y diseños industriales. El uso indebido y la reproducción total o parcial de dichos contenidos quedan prohibidos, salvo autorización expresa y por escrito de <strong>WHIPAY</strong>, asimismo, no pueden ser usadas por los Usuarios en conexión con cualquier producto o servicio que no sea provisto por <strong>WHIPAY</strong>. En el mismo sentido, la Propiedad Industrial no podrá ser usada por los Usuarios en conexión con cualquier producto y servicio que no sea de aquellos que comercializan dentro del Sitio o de forma que produzca confusión.
                 </p>
                 </section>
                 <section id="section13">
                 <Typography
                 variant="h2"
                 component="h5"
                 gutterBottom
                 className={classes.subTitulos}
                 >
                   13.  Propiedad Intelectual DE TERCEROS
                 </Typography>
                 <p>
                 <strong>WHIPAY</strong> es una empresa respetuosa de las leyes y no pretende aprovecharse de la reputación de terceros, apropiándose de la propiedad intelectual por ellos protegida. Teniendo en cuenta lo anterior, si usted sospecha que algún Usuario se encuentra en nuestra página infringe derecho de propiedad intelectual de terceros o infringe derechos legalmente protegido por usted, agradecemos notificárnoslo para iniciar una investigación inmediatamente y, de ser el caso, iniciar todas las acciones tendientes a evitar que esto siga sucediendo.
                 </p>
                 </section>
                 <section id="section14">
                 <Typography
                 variant="h2"
                 component="h5"
                 gutterBottom
                 className={classes.subTitulos}
                 >
                   14.  Responsabilidad de WHIPAY 
                 </Typography>
                 <p>
                 <strong>WHIPAY</strong> hará lo posible dentro de sus capacidades para que la transmisión del Sitio sea ininterrumpida y libre de errores. Sin embargo, dada la naturaleza de la Internet, dichas condiciones no pueden ser garantizadas. En el mismo sentido, el acceso del Usuario a la Cuenta y/o a los perfiles otorgados puede ser ocasionalmente restringido o suspendido con el objeto de efectuar reparaciones, mantenimiento o introducir nuevos Servicios. <strong>WHIPAY</strong> no será responsable por pérdidas (i) que no hayan sido causadas por el incumplimiento de sus obligaciones; (ii) lucro cesante o pérdidas de oportunidades comerciales; y/o (iii) cualquier daño indirecto.
                 </p>
                 </section>
                 <section id="section15">
                 <Typography
                 variant="h2"
                 component="h5"
                 gutterBottom
                 className={classes.subTitulos}
                 >
                   15.  Indemnización
                 </Typography>
                 <p>
                 El Usuario indemnizará y mantendrá indemne a <strong>WHIPAY</strong>, sus filiales, empresas controladas y/o controlantes, directivos, administradores, representantes y empleados, por su incumplimiento en los Términos y Condiciones Generales y demás Políticas que se entienden incorporadas al presente o por la violación de cualesquiera leyes o derechos de terceros, incluyendo los honorarios de abogados en una cantidad razonable.
                 </p>
                 </section>
                 <section id="section16">
                 <Typography
                 variant="h2"
                 component="h5"
                 gutterBottom
                 className={classes.subTitulos}
                 >
                   16.    Términos de Ley
                 </Typography>
                 <p>
                 Este acuerdo será gobernado e interpretado de acuerdo con las leyes de Perú, sin dar efecto a cualquier principio de conflictos de ley. Si alguna disposición de estos Términos y Condiciones es declarada ilegal, o presenta un vacío, o por cualquier razón resulta inaplicable, la misma deberá ser interpretada dentro del marco del mismo y en cualquier caso no afectará la validez y la aplicabilidad de las provisiones restantes.
                 </p>
                 </section>
                 <section id="section17">
                 <Typography
                 variant="h2"
                 component="h5"
                 gutterBottom
                 className={classes.subTitulos}
                 >
                   17. Notificaciones
                 </Typography>
                 <p>
                 Cualquier comentario, inquietud o reclamación respecto de los anteriores Términos y Condiciones, la Política de Privacidad, o la ejecución de cualquiera de éstos, podrá ser efectuada a través de El Sitio o por escrito a clientes@whipay.pe.
                 </p>
                 </section>
                    <section id="section18">
                 <Typography
                 variant="h2"
                 component="h5"
                 gutterBottom
                 className={classes.subTitulos}
                 >
                  18. Jurisdicción y Ley aplicable
                </Typography>
                 <p>
                 Este acuerdo estará regido en todos sus puntos por las leyes vigentes en la República del Perú. Cualquier controversia derivada del presente acuerdo, su existencia, validez, interpretación, alcance o cumplimiento, será sometida a los tribunales competentes de la ciudad de Lima, Perú.
                 </p>
                 </section>
                 </div>
            </Paper>
        </LayoutWrapper>
    );
}
