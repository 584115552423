import { makeStyles } from "@material-ui/core/styles";

export const PaymentGatewayStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontSize: "20px",
    color: "#808285",
  },
  addToCatrPopup: {
    "& .MuiDialog-paper": {
      borderRadius: "25px",
      padding: "25px 25px",
      width: "960px",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  divider: {
    borderBottom: "1px solid #e0e0e0",
  },
  paymentForm: {
    margin: "1rem 0",
    display:"flex",
    flexDirection:"column",
    alignItems:"center"
  },
  marginUpDown: {
    margin: "1rem 0",
    justifyContent: "space-between",
    "& > span": {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "19px",
      color: "#808285",
    },
  },
  countDownTimer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  height: {
    minHeight: "300px",
  },
  icon: {
    color: "#53cd94",
  },
  errorLabel: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.59px",
    display: "flex",
    marginTop: "10px",
    minHeight: "16px",
  },
  errorColor: {
    color: "red",
  },
  container:{
    display:"flex",
    justifyContent:"center",
},
logo: {
  width: '200px',
  marginBottom: '5px',
  marginTop: '25px',
},
}));
