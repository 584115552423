import React, { useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { offerMiniSlideStyles } from "./styles";

export default function SlideChart(props) {
  const classes = offerMiniSlideStyles();

  const [chart] = useState({
    time: {
      useUTC: true,
      timezoneOffset: 300, //UTC-5:00 time zone
    },
    credits: {
      enabled: false,
    },
    // width: 350,
    // height: 300,
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
      series: {
        color: "#F2994A",
      },
    },
    chart: {
      type: "spline",
      width: 290,
      height: (9 / 16 * 100) + '%',
      events: {
        load: function () {
          // set up the updating of the chart each second
          /*var series = this.series[0];
                    setInterval(function () {
                      var x = (new Date()).getTime(), // current time
                          y = Math.random();
                      series.addPoint([x, y], true, true);
                    }, 1000);*/
        },
      },
    },
    accessibility: {
      announceNewData: {
        enabled: true,
        minAnnounceInterval: 15000,
        announcementFormatter: function (allSeries, newSeries, newPoint) {
          if (newPoint) {
            return "New point added. Value: " + newPoint.y;
          }
          return false;
        },
      },
    },

    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      tickPixelInterval: 24 * 3600 * 1000,
    },

    yAxis: {
      title: {
        enabled: false,
        text: "Value",
      },
      plotLines: [
        {
          enabled: false,
          value: 0,
          width: 1,
          color: "#808080",
        },
      ],
    },

    tooltip: {
      // enabled: false,
      headerFormat: "<b>{series.name}</b><br/>",
      pointFormat: "{point.x:%d-%m-%y - %H:%M:%S}<br/>{point.y:.2f}",
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: "Indicadores",
        data: props.data.data,
      },
    ],
    pointInterval: 3600 * 1000,

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: "100%",
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  });



  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={chart}
        className={classes.ChartWrap}
      />
    </>
  );
}
