import { Step, StepLabel, Stepper, withStyles} from "@material-ui/core";
import { stepperStyles } from './styles';

const StyledStepLabel = withStyles({
    /*iconContainer: {
        paddingRight: 0,
        marginRight: '8px',
        borderRadius: '50px',
        border: '3px solid #808285',
        '& MuiStepIcon-active':{
            border: '3px solid #53CD94',
        },
        "& > svg": {
            // color: 'transparent !important',
            '& > text': {
                // fill: '#53CD94',
                // fontSize: '16px',
                fontWeight: 700
            }
        }
    },
    active: {
        '& $iconContainer':{
            border: '3px solid #53CD94',
        }
    },
    labelContainer: {
        color: 'transparent'
    },
    disabledIconContainer: {
        border: '3px solid #53CD94',
    },
    disabled: {
        iconContainer: {
            border: '3px solid #53CD94',
        },
        "& > svg": {
            color: 'transparent !important',
            '& > text': {
                fill: '#53CD94',
                // fontSize: '16px',
                fontWeight: 700
            }
        }
    }*/
})(StepLabel);

const StyledStepper = withStyles( ( theme ) => ({
    horizontal: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row !important',
            '& > ': {
                width: '100%',
                display: 'block',
                
            }
        }
    }
}))(Stepper);

const StyledStep = withStyles( ( theme ) => ({
    horizontal: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'block',
        }
    }
}))(Step);

export default function AppStepper ( props ) {

    const classes = stepperStyles();

    return (
        <>
            <StyledStepper activeStep={props.stepNumber} className={classes.stepper}>
                <StyledStep>
                    <StyledStepLabel>{'Carrito de compras'}</StyledStepLabel>
                </StyledStep>
                <StyledStep>
                    <StyledStepLabel>{'Envío'}</StyledStepLabel>
                </StyledStep>
                <StyledStep>
                    <StyledStepLabel>{'Pago'}</StyledStepLabel>
                </StyledStep>
            </StyledStepper>
        </>
    )
}