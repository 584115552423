import Carousel from 'react-material-ui-carousel';
import React from "react";
import VariationSlide from "./variationSlide";
import {withStyles} from "@material-ui/core";

const StyledCarousel = withStyles({
    prev: {
        right: '50px !important',
        left: 'auto !important'
    }
})(Carousel);

// Renders each variation from traded_goods_index
export default function VariationList ( props ) {

    return (
        <StyledCarousel
            autoPlay={false}
            animation={'slide'}
            navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                style: {
                    backgroundColor: '#FFF',
                    border: '1px solid #E0E0E0',
                    color: '#53CD94',
                    borderRadius: '4px',
                    height: '24px',
                    width: '24px',
                    '&:hover': {
                        border: '1px solid #53CD94 !important',
                        backgroundColor: '#53CD94 !important',
                        color: '#FFF !important'
                    }
                }
            }}
            navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                style: {
                    top: '30px',
                    right: '12px',
                    height: '24px',
                    width: '24px'
                }
            }}
            indicatorContainerProps={{
                style: {
                    textAlign: 'right',
                    position: 'absolute',
                    marginTop: '0px',
                    top: '0px'
                }
            }}
            activeIndicatorIconButtonProps={{
                style: {
                    color: '#53CD94' // 2
                }
            }}
        >
            {
                props._variations.map( ( variation, index ) => (
                    <VariationSlide _variation={variation} key={index} {...props} />
                ))
            }
        </StyledCarousel>
    )

}