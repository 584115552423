import { makeStyles } from "@material-ui/core/styles";

export const mySalesStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      display: "flow-root",
    },
  },
  pageTitle: {
    color: "#53CD94",
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "38px",
    alignItems: "baseline",
    flexGrow: 1,
  },
  button: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "14px",
    height: "32px",
    width: "189px",
    background: "#F2994A",
    borderColor: "#4BB985",
    boxShadow: "none",
    borderRadius: "8px",
    "&:hover": {
      background: "#e0e0e0",
      boxShadow: "none",
      color:"#333"
    },
  },
  paper: {
    padding: "35px 55px",
    borderRadius: "12px",
    color: "#53CD94",
    boxShadow: "none",
    width: "100%",
    marginBottom: "30px",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "25px",
    },
  },
  formGroupContainer:{
    display:"flex",
    alignContent:"center",
    margin:"0 0 0 30px",
    [theme.breakpoints.down("sm")]: {
      margin:"0",
      flexDirection:"row",
      justifyContent:"space-between"
    },
  },
  listProducts:{
    flexDirection:"row",
    flexWrap:"wrap",
    [theme.breakpoints.down("sm")]: {
      flexDirection:"column-reverse",
    },
  },
  state__circularProgress: {
    display:"none",

    [theme.breakpoints.down("sm")]: {
      display:"block"  ,
      borderRadius:"50%",
      border:"1px solid #53cd94",
      padding:"0.2rem",
      margin:"0 0.5rem"
    },
  },
  listProducts__circularPogress: {
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    [theme.breakpoints.down("sm")]: {
      display:"none"
    },
  },

  sale__item:{
    paddingBottom:"1rem",
    borderBottom:"1px solid #e0e0e0",
  },
  sale__status: {
    display: "flex",
    justifyContent:"space-between",
    color:"#333333",
    [theme.breakpoints.down("xs")]: {
      fontSize:"16px",
      flexDirection:"column"
    },
  },

  sale__flex:{
    display:"flex",
    "& span":{
      color:"#808285",
      fontSize:"12px",
      fontWeight:"lighter",
    },
  },
  sale__link: {
    fontSize: "12px",
    color: "#53CD94",
    lineHeight: "18.96px",
    fontWeight: "normal",
    margin: "0.05rem",
    cursor: "pointer",
    "&:hover": {
        color:"#333"
    }
  },

  sale__title: {
    fontSize: "14px",
    fontWeight: "400",
  },
  sale__leftTitle:{
    fontSize: "14px",
    fontWeight: "400",
    color:"#53CD94",
    textTransform:"uppercase",
  },
  sale__date: {
    color: "#808285",
    fontSize: "12px",
    paddingTop:"10px",
  },
  sale__voucher:{
    fontSize: "10px",
    color: "#f01919",
    justifyContent:"space-end",
    float: "right",
    [theme.breakpoints.down("xs")]: {
      float: "left",
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  popover__text:{
    fontSize:"12px"
  },
  popover__paper: {
    padding: theme.spacing(1),
    width:'200px',
    color:"#808285",
  },
  products: {
    [theme.breakpoints.down("xs")]: {
      " & > div":{
        borderBottom:"0.5px dashed #e0e0e0"
      },
      " & > div:last-child":{
        borderBottom:"none"
      }
    },

  },
  product:{
    flexDirection:"row",
    padding:"0.5rem 0",
  },
  product__row:{
    display:"flex",
    flexDirection:"column",
    width:"16.66%",
    alignSelf:"center",
    alignItems:"center",
    // [theme.breakpoints.down("xs")]: {
    //   width:"33%",
    // },
    [theme.breakpoints.down("xs")]: {
      width:"50%",
      margin:"0.1rem 0"
    },
  },
  product__image: {
    width:"80px",
    margin:"auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80px",
      maxHeight: "80px",
    },
  },

  product__title: {
    maxWidth: "100px",
    fontSize: "16px",
    color: "#000000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  product__grade: {
    textAlign: "center",
    width:"100px",
    borderRadius: "3px",
    lineHeight: "25px",
    alignItems: "center",
    textTransform: "uppercase",
    fontSize: "10px",
    color: "rgb(83, 205, 148)",
    background: "rgb(221, 245, 234)",
    margin:"auto",
  },
  product__textBold: {
    fontSize: "16px",
    color: "#000000",
    textAlign: "center",
  },

  product__textSpan: {
    color:"#808285",
    fontSize:"12px",
    fontWeight:400,
    textAlign:"center",
    margin: "auto 1rem",
  },
}));
